'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';

class InlineBlock extends  React.Component {
    render() {
        return <div className={`InlineBlock ${this.props.className}`} style={{'display':'inline-block'}}>{this.props.children}</div>;
    }
}

InlineBlock.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
};

export { InlineBlock };
