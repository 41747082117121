'use strict';

import { TPError } from '@totalpave/error';
import { ObjectUtils } from './ObjectUtils';
import { ApplicationInstance } from '@totalpave/application-instance';

/*
    REQUIRED:
    array - Array to loop through. Must have length greater than 0.
    chunkSize - Int to represent the number of array elements to include in each chunk. 
    chunkFn - Function that takes in an Array. Function must return an resolving or rejecting promise. The Array passed in will be a chunk from array.
                WARNING: If promise does not resolve; then, ChunkLoader will lock up. Be absolutely certain that chunkFn will always resolve or reject.

    OPTIONAL:
    delay - DEPRECATED PROP -    Int to represent time in milliseconds. Will be used in a window.setTimeout just before starting a new iteration of chunk.
    onFailProtocal - Enum (See ChunkLoader.OnFailProtocal). Tells ChunkLoader what to do when chunkFn rejects. Defaults to ChunkLoader.OnFailProtocal.CONTINUE_ON_FAIL.
*/
class ChunkLoader {
    constructor(array, /*delay,*/ chunkSize, chunkFn, onFailProtocal) {
        if (!(array instanceof Array) || ObjectUtils.isVoid(array)) {
            throw new Error("array must be defined.");
        }

        if (!chunkFn || typeof chunkFn !== "function") {
            throw new Error("chunkFn must be defined.");
        }

        if (ObjectUtils.isVoid(chunkSize) || typeof chunkSize !== "number" || chunkSize < 1) {
            throw new Error("chunkSize must be greater than 0.");
        }


        this.array = array.slice();
        // this.delay = (delay < 0 ? 0 : delay);
        this.chunkSize = Math.round(chunkSize); //Don't rely on this and pass in floats. This is purely to enforce integer values. Seriously just pass an int in.
        this.chunkFn = chunkFn;
        this.onFailProtocal = (ObjectUtils.isVoid(onFailProtocal) ? ChunkLoader.OnFailProtocal.CONTINUE_ON_FAIL : onFailProtocal);
        this._current;

        this.isRunning = false;
        this._shouldStop = false;

        this._app = ApplicationInstance.getInstance();
    }

    //Can use this to check if the ChunkLoader has been pre-maturely stopped in the middle of a chunk.
    //This check can be required; because, stopping ChunkLoader only prevents chunks from starting. It is not possible to stop a chunk once it is started from ChunkLoader.
    shouldStop() {
        return this._shouldStop;
    }

    //Updates array.
    setArray(array) {
        if (this.isRunning) {
            throw new Error("Can not change array while ChunkLoader is running.");
        }
        if (!(array instanceof Array) || ObjectUtils.isVoid(array)) {
            throw new Error("array must be defined.");
        }
        this.array = array.slice();
    }

    _chunk() {
        this.isRunning = true;
        return new Promise((resolve, reject) => {
            let duration = null;

            // setup executor
            let executor = async () => {
                // run user's function
                this.chunkFn(this.array.splice(0, this.chunkSize)).then(() => {
                    let startTime = this._app.getDateFactory().create().getTime();
                    // are there items to process and should I continue running?
                    if (this.array.length > 0 && !this._shouldStop) {
                        // if we are getting close to spending 60ms or have gone over it; then, stop running and request an new animation frame.
                        duration = (this._app.getDateFactory().create().getTime() - startTime);
                        if (duration < 16) {
                            executor();
                        }
                        else {
                            if (duration > 16) {
                                this._app.getLogger().warn(`ChunkLoader iteration took longer than 16ms. This can cause UI lag. Duration: ${duration}`);
                            }
                            window.requestAnimationFrame(executor)
                        }
                    }
                    else {
                        this.isRunning = false;
                        resolve(this._shouldStop); // resolve value for "gracefullyInturrepted"
                    }
                }).catch((error) => {
                    let tpError;
                    if (error instanceof TPError){
                        tpError = error; 
                    }
                    else {
                        tpError = new TPError({
                            message: "Chunk in ChunkLoader rejected",
                            details: {
                                error: error
                            }
                        });
                    }

                    // if protocal says continue, go to next chunk
                    if (this.onFailProtocal === ChunkLoader.OnFailProtocal.CONTINUE_ON_FAIL) {
                        executor();
                    }
                    else { // else stop processing chunks
                        this.isRunning = false;
                        reject(tpError)
                    }
                });
            };

            //start executor loop
            window.requestAnimationFrame(executor);
        });
        // this.isRunning = true;
        // return new Promise((resolve, reject) => {
        //     let frame;
        //     new Promise((resolve, reject) => {
        //         let chunk = this.array.splice(0, this.chunkSize);

        //         //using "go" instead of "continue" due to keywords...
        //         let go = () => {
        //             if (this.array.length > 0 && !this._shouldStop) {
        //                 window.setTimeout(() => {
        //                     frame = window.requestAnimationFrame(() => {
        //                         this._chunk().then(resolve).catch(reject);
        //                     });
        //                 }, this.delay);
        //             }
        //             else {
        //                 window.cancelAnimationFrame(frame);
        //                 resolve(this._shouldStop);
        //             }
        //         };

        //         this.chunkFn(chunk).then(() => {
        //             go();
        //         }).catch((error) => {
        //             let tpError;
        //             if (error instanceof TPError){
        //                 tpError = error; 
        //             }
        //             else {
        //                 tpError = new TPError({
        //                     message: "Chunk in ChunkLoader rejected",
        //                     details: {
        //                         error: error
        //                     }
        //                 });
        //             }
        //             if (this.onFailProtocal === ChunkLoader.OnFailProtocal.CONTINUE_ON_FAIL) {
        //                 go();
        //             }
        //             else { //stop
        //                 reject(tpError)
        //             }
        //         });
        //     }).then((gracefullyInturrepted) => {
        //         //window.cancelAnimationFrame(frame);
        //         this.isRunning = false;
        //         resolve(gracefullyInturrepted);
        //     }).catch((error) => {
        //         window.cancelAnimationFrame(frame);
        //         this.isRunning = false;
        //         reject(error);
        //     });
        // });
    }

    start() {
        this._shouldStop = false;
        this._current = this._chunk();
        return this._current;
    }

    /*
        Pre-maturely stops ChunkLoader at the start of the next chunk.
        ChunkLoader should successfully resolve.
    */
    stop() {
        if (!this.isRunning) {
            return Promise.resolve();
        }
        this._shouldStop = true;
        return new Promise((resolve) => {
            //using resolve in both then and catch is NOT typo. The purpose of returning a promise in stop is to purely to indicate when it is done stopping. 
            this._current.then(resolve).catch(resolve);
        });
    }
}


ChunkLoader.OnFailProtocal = {
    //Same concept as the continue and break keywords.
    CONTINUE_ON_FAIL: 0, 
    BREAK_ON_FAIL: 1 
}

export { ChunkLoader };
