'use strict';

import React from 'react';
import {AbstractEditorFactory} from './AbstractEditorFactory';
import {Textfield, InputMode} from '../components/Textfield';
import {Checkbox} from '../components/Checkbox';
import {DatePicker} from '../components/DatePicker';
import {Select} from '../components/Select';
import {ApplicationInstance} from '@totalpave/application-instance';
import {DateUtils} from '../utils/DateUtils';
import {DataType} from '@totalpave/interfaces';
import {TableNumberType} from '../utils/TableNumberType';
import {TextfieldFactory} from '../factories/TextfieldFactory';
import {CurrencyTextfieldPropsBuilder} from '../builders/CurrencyTextfieldPropsBuilder';
import { ObjectUtils } from '../utils/ObjectUtils';
import BigNumber from 'bignumber.js';

class TableEditorFactory extends AbstractEditorFactory {
    constructor() {
        super();

        this._textfieldFactory = new TextfieldFactory();
        this._currencyPropsBuilder = new CurrencyTextfieldPropsBuilder();
    }

    create(type, value, onChangeCallback, additionalOptions, data, key) {
        if (additionalOptions === undefined) {
            additionalOptions = {};
        }
        switch (type) {
            case TableEditorFactory.BOOLEAN:
                return this._renderBoolean(value, onChangeCallback, additionalOptions, data, key);
            case TableEditorFactory.NUMBER:
                if (value === null || value === undefined) {
                    value = '';
                }
                return this._renderNumber(value, onChangeCallback, additionalOptions, data, key);
            case TableEditorFactory.STRING:
                if (value === null || value === undefined) {
                    value = '';
                }
                return this._renderString(value, onChangeCallback, additionalOptions, data, key);
            case TableEditorFactory.DATE:
                if (typeof value === 'string') {
                    value = ApplicationInstance.getInstance().getDateFactory().create(value);
                }

                if (!DateUtils.isValid(value)) {
                    value = ApplicationInstance.getInstance().getDateFactory().create();
                }

                return this._renderDate(value, onChangeCallback, additionalOptions, data, key);
            case TableEditorFactory.SELECT:
                if (typeof additionalOptions.options === 'function') {
                    additionalOptions.options = additionalOptions.options(value, data);
                }

                return this._renderSelect(value, onChangeCallback, additionalOptions, data, key);
            case TableEditorFactory.CUSTOM:
                if (typeof additionalOptions.options === 'function') {
                    additionalOptions.options = additionalOptions.options(value, data);
                }

                return this._renderCustom(value, onChangeCallback, additionalOptions, data, key);
            default:
                throw new Error(`Unsupported editor type ${type}`);
        }
    }

    _renderBoolean(value, onChangeCallback, additionalOptions, data, key) {
        return <Checkbox key={key} value={value} onChange={onChangeCallback} />;
    }

    _renderNumber(value, onChangeCallback, additionalOptions, data, key) {
        if (additionalOptions.numberType === TableNumberType.CURRENCY) {
            return this._textfieldFactory.create(
                this._currencyPropsBuilder
                    .setValue(value)
                    .setPlaceholder(additionalOptions.placeholder)
                    .setAutoSelect(true)
                    .setKey(key)
                    .setOnChange(onChangeCallback)
                    .build()
            );
        }
        else {
            return <Textfield 
                key={key}
                type={InputMode.TEXT} 
                value={value} 
                autoSelect
                onChange={(newValue) => {
                    if (onChangeCallback) {
                        if (ObjectUtils.isVoid(newValue) || (newValue instanceof BigNumber && isNaN(newValue.toNumber()))) {
                            newValue = '';
                        }
        
                        // Prevent more than one decimal character
                        if (newValue.indexOf('.') > -1 && newValue.indexOf('.') !== newValue.lastIndexOf('.')) {
                            newValue = value;
                        }
        
                        // Only parse float if we have a value, and if the last character is not a decimal point, (which we would expect more incoming digits)
                        if (newValue !== '' && 
                            newValue[newValue.length - 1] !== '.' &&
                            (newValue[0] === '-' && newValue.length > 1 || newValue[0] !== undefined)) {
                            newValue = parseFloat(newValue);
        
                            // If the value produces an invalid number, revert back to the old value, in other words, deny entry.
                            if (isNaN(newValue)) {
                                newValue = value;
                            }
                        }
        
                        onChangeCallback(newValue);
                    }
                }}
                placeholder={additionalOptions.placeholder}
            />;
        }
    }

    _renderString(value, onChangeCallback, additionalOptions, data, key) {
        return <Textfield 
            key={key}
            type={InputMode.TEXT} 
            value={value} 
            autoSelect
            onChange={onChangeCallback} 
            placeholder={additionalOptions.placeholder}
        />;
    }

    _renderDate(value, onChangeCallback, additionalOptions, data, key) {
        return <DatePicker key={key} value={value} onChange={onChangeCallback} useDetachedCalendar />;
    }

    _renderCustom(value, onChangeCallback, additionalOptions, data, key) {
        return value;
    }

    _renderSelect(value, onChangeCallback, additionalOptions, data, key) {
        return (
            <Select 
                key={key}
                value={this._getSelectedValueFromOptions(additionalOptions.options, value)} 
                onChange={(value) => {
                    onChangeCallback(value.getValue());
                }}
                options={additionalOptions.options} 
                nullOptionText={additionalOptions.nullOptionText}
                allowSelectNullOption={additionalOptions.allowSelectNullOption}
                scrollable={additionalOptions.scrollable}
                width={additionalOptions.width}
                menuWidth={additionalOptions.menuWidth}
                menuAlignment={additionalOptions.menuAlignment}
            />
        );
    }

    _getSelectedValueFromOptions(options, value) {
        let selectedValue = null;

        for (let i = 0; i < options.length; i++) {
            let opt = options[i];
            if (opt.getValue() === value) {
                selectedValue = opt;
                break;
            }
        }

        return selectedValue;
    }
}

/**
 * @deprecated Use `DataType` directly
 */
TableEditorFactory.STRING = DataType.STRING;

/**
 * @deprecated Use `DataType` directly
 */
TableEditorFactory.NUMBER = DataType.NUMBER;

/**
 * @deprecated Use `DataType` directly
 */
TableEditorFactory.SELECT = DataType.SELECT;

/**
 * @deprecated Use `DataType` directly
 */
TableEditorFactory.BOOLEAN = DataType.BOOLEAN;

/**
 * @deprecated Use `DataType` directly
 */
TableEditorFactory.DATE = DataType.DATE;

/**
 * @deprecated Use `DataType` directly
 */
TableEditorFactory.LIST = DataType.LIST;

/**
 * @deprecated Use `DataType` directly
 */
TableEditorFactory.CUSTOM = DataType.CUSTOM;

export { TableEditorFactory };
