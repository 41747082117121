'use strict';

/**
 * @deprecated - Use `@totalpave/interfaces` IFormatter interface instead.
 */
export class Formatter {
    // eslint-disable-next-line
    format(value) {
        throw new Error('Formatter.format is abstract.');
    }
}
