'use strict';

import * as React from 'react';

class Spinner extends React.Component {
    render() {
        return <span className="Spinner fa fa-pulse fa-spinner"></span>;
    }
}

export {Spinner};
