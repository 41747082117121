'use strict';

import {Action} from './Action';
import {AuthClient} from '../net/AuthClient';

class RenewTokenActionSingleton extends Action {
    getTag() {
        return 'renew_token';
    }

    _shouldDispatchOnSuccess() {
        // Renew token will trigger an access token update, so we don't actually
        // need to dispatch here
        return false;
    }

    _shouldDispatchOnError() {
        return false;
    }

    _execute() {
        return new Promise((resolve) => {
            AuthClient.getInstance().renewToken();
            resolve();
        });
    }
}

const RenewTokenAction = new RenewTokenActionSingleton();

export { RenewTokenAction };
