'use strict';

import {StorageStrategy} from './StorageStrategy';

class WebStorageStrategy extends StorageStrategy {
    constructor() {
        super();
        // Commented out persist beacuse it's actually not needed anymore if just we store the storage key. We might need it later on though.
        // this._persist = window[this._getStorageKey(true)].webStorageStrategy;
        // this._storage = this._persist ? "localStorage" : "sessionStorage";
        this._storage = window.localStorage.webStorageStrategy ? "localStorage" : "sessionStorage";
        this._ensureExistence();
    }

    setPersist(persist) {
        // this._persist = persist;
        // this._storage = this._persist ? "localStorage" : "sessionStorage";
        this._storage = persist ? "localStorage" : "sessionStorage";
        this._ensureExistence();

        if (!persist) {
            delete window.localStorage.webStorageStrategy;
        }
    }

    async save(dictionary) {
        // Apparently ie11 doesn't let us store the storage in an variable, so let's
        // store the key that repesents the storage on the window object in a variable.
        let obj = this._getStorage();
        for (let key in dictionary) {
            obj[key] = dictionary[key];
        }
        this._save(obj);
    }

    get(key) {
        return this._getStorage()[key] !== undefined ? this._getStorage()[key] : null;
    }

    async delete(key) {
        let obj = this._getStorage();
        delete obj[key];
        this._save(obj);
    }

    initialize() {
        return Promise.resolve();
    }

    getDiagnosticData() {
        return Promise.resolve({
            localStorage: window.localStorage.webStorageStrategy,
            sessionStorage: window.sessionStorage.webStorageStrategy,
            selectedStorage: this._storage
        });
    }

    _save(storage) {
        window[this._storage].webStorageStrategy = JSON.stringify(storage);
    }

    _getStorage() {
        return JSON.parse(window[this._storage].webStorageStrategy);
    }

    _ensureExistence() {
        if (!window[this._storage].webStorageStrategy) {
            this._save({});
        }
    }
}

export { WebStorageStrategy };
