'use strict';

const emailFormatPattern = new RegExp("^\\s*\\w+(?:\\.{0,1}[\\w-]+)*@[a-zA-Z0-9]+(?:[-.][a-zA-Z0-9]+)*\\.[a-zA-Z]+\\s*$");

/**
 * @deprecated use `@totalpave/email-validator` instead
 */
class EmailValidator {
    constructor() {
        throw new Error('EmailValidator is static and cannot be instantiated');
    }

    static validate(email) {
        if (!email) return false;

        return emailFormatPattern.test(email);
    }
}

export { EmailValidator };
