'use strict';

import * as React from 'react';
import {Modal} from './Modal';
import {Progressbar as ProgressBar} from './Progressbar';
import {ProgressStore} from '../store/ProgressStore';
import PropTypes from 'prop-types';
import "../style/ProgressBarModal.less";

class ProgressBarModal extends Modal {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            value: ProgressStore.getInstance().getProgress(this.props.progressId)
        };

        this._onProgressStoreUpdate = this._onProgressStoreUpdate.bind(this);
    }

    _getClassName() {
        return 'ProgressBarModal';
    }

    componentDidMount() {
        super.componentDidMount();

        ProgressStore.getInstance().register(this._onProgressStoreUpdate);
    }

    _onProgressStoreUpdate() {
        this.setState({
            value: ProgressStore.getInstance().getProgress(this.props.progressId)
        });
    }

    getTitle() {
        return this.props.title ? this.props.title : "Loading...";
    }

    isFlex() {
        return true;
    }

    shouldShowCancelButton() {
        return false;
    }

    shouldCloseOnBackgroundClick() {
        return false;
    }

    isHeaderCentered() {
        return true;
    }

    _render() {
        return (
            <div className="content-wrapper">
                <ProgressBar 
                    min={this.props.min}
                    max={this.props.max}
                    value={this.state.value}
                />
            </div>
        );
    }
}

PropTypes.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    progressId: PropTypes.string.isRequired
}

export { ProgressBarModal };
