'use strict';

import * as React from 'react';

import {TPComponent} from './TPComponent';
import {HandleBar} from './HandleBar';
import {
    ClassName,
    Header,
    Button
} from '@totalpave/ui-core';

import {Select} from './Select';
import {Option} from '../utils/Option';

import {ComparisonRule} from '@totalpave/comparisons';

import { ComparisonUIBuilderFactory } from '../factories/ComparisonUIBuilderFactory';
import {ComparisonFactory} from '@totalpave/comparisons';
import {TableDefinitionFactory} from '../factories/TableDefinitionFactory';
import {IconFactory} from '../factories/IconFactory';

import {DispatchMessage} from '@totalpave/error';
import {MessageType} from '@totalpave/finterfaces';

import {
    ComparisonMethod
} from '@totalpave/interfaces';

import {HANDLEBAR_WIDTH} from '../utils/Constants';

import '../style/ComparisonComponent.less';
import PropTypes from 'prop-types';
import { Spinner } from './Spinner';
import { ObjectUtils } from '@totalpave/object';

const SELECT_WIDTH = '100%';

/*
    TODO:
    The handle bars functionality should be extracted into it's own CollapsableView
    component that handles showing/hiding it's content.
*/
export class ComparisonComponent extends TPComponent {
    constructor(props) {
        super(props);

        this.comparisonFactory = new ComparisonFactory();
        this.uiBuilderFactory = new ComparisonUIBuilderFactory();
        this.comparison = null;
        this.uiBuilder = null;

        this.state.openHandleBar = !!props.isOpenOnMount;
        this.state.editMode = false;
        this.state.selectedIndex = null;
        // data for new filter rule
        this.state.column = null;
        this.state.columnOption = null; // actually for Select instead of filter rule
        this.state.type = null;

        this.state.comparisonMethod = null;
        this.state.comparisonMethodOption = null; // actually for Select instead of filter rule
        this.state.params = null;

        this._leaveEditMode = this._leaveEditMode.bind(this);
        this._addFilter = this._addFilter.bind(this);
        this._editFilter = this._editFilter.bind(this);
        this._saveFilter = this._saveFilter.bind(this);

        this._onComparisonMethodChange = this._onComparisonMethodChange.bind(this);
        this._onColumnChange = this._onColumnChange.bind(this);
        this._onHandleBarClick = this._onHandleBarClick.bind(this);
        this._onTransitionEnd = this._onTransitionEnd.bind(this);

        this._getColumnOptions = this._getColumnOptions.bind(this);
        this._getComparisonMethodOptions = this._getComparisonMethodOptions.bind(this);

        this._renderFilterList = this._renderFilterList.bind(this);
        this._renderUpdateFilter = this._renderUpdateFilter.bind(this);
    }

    _getContentWrapperWidth() {
        if (this.state.openHandleBar) {
            return this.props.width - (HANDLEBAR_WIDTH);
        }
        else {
            return 0;
        }
    }

    componentDidMount() {
        super.componentDidMount();

        if (
            // if open state should be open and is not open
            (this.props.isOpenOnMount && !this.state.openHandleBar) ||
            // or open state should not be open and is open
            (!this.props.isOpenOnMount && this.state.openHandleBar)
        ) {
            // then toggle open state
            this._onHandleBarClick();
        }
    }

    _getColumnOptions() { //WARNING: table def factory CAN NOT be cached. It is unsafe to cache data.
        let defs = this.props.tableDefinitionFactory.getDefinitions();
        // array of Options containg column keys and the column text.
        let columnOptions = [];
        for (let key in defs) {
            if (this._isColumnFilterable(defs[key])) {
                columnOptions.push(new Option(defs[key].text, defs[key].key));
            }
        }

        return columnOptions;
    }

    _isColumnFilterable(def) {
        if (ObjectUtils.isVoid(def.filterable)) {
            return true;
        }
        else {
            return !!def.filterable;
        }
    }

    _getComparisonMethodOptions() {
        return this.comparison.getSupportedComparisonMethods().map((method) => {
            return new Option(this.comparison.getComparisonMethodText(method), method);
        });
    }

    _onHandleBarClick() {
        this.setState({
            openHandleBar: !this.state.openHandleBar,
            fireOpenCloseEvent: true
        });
    }

    // not called cancel; because, it's also used in save.
    _leaveEditMode() {
        this.setState({
            editMode: false,
            selectedIndex: null,
            column: null,
            columnOption: null,
            type: null,
            comparisonMethod: null,
            comparisonMethodOption: null,
            params: null
        }, () => {
            this._onEditStateChange(this.state.editMode);
        });
    }

    _addFilter() {
        this.setState({
            editMode: true,
            selectedIndex: null
        }, () => {
            this._onEditStateChange(this.state.editMode);
        });
    }

    _onEditStateChange(isEditMode) {
        this.props.onEditStateChange && this.props.onEditStateChange(isEditMode);
    }

    _editFilter(index) {
        let defs = this.props.tableDefinitionFactory.getDefinitions();
        this.setState({
            editMode: true,
            selectedIndex: index
        }, () => {
            // ensure component state is properly setup by using the on change methods.
            this._onEditStateChange(this.state.editMode);
            let filter = this.props.rules[index];
            this._onColumnChange(new Option(defs[filter.getColumn()].text, defs[filter.getColumn()].key));
            this._onComparisonMethodChange(new Option(this.comparison.getComparisonMethodText(filter.getComparisonMethod()), filter.getComparisonMethod()));
            this._onParamsChange(filter.getParams());
        });
    }

    _deleteFilter(index) {
        let rules = this.props.rules.slice();
        rules.splice(index, 1);
        this.props.onFilter(rules);
    }

    _saveFilter() {
        let index = this.state.selectedIndex;
        let filter = null;
        let def = this.props.tableDefinitionFactory.getDefinition(this.state.column);
        let rules = this.props.rules.slice();
        if (index !== null) {
            filter = this.props.rules[index].clone();
            // the order that we set stuff is important
            filter.setColumn(this.state.column);
            filter.setType(this.state.type);
            filter.setComparisonMethod(this.state.comparisonMethod);
            filter.setParams(this.state.params);
            filter.setOptions(def.filterOptions);
            
            rules[index] = filter;
        }
        else {
            filter = new ComparisonRule({
                type: this.state.type,
                column: this.state.column,
                comparisonMethod: this.state.comparisonMethod,
                params: this.state.params,
                options: def.filterOptions
            });
            rules.push(filter);
        }
        this._leaveEditMode();
        this.props.onFilter(rules);
    }

    _onColumnChange(option) {
        let def = this.props.tableDefinitionFactory.getDefinition(option.getValue());
        this.comparison = this.comparisonFactory.create(def.type);
        this.uiBuilder = this.uiBuilderFactory.create(def.type, def.extra ? def.extra : {})
        this.setState({
            column: option.getValue(),
            columnOption: option,
            type: def.type,
            comparisonMethod: null,
            comparisonMethodOption: null,
            params: null
        });
    }

    _onComparisonMethodChange(option) {
        let defaultValue = this.uiBuilder.getDefaultValue();
        let params = {};
        // Ensure the params props are defined for canSave checks
        // Default values can also be handled here.
        switch (option.getValue()) {
            // pass through cases that do the same thing
            case ComparisonMethod.IS_EQUAL_TO:
            case ComparisonMethod.IS_NOT_EQUAL_TO:
            case ComparisonMethod.CONTAINS:
            case ComparisonMethod.DOES_NOT_CONTAIN:
            case ComparisonMethod.IS_LESS_THAN:
            case ComparisonMethod.IS_NOT_LESS_THAN:
            case ComparisonMethod.IS_LESS_THAN_OR_EQUAL_TO:
            case ComparisonMethod.IS_NOT_LESS_THAN_OR_EQUAL_TO:
            case ComparisonMethod.IS_GREATER_THAN:
            case ComparisonMethod.IS_NOT_GREATER_THAN:
            case ComparisonMethod.IS_GREATER_THAN_OR_EQUAL_TO:
            case ComparisonMethod.IS_NOT_GREATER_THAN_OR_EQUAL_TO:
                params.value = defaultValue;
                break;
            case ComparisonMethod.IS_BETWEEN:
            case ComparisonMethod.IS_NOT_BETWEEN:
                params.start = defaultValue;
                params.end = defaultValue;
                break;
            // no onChange prop to set. There is no params props.
            // case ComparisonMethod.IS_KNOWN:
            // case ComparisonMethod.IS_NOT_KNOWN:
        }

        this.setState({
            comparisonMethod: option.getValue(),
            comparisonMethodOption: option,
            params: params
        });
    }

    _onParamsChange(params) {
        this.setState({
            params: params
        });
    }

    _renderParamsBuilder(builder, comparisonMethod, params, onChange, editable) {
        switch (comparisonMethod) {
            case ComparisonMethod.IS_EQUAL_TO:
                return builder.buildIsEqualTo(
                    params.value,
                    onChange,
                    editable
                );
            case ComparisonMethod.IS_NOT_EQUAL_TO:
                return builder.buildIsNotEqualTo(
                    params.value,
                    onChange,
                    editable
                );
            case ComparisonMethod.IS_KNOWN:
                return builder.buildIsKnown();
            case ComparisonMethod.IS_NOT_KNOWN:
                return builder.buildIsNotKnown();
            case ComparisonMethod.CONTAINS:
                return builder.buildContains(
                    params.value,
                    onChange,
                    editable
                );
            case ComparisonMethod.DOES_NOT_CONTAIN:
                return builder.buildDoesNotContain(
                    params.value,
                    onChange,
                    editable
                );
            case ComparisonMethod.IS_LESS_THAN:
                return builder.buildIsLessThan(
                    params.value,
                    onChange,
                    editable
                );
            case ComparisonMethod.IS_NOT_LESS_THAN:
                return builder.buildIsNotLessThan(
                    params.value,
                    onChange,
                    editable
                );
            case ComparisonMethod.IS_LESS_THAN_OR_EQUAL_TO:
                return builder.buildIsLessThanOrEqualTo(
                    params.value,
                    onChange,
                    editable
                );
            case ComparisonMethod.IS_NOT_LESS_THAN_OR_EQUAL_TO:
                return builder.buildIsNotLessThanOrEqualTo(
                    params.value,
                    onChange,
                    editable
                );
            case ComparisonMethod.IS_GREATER_THAN:
                return builder.buildIsGreaterThan(
                    params.value,
                    onChange,
                    editable
                );
            case ComparisonMethod.IS_NOT_GREATER_THAN:
                return builder.buildIsNotGreaterThan(
                    params.value,
                    onChange,
                    editable
                );
            case ComparisonMethod.IS_GREATER_THAN_OR_EQUAL_TO:
                return builder.buildIsGreaterThanOrEqualTo(
                    params.value,
                    onChange,
                    editable
                );
            case ComparisonMethod.IS_NOT_GREATER_THAN_OR_EQUAL_TO:
                return builder.buildIsNotGreaterThanOrEqualTo(
                    params.value,
                    onChange,
                    editable
                );
            case ComparisonMethod.IS_BETWEEN:
                return builder.buildIsBetween(
                    params.start,
                    params.end,
                    onChange,
                    editable
                );
            case ComparisonMethod.IS_NOT_BETWEEN:
                return builder.buildIsNotBetween(
                    params.start,
                    params.end,
                    onChange,
                    editable
                );
            default: 
                throw new Error('ComparisonComponent found comparison method that is not yet supported. Devs must have forgotten something.');
        }
    }

    _renderFilterItemIcon(index) {
        if (this.props.loading) {
            return <Spinner />;
        }
        else {
            return IconFactory.create(
                IconFactory.Icons.CANCEL, 
                {
                    onClick: (event) => {
                        event.stopPropagation(); // don't let parent's click event fire
                        this._deleteFilter(index);
                    }
                }
            );
        }
    }

    _renderNoFilters() {
        let content;

        if (this.props.noContentView) {
            content = this.noContentView;
        }
        else {
            content = <span className="no-filters-message">No active filters</span>;
        }

        return content;
    }

    _renderFilterList() {
        let defs = this.props.tableDefinitionFactory.getDefinitions();
        if (this.props.rules.length > 0) {
            return (
                <div className="view">
                    {this.props.rules.map((filter, i) => {
                        let column = defs[filter.getColumn()].text;
                        let params = null;
                        let comparison = this.comparisonFactory.create(filter.getType());
                        let builder = this.uiBuilderFactory.create(filter.getType(), defs[filter.getColumn()].extra ? defs[filter.getColumn()].extra : {});
                        let comparisonText = comparison.getComparisonMethodText(filter.getComparisonMethod());
                        params = this._renderParamsBuilder(builder, filter.getComparisonMethod(), filter.getParams(), null, false);

                        return <div key={i} className='filter' onClick={() => {
                            this._editFilter(i); // we want to maintain references. Passing in i instead of filter will make this an little more readable.
                        }}>
                            <span className='text'>{column} {comparisonText} {params}</span>
                            {this._renderFilterItemIcon(i)}
                        </div>;
                    })}
                </div>
            );
        }
        else {
            let noFilters = this._renderNoFilters();
            if (noFilters) {
                return (
                    <div className="view">
                        {noFilters}
                    </div>
                );
            }
        }

        return null;
    }

    // ComparisonRule can be null. If null, we are adding an ComparisonRule.
    // if not null, we are updating an ComparisonRule
    _renderUpdateFilter(index) {
        let comparisonMethodSelect = null;
        let comparisonMethodParamSelectors = null;
        let onChange = null;
        if (this.state.column) {
            comparisonMethodSelect = <Select
                value={this.state.comparisonMethodOption}
                nullOptionText='Select condition'
                options={this._getComparisonMethodOptions()}
                onChange={this._onComparisonMethodChange}
                scrollable={true}
                width={SELECT_WIDTH}
            />;
        }

        if (this.state.comparisonMethod) {
            switch (this.state.comparisonMethod) {
                // pass through cases that do the same thing
                case ComparisonMethod.IS_EQUAL_TO:
                case ComparisonMethod.IS_NOT_EQUAL_TO:
                case ComparisonMethod.CONTAINS:
                case ComparisonMethod.DOES_NOT_CONTAIN:
                case ComparisonMethod.IS_LESS_THAN:
                case ComparisonMethod.IS_NOT_LESS_THAN:
                case ComparisonMethod.IS_LESS_THAN_OR_EQUAL_TO:
                case ComparisonMethod.IS_NOT_LESS_THAN_OR_EQUAL_TO:
                case ComparisonMethod.IS_GREATER_THAN:
                case ComparisonMethod.IS_NOT_GREATER_THAN:
                case ComparisonMethod.IS_GREATER_THAN_OR_EQUAL_TO:
                case ComparisonMethod.IS_NOT_GREATER_THAN_OR_EQUAL_TO:
                    onChange = (value) => {
                        this._onParamsChange({value: value});
                    };
                    break;
                case ComparisonMethod.IS_BETWEEN:
                case ComparisonMethod.IS_NOT_BETWEEN:
                    onChange = (value) => {
                        this._onParamsChange(value);
                    };
                    break;
                // no onChange prop to set. There is no params props.
                // case ComparisonMethod.IS_KNOWN:
                // case ComparisonMethod.IS_NOT_KNOWN:
            }

            comparisonMethodParamSelectors = this._renderParamsBuilder(this.uiBuilder, this.state.comparisonMethod, this.state.params, onChange, true);
        }

        return <div className='edit'>
            <Select 
                value={this.state.columnOption}
                nullOptionText='Select column'
                allowSelectNullOption={false}
                options={this._getColumnOptions()}
                onChange={this._onColumnChange}
                scrollable={true}
                width={SELECT_WIDTH}
            />
            {comparisonMethodSelect}
            {comparisonMethodParamSelectors}
        </div>;
    }

    _onTransitionEnd(e) {
        if (e.target === this.getNode() && e.propertyName === 'width' && this.state.fireOpenCloseEvent) {
            window.requestAnimationFrame(() => {
                if (this.state.openHandleBar) {
                    this.props.onOpen && this.props.onOpen();
                }
                else {
                    this.props.onClose && this.props.onClose();
                }
            });

            this.setState({
                fireOpenCloseEvent: false
            });
        }
    }

    getSaveButtonLabel() {
        return this.props.saveButtonLabel || 'Save';
    }

    render() {
        let buttons = [];
        if (this.state.editMode) {
            let enableSave = this.state.params !== null;
            if (enableSave) {
                for (let i in this.state.params) {
                    if (this.state.params[i] === '' || this.state.params[i] === null) { // use emptry string instead of null so that the component that uses this value is always 'controlled'
                        enableSave = false;
                        break;
                    }
                }
            }

            buttons.push(<Button key='cancel' className='cancel' text='Cancel' onClick={this._leaveEditMode} />);
            buttons.push(
                <Button 
                    key='save'
                    className='save'
                    text={this.getSaveButtonLabel()}
                    onClick={this._saveFilter} 
                    enabled={enableSave} 
                    onDisabledClick={() => {
                        DispatchMessage.getInstance().execute({
                            message: 'Please fill in all fields.',
                            type: MessageType.ERROR
                        });
                    }} 
                />
            );
        }
        else {
            if (!this.props.floatAddButton) {
                buttons.push(this._createAddButton());
            }
        }

        return <div 
            className={`ComparisonComponent${this.state.openHandleBar ? ' open' : ''}`} 
            style={{width: this.state.openHandleBar ? this.props.width + 'px' : '0px'}}
            ref={(ref) => { this._setNode(ref); }}
            onTransitionEnd={this._onTransitionEnd}
        >
            <div className='container' style={{width: this.props.width + 'px'}}>
                <Header centered={true} title='Filters' />
                <HandleBar 
                    direction={HandleBar.ChevronDirection.RIGHT}
                    onClick={this._onHandleBarClick}
                    isOpen={this.state.openHandleBar}
                />
                <div className='content-wrapper' style={{width: this._getContentWrapperWidth() + 'px'}}>
                    <div className='filters'>
                        {this.state.editMode ? this._renderUpdateFilter(this.state.selectedIndex) : this._renderFilterList()}
                        {(!this.state.editMode && this.props.floatAddButton) ? this._createAddButton() : null}
                    </div>
                    <div className='button-wrapper'>
                        {buttons}
                    </div>
                </div>
            </div>
        </div>;
    }

    _createAddButton() {
        return (
            <Button 
                key='add'
                className={ClassName.execute({
                    add: true,
                    cancel: !!this.props.addButtonUseSecondaryStyle
                })}
                standard={false}
                text='Add filter'
                onClick={this._addFilter} 
                preIcon={IconFactory.create(IconFactory.Icons.PLUS)}
            />
        );
    }
}

ComparisonComponent.propTypes = {
    onFilter: PropTypes.func.isRequired,
    width: PropTypes.number, // Is required using showing Handle Bars
    tableDefinitionFactory: PropTypes.instanceOf(TableDefinitionFactory).isRequired,
    rules: PropTypes.arrayOf(PropTypes.instanceOf(ComparisonRule)).isRequired,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    floatAddButton: PropTypes.bool,
    addButtonUseSecondaryStyle: PropTypes.bool,
    saveButtonLabel: PropTypes.string,
    noContentView: PropTypes.node,
    isOpenOnMount: PropTypes.bool, // defaults to false
    onEditStateChange: PropTypes.func
};
