'use strict';

import {ComponentContainer} from './ComponentContainer';
import {ToolTipStore} from '../store/ToolTipStore';

export class ToolTipContainer extends ComponentContainer {
    constructor(props) {
        super(props);
        this.state = {
            tooltip: null
        };
    }

    getCssClass() {
        return 'ToolTipContainer';
    }

    getStore() {
        return ToolTipStore;
    }

    onUpdate() {
        this.setState({
            tooltip: ToolTipStore.getInstance().getTip()
        });
    }

    _render() {
        return this.state.tooltip;
    }
}
