'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from './Modal';
import { TrackerUtils } from '../utils/TrackerUtils';
import { PreferenceStore } from '../store/PreferenceStore';
import { LoadOrganizationPreferences } from '../actions/LoadOrganizationPreferences';
import { Color } from '../utils/Color';
import { IconFactory } from '../factories/IconFactory';
import "../style/MarkerColorPickerModal.less";

/*
    This MarkerColorPickerModal does NOT use pointers as they were causing tons of UI problems on Web Portal.
*/

/*
 * Props
 *
 * color    string      The HEX string value of a color. Example: #000000
 * onChange function    Function to be called when color is changed. Color string will be passed in.
*/
class MarkerColorPickerModal extends Modal {
    constructor(props) {
        super(props)

        this._popUpPointerNode;
        this._relativeParentNode;
        this._markersContainerNode;

        if (this.state){
            this.state.colors = this._getColors();
        }
        else {
            this.state = {
                colors : this._getColors(),
                isColorsReady : false
            };
        }

        this._onMarkerClick = this._onMarkerClick.bind(this);
        this._updateColors = this._updateColors.bind(this);
        this._onPreferenceUpdated = this._onPreferenceUpdated.bind(this);
        this._getColors = this._getColors.bind(this);
    }

    _getClassName() {
        return 'MarkerColorPickerModal';
    }

    _getPointerOpts() {
        return {};
    }

    isPopUp() {
        return true;
    }

    showHeader() {
        return false;
    }

    componentDidMount() {
        super.componentDidMount();
        PreferenceStore.getInstance().register(this._onPreferenceUpdated);
        LoadOrganizationPreferences.execute().catch((e) => { /* swallow */ });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        PreferenceStore.getInstance().unregister(this._onPreferenceUpdated);
    }

    _onPreferenceUpdated() {
        this._updateColors();
    }

    _updateColors() {
        this.setState({
            colors : this._getColors()
        });
    }

    _getColors() {
        return TrackerUtils.getMarkerColors(); //PreferenceStore.getInstance().getOrganizationPreference(TR_MARKER_COLOR_PREFERENCE_KEY) ? JSON.parse(PreferenceStore.getInstance().getOrganizationPreference(TR_MARKER_COLOR_PREFERENCE_KEY)) : TR_DEFAULT_MARKER_COLORS;
    }

    _onMarkerClick(index) {
        if (this.touchmoved) {
            return;
        }
        this.props.onColorSelected(this.state.colors[index]);
        this.close();
    }

    _render() {
        let self = this;

        let colors = this.state.colors.map((color, index) => {
            return IconFactory.create('map-marker', {"key":index, "style": {"color":Color.fromString(color).toHexString()}, "height":32, "onClick": () => { self._onMarkerClick(index); }});
        });

        let events = {
            onTouchMove : () => {
                self.touchmoved = true;
            },
            onTouchEnd : () => {
                self.touchmoved = false;
            }
        };
        events.onTouchCancel = events.onTouchEnd;
        //<div className="popUpPointer" ref={(c) => { /*this.setPointer(c); /*this._popUpPointerNode = c;*/ }}></div>
                
        return (
            <div className="colorPickerContent" ref={(c) => { this._relativeParentNode = c; }}>
                <div className="markersContainer" {...events}>
                    {colors}
                </div>
            </div>
        );
    }
}

MarkerColorPickerModal.propTypes = {
    onColorSelected: PropTypes.func.isRequired  
};

export { MarkerColorPickerModal };
