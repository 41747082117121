
/**
 * @var NUMBER {string} Safe for integeres. Not safe for decimals. Some phones will display characters like `.` in the keyboard, other phones won't.
 * @var DECIMAL {string} Safe for decimals and integers. All phones that support a numeric keyboard will display `.`. The phones that don't will display the textual keyboard.
 */
export const InputType = {
    TEXT            : 'text',
    PASSWORD        : 'password',
    EMAIL           : 'email',
    NUMBER          : 'number',
    DECIMAL         : 'decimal',
    CURRENCY        : 'currency',
    NUMBER_FORMAT   : 'numberformat'
};
