
/**
 * Copyright 2023-2024 Total Pave Inc.
 * All Rights Reserved.
 */

import * as React from 'react';
import { IIconProps, Icon } from './Icon';
import { SVGPreset, SVGUtils } from '../utils/SVGUtils';

export interface ISVGIconProps extends IIconProps<SVGPreset> {
    rotate?: number;
    display?: string;
    children?: React.ReactNode | React.ReactNode[];
    width?: number;
    height?: number;
    className?: string;
    imgStyle?: React.CSSProperties;
}

export class SVGIcon extends Icon<ISVGIconProps> {
    public constructor(props: ISVGIconProps) {
        super(props);
    }

    public override getAdditionalCSSClass(): string {
        return 'SVGIcon';
    }

    protected override _render(): React.ReactNode {
        let style: React.CSSProperties = {};

        if (this.props.rotate) {
            style.transform = `rotate(${this.props.rotate}deg)`;
        }

        if (this.props.display === undefined) {
            style.display = "inline-block";
        }
        else if (this.props.display) {
            style.display = this.props.display;
        }

        let ele;

        if (typeof this.props.value === "object") {
            ele = SVGUtils.createJSXNode(this.props.value);
        }
        else if (!!this.props.children) {
            ele = this.props.children;
        }
        else {
            //IE10 requires width and height to be set on img or else they become 0...
            ele = <img src={this.props.value} width={this.props.width} height={this.props.height} style={this.props.imgStyle}/>;
        }
        
        return <div style={style} className={this.props.className}>{ele}</div>;
    }
}
