
import {TextfieldPropsBuilder} from './TextfieldPropsBuilder';
import {InputType} from '../utils/InputType';

class EmailTextfieldPropsBuilder extends TextfieldPropsBuilder {
    _getInputType() {
        return InputType.EMAIL;
    }
}

export {EmailTextfieldPropsBuilder};
