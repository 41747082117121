'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';
import {KeyCode} from '../utils/KeyCode';
import "../style/Textfield.less";
import {Browser} from '../utils/Browser';
import { InputType } from '../utils/InputType';

const InputMode = InputType;

/**
 * A textfield component to accept input and provides focus, blur, and on change events.
 */
class Textfield extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}; // defined for extending classes

        this.$onFocus = this.$onFocus.bind(this);
        this.$onBlur  = this.$onBlur.bind(this);
        this.$onChange= this.$onChange.bind(this);
        this.$onKeyUp= this.$onKeyUp.bind(this);

        this._node;
    }

    getNode() {
        return this._node;
    }

    componentDidMount() {
        if (this._node && !this.props.autoFocus) {
            //Fixes unwanted autofocusing.
            this._node.blur();
        }
    }

    componentWillUnmount() {} // Defined for extending classes

    render() {
        return this._render(this.$getInternalProps());
    }

    _render(props) {
        return <input {...props} />;
    }

    _getPlaceholder() {
        let ph = this.props.placeholder;

        if (this.props.defaultValue && !this.props.hidePlaceholderDefaultValue) {
            ph += ` (${this.props.defaultValue})`;
        }

        return ph;
    }

    _getType() {
        let type;
        if (this.props.type === InputMode.DECIMAL && Browser.isMobile() && Browser.getOS() === Browser.ANDROID) {
            type = InputMode.NUMBER; // Android doesn't support DECIMAL
        }
        else {
            type = this.props.type ? this.props.type : InputMode.TEXT;
        }
        return type;
    }

    _getInternalProps(props) {
        return props;
    }

    _getRefPropName() {
        return 'ref';
    }

    // For extending classes, Textfield's classname is hardcoded
    _getClassName() {
        return ''; 
    }

    _parseValue(event) {
        return event.target.value;
    }

    $getInternalProps() {
        let props = {
            className : `Textfield ${this._getClassName()} ${this.props.readonly ? 'readonly' : ''} ${this.props.className ? " " + this.props.className : ""}`,
            [this._getRefPropName()] : (c) => { 
                this._node = c; 
                if (this._node && this.props.autoFocus) {
                    this._node.focus();
                }
            },
            type : this._getType(),
            value : this.props.value,
            placeholder : this._getPlaceholder(),
            onFocus : this.$onFocus,
            onBlur : this.$onBlur,
            onChange : this.$onChange,
            autoFocus : this.props.autoFocus,
            spellCheck : false,
            autoComplete: 'off',
            autoCorrect : 'off',
            autoCapitalize: 'off',
            noValidate : true,
            readOnly: this.props.readonly,
            title : this.props.title || (this.props.readonly ? this.getReadonlyTitle() : ''),
            maxLength : this.props.maxLength
        };

        if (this.props.disabled){
            props.disabled = true;
        }

        props.onKeyUp = this.$onKeyUp;

        if (this.props.type === InputMode.NUMBER || this.props.type === InputMode.DECIMAL) {
            props.inputMode = this._getType() === InputMode.NUMBER ? 'numeric' : 'decimal';
            props.pattern = 'd+(.d*)?';

            let step = this.props.step;
            if (step === undefined || step === null) {
                step = '0.01';
            }
            props.step = step;
        }

        return this._getInternalProps(props);
    }

    getReadonlyTitle() {
        return this.props.readonlyTitle || 'This field cannot be edited';
    }

    $onKeyUp(event) {
        if (event.keyCode === KeyCode.ENTER) {
            this._node.blur();
            this.props.onSubmit && this.props.onSubmit();
        }
    }

    $parseValue(event) {
        let value = this._parseValue(event);
        return value === '' ? this.props.defaultValue : value;
    }

    $onChange(event) {
        this.props.onChange && this.props.onChange(this.$parseValue(event));
    }

    $onFocus(event) {
        if (this.props.autoSelect) {
            event.target.select();
        }

        this.props.onFocus && this.props.onFocus(event);
    }

    $onBlur(event) {
        this.props.onBlur && this.props.onBlur(event);
    }
}

Textfield.propTypes = {
    onSubmit: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    step: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    autoSelect: PropTypes.bool,
    className: PropTypes.string,
    title: PropTypes.string,
    readonlyTitle: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    autoFocus: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    pattern: PropTypes.string,
    hidePlaceholderDefaultValue: PropTypes.bool,
    maxLength: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};

export { Textfield, InputMode };
