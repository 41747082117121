
import * as React from 'react';
import PropTypes from 'prop-types';
import {ApplicationInstance} from '@totalpave/application-instance';
import {Button} from './Button';
import {IconFactory} from '../factories/IconFactory';
import {SnapUtils} from '../utils/SnapUtils';
import {MONTHS, WEEKDAYS, DateUtils} from '../utils/DateUtils';
import {Select} from './Select'
import {Option} from '../utils/Option';
import "../style/Calendar.less";

export class Calendar extends React.Component {
    constructor(props) {
        super(props);
        this._node = null;

        let date = this.props.value;
        if (!DateUtils.isValid(date)) {
            date = ApplicationInstance.getInstance().getDateFactory().create();
        }

        this.state = {
            visible: props.detached ? true : false,
            date: date
        };
    }

    getNode() {
        return this._node;
    }

    open(target) {
        this.snap(target);
        this.setState({visible: true}, () => {
            this.props.onOpen && this.props.onOpen();
        });
    }

    snap(target) {
        if (!this.props.detached) {
            let t;
            if (target.getNode) {
                t = target.getNode();
            }
            else {
                // must be an x/y object
                t = target;
            }
            let subject = this._node;

            SnapUtils.snap({
                subject: subject,
                target: t,
                alignment: SnapUtils.ALIGNMENT_BOT_CENTER
            });
        }
    }

    close() {
        let visible = false;

        if (this.props.detached) {
            visible = true;
        }

        this.setState({
            visible: visible
        });
        this.props.onClose && this.props.onClose();
    }

    navigateMonth(step) {
        let newDate = ApplicationInstance.getInstance().getDateFactory().create(this.state.date);
        newDate.setMonth(this.state.date.getMonth() + step);
        this.setState({
            date: newDate
        });
    }

    setYear(year) {
        let newDate = ApplicationInstance.getInstance().getDateFactory().create(this.state.date);
        newDate.setFullYear(year);
        this.setState({
            date: newDate
        });
    }

    setDate(date) {
        let newDate = ApplicationInstance.getInstance().getDateFactory().create(this.state.date);
        newDate.setDate(date);
        this.setState({
            date: newDate
        });
    }

    previousMonth() {
        this.navigateMonth(-1);
    }

    nextMonth() {
        this.navigateMonth(1);
    }

    render() {
        let activeDate = this.state.date;

        let yearOptions = this._buildYearOptions(activeDate.getFullYear());

        return (
            <div className={`Calendar calendar-overlay ${this.state.visible ? '' : 'hide-calendar'}`} ref={(n) => { this._overlayNode = n; }} onClick={(e) => {
                e.stopPropagation();
                this.close();
            }}>
                <div className={`calendar-popup`} ref={(n) => { this._node = n; }} onClick={(e) => { e.stopPropagation(); }}>
                    <div className="calendar-header">
                        {IconFactory.create(IconFactory.Icons.CHEVRON_LEFT, {
                            onClick: (e) => {
                                e.stopPropagation();
                                this.previousMonth();
                            }
                        })}
                        <div className="detail-container">
                            <div className="year">
                                <Select
                                    options={yearOptions.options}
                                    value={yearOptions.selected}
                                    scrollable
                                    onChange={(value) => {
                                        let newDate = new Date(activeDate);
                                        newDate.setFullYear(parseInt(value.getValue()));
                                        this.setState({
                                            date: newDate
                                        });
                                    }}
                                />
                            </div>
                            <div className="month">{MONTHS[activeDate.getMonth()]}</div>
                        </div>
                        {IconFactory.create(IconFactory.Icons.CHEVRON_RIGHT, {
                            onClick: (e) => {
                                e.stopPropagation();
                                this.nextMonth();
                            }
                        })}
                    </div>
                    {this._renderDateGrid(activeDate)}
                    <div className="calendar-footer">
                        <Button text="Cancel" className="cancel" onClick={(e) => {
                            e.stopPropagation();
                            this.close();
                        }} />
                        <Button text="Save" onClick={(e) => {
                            e.stopPropagation();
                            if (this.props.onChange) {
                                this.props.onChange(ApplicationInstance.getInstance().getDateFactory().create(this.state.date));
                            }
                            this.close();
                        }} />
                    </div>
                </div>
            </div>
        );
    }

    _renderDateGrid(date) {
        // let previousMonth = new Date(date);
        // previousMonth.setMonth(date.getMonth() - 1);

        // let nextMonth = new Date(date);
        // nextMonth.setMonth(date.getMonth() + 1);
        // 
        
        const NUM_OF_DAYS = 7;

        let rows = [
            new Array(NUM_OF_DAYS),
            new Array(NUM_OF_DAYS),
            new Array(NUM_OF_DAYS),
            new Array(NUM_OF_DAYS),
            new Array(NUM_OF_DAYS),
            new Array(NUM_OF_DAYS)
        ];

        let firstDate = ApplicationInstance.getInstance().getDateFactory().create(date);
        firstDate.setDate(1);

        if (firstDate.getDay() !== 0) {
            firstDate.setDate(- firstDate.getDay());
        }

        let dateRows = [];
        let today = ApplicationInstance.getInstance().getDateFactory().create();

        for (let i = 0; i < (rows.length * NUM_OF_DAYS); i++) {
            let row = Math.floor(i / NUM_OF_DAYS);
            let col = i % NUM_OF_DAYS;

            let d = ApplicationInstance.getInstance().getDateFactory().create(firstDate);
            d.setDate(d.getDate() + i);

            rows[row][col] = this._makeDateColumn(col, d, today, date);
        }

        for (let i = 0; i < rows.length; i++) {
            let row = rows[i];
            dateRows.push(<tr key={i}>{row}</tr>);
        }

        let dayHeaders = [];

        for (let i = 0; i < WEEKDAYS.length; i++) {
            let weekday = WEEKDAYS[i];
            dayHeaders.push(<td key={weekday}>{weekday.toUpperCase()[0]}</td>);
        }

        return (
            <div className="calendar-grid">
                <table>
                    <thead>
                        <tr>
                            {dayHeaders}
                        </tr>
                    </thead>
                    <tbody>
                        {dateRows}
                    </tbody>
                </table>
            </div>
        );
    }

    _makeDateColumn(col, d, today, activeDate) {
        let isToday = d.getFullYear() === today.getFullYear() && d.getMonth() === today.getMonth() && d.getDate() === today.getDate();
        let isSameMonth = d.getMonth() === activeDate.getMonth();
        let isSelected = d.getFullYear() === activeDate.getFullYear() && d.getMonth() === activeDate.getMonth() && d.getDate() === activeDate.getDate();

        return (
            <td 
                key={col} 
                className={`${isSameMonth ? '' : 'sibling-month'} ${isToday ? 'active' : ''} ${isSelected ? 'selected' : ''}`}
                onClick={(e) => {
                    e.stopPropagation();
                    this.setState({
                        date : ApplicationInstance.getInstance().getDateFactory().create(d)
                    });
                    if (this.props.onClick) {
                        this.props.onClick(d);
                    }
                }}
            >
                {d.getDate()}
            </td>
        );
    }

    _buildYearOptions(activeYear) {
        let currentYear = new Date().getFullYear();
        let lowRange = currentYear - 100;
        let highRange = currentYear + 100;

        let options = [];
        let selectedOption = null;

        for (let i = lowRange; i < highRange; i++) {
            let opt = new Option(i.toString(), i);
            options.push(opt);
            if (i === activeYear) {
                selectedOption = opt;
            }
        }

        if (selectedOption !== null) {
            selectedOption.setHighlight(true);
        }

        return {
            options: options,
            selected: selectedOption
        };
    }
}

Calendar.propTypes = {
    value: PropTypes.instanceOf(Date),
    target: PropTypes.any,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    detached: PropTypes.bool,
    onClose: PropTypes.func,
    onOpen: PropTypes.func
};
