export class Serializer {
    constructor() {
        this._serializeItem = this._serializeItem.bind(this);
    }

    _serializeItem(key, value) {
        if (value instanceof Error) {
            return {
                message: value.message,
                stack: value.stack,
                name: value.name
            };
        }
        else {
            return value;
        }
    }

    serialize(object) {
        return JSON.stringify(object, this._serializeItem);
    }
}
