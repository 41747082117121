'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from './FontAwesomeIcon';
import '../style/Button.less';
import {ClassName} from '../utils/ClassName';
import {ApplicationInstance} from '@totalpave/application-instance';
import {deprecated} from '@totalpave/deprecated-prop-type';
import {ObjectUtils} from '@totalpave/object';

/**
 * @deprecated Use @totalpave/ui-core instead
 */
class Button extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
            className: props.className,
            loadingIndicatorDelayExceeded: false
        };

        this._onTouchStart = this._onTouchStart.bind(this);
        this._onTouchEnd = this._onTouchEnd.bind(this);
        this._onMouseDown = this._onMouseDown.bind(this);
        this._onMouseUp = this._onMouseUp.bind(this);

        this._onRelease = this._onRelease.bind(this);
        this._onActive = this._onActive.bind(this);

        this._onClick = this._onClick.bind(this);

        this._node;
        this._eventManager;
        this._waiting = false;
        this._loadingIndicatorTimeout = null;
    }
    
    getNode() {
        return this._node;
    }

    getIcon() {
        if (this.props.icon) {
            ApplicationInstance.getInstance().getLogger().deprecate();
        }
        return this.props.icon;
    }

    getText() {
        return this.props.text;
    }

    getClassName() {
        return 'Button';
    }

    isEnabled() {
        let enabled = this.props.enabled;
        return enabled !== false && enabled !== 'false';
    }

    isWaiting() {
        return this.props.waiting || this._waiting;
    }

    isWaitSupported() {
        return this.props.waiting !== undefined || !!this.props.waitForCallback;
    }

    //onTouch / onMouseDown
    _onActive(prop, event) {
        if (this.isEnabled() && !this.isWaiting()) {
            this.setState({
                active: true
            });
            prop && prop(event);
        }
    }

    _onTouchStart(event) {
        this._onActive(this.props.onTouch, event);
    }

    _onMouseDown(event) {
        this._onActive(this.props.onMouseDown, event);
    }

    _onRelease(prop, event) {
        if (this.isEnabled() && !this.isWaiting()) {
            this.setState({
                active: false
            });
            prop && prop(event);
        }
    }
    _onTouchEnd(event) {
        let prop = this.props.onTouchEnd;
        if (!prop) {
            if (this.props.onRelease) {
                prop = this.props.onRelease;
            }
        }
        this._onRelease(prop, event);
    }

    _onMouseUp(event) {
        this._onRelease(this.props.onMouseUp, event);
    }

    _onClick(event) {
        let prop = this.props.onClick ? this.props.onClick : this.props.onTap;

        if (this.isEnabled() && !this.isWaiting()) {
            let callbackContext = null;
            if (this.props.waitForCallback) {
                let timeoutDelay = 300;
                if (!ObjectUtils.isVoid(this.props.loadingIndicatorDelay)) {
                    timeoutDelay = this.props.loadingIndicatorDelay;
                }
                else if (!ObjectUtils.isVoid(this.props.spinnerDelay)) {
                    // Prop Types mark this prop as deprecated. Use loadingIndcatorDelay instead.
                    timeoutDelay = this.props.spinnerDelay;
                }
                this._loadingIndicatorTimeout = setTimeout(() => {
                    this.setState({
                        loadingIndicatorDelayExceeded: true
                    });
                }, timeoutDelay);
                callbackContext = this._buildCallbackContext();
                this._waiting = true;
                this.forceUpdate();
            }
            prop && prop(event, callbackContext);
        }
        else {
            this.props.onDisabledClick && this.props.onDisabledClick(event);
        }
    }

    _buildCallbackContext() {
        return () => {
            clearTimeout(this._loadingIndicatorTimeout);
            this._waiting = false;
            this.setState({
                loadingIndicatorDelayExceeded: false
            });
        };
    }

    _renderWaitingIndicator() {
        if (this.props.renderWaitIndicator) {
            return this.props.renderWaitIndicator();
        }

        // animDuration of 1s for 100px width Button works well. If Button width is different, the animation duration should be adjusted.
        // For example, Button width of 50px, 0.5s animation duration actually works well. 
        let indicator = (
            <div className='loading-bar-container'>
                <div
                    className='loading-bar'
                    style={{animationDuration: `${parseInt(getComputedStyle(this._node).width) / 100}s`}}
                />
            </div>
        );

        return indicator;
    }

    render() {
        let icon, preIcon, postIcon, loadingbar = null;
        if (!this.isWaiting() && this.getIcon()) {
            icon = <FontAwesomeIcon value={this.getIcon()} size="lg" />
        }

        if (!this.isWaiting()) {
            preIcon = this.props.preIcon;
        }

        if (!this.isWaiting()) {
            postIcon = this.props.postIcon;
        }

        if (this.isWaiting() && this.state.loadingIndicatorDelayExceeded) {
            loadingbar = this._renderWaitingIndicator();
        }

        let text = this.getText() || '';

        return (
            <div 
                className={ClassName.execute({
                    'wait-support': this.isWaitSupported(),
                    'no-border-radius': this.props.noBorderRadius,
                    'no-top-border-radius': this.props.noTopBorderRadius,
                    'disabled': !this.isEnabled() || this.isWaiting(),
                    'waiting': this.isWaiting(),
                    'active': this.state.active,
                    'safeInsetModeTop': this.props.safeInsetMode ? this.props.safeInsetMode.top : false,
                    'safeInsetModeRight': this.props.safeInsetMode ? this.props.safeInsetMode.right : false,
                    'safeInsetModeBottom': this.props.safeInsetMode ? this.props.safeInsetMode.bottom : false,
                    'safeInsetModeLeft': this.props.safeInsetMode ? this.props.safeInsetMode.left : false,
                    'standard': ObjectUtils.isVoid(this.props.standard) || this.props.standard ? true : false
                }, [
                    this.getClassName(), 
                    this.props.className
                ])} 
                ref={(c) => { this._node = c; }}
                style={this.props.style}
                onMouseDown={this._onMouseDown} 
                onMouseUp={this._onMouseUp}
                onTouchStart={this._onTouchStart}
                onTouchEnd={this._onTouchEnd}
                onClick={this._onClick}
                title={this.props.title}
                onAnimationEnd={this.props.onAnimationEnd}
                onAnimationStart={this.props.onAnimationStart}
                onAnimationIteration={this.props.onAnimationIteration}
                onTransitionEnd={this.props.onTransitionEnd}
            >
                {this._renderIcon(preIcon)}
                <span className="button-label">
                    {text}
                </span>
                {loadingbar}
                {this._renderIcon(icon)}
                {this._renderIcon(postIcon)}
            </div>
        );
    }

    _renderIcon(icon) {
        if (!icon) {
            return null;
        }

        return (
            <div className="icon-container">
                {icon}
            </div>
        );
    }
}

Button.propTypes = {
    className: PropTypes.string,
    icon: deprecated(PropTypes.string, 'Use preIcon or postIcon instead'),
    preIcon: PropTypes.node,
    postIcon: PropTypes.node,
    text: PropTypes.string.isRequired,
    noBorderRadius: PropTypes.bool,
    noTopBorderRadius: PropTypes.bool,
    enabled: PropTypes.oneOfType([
        PropTypes.bool, 
        PropTypes.string
    ]),
    waiting: deprecated(PropTypes.bool, 'Use waitForCallback instead'),
    waitForCallback: PropTypes.bool,
    loadingIndicatorDelay: PropTypes.number, // milliseconds
    spinnerDelay: deprecated(PropTypes.number, 'Use loadingIndicatorDelay instead'), // milliseconds
    onTouch: PropTypes.func,
    onMouseDown: PropTypes.func,
    onTouchEnd: PropTypes.func,
    onRelease: PropTypes.func,
    onMouseUp: PropTypes.func,
    onClick: PropTypes.func,
    renderWaitIndicator: PropTypes.func,
    onTap: deprecated(PropTypes.func, 'Use onClick instead'),
    onDisabledClick: PropTypes.func,
    safeInsetMode: PropTypes.shape({
        top: PropTypes.bool,
        right: PropTypes.bool,
        bottom: PropTypes.bool,
        left: PropTypes.bool
    }),
    title: PropTypes.string,
    style: PropTypes.object,
    /**
     * Defaults to true
     */
    standard: PropTypes.bool,

    onAnimationStart: PropTypes.func,
    onAnimationIteration: PropTypes.func,
    onAnimationEnd: PropTypes.func,
    onTransitionEnd: PropTypes.func
};

export { Button };
