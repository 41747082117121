'use strict';

import * as React from 'react';
import {ApplicationInstance} from '@totalpave/application-instance';
import {ComparisonParamsBuilder} from '../builders/ComparisonParamsBuilder';
import {DatePicker} from '../components/DatePicker';
import {DateFormatter} from '../utils/DateFormatter';

export class DateComparisonParamsBuilder extends ComparisonParamsBuilder {

    getDefaultValue() {
        return ApplicationInstance.getInstance().getDateFactory().create();
    }

    // Abstraction for very simple and common UI
    _buildDatePickerAndSpan(value, onChange, editable) {

        if (editable) {
            return <DatePicker value={value === '' ? null : value} onChange={onChange} useDetachedCalender={true} dateFormat={DateFormatter.LONG_DATE} />
        }
        else {
            return <span>{DateFormatter.format(value)}</span>;
        }
    }

    _buildIsBetweenDatePickerAndSpan(start, end, onChange, editable) {
        if (editable) {
            let startField = <DatePicker 
                value={start === '' ? null : start}
                onChange={(value) => {
                    onChange({start: value});
                }}
                useDetachedCalender={false}
                dateFormat={DateFormatter.LONG_DATE}
            />;
            let endField = <DatePicker 
                value={end === '' ? null : end} 
                onChange={(value) => {
                    onChange({end: value});
                }}
                useDetachedCalender={false}
                dateFormat={DateFormatter.LONG_DATE}
            />;
            return <p>{startField}{endField}</p>;
        }
        else {
            return <span>{DateFormatter.format(start)} and {DateFormatter.format(end)}</span>;
        }
    }

    _buildIsEqualTo(value, onChange, editable) {
        return this._buildDatePickerAndSpan(value, onChange, editable);
    }

    _buildIsNotEqualTo(value, onChange, editable) {
        return this._buildDatePickerAndSpan(value, onChange, editable);
    }

    _buildIsLessThan(value, onChange, editable) {
        return this._buildDatePickerAndSpan(value, onChange, editable);
    }

    _buildIsLessThanOrEqualTo(value, onChange, editable) {
        return this._buildDatePickerAndSpan(value, onChange, editable);
    }

    _buildIsNotLessThan(value, onChange, editable) {
        return this._buildDatePickerAndSpan(value, onChange, editable);
    }

    _buildIsNotLessThanOrEqualTo(value, onChange, editable) {
        return this._buildDatePickerAndSpan(value, onChange, editable);
    }

    _buildIsGreaterThan(value, onChange, editable) {
        return this._buildDatePickerAndSpan(value, onChange, editable);
    }

    _buildIsGreaterThanOrEqualTo(value, onChange, editable) {
        return this._buildDatePickerAndSpan(value, onChange, editable);
    }

    _buildIsNotGreaterThan(value, onChange, editable) {
        return this._buildDatePickerAndSpan(value, onChange, editable);
    }

    _buildIsNotGreaterThanOrEqualTo(value, onChange, editable) {
        return this._buildDatePickerAndSpan(value, onChange, editable);
    }

    _buildIsBetween(start, end, onChange, editable) {
        return this._buildIsBetweenDatePickerAndSpan(start, end, onChange, editable);
    }

    _buildIsNotBetween(start, end, onChange, editable) {
        return this._buildIsBetweenDatePickerAndSpan(start, end, onChange, editable);
    }
}
