
import * as React from 'react';
import {
    ListItem
} from './ListItem';

export class BlankListItem extends ListItem {
    _getClassName() {
        return 'BlankListItem';
    }

    _render() {
        return <div></div>;
    }
}
