import * as React from 'react';
import PropTypes from 'prop-types';
import {FormItem} from './FormItem';
import {Select} from './Select';
import '../style/FormItemSelect.less';

export class FormItemSelect extends React.Component {
    constructor(props) {
        super(props);
        this.$classNames = ["FormItemSelect"];
    }

    _addClassName(name) {
        this.$classNames.push(name);
    }

    render() {
        return <FormItem
            label={this.props.label}
            isRequired={this.props.isRequired}
            className={`${this.props.className} ${this.$classNames} ${this.props.hasError ? 'error' : ''}`}
            toolTipMessage={this.props.toolTipMessage}
        >
            <Select {...this.props.contentProps} />
        </FormItem>;
    }
}

FormItemSelect.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    toolTipMessage: PropTypes.string,
    /**
     * Defaults to false
     */
    isRequired: PropTypes.bool,
    /**
     * Defaults to false
     */
    hasError: PropTypes.bool,
    contentProps: PropTypes.exact(Select.propTypes)
}
