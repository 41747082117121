'use strict';

import {Action} from './Action';

class RemoveBodyClassAction extends Action {
    getTag() {
        return 'remove-body-class';
    }

    _shouldDispatchOnError() {
        return false;
    }

    _execute(className) {
        document.body.classList.remove(className);
        return Promise.resolve();
    }
}

const RemoveBodyClass = new RemoveBodyClassAction();

export { RemoveBodyClass };
