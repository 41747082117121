'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';
import {ApplicationInstance} from '@totalpave/application-instance';
import {DraggableComponent} from '../components/DraggableComponent';
import {ComparisonComponent} from '../components/ComparisonComponent';
import {IDGenerator} from '@totalpave/idgenerator';
import {IconFactory} from '../factories/IconFactory';
import {ObjectUtils} from '../utils/ObjectUtils';
import {Color} from '../utils/Color';
import {UIPlaceholder} from './UIPlaceholder';
import {Entities} from '../utils/Entities';
import {UserStore} from '../store/UserStore';
import {ClassName} from '../utils/ClassName';
import {Immutable} from '../utils/Immutable';
import {PreferenceStore} from '../store/PreferenceStore';
import {KeyboardListener} from '../utils/KeyboardListener';
import {KeyCode} from '../utils/KeyCode';
import {Browser} from '../utils/Browser';
import {Node} from '../utils/Node';
import {NodeGeometry} from '../utils/NodeGeometry';
import {SaveOrganizationPreference} from '../actions/SaveOrganizationPreference';
import {Checkbox} from './Checkbox';
import {StylesheetManager} from '../utils/StylesheetManager';
import {TextMeasurer} from '../utils/TextMeasurer';
import {OrderFilter} from '../utils/OrderFilter';
import {QueryFilter} from '../utils/QueryFilter';
import {FilterStrategy} from '../strategies/FilterStrategy';
import {TableEditorFactory} from '../factories/TableEditorFactory';
import {DefaultTableEditorFactory} from '../factories/DefaultTableEditorFactory';
import {ViewportStore} from '../store/ViewportStore';
import {TableDefinitionFactory} from '../factories/TableDefinitionFactory';
import {ComparisonRule} from '@totalpave/comparisons';
import {TPComponent} from './TPComponent';
import {
    SelectionContext,
    SelectionContextAdapter
} from '@totalpave/selection-context';
import {TPError} from '@totalpave/error';
import {
    SelectionState,
    DataType
} from '@totalpave/interfaces';
import {TextfieldFactory} from '../factories/TextfieldFactory';
import {CurrencyTextfieldPropsBuilder} from '../builders/CurrencyTextfieldPropsBuilder';
import {NumberFormatTextfield} from '../components/NumberFormatTextfield';
import {TableNumberType} from '../utils/TableNumberType';
import {DispatchMessage} from '@totalpave/error';
import { MessageType } from '@totalpave/finterfaces';
import {deprecated} from '@totalpave/deprecated-prop-type';

import '../style/Table.less';

const tableEditorFactory = new DefaultTableEditorFactory();
const HIGHLIGHT_COLOR = new Color(12, 128, 195, 0.15);
const HOVER_COLOR = new Color(225, 225, 225);
const SELECTED_COLOR = HOVER_COLOR;
const CHECKBOX_COL_WIDTH = 50;
const PADDING_FOR_CARET_ICONS = 70;
const ROW_HEIGHT = 40;

const LOADING_MOCK_DATA = [];
for (let i = 0; i < 512; i++) {
    LOADING_MOCK_DATA.push({
        id: i
    });
}

// =============================================================================
// Guidelines
// =============================================================================

class $GuidelineFlyweight {
    constructor() {
        this._node = this._createNode();
    }

    getNode() {
        return this._node;
    }

    _createNode() {
        this._node = document.createElement('div');
        this._node.setAttribute('class', 'guideline');
        return this._node;
    }

    setX(x) {
        this._node.style.left = x + 'px';
    }

    activate(tableNode) {
        tableNode.appendChild(this._node);
    }

    deactivate() {
        if (this._node.parentNode) {
            this._node.parentNode.removeChild(this._node);
        }
    }

    isActivated() {
        return !!this._node.parentNode;
    }
}

const GuidelineFlyweight = new $GuidelineFlyweight();

class Guideline extends React.Component {
    constructor(props) {
        super(props);
    }

    setX(x) {
        if (x !== null) {
            if (!GuidelineFlyweight.isActivated()) {
                GuidelineFlyweight.activate(this.props.tableNode);
            }

            GuidelineFlyweight.setX(x);
        }
        else {
            GuidelineFlyweight.deactivate();
            GuidelineFlyweight.setX(0);
        }
    }

    render() {
        return null;
    }
}

Guideline.propTypes = {
    tableNode: PropTypes.any
};

// =============================================================================
// Table
// =============================================================================

class Table extends TPComponent {
    constructor(props) {
        super(props);

        this.isUnmounting = false;
                
        if (!this.props.definitionFactory) {
            throw new Error('definitionFactory prop is required. Expecting a TableDefinitionFactory interface');
        }

        this.state.filterRules = this.props.defaultRules || [];

        this.id = IDGenerator.generate();
        this._lastSheetRemoveOp = null;

        this._hoverStyle = `hover-style-${this.id}`;
        this._selectedStyle = `selected-style-${this.id}`;
        this._refHandlers = {};
        this._styleManager = new StylesheetManager();
        this._headerGrid;
        this._dataGrid;
        this._node;
        this._comparisonComponentNode;
        this._filterStrategy;
        this._remainingColumnWidth = 0;
        this._autoWidthPerColumn = 0;
        this._minColumnWidth = 120;
        this._textFieldFactory = new TextfieldFactory();
        this._defaultValueCurrencyPropsBuilder = new CurrencyTextfieldPropsBuilder()
            .setDisplayType(NumberFormatTextfield.DisplayType.SPAN);
    
        // =====================================================================
        // Hook bindings
        // =====================================================================
        this._initChangeset = this._initChangeset.bind(this);
        this._defaultNoContentRenderer = this._defaultNoContentRenderer.bind(this);
        this.calculateColumnWidth = this.calculateColumnWidth.bind(this);
        this._headerCellRenderer = this._headerCellRenderer.bind(this);
        this._contentCellRenderer = this._contentCellRenderer.bind(this);
        this._onSelectAllChange = this._onSelectAllChange.bind(this);
        this._onCellEnter = this._onCellEnter.bind(this);
        this._onCellLeave = this._onCellLeave.bind(this);
        this._onKeyUp = this._onKeyUp.bind(this);
        this._onSelectionChange = this._onSelectionChange.bind(this);
        this._onViewportUpdate = this._onViewportUpdate.bind(this);
        this._onUserUpdate = this._onUserUpdate.bind(this);
        this._onFilter = this._onFilter.bind(this);
        this._applyFilterRules = this._applyFilterRules.bind(this);
        this._onComparisonComponentOpen = this._onComparisonComponentOpen.bind(this);
        this._onComparisonComponentClose = this._onComparisonComponentClose.bind(this);
    }

    willEmitEvents() {
        return true;
    }

    filterUnsupportedHeaders(headers) {
        let filteredHeaders = [];
        for (let i = 0; i < headers.length; i++) {
            let header = headers[i];
            if (this.props.definitionFactory.hasDefinition(header)) {
                filteredHeaders.push(header);
            }
        }
        return filteredHeaders;
    }

    _initState(props) {
        let data = (this.props.data || []).slice()
        this._selectionContext = (props.selection ? props.selection.clone() : null) || new SelectionContext([], this.props.totalRowCount ? this.props.totalRowCount : data.length);

        return {
            loading: false,
            data : data,
            editing: false,
            changesets : new Immutable(),
            headers : this.filterUnsupportedHeaders((this.props.headers || []).slice()),
            selectAllValue : data.length === 0 ? SelectionState.UNCHECKED : this._selectionContext.getAllSelectionState(),
            columnWidths: this._getColumnWidths(),
            forceUpdate: 0,
            guidelinePos: null,
            filterRules: [],
            filteredData: data, // data set filtered by FilterRules
            view: [], // filteredData that may have been filtered further by other systems
            sortColumn: this.props.defaultSortColumn || this.getIDKey(),
            sortReverse: this.props.defaultSortReverse  || false
        };
    }

    _defaultIsRowEnabledFunc(row) {
        return true;
    }

    _defaultValueRenderer(value, row, header) { 
        if (header.type === DataType.NUMBER && !ObjectUtils.isVoid(header.extra) && header.extra.numberType === TableNumberType.CURRENCY) {
            return this._textFieldFactory.create(
                this._defaultValueCurrencyPropsBuilder
                    .setPlaceholder(header.extra.placeholder)
                    .setValue(value)
                    .build()
            );
        }
        else {
            if (value === undefined || value === null) {
                value = '';
            }
        }

        return value;
    }

    _getColumnWidths() {
        let saveKey = this.props.columnWidthSaveKey;
        if (saveKey) {
            let widths = PreferenceStore.getInstance().getOrganizationPreference(saveKey);
            if (widths !== null) {
                try {
                    widths = JSON.parse(widths);
                }
                catch (ex) {
                    widths = this._getDefaultColumnWidths();
                }
            }
            else {
                widths = this._getDefaultColumnWidths();
            }
            return widths;
        }
        else {
            return this._getDefaultColumnWidths();
        }
    }

    _getDefaultColumnWidths() {
        let widths = {...(ObjectUtils.isVoid(this.props.defaultColumnWidths) ? {} : this.props.defaultColumnWidths)};
        if (this.isSelectionCheckboxEnabled()) {
            widths['0'] = CHECKBOX_COL_WIDTH;
        }
        return widths;
    }

    shouldUpdateOnViewportChange() {
        return this.props.updateOnViewportChange || this.props.updateOnViewportChange === undefined;
    }

    _getRenderNode() {
        throw new Error("Table._getRenderNode is abstract. Get the ref of the node returned in _render and return it here.");
    }

    _onUserUpdate() {
        if (this.props.columnWidthSaveKey) {
            let widths = this._getColumnWidths();
            this.setState({
                columnWidths : widths
            });
        }
    }

    _onViewportUpdate() {
        if (this.shouldUpdateOnViewportChange()) {
            this.forceUpdate();
        }
    }

    isDeletable() {
        let enabled = this.props.deletable;
        return enabled || enabled === undefined;
    }

    isEditable() {
        let enabled = this.props.editable;
        return enabled || enabled === undefined;
    }

    isBulkEditable() {
        let enabled = this.props.bulkEditable;
        return this.isEditable() && (enabled || enabled === undefined);
    }

    getNode() {
        return this._node;
    }

    _getComparisonComponentNode() {
        return this._comparisonComponentNode;
    }

    getID() {
        return this.id;
    }

    getIDKey() {
        return this.props.idKey;
    }

    indexOf(id) {
        for (let i = 0; i < this.state.view.length; i++) {
            let d = this.state.view[i];
            if (!d) {
                continue; // data not loaded
            }
            if (d[this.getIDKey()] === id) {
                return i;
            }
        }

        return -1;
    }

    seek(rowIndex) {
        this._dataGrid.scrollToCell({
            columnIndex: 0,
            rowIndex: rowIndex
        });
    }

    getHeaders() {
        return this.state.headers;
    }

    getHeaderByKey(key) {
        return this.props.definitionFactory.create(key);
    }

    getData() {
        return this.props.showLoadingMock ? LOADING_MOCK_DATA : (this.state.data || []);
    }

    getViewData() {
        return this.props.showLoadingMock ? LOADING_MOCK_DATA : (this.state.view || []);
    }

    setHeaderGrid(grid) {
        this._headerGrid = grid;
    }

    setDataGrid(grid) {
        this._dataGrid = grid;
    }

    getHeaderGrid() {
        return this._headerGrid;
    }

    getDataGrid() {
        return this._dataGrid;
    }

    isEditing() {
        return this.state.editing;
    }

    setAutoWidthPerColumn(width) {
        this._autoWidthPerColumn = width;
    }

    componentDidMount() {
        this.isUnmounting = false;
        ViewportStore.getInstance().register(this._onViewportUpdate);
        KeyboardListener.register(KeyboardListener.E_KEYUP, this._onKeyUp);
        UserStore.getInstance().register(this._onUserUpdate);
        this._applyFilterRules();
    }

    getSortSetting() {
        return {
            column: this.state.sortColumn,
            descending: this.state.sortReverse
        };
    }

    forceUpdate() {
        this.setState({
            forceUpdate : this.state.forceUpdate + 1
        }, () => {
            this.recompute();
        });
    }

    recompute() {
        if (this._headerGrid) {
            this._headerGrid.recomputeGridSize();
        }
        if (this._dataGrid) {
            this._dataGrid.recomputeGridSize();
        }
    }

    getTableEditorFactory() {
        if (this.props.tableEditorFactory) {
            return this.props.tableEditorFactory;
        }
        else {
            return tableEditorFactory;
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            headers: this.filterUnsupportedHeaders((nextProps.headers || []).slice()),
            data : (nextProps.data || []).slice()
        });

        this._applyFilterRules(nextProps.data, false).then(() => {
            this._refilter(this.state.filteredData, this.state.sortColumn, this.state.sortReverse, nextProps.query);
        }).catch((error) => {
            new (ApplicationInstance.getInstance().getErrorClass())({
                message: "Table.UNSAFE_componentWillReceiveProps errored out when filtering data.",
                error: error,
                stack: new Error().stack
            });
            this._refilter(this.state.filteredData, this.state.sortColumn, this.state.sortReverse, nextProps.query);
        });
    }

    componentDidUpdate() {
        this._selectionContext.setTotal(this.getTotalRowCount());
        this.recompute();
    }

    componentWillUnmount() {
        this.isUnmounting = true;
        ViewportStore.getInstance().unregister(this._onViewportUpdate);
        UserStore.getInstance().unregister(this._onUserUpdate);
        KeyboardListener.unregister(KeyboardListener.E_KEYUP, this._onKeyUp);
    }

    _onKeyUp(e) {
        if (e.keyCode !== KeyCode.ENTER) {
            return;
        }

        if (Browser.isIE()) {
            return;
        }

        if (this.isEditing()) {
            let node = new Node(e.target);
            while (node && !node.containsClass('cell')) {
                node = node.getParent();
            }

            if (!node || (node && !node.containsClass('cell'))) {
                return;
            }

            //Here we have a node that is the cell, and we can obtain the row/cell to
            //seek to next cell to the bottom

            let target = node.getNextSibling();
            if (!target) {
                return;
            }

            //Seek to the next row of the same column, ignoring rows that are not selected.
            while (target && (target.getDataValue('column') !== node.getDataValue('column') || (target.getDataValue('column') === node.getDataValue('column') && !target.containsClass('selected')))) {
                target = target.getNextSibling();
            }

            if (!target) {
                return;
            }

            let inputField = target.querySelector('input');
            if (inputField) {
                inputField.focus();
            }
        }
    }

    calculateColumnWidth({index}) {
        let width = null;

        if (this.isSelectionCheckboxEnabled()) {
            if (index > 0) {
                let header = this.props.definitionFactory.create(this.getHeaderKey(index));
                width = this.state.columnWidths[header.key];
            }
            else {
                width = this.state.columnWidths['0'];
            }
        }
        else {
            let header = this.props.definitionFactory.create(this.getHeaderKey(index));
            width = this.state.columnWidths[header.key];
        }
        
        if (!width) {
            width = this._autoWidthPerColumn;
        }

        if (index > 0 && width < this._minColumnWidth) {
            width = this._minColumnWidth;
        }

        if ((this.isSelectionCheckboxEnabled() && index > 0) || !this.isSelectionCheckboxEnabled()) {
            let header = this.props.definitionFactory.create(this.getHeaderKey(index));
            let text = header.text;
            let minWidth = TextMeasurer.measure(text) + PADDING_FOR_CARET_ICONS;
            if (width < minWidth) {
                width = minWidth;
            }
        }

        if (isNaN(width)) {
            width = this._minColumnWidth;
        }

        return width;
    }

    getTotalRowCount() {
        if (!ObjectUtils.isVoid(this.props.totalRowCount)) {
            let rowCount = this.props.totalRowCount;
            if (typeof rowCount !== 'number') {
                rowCount = parseInt(rowCount);
                if (isNaN(rowCount)) {
                    rowCount = this.getData().lengh;
                }
            }
            return rowCount;
        }
        else {
            return this.getData().length;
        }
    }

    calculateAutoWidths() {
        let w = 0;
        if (this._getRenderNode()) {
            w = this._getRenderNode().clientWidth;
        }

        let totalWidth = 0;
        let remainingWidth = 0;
        let columnWidths = this.state.columnWidths;

        for (let i in columnWidths) {
            totalWidth += columnWidths[i];
        }

        if (totalWidth < w) {
            remainingWidth = w - totalWidth;
        }

        if (this.getColumnCount() - Object.keys(columnWidths).length === 0) {
            //Prevent dividing by 0
            return this._minColumnWidth;
        }

        return remainingWidth / (this.getColumnCount() - Object.keys(columnWidths).length);
    }

    getColumnCount(nextState) {
        if (!nextState) {
            nextState = this.state;
        }

        //+1 because the select checkbox column is not part inside the headers dataset.
        return (this.state.headers || []).length + (this.isSelectionCheckboxEnabled() ? 1 : 0);
    }

    getRowCount() {
        return (this.getViewData() || []).length;
    }

    // FilterUI callback
    _onFilter(rules) {
        this.setState({
            filterRules: rules
        }, () => {
            this.props.onFilterChange && this.props.onFilterChange(rules);
            this._applyFilterRules().then(() => {
                this._onSelectionChange();
            });
        });
    }

    // parameter can be used when in functions like componentWillReceiveProps
    _applyFilterRules(data, shouldRefilter) {
        if (!this._filterStrategy) {
            this._filterStrategy = new FilterStrategy();
        }

        if (ObjectUtils.isVoid(shouldRefilter)) {
            shouldRefilter = true;
        }

        if (!data) {
            data = this.state.data;
        }

        // Wanted to avoid doing this; but, it ended up being required for UNSAFE_componentWilLReceiveProps
        // The only other alternative was to pass through nextProps.query to _refilter.
        return new Promise((resolve) => {
            this.setState({
                filteredData: this._filterStrategy.filter(data, this.state.filterRules)
            }, () => {
                if (shouldRefilter) {
                    this._refilter(this.state.filteredData).then(resolve).catch(resolve); // .catch(resolve) is NOT an typo
                }
                else {
                    resolve();
                }
            });
        });
    }

    // existing, untouched code
    _refilter(data, sortColumn, sortReverse, query) {
        if (data === undefined) {
            data = this.state.data;
        }

        if (sortColumn === undefined) {
            sortColumn = this.state.sortColumn;
        }

        if (sortReverse === undefined) {
            sortReverse = this.state.sortReverse;
        }

        if (query === undefined) {
            query = this.props.query;
        }

        // Paginated tables must handle filtering and sorting themselves.
        // TODO: Separate Filtering logic to a swappable strategy?
        if (this.isPaginated()) {
            return new Promise((resolve, reject) => {
                this.setState({
                    view : data,
                    sortColumn,
                    sortReverse
                }, resolve);
            });
        }

        let view = data;
        if (query) {
            view = QueryFilter.execute(view, query, (item, query) => {
                let value;

                query = query.toLowerCase();

                if (item !== null && item !== undefined) {
                    value = item.toString().toLowerCase();
                }
                else {
                    value = '';
                }

                if (value.indexOf(query) > -1) {
                    return true;
                }
            });
        }

        let compareFn;
        if (this.state.editing) {
            compareFn = (a, b, ret) => {
                let aSelected = this.isSelected(a.id);
                let bSelected = this.isSelected(b.id);

                if (aSelected && bSelected) {
                    return ret;
                }
                else if (aSelected && !bSelected) {
                    return -1;
                }
                else if (!aSelected && bSelected) {
                    return 1;
                }
                else {
                    return ret;
                }
            };
        }

        if (sortColumn !== undefined) {
            view = OrderFilter.execute(view, sortColumn, sortReverse, compareFn);
        }
        else {
            view = OrderFilter.execute(view, null, null, compareFn);
        }

        return new Promise((resolve) => {
            this.setState({
                view : view,
                sortReverse : sortReverse,
                sortColumn : sortColumn
            }, resolve);
        });
    }

    _changeSortDirection(headerKey) {
        let newSortColumn;
        let newSortReverse;

        if (this.state.sortColumn !== headerKey) {
            newSortColumn = headerKey;
            newSortReverse = false;
        }
        else {
            newSortColumn = this.state.sortColumn;
            newSortReverse = !this.state.sortReverse;
        }

        this._refilter(this.state.filteredData, newSortColumn, newSortReverse).then(() => {
            if (this.props.onSortChange) {
                this._onLoadingStateChange(true);
                let promise = this.props.onSortChange(newSortColumn, newSortReverse);
                if (!promise) {
                    // For backwards compatibility
                    promise = Promise.resolve();
                }
                promise.then(() => {
                    this._onLoadingStateChange(false);
                }).catch(() => {
                    this._onLoadingStateChange(false);
                });
            }
        });
    }

    _onSelectionChange() {
        if (this.props.onSelectionChange) {
            this.props.onSelectionChange(this.getSelectionContext());
        }
    }

    _onSelectAllChange(value) {
        this.setState({
            selectAllValue: this.getSelectionContext().onSelectAllClick()
        }, this._onSelectionChange);
    }

    _getCellNode(node) {
        return node;
    }

    isPaginated() {
        return false;
    }

    enableFiltering() {
        return this.props.enableFiltering || this.props.enableFiltering === undefined;
    }

    isSelectionCheckboxEnabled() {
        let value;
        if (ObjectUtils.isVoid(this.props.enableSelectionCheckbox)) {
            value = true;
        }
        else {
            value = this.props.enableSelectionCheckbox;
        }

        return value;
    }

    getColumnIndexMapping(index) {
        return index - (this.isSelectionCheckboxEnabled() ? 1 : 0);
    }

    getHeaderKey(index) {
        return this.state.headers[this.getColumnIndexMapping(index)];
    }

    _headerCellRenderer({columnIndex, key, rowIndex, style}) {
        let cellContent;
        let guidelineOffset = 0;

        let sortUI;
        if (this.isSelectionCheckboxEnabled() && columnIndex === 0) {
            cellContent = <Checkbox key={`content-${rowIndex}-${columnIndex}`} value={this.state.selectAllValue} readonly={this.props.selectable === false || this.state.editing} onChange={this._onSelectAllChange} />;
        }
        else {
            let caretUpStyle = {};
            let caretDownStyle = {};
            let header = this.props.definitionFactory.create(this.getHeaderKey(columnIndex));
            guidelineOffset = header.guidelineOffset || 0;
            let sortColumn = this.state.sortColumn;
            let sortReverse = this.state.sortReverse;

            if ((sortColumn !== header.key || (sortColumn === header.key && (sortReverse === null || sortReverse === true)))) {
                caretUpStyle.visibility = 'visible';
            }
            else {
                caretUpStyle.visibility = 'hidden';
            }

            if ((sortColumn !== header.key || (sortColumn === header.key && (sortReverse === null || sortReverse === false)))) {
                caretDownStyle.visibility = 'visible';
            }
            else {
                caretDownStyle.visibility = 'hidden';
            }

            let headerText;
            if (typeof header.text === 'function') {
                headerText = header.text();
            }
            else {
                headerText = header.text;
            }

            sortUI = <div className="sort-ui" key={`sort-ui-${rowIndex}-${columnIndex}`}>
                {IconFactory.create('caret-up', {
                    style : caretUpStyle
                })}
                {IconFactory.create('caret-down', {
                    style: caretDownStyle
                })}
            </div>;
            cellContent = <span key={`content-${rowIndex}-${columnIndex}`}>{headerText}</span>;
        }

        let guideline = null;

        let mainCellContent = [
            <Guideline key="guideline" tableNode={this._node} ref={(c) => {guideline = c;}} />,
            cellContent,
            sortUI,
            <DraggableComponent
                key={`draggable-${rowIndex}-${columnIndex}`}
                axis='x'
                zIndex={999}
                onDrag={(data) => {
                    let node = data.node.parentNode;

                    if (!node || !guideline) {
                        return;
                    }

                    guideline.setX(data.x + guidelineOffset - NodeGeometry.getAbsoluteX(this.getNode()));
                }}
                onDragStart={(event) => {
                    this._ignoreClicks = true;
                    event.preventDefault();
                }}
                onDragStop={(data) => {
                    setTimeout(() => {
                        this._ignoreClicks = false;
                    }, 1);

                    let key;
                    if (this.isSelectionCheckboxEnabled() && columnIndex === 0) {
                        key = 0;
                    }
                    else {
                        let header = this.props.definitionFactory.create(this.getHeaderKey(columnIndex));
                        key = header.key;
                    }

                    let node = this._getCellNode(data.node.parentNode);
                    
                    let bounds = node.getBoundingClientRect();
                    let pos = NodeGeometry.getRelativeX(node, this.getNode()) + bounds.width - NodeGeometry.getScrollX(node);

                    let width = node.clientWidth;
                    width += data.x + guidelineOffset - pos - NodeGeometry.getAbsoluteX(this.getNode());
                    let columnWidths = this.state.columnWidths;
                    columnWidths[key] = width;
                    guideline.setX(null);

                    this.setState({
                        columnWidths : columnWidths
                    }, () => {
                        if (this.props.columnWidthSaveKey) {
                            SaveOrganizationPreference.execute({preferenceKey: this.props.columnWidthSaveKey, version: this._getColumnPreferenceVersion(), value: columnWidths}).catch((e) => {
                                TPError.wrap(e).dispatch();
                            });
                        }

                        if (this._headerGrid && this._headerGrid.recomputeGridSize) {
                            this._headerGrid.recomputeGridSize({
                                columnIndex: columnIndex,
                                rowIndex: rowIndex
                            });
                        }
                        if (this._dataGrid && this._dataGrid.recomputeGridSize) {
                            this._dataGrid.recomputeGridSize({
                                columnIndex: columnIndex,
                                rowIndex: rowIndex
                            });
                        }
                    });
                }}
                position={{x:0, y:0}}
            >
                <div className="cell-resizer">{Entities.NBSP}</div>
            </DraggableComponent>
        ];

        let mainCell = <div 
            data-column={columnIndex} 
            key={key} 
            className="cell" 
            style={style} 
            onClick={() => {
                if (this._ignoreClicks) {
                    return;
                }

                if (columnIndex > 0 || !this.isSelectionCheckboxEnabled()) {
                    let header = this.props.definitionFactory.create(this.getHeaderKey(columnIndex));
                    this._changeSortDirection(header.key);
                }
            }}
        >
            {mainCellContent}
        </div>;

        return mainCell;
    }

    _getColumnPreferenceVersion() {
        return this.props.columnPreferenceVesion || 1;
    }

    _contentCellRendererNotEditing(columnIndex, key, rowIndex) {
        let cellContent;
        let header = this.props.definitionFactory.create(this.getHeaderKey(columnIndex));
        let renderer = header.renderer;

        if (!renderer) {
            renderer = this._defaultValueRenderer;
        }

        let viewData = this.getViewData()[rowIndex];
        let value = viewData[header.key];
        value = renderer(value, viewData, header);

        if (value === undefined || value === null || value === '') {
            value = '-';
        }
        cellContent = <span key={key} style={header.valueContainerStyle}>{value}</span>;
        return cellContent;
    }

    //protected, modifies the second parameter, changesets, and returns a new copy of it.
    _initChangeset(rowData, changesets) {
        let id = rowData[this.getIDKey()];
        if (!changesets.get(id)) {
            changesets = changesets.set(id, ObjectUtils.clone(rowData));
        }

        changesets.get(id).__original = rowData;
        return changesets;
    }

    _contentCellRendererEditing(columnIndex, key, rowIndex) {
        let editorFactory = this.getTableEditorFactory()
        let data = this.props.definitionFactory.create(this.getHeaderKey(columnIndex));
        let viewData = this.getViewData()[rowIndex];
        let id = viewData[this.getIDKey()];
        let changesets = this.state.changesets;
        changesets = this._initChangeset(viewData, changesets);

        if (data.type === TableEditorFactory.CUSTOM) {
            return this._contentCellRendererNotEditing(columnIndex, key, rowIndex);
        }
        else {
            return editorFactory.create(data.type, changesets.get(id)[data.key], (value) => {
                changesets.get(id)[data.key] = value;
                this.setState({
                    changesets : changesets.clone()
                }, () => {
                    if (this.props.onChange) {
                        this.props.onChange(data.key, value, rowIndex, columnIndex, viewData);
                    }
                });
            }, data.extra, data, key);
        }
    }

    updateDataPoint(rowIndex, columnIndex, dataPoint) {
        let view = this.getViewData();
        view[rowIndex] = dataPoint;
        this.setState({
            view : view
        });
    }

    revertChangesets(changesets) {
        let dataset = this.state.data;
        let iterator = changesets.iterator();

        for (let i = 0; i < dataset.length; i++) {
            let data = dataset[i];
            iterator.reset();
            while (iterator.hasNext()) {
                let changeset = iterator.next().value;
                if (data[this.getIDKey()] === changeset[this.getIDKey()]) {
                    data = changeset.__original;
                    dataset[i] = data;
                }
            }
        }

        this.setState({
            data : dataset
        }, this._applyFilterRules);
    }

    _onCellEnter(event) {
        let target = event.target;
        if (target.className.indexOf('cell') === -1) {
            do {
                target = target.parentNode;
            } while (target && target.className.indexOf('cell') === -1)
            if (!target) {
                return;
            }
        }

        let row = target.dataset.row;
        let node;
        if (row) {
            let interestedNodes = [target];
            
            node = target.previousElementSibling;

            if (node && node.dataset && node.dataset.row === row) {
                do {
                    interestedNodes.push(node);
                    node = node.previousElementSibling;
                } while (node && node.dataset && node.dataset.row === row)
            }

            node = target.nextElementSibling;
            if (node && node.dataset && node.dataset.row === row) {
                do {
                    interestedNodes.push(node);
                    node = node.nextElementSibling;
                } while (node && node.dataset && node.dataset.row === row)
            }

            for (let i = 0; i < interestedNodes.length; i++) {
                node = interestedNodes[i];
                if (node.classList.contains('highlighted')) {
                    let color = this.props.highlightColor ? this.props.highlightColor.lighten(0.1) : HIGHLIGHT_COLOR.lighten(0.1);
                    node.style.backgroundColor = color.toString();
                }
                else {
                    node.style.backgroundColor = HOVER_COLOR.toRGBString();
                }
            }
        }
    }

    _onCellLeave(event) {
        let target = event.target;
        if (target.className.indexOf('cell') === -1) {
            do {
                target = target.parentNode;
            } while (target && target.className.indexOf('cell') === -1)
            if (!target) {
                return;
            }
        }
        
        let row = target.dataset.row;
        if (row) {

            let interestedNodes = [target];
            
            let node = target.previousElementSibling;

            if (node && node.dataset && node.dataset.row === row) {
                do {
                    interestedNodes.push(node);
                    node = node.previousElementSibling;
                } while (node && node.dataset && node.dataset.row === row)
            }

            node = target.nextElementSibling;
            if (node && node.dataset && node.dataset.row === row) {
                do {
                    interestedNodes.push(node);
                    node = node.nextElementSibling;
                } while (node && node.dataset && node.dataset.row === row)
            }

            for (let i = 0; i < interestedNodes.length; i++) {
                node = interestedNodes[i];
                if (node.classList.contains('highlighted')) {
                    node.style.backgroundColor = this.props.highlightColor ? this.props.highlightColor.toString() : HIGHLIGHT_COLOR.toString();
                }
                else {
                    node.style.backgroundColor = '';
                }
            }
        }
    }

    shouldCellReceiveHighlighting() {
        return true;
    }

    _contentCellRenderer({columnIndex, key, rowIndex, style}) {
        if (this.props.showLoadingMock) {
            return this._contentCellRendererLoadingMock({
                columnIndex, key, rowIndex, style
            });
        }
        else {
            return this._contentCellRendererImpl({
                columnIndex, key, rowIndex, style
            });
        }
    }

    _contentCellRendererLoadingMock({columnIndex, key, rowIndex, style}) {
        return (
            <div 
                className="cell"
                data-column={columnIndex}
                data-row={rowIndex}
                key={key}
                style={{
                    ...style,
                    animationDelay: `-${columnIndex}s`
                }}
            >
                {columnIndex === 0 ? this.$renderCellCheckbox(key, null, null, false) : <UIPlaceholder /> }
            </div>
        )
    }

    $renderCellCheckbox(id, item, selectionContext, rowEnabled) {
        return (
            <Checkbox 
                value={selectionContext ? selectionContext.isSelected(id) : 0}
                readonly={this.props.selectable === false || !rowEnabled || !this._isRowSelectable(item) || this.state.editing} 
                onDisabledClick={rowEnabled ? null : this.props.onDisabledRowClick}
                onChange={(value) => {
                    if (value) {
                        selectionContext.add(id);
                    }
                    else {
                        selectionContext.remove(id);
                    }

                    if (this.state.selectAllValue !== selectionContext.getAllSelectionState()) {
                        this.setState({
                            selectAllValue : selectionContext.getAllSelectionState()
                        }, () => {
                            this._onSelectionChange();
                        });
                    }
                    else {
                        this.forceUpdate();
                        this._onSelectionChange();
                    }
                }}
            />
        );
    }

    _contentCellRendererImpl({columnIndex, key, rowIndex, style}) {
        let cellContent;
        let item = this.getViewData()[rowIndex];
        let id = item[this.getIDKey()];
        let rowEnabled = this._isRowEnabled(item);
        let header = null;
        let readonly = null;
        let value = null;
        let isLoading = false;
        
        if (columnIndex > 0 || !this.isSelectionCheckboxEnabled()) {
            header = this.props.definitionFactory.create(this.getHeaderKey(columnIndex));
            readonly = header.readonly;
            value = item[header.key];
        }

        if (header) {
            isLoading = typeof header.isLoading === 'function' ? header.isLoading(value) : header.isLoading;
        }

        // If readonly is not already true, then check if the row is editable
        // if it's not editable, then readonly must be flipped to true
        // TODO: optimization opportunity, as this is a row check, perhaps we
        // can lift this somehow, but it might not play well with the underlying
        // virtualization library.
        if (readonly !== true && this.props.isRowEditable) {
            readonly = !this.props.isRowEditable(item);
        }

        // If readonly is not already true, check the column readonly flag
        if (readonly !== true && typeof readonly === 'function') {
            readonly = readonly(value, item);
        }

        let selectionContext = this.getSelectionContext();

        if (this.isSelectionCheckboxEnabled() && columnIndex === 0) {
            cellContent = this.$renderCellCheckbox(id, item, selectionContext, rowEnabled);
        }
        else if (isLoading) {
            cellContent = <UIPlaceholder />;
        }
        else {
            if (this.state.editing && !readonly && this.isSelected(id)) {
                cellContent = this._contentCellRendererEditing(columnIndex, key, rowIndex, style);
            }
            else {
                cellContent = this._contentCellRendererNotEditing(columnIndex, key, rowIndex, style);
            }
        }

        let cellStyle = {};
        for (let i in style) {
            cellStyle[i] = style[i];
        }

        let highlightKey = this.props.highlightKey || this.getIDKey();
        let highlightId = item[highlightKey];

        if (this.shouldCellReceiveHighlighting() && this.isSelected(id)) {
            cellStyle.backgroundColor = SELECTED_COLOR.toString();
        }
        else if (this.shouldCellReceiveHighlighting() && !this.isSelected(id) && this.isHighlighted(highlightId)) {
            cellStyle.backgroundColor = this.props.highlightColor ? this.props.highlightColor.toString() : HIGHLIGHT_COLOR.toString();
        }
        else {
            cellStyle.backgroundColor = '';
        }

        let title = null;
        if (this.state.editing && readonly) {
            let customTitle = header?.readonlyTitle;
            if (typeof customTitle === 'function') {
                customTitle = customTitle(value, item);
            }
            title = !customTitle ? 'This field is readonly' : customTitle;
        }
        else if (!rowEnabled) {
            if (typeof this.props.rowDisabledTitle === 'function') {
                title = this.props.rowDisabledTitle(value, item);
            }
            else {
                title = this.props.rowDisabledTitle;
            }
        }

        return <div 
            data-column={columnIndex} 
            data-row={rowIndex} 
            data-id={id}
            key={key} 
            title={title}
            className={ClassName.execute({
                'cell': true,
                'selected': this.shouldCellReceiveHighlighting() && this.isSelected(id),
                'highlighted': this.shouldCellReceiveHighlighting() && !this.isSelected(id) && this.isHighlighted(highlightId),
                'readonly' : this.state.editing && readonly || !rowEnabled,
                'lock': !rowEnabled && !this.props.onDisabledRowClick
            })}
            style={cellStyle}
            onClick={() => {
                if (!rowEnabled && this.props.onDisabledRowClick) {
                    this.props.onDisabledRowClick(id, item);
                }
                else if (rowEnabled && this.props.onRowClick) {
                    this.props.onRowClick(id, item);
                }
            }}
            onDoubleClick={() => {
                if (rowEnabled && this.props.onRowDoubleClick) {
                    this.props.onRowDoubleClick(id, item);
                }
            }}
            onMouseEnter={(event) => {
                if (rowEnabled) {
                    this._onCellEnter(event);
                }
            }}
            onMouseLeave={(event) => {
                if (rowEnabled) {
                    this._onCellLeave(event);
                }
            }}
        >
            {cellContent}
        </div>;
    }
    
    _isRowEnabled(row) {
        let func = this.props.isRowEnabled || this._defaultIsRowEnabledFunc;
        return func(row);
    }

    _isRowSelectable(row) {
        return this.props.isRowSelectable ? this.props.isRowSelectable(row) : true;
    }

    isHighlighted(id) {
        return this.props.highlight instanceof Array ? (this.props.highlight.indexOf(id) !== -1) : (this.props.highlight === id);
    }

    getRowHeight() {
        return ROW_HEIGHT;
    }

    getColumnWidth(columnIndex) {
        ApplicationInstance.getInstance().getLogger().deprecate();
        return this.state.columnWidths[columnIndex];
    }

    getNoContentRenderer() {
        return this.props.noContentRenderer || this._defaultNoContentRenderer;
    }

    _defaultNoContentRenderer() {
        return <div className="no-content-table-data-view">No content available</div>;
    }

    getClassName() {
        throw new Error('Table.getClassName() is abstract.');
    }

    _render() {
        throw new Error('Table._render() is abstract');
    }

    _onComparisonComponentOpen() {
        this.forceUpdate();
    }

    _onComparisonComponentClose() {
        this.forceUpdate();
    }
    
    render() {
        return (
            <div
                className={ClassName.execute({
                    'editing': this.state.editing,
                    'loading-mock': this.props.showLoadingMock
                }, [
                    'Table',
                    this.getClassName()
                ])}
                ref={(c) => {this._node = c;}}
            >
                {this.props.enableComparisonComponent !== false &&
                    <ComparisonComponent 
                        ref={(ref) => { this._comparisonComponentNode = ref; }}
                        onFilter={this._onFilter} 
                        width={250} 
                        tableDefinitionFactory={this.props.definitionFactory}
                        onOpen={this._onComparisonComponentOpen}
                        onClose={this._onComparisonComponentClose}
                        rules={this.state.filterRules}
                        isOpenOnMount={this.props.isComparisonComponentOpenOnMount}
                        floatAddButton={this.props.filterFloatAddButton}
                        loading={this.state.loading}
                    />
                }
                {this._render()}
            </div>
        );
    }

    _renderDeletePrompt(selectionContext) {
        return new Promise((resolve, reject) => {
            if (this.props.renderDeletePrompt) {
                this.props.renderDeletePrompt(selectionContext).then(resolve).catch(reject);
            }
            else {
                let selectionCount = this.getSelectionCount();

                let promptMessage;
                if (this.props.getDeletePromptMessage) {
                    promptMessage = this.props.getDeletePromptMessage(selectionCount);
                }
                else {
                    promptMessage = `Are you sure you want to delete ${selectionCount} selected ${selectionCount > 1 ? 'items' : 'item'}`;
                }

                let answer = window.confirm(`${promptMessage}? This action is not reversible!`);

                resolve(answer);
            }
        });
    }

    _onLoadingStateChange(isLoading) {
        if (this.state.loading !== isLoading) {
            this.setState({
                loading: isLoading
            });
            this._emit(Table.Events.LOADING_STATE_CHANGE, isLoading);
            if (this.props.onLoadingStateChange) {
                this.props.onLoadingStateChange(isLoading);
            }
        }
    }

    _onDelete(selectionContext) {
        let data = SelectionContextAdapter.adapt(this, selectionContext);
        let dataset = this.state.data;
        let ids = [];
        let newDataset = [];
        let item;
        let triedToDeleteUndeletableRow = false;

        for (let i = 0; i < data.length; i++) {
            item = data[i];
            if (!item) {
                continue; // row not loaded
            }
            if (this.props.isRowDeletable && !this.props.isRowDeletable(item)) {
                triedToDeleteUndeletableRow = true;
                continue; // row not deletable
            }

            item.__deleted = true;
            ids.push(item[this.getIDKey()]);
        }

        if (triedToDeleteUndeletableRow) {
            DispatchMessage.getInstance().execute({
                message: 'Some rows were not deleted due to them be undeletable.',
                type: MessageType.ERROR,
                title: 'Error'
            });
        }

        if (ids.length === 0) {
            // There was no deletable rows selected.
            return;
        }

        for (let j = 0; j < dataset.length; j++) {
            item = dataset[j];
            if (ids.indexOf(item[this.getIDKey()]) === -1) {
                newDataset.push(item);
            }
        }

        let onDeleteReturnValue = this.props.onDelete(ids);
        if (onDeleteReturnValue === undefined) {
            onDeleteReturnValue = true;
        }

        if (onDeleteReturnValue === true) {
            this._clearDataset(newDataset);
        }
        else if (onDeleteReturnValue instanceof Promise) {
            onDeleteReturnValue.then((shouldDelete) => {
                if (shouldDelete === undefined) {
                    shouldDelete = true;
                }

                if (shouldDelete) {
                    this._clearDataset(newDataset);
                }
            });
        }
    }

    /**
     * Data is an interface that looks like:
     * 
     * {
     *      "column": string;
     *      "value": any;
     * }
     * 
     * @param {*} data 
     */
    onBulkEdit(data) {
        let selected = SelectionContextAdapter.adapt(this, this.getSelectionContext());
        let changesets = this.state.changesets;
        return new Promise((resolve) => {
            for (let i = 0, length = selected.length; i < length; ++i) {
                let selectedData = selected[i];
                let readonly = this.props.definitionFactory.getDefinition(data.column).readonly;
                if ((typeof readonly === 'function' && readonly(selectedData[data.column], selectedData) === false) || readonly !== true) {
                    changesets = this._initChangeset(selectedData, changesets);
                    changesets.get(selectedData[this.props.idKey])[data.column] = data.value;
                }
            }

            this.setState({
                changesets : changesets.clone()
            }, () => {
                if (this.props.onChange) {
                    for (let i = 0, length = selected.length; i < length; ++i) {
                        let selectedData = selected[i];
                        this.props.onChange(data.column, data.value, this.indexOf(selectedData.id), this.state.headers.indexOf(data.column) + 1, selectedData);
                    }
                }
                resolve();
            });
        }).then(() => {
            this._onEditSubmission();
        }).catch((error) => {
            return Promise.reject(new TPError({
                message: "Could not submit bulk edit.",
                error: error
            }));
        });
    }

    async showDeletePrompt() {
        let answer = await this._renderDeletePrompt(this.getSelectionContext());

        if (answer && this.props.onDelete) {
            this._onDelete(this.getSelectionContext());
        }
    }

    _clearDataset(newDataset) {
        this._selectionContext.reset();
        this.setState({
            data : newDataset,
            selectAllValue: 0
        });
    }

    _onEditSubmission() {
        let changesets = this.state.changesets;
        let data = this.state.data;
        let iterator = changesets.iterator();

        for (let i = 0; i < data.length; i++) {
            let item = data[i];
            if (!item) {
                continue; //row not loaded
            }

            iterator.reset();
            while (iterator.hasNext()) {
                let changeset = iterator.next().value;
                if (item[this.getIDKey()] === changeset[this.getIDKey()]) {
                    data[i] = changeset;
                }
            }
        }

        this.setState({
            data : data,
            changesets: new Immutable()
        });

        if (this.props.onEditSubmission) {
            this.props.onEditSubmission(changesets);
        }

        this.setEditingState(false);
    }

    revertChanges() {
        this.setState({
            changesets: new Immutable()
        });
        this.setEditingState(false);
    }

    setEditingState(state) {
        this.setState({
            editing: !!state
        }, this._applyFilterRules);

        this._onEditStateChange(!!state);
        this.props.onEditStateChange && this.props.onEditStateChange(!!state);
    }

    // eslint-disable-next-line
    _onEditStateChange(state) {}

    _selectText(node) {
        if (document.body.createTextRange) {
            const range = document.body.createTextRange();
            range.moveToElementText(node);
            range.select();
        } 
        else if (window.getSelection) {
            const selection = window.getSelection();
            const range = document.createRange();
            range.selectNodeContents(node);
            selection.removeAllRanges();
            selection.addRange(range);
        } 
        else {
            ApplicationInstance.getInstance().getLogger().warn("Could not select text in node: Unsupported browser.");
        }
    }

    // =========================================================================
    // SelectionContext APIS
    // =========================================================================
    
    setSelectionContext(sc) {
        this._selectionContext = sc;
        this.forceUpdate();
    }

    getSelectionContext() {
        return this._selectionContext;
    }

    isSelected(id) {
        return this._selectionContext.isSelected(id);
    }

    getSelectionCount() {
        return this._selectionContext.getSelectionCount();
    }

    // allowDupe defaults to true
    addFilterRule(rule, allowDupe) {
        if (allowDupe === false) {
            for (let i = 0, length = this.state.filterRules.length; i < length; ++i) {
                if (rule.isEqual(this.state.filterRules[i])) {
                    // Adding this rule will result in a duplicate rule.
                    // Return early.
                    return;
                }
            }
        }

        this.state.filterRules.push(rule);
        this.setState({
            filterRules: this.state.filterRules
        }, () => {
            this._applyFilterRules();
        });
    }

    getFilterRules() {
        return this.state.filterRules.slice();
    }

    // Returns true if rule was removed, returns false otherwise.
    // Note will remove all instances of the rule.
    removeFilterRule(rule) {
        let rules = this.state.filterRules.slice();
        for (let i = 0, length = rules.length; i < length; ++i) {
            if (rule.isEqual(rules[i])) {
                rules.splice(i, 1);
                i -= 1; // Reduce index and length in accordance with changes to rules
                length -= 1;
            }
        }
        if (rules.length !== this.state.filterRules.length) {
            this.setState({
                filterRules: rules
            }, () => {
                this._applyFilterRules();
            });
            return true;
        }
        else {
            return false;
        }
    }
}

Guideline.propTypes = {
    tableNode: PropTypes.instanceOf(Element)
};

Table.Events = {
    LOADING_STATE_CHANGE: 0x0001
};

Table.propTypes = {
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    headers: PropTypes.arrayOf(PropTypes.string).isRequired,
    idKey: PropTypes.string,
    defaultSortColumn: PropTypes.string,
    defaultSortReverse: PropTypes.bool,
    allowRendering: PropTypes.bool,
    columnWidthSaveKey: PropTypes.string,
    query: PropTypes.string,
    highlight: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(PropTypes.number)
    ]),
    highlightKey: PropTypes.string,
    highlightColor: PropTypes.instanceOf(Color),
    stopResizeWatch: PropTypes.bool,
    dataProvider: deprecated(PropTypes.any, 'dataProvider is no longer used.'),
    definitionFactory: PropTypes.instanceOf(TableDefinitionFactory),
    updateOnViewportChange: PropTypes.bool,
    deletable: PropTypes.bool,
    editable: PropTypes.bool,
    selectable: PropTypes.bool,
    tableEditorFactory: PropTypes.instanceOf(TableEditorFactory),
    onSelectionChange: PropTypes.func,
    onChange: PropTypes.func,
    isRowEnabled: PropTypes.func,
    isRowSelectable: PropTypes.func,
    rowDisabledTitle: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
    ]),
    onDisabledRowClick: PropTypes.func,
    onRowClick: PropTypes.func,
    onRowDoubleClick: PropTypes.func,
    onDelete: PropTypes.func,
    onEditSubmission: PropTypes.func,
    onEditStateChange: PropTypes.func,
    selection: PropTypes.instanceOf(SelectionContext),
    defaultRules: PropTypes.arrayOf(PropTypes.instanceOf(ComparisonRule)),
    isComparisonComponentOpenOnMount: PropTypes.bool,
    filterFloatAddButton: PropTypes.bool,
    //Will receive Array<FilterRule>
    onFilterChange: PropTypes.func,
    getDeletePromptMessage: PropTypes.func,
    onSortChange: PropTypes.func,
    totalRowCount: PropTypes.number,
    columnPreferenceVesion: PropTypes.number,
    onLoadingStateChange: PropTypes.func,
    // Defaults to true, see isSelectionCheckboxEnabled
    enableSelectionCheckbox: PropTypes.bool,
    enableComparisonComponent: PropTypes.bool,
    enableFiltering: PropTypes.bool,
    defaultColumnWidths: PropTypes.shape({
        [PropTypes.string]: PropTypes.number
    }),
    isRowDeletable: PropTypes.func,
    isRowEditable: PropTypes.func,
    showLoadingMock: PropTypes.func
};

export { Table };
