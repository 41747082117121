'use strict';

import * as React from 'react';

export class ComparisonParamsBuilder {
    constructor() {}

    /**
     * Returns default value for Comparison editing UI.
     */
    getDefaultValue() { 
        return '';
    }
    
    // private method
    _buildBase(ui, methodClassName, editable) {
        return <div className={`comparisonParams ${methodClassName} ${editable ? 'editing' : 'viewing'}`}>
            {ui}
        </div>;
    }

    buildIsEqualTo(value, onChange, editable) {
        return this._buildBase(this._buildIsEqualTo(value, onChange, editable), 'isEqualTo', editable);
    }

    buildIsNotEqualTo(value, onChange, editable) {
        return this._buildBase(this._buildIsNotEqualTo(value, onChange, editable), 'isEqualNotTo', editable);
    }

    buildIsKnown() {
        return null;
    }

    buildIsNotKnown() {
        return null;
    }

    buildContains(value, onChange, editable) {
        return this._buildBase(this._buildContains(value, onChange, editable), 'contains', editable);
    }

    buildDoesNotContain(value, onChange, editable) {
        return this._buildBase(this._buildDoesNotContain(value, onChange, editable), 'doesNotContain', editable);
    }

    buildIsLessThan(value, onChange, editable) {
        return this._buildBase(this._buildIsLessThan(value, onChange, editable), 'isLessThan', editable);
    }

    buildIsNotLessThan(value, onChange, editable) {
        return this._buildBase(this._buildIsNotLessThan(value, onChange, editable), 'isNotLessThan', editable);
    }

    buildIsLessThanOrEqualTo(value, onChange, editable) {
        return this._buildBase(this._buildIsLessThanOrEqualTo(value, onChange, editable), 'isLessThanOrEqualTo', editable);
    }

    buildIsNotLessThanOrEqualTo(value, onChange, editable) {
        return this._buildBase(this._buildIsNotLessThanOrEqualTo(value, onChange, editable), 'isNotLessThanOrEqualTo', editable);
    }

    buildIsGreaterThan(value, onChange, editable) {
        return this._buildBase(this._buildIsGreaterThan(value, onChange, editable), 'isGreaterThan', editable);
    }

    buildIsNotGreaterThan(value, onChange, editable) {
        return this._buildBase(this._buildIsNotGreaterThan(value, onChange, editable), 'isNotGreaterThan', editable);
    }

    buildIsGreaterThanOrEqualTo(value, onChange, editable) {
        return this._buildBase(this._buildIsGreaterThanOrEqualTo(value, onChange, editable), 'isGreaterThanOrEqualTo', editable);
    }

    buildIsNotGreaterThanOrEqualTo(value, onChange, editable) {
        return this._buildBase(this._buildIsNotGreaterThanOrEqualTo(value, onChange, editable), 'isNotGreaterThanOrEqualTo', editable);
    }

    // {start: <value>, end: <value>} should be passed into onChange
    buildIsBetween(start, end, onChange, editable) {
        return this._buildBase(this._buildIsBetween(start, end, onChange, editable), 'isBetween', editable);
    }

    // {start: <value>, end: <value>} should be passed into onChange
    buildIsNotBetween(start, end, onChange, editable) {
        return this._buildBase(this._buildIsNotBetween(start, end, onChange, editable), 'isNotBetween', editable);
    }

    // All _build functions are protected
    // Normally we would just have 1 abstract _build; but, that means extending classes would have to repeat
    // and switch statement to figure out what method we are using and which UI to build.
    // Instead we will write that switch statement here once and have the extending classes extend
    // these _build functions. This also allows us to ensure each method is setup to be built even if it only errors out when used.
    _buildIsEqualTo(value, onChange, editable) {
        throw new Error('ComparisonParamsBuilder._buildIsEqualTo is abstract.');
    }

    _buildIsNotEqualTo(value, onChange, editable) {
        throw new Error('ComparisonParamsBuilder._buildIsNotEqualTo is abstract.');        
    }

    _buildContains(value, onChange, editable) {
        throw new Error('ComparisonParamsBuilder._buildContains is abstract.');        
    }

    _buildDoesNotContain(value, onChange, editable) {
        throw new Error('ComparisonParamsBuilder._buildDoesNotContain is abstract.');        
    }

    _buildIsLessThan(value, onChange, editable) {
        throw new Error('ComparisonParamsBuilder._buildIsLessThan is abstract.');        
    }

    _buildIsNotLessThan(value, onChange, editable) {
        throw new Error('ComparisonParamsBuilder._buildIsNotLessThan is abstract.');        
    }
    
    _buildIsLessThanOrEqualTo(value, onChange, editable) {
        throw new Error('ComparisonParamsBuilder._buildIsLessThanOrEqualTo is abstract.');        
    }

    _buildIsNotLessThanOrEqualTo(value, onChange, editable) {
        throw new Error('ComparisonParamsBuilder._buildIsNotLessThanOrEqualTo is abstract.');        
    }

    _buildIsGreaterThan(value, onChange, editable) {
        throw new Error('ComparisonParamsBuilder._buildIsGreaterThan is abstract.');        
    }

    _buildIsNotGreaterThan(value, onChange, editable) {
        throw new Error('ComparisonParamsBuilder._buildIsNotGreaterThan is abstract.');        
    }

    _buildIsGreaterThanOrEqualTo(value, onChange, editable) {
        throw new Error('ComparisonParamsBuilder._buildIsGreaterThanOrEqualTo is abstract.');        
    }

    _buildIsNotGreaterThanOrEqualTo(value, onChange, editable) {
        throw new Error('ComparisonParamsBuilder._buildIsNotGreaterThanOrEqualTo is abstract.');        
    }

    _buildIsBetween(start, end, onChange, editable) {
        throw new Error('ComparisonParamsBuilder._buildIsBetween is abstract.');        
    }

    _buildIsNotBetween(start, end, onChange, editable) {
        throw new Error('ComparisonParamsBuilder._buildIsNotBetween is abstract.');        
    }
}
