import { AbstractScreenOrientation } from './AbstractScreenOrientation';
import { Browser } from './Browser';
import { IOSScreenOrientation } from './IOSScreenOrientation';
import { W3CScreenOrientation } from './W3CScreenOrientation';

export class ScreenOrientationFactory {
    public constructor() {}

    public create(): AbstractScreenOrientation {
        if (Browser.isApplication()) {
            // Applications has access to cordova/native that polyfills W3C
            return W3CScreenOrientation.getInstance();
        }

        if (Browser.isDesktop() && window.screen.orientation) {
            // If desktop and it has orientation defined, then use W3C
            return W3CScreenOrientation.getInstance();
        }

        if (Browser.getOS() === Browser.ANDROID && window.screen.orientation) {
            // Android with standard orientation defined, use W3C
            return W3CScreenOrientation.getInstance();
        }

        if (Browser.getOS() === Browser.MAC) {
            if (window.screen.orientation) {
                return W3CScreenOrientation.getInstance();
            }
            else {
                return IOSScreenOrientation.getInstance();
            }
        }

        if (Browser.getOS() === Browser.IOS) {
            // iOS 16.4 and lower we need to use a hack fallback
            let version: number = Browser.getVersion().major;
            if (version >= 17) {
                return W3CScreenOrientation.getInstance();
            }
            else {
                return IOSScreenOrientation.getInstance();
            }
        }
        
        // when in doubt, use W3C, do a basic test
        if (W3CScreenOrientation.isSupported()) {
            return W3CScreenOrientation.getInstance();
        }
        else {
            return IOSScreenOrientation.getInstance();
        }
    }
}
