import { TPError } from '@totalpave/error';
import { OrientationType } from './OrientationType';

export type TOrientationChangeHandler = (orientation: OrientationType, isVirtual: boolean) => void;

/**
 * An abstraction to the W3C orientation API. While Android/Chrome has supported
 * Screen Orientation API for like 10+ years, iOS has just received support in
 * 17.0.
 * 
 * Screen orientation was introduced in 16.4, but in my testing it only ever
 * reports portrait and is not reliable.
 * 
 * This is an abstract API, use a concrete implementation.
 * The ScreenOrientationFactory will give you a concrete implementation based
 * on the device.
 */
export abstract class AbstractScreenOrientation {
    private $handlers: TOrientationChangeHandler[];
    private $pendingAnimFrame: number;

    public constructor() {
        this.$handlers = [];
        this._initListener();

        this.$pendingAnimFrame = null;
        window.addEventListener('resize', () => {
            if (this.$pendingAnimFrame) {
                window.cancelAnimationFrame(this.$pendingAnimFrame);
            }

            this.$pendingAnimFrame = window.requestAnimationFrame(() => {
                this._notify(this.getVirtualOrientation(), true);
            });
        });
    }

    protected abstract _initListener(): void;
    protected abstract _getPhysicalOrientation(): OrientationType;

    public register(handler: TOrientationChangeHandler): void {
        this.$handlers.push(handler);
    }

    public unregister(handler: TOrientationChangeHandler): void {
        let idx: number = this.$handlers.indexOf(handler);
        if (idx > -1) {
            this.$handlers.splice(idx, 1);
        }
    }

    public getPhysicalOrientation(): OrientationType {
        return this._getPhysicalOrientation();
    }

    public isPhysicalLandscape(): boolean {
        return /^landscape/i.test(this.getPhysicalOrientation());
    }

    public isPhysicalPortrait(): boolean {
        return /^portrait/i.test(this.getPhysicalOrientation());
    }

    public isPhysicalPrimary(): boolean {
        return /primary$/i.test(this.getPhysicalOrientation());
    }

    public isPhysicalSecondary(): boolean {
        return /secondary$/i.test(this.getPhysicalOrientation());
    }

    public getVirtualOrientation(): OrientationType {
        let isWide: boolean = window.screen.width > window.screen.height;
        return isWide ? OrientationType.LANDSCAPE_PRIMARY : OrientationType.PORTRAIT_PRIMARY;
    }

    public isVirtualLandscape(): boolean {
        return /^landscape/i.test(this.getVirtualOrientation());
    }

    public isVirtualPortrait(): boolean {
        return /^portrait/i.test(this.getVirtualOrientation());
    }

    public isVirtualPrimary(): boolean {
        return /primary$/i.test(this.getPhysicalOrientation());
    }

    public isVirtualSecondary(): boolean {
        return /secondary$/i.test(this.getPhysicalOrientation());
    }

    protected _notify(orientation: OrientationType, isVirtual: boolean): void {
        for (let i = 0; i < this.$handlers.length; i++) {
            try {
                this.$handlers[i](orientation, isVirtual);
            }
            catch (ex) {
                let e = TPError.wrap(ex);
                e.dispatch();
            }
        }
    }
}
