'use strict';

import { Action } from './Action';

class MouseButtonUpActionSingleton extends Action {
    getTag() {
        return 'mousebutton_up';
    }

    _shouldDispatchOnError() {
        return false;
    }

    _execute({button} = {}) {
        return Promise.resolve({button:button});
    }
}

const MouseButtonUpAction = new MouseButtonUpActionSingleton();

export { MouseButtonUpAction };
