
'use strict';

import {Store} from '@totalpave/store';
import {ApplicationInstance} from '@totalpave/application-instance';
import {TPError} from '@totalpave/error';
import {ObjectUtils} from '@totalpave/object';

const ORG_PREFS_KEY = "organization_preferences";

let _instance = null;

export class PreferenceStore extends Store {
    constructor() {
        super();

        this.migrateStorageSystem = this.migrateStorageSystem.bind(this);
        this._organizationPreferences = this._getStorageStrategy().get(ORG_PREFS_KEY);

        if (!this._organizationPreferences) {
            this._organizationPreferences = {};
        }

        if (this._isLegacy()) {
            //This is a legacy format that needs to be wiped
            this._organizationPreferences = {};
            delete window.localStorage.organization_preferences;
            delete window.sessionStorage.organization_preferences;
        }
    }

    static getInstance() {
        if (!_instance) {
            _instance = new PreferenceStore();
        }
        return _instance;
    }

    async migrateStorageSystem() {
        if (window.localStorage.organization_preferences || window.sessionStorage.organization_preferences) {
            let storage = localStorage.organization_preferences ? "localStorage" : "sessionStorage";
            await this._getStorageStrategy().save({[ORG_PREFS_KEY]: window[storage].organization_preferences});
            this._organizationPreferences = this._getStorageStrategy().get(ORG_PREFS_KEY);
            delete window.localStorage.organization_preferences;
            delete window.sessionStorage.organization_preferences;
        }

        let needsDataFormatMigration = false;
        // Check for old-format
        for (let i in this._organizationPreferences) {
            for (let y in this._organizationPreferences[i]) {
                if (isNaN(parseInt(y))) {
                    needsDataFormatMigration = true;
                    break;
                }
            }

            if (needsDataFormatMigration) {
                break;
            }
        }

        if (needsDataFormatMigration) {
            let newFormat = {};

            for (let key in this._organizationPreferences) {
                let pref = this._organizationPreferences[key];
                newFormat[key] = {
                    [pref.version || 1]: pref
                };
            }

            await this._getStorageStrategy().save({[ORG_PREFS_KEY]: newFormat});
        }
    }

    _getStorageStrategy() {
        return ApplicationInstance.getInstance().getStorageStrategy();
    }

    _isLegacy() {
        if (this._organizationPreferences && Object.keys(this._organizationPreferences).length > 0) {
            for (let i in this._organizationPreferences) {
                let kv = this._organizationPreferences[i];
                if (typeof kv === 'string') {
                    return true;
                }
            }
        }

        return false;
    }

    getOrganizationPreference(key, version) {
        if (ObjectUtils.isVoid(version)) {
            version = 1;
        }

        let preference = this._organizationPreferences[key];

        if (preference && preference[version]) {
            return preference[version];
        }
        else {
            return null;
        }
    }

    _deleteOrganizationPreference(preference, version) {
        if (this._organizationPreferences[preference] && this._organizationPreferences[preference][version]) {
            delete this._organizationPreferences[preference][version];
        }

        this._setOrganizationPreference(this._organizationPreferences, true);
    }

    _saveOrganizationPreferences(preferences) {
        let data = {};

        for (let i = 0; i < preferences.length; i++) {
            let preference = preferences[i];
            data[preference.key] = {
                [preference.version]: preference.value
            };
        }

        this._setOrganizationPreference(data);
    }

    _setOrganizationPreference(data, overwrite) {
        if (overwrite) {
            this._organizationPreferences = data;
        }
        else {
            for (let key in data) {
                for (let version in data[key]) {
                    if (!this._organizationPreferences[key]) {
                        this._organizationPreferences[key] = {};
                    }
                    this._organizationPreferences[key][version] = data[key][version];
                }
            }
        }

        this._organizationPreferences.persist = this._persist;
        this._getStorageStrategy().save({[ORG_PREFS_KEY]: JSON.stringify(this._organizationPreferences)}).then(() => {
            return this._forceUpdate();
        }).catch((error) => {
            new TPError({
                message: "Could not save organization preferences in PreferenceStore.",
                details: error
            });
        });
    }

    _reset() {
        return new Promise((resolve, reject) => {
            this._organizationPreferences = {};
            this._getStorageStrategy().delete(ORG_PREFS_KEY).then(() => {
                resolve();
            }).catch((error) => {
                reject(new TPError({
                    message: "Could not reset PreferenceStore.",
                    details: error
                }));
            });
        });
    }

    _update(actionData) {
        let data;
        switch (actionData.getTag()) {
            default: return false;
            case 'save_organization_preference':
                data = actionData.getData();
                data = {
                    [data.key]: {
                        [data.version]: data.value
                    }
                };
                this._setOrganizationPreference(data);
                return false; // Return false because we will be doing asynchronous work. The update event will be emitted through a different means later.
            case 'save_organization_preferences':
                this._saveOrganizationPreferences(actionData.getData());
                return false; // Return false because we will be doing asynchronous work. The update event will be emitted through a different means later.
            case 'organization_preferences_load':
                this._setOrganizationPreference(actionData.getData(), true);
                return false; // Return false because we will be doing asynchronous work. The update event will be emitted through a different means later.
            case 'delete_organization_preference':
                data = actionData.getData();
                this._deleteOrganizationPreference(data.key, data.version);
                return false; // Return false because we will be doing asynchronous work. The update event will be emitted through a different means later.
            case 'login_action':
                return true;
            case 'logout_action':
                return true;
        }
    }
}
