'use strict';

import React from 'react';
import {UIFactory} from './UIFactory';
import {VirtualTable} from '../components/VirtualTable';
import {Message} from '../components/Message';
import { VirtualPaginatedTable } from '../components/VirtualPaginatedTable';

class DefaultUIFactory extends UIFactory {
    create(component, props) {
        switch (component) {
            case UIFactory.PAGINATED_TABLE:
                return <VirtualPaginatedTable {...props} />;
            case UIFactory.TABLE:
                return <VirtualTable {...props} />;
            case UIFactory.MESSAGE:
                return <Message {...props} />;
        }
    }
}

export { DefaultUIFactory };
