
export const WEEKDAYS = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
];

export const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

export class DateUtils {
    static isValid(date) {
        if (date instanceof Date) {
            return !(date.toString() === 'Invalid Date');
        }
        else {
            return false;
        }
    }
}
