
import * as React from 'react';
import R_BulkEditIcon from '../assets/embed/bulk_edit.svg';
import '../style/BulkEditIcon.less';

export class BulkEditIcon extends React.Component<void, void> {
    render() {
        return (
            <div className="BulkEditIcon">
                <R_BulkEditIcon />
            </div>
        );
    }
}
