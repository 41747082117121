//Don't forget the less color constants
const Constants = {};

const TRANSITION_DURATION = '0.1s';
const TRANSITION_STRATEGY = 'ease-in-out';

/**
 * @deprecated Use COLORS from @totalpave/interfaces instead
 */
Constants.colors = {
    BLUE : '#00a2d7',
    WHITE : '#FFFFFF',
    BLACK : '#000000',
    GRAY : '#97a5ac',
    RED :  '#f16865',
    GREEN : '#70c26a'
};

/**
 * @deprecated Use KeyCode instead
 */
Constants.KEYS = {
    SUBMIT : 'Enter'
};

/**
 * Units of tolerance that is allowed when validating sample area. Should mimick backend tolerance.
 * @deprecated use SAMPLE_AREA_TOLERANCE from @totalpave/interfaces instead
 */
Constants.SAMPLE_AREA_TOLERANCE = 1;

// This constant is also replicated in constants.less
export const HANDLEBAR_WIDTH = 25;

export { 
    Constants,
    TRANSITION_DURATION,
    TRANSITION_STRATEGY
};
