'use strict';

import * as React from 'react';
import {BlankListItem} from '../components/BlankListItem';

export class ListRowBuilder {
    constructor() {}

    _getRowClass() {
        throw new Error("ListRowBuilder._getRowClass is abstract.");
    }

    // Can only return a number. We don't support react-virtualized's return a function feature.
    getRowHeight() {
        throw new Error("ListRowBuilder.getRowHeight is abstract.");
    }

    build(item, props) {
        let RowClass = this._getRowClass();

        if (!item) {
            return <BlankListItem {...props} item={item} />;
        }
        else {
            return (
                <RowClass
                    {...props}
                    item={item}
                />
            );
        }
    }
}
