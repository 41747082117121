'use strict';

import {ObjectUtils} from './ObjectUtils';
import {DictionaryIterator} from './DictionaryIterator';

class Immutable {
    constructor(data) {
        this._data = data || {};
    }

    set(key, value) {
        let data = ObjectUtils.clone(this._data);
        data[key] = value;
        return new Immutable(data);
    }

    remove(key) {
        let data = ObjectUtils.clone(this._data);
        delete data[key];
        return new Immutable(data);
    }

    get(key) {
        return this._data[key];
    }

    clone() {
        return new Immutable(ObjectUtils.clone(this._data));
    }

    iterator() {
        return new DictionaryIterator(this._data);
    }

    keyLength() {
        return Object.keys(this._data).length;
    }
}

export { Immutable };
