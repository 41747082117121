
export interface IInsetResult {
    top: number;
    right: number;
    bottom: number;
    left: number;
}

/**
 * An API to apply inset constraints.
 * This class by default does nothing by provide an empty/zero inset
 */
export class InsetStrategy {
    public execute(): IInsetResult {
        return {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
        };
    }
}