'use strict';

import { ObjectUtils } from "./ObjectUtils";
import {Option} from './Option';

/** @deprecated Use @totalpave/ui-menu */
export class OptionGroup {
    constructor(name) {
        this._isHighlight = false;
        this._name = name;
        this._isHeaderVisible = true;
        this._options = [];
        this._userClasses = {};
    }

    setIsHeaderVisible(value) {
        this._isHeaderVisible = !!value;
    }

    isHeaderVisible() {
        return this._isHeaderVisible;
    }

    setEnabled(enabled) {
        // this is a no-op
    }

    isEnabled() {
        return true;
    }

    setUserClasses(userClasses) {
        this._userClasses = userClasses;
    }

    getUserClasses() {
        return this._userClasses;
    }

    isHighlightable() {
        return false;
    }

    setHighlight(flag) {
        /*
            OptionGroup is not actually highlightable
        */
        this._isHighlight = false;
        let options = this.getOptions();
        for (let i = 0; i < options.length; i++) {
            let option = options[i];
            option.setHighlight(false);
        }
    }

    isHighlight() {
        return this._isHighlight;
    }

    addOption(option) {
        if (option.getGroup()) {
            option.getGroup().removeOption(option);
        }

        option.setGroup(this);
        this._options.push(option);
    }

    removeOption(option) {
        let index = this.indexOf(option);
        if (index > -1) {
            option.setGroup(null);
            this._options.splice(index, 1);
        }
    }

    sort(compareFn) {
        this._options.sort(compareFn);
    }

    getSize() {
        return this._options.length;
    }

    getOptionAt(index) {
        return this._options[index];
    }

    _setOptions(options) {
        this._options = options;
    }

    getOptions() {
        return this._options;
    }

    getText() {
        return this._name;
    }

    getTitle() {
        return this.getText();
    }

    getFuzzyValues() {
        let values = [];

        for (let i = 0; i < this._options.length; i++) {
            let option = this._options[i];
            values.push(option.getFuzzyValues());   
        }

        return values;
    }

    getFuzzyKeys() {
        return OptionGroup.getFuzzyKeys();
    }

    static getFuzzyKeys() {
        return Option.getFuzzyKeys();
    }

    clone() {
        let og = new OptionGroup(this.getText());
        og._setOptions(ObjectUtils.clone(this.getOptions()));
        return og;
    }
}
