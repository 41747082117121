
import * as React from 'react';
import * as PropTypes from 'prop-types';
import {FormModal} from './FormModal';
import {Checkbox} from './Checkbox';
import {LabeledCheckbox} from './LabeledCheckbox';
import "../style/ConfirmModal.less";
import { TPError } from '@totalpave/error';
import {FormItem} from './FormItem';

export class ConfirmModal extends FormModal {
    constructor(props) {
        super(props);
        this._addClassName('ConfirmModal');

        this.state.shouldRemember = Checkbox.VALUES.UNCHECKED;
    }

    getTitle() {
        return this.props.title || 'Confirm';
    }

    isFlex() {
        return true;
    }

    isSmooth() {
        return false;
    }

    _onCancel() {
        this.props.cancel && this.props.cancel();
    }

    _onSubmit() {
        return this.props.confirm(this.state.shouldRemember).catch((error) => {
            let message;
            if (error instanceof TPError) {
                message = error.getMessage();
            }
            else if (error instanceof Error) {
                message = error.message;
            }
            
            if (!message) {
                error = 'Unknown error has occurred. Please try again.';
                // new TPError(message).dispatch();
            }
            return Promise.reject(error);
        });
    }

    _renderFooterContent() {
        return this.props.canRemember ?
            <div className='footer-content'>
                <LabeledCheckbox
                    className='rememberMe'
                    key='rememberMe'
                    value={this.state.shouldRemember}
                    onChange={(value) => {
                        this.setState({
                            shouldRemember: value
                        });
                    }}
                >
                    Remember this
                </LabeledCheckbox>
            </div>
            :
            null
        ;
    }

    _getAdditionalPrimaryButtons() {
        return this.props.getAdditionalPrimaryButtons ? this.props.getAdditionalPrimaryButtons(this) : [];
    }

    _getAdditionalSecondaryButtons() {
        return this.props.getAdditionalSecondaryButtons ? this.props.getAdditionalSecondaryButtons(this) : [];
    }

    _getAdditionalTertiaryButtons() {
        return this.props.getAdditionalTertiaryButtons ? this.props.getAdditionalTertiaryButtons(this) : [];
    }

    _getSubmitButtonProps(props) {
        if (this.props.submitButtonProps) {
            props = {
                ...props,
                ...this.props.submitButtonProps
            };
        }
        return props;
    }

    _getCancelButtonProps(props) {
        if (this.props.cancelButtonProps) {
            props = {
                ...props,
                ...this.props.cancelButtonProps
            };
        }
        return props;
    }

    _renderContent() {
        return (
            <FormItem className="confirm-container">
                <pre className="use-tp-fonts">{this.props.text}</pre>
            </FormItem>
        );
    }
}

ConfirmModal.propTypes = {
    text: PropTypes.string.isRequired,
    /**
     * function(): Promise<void>
     */
    confirm: PropTypes.func.isRequired,
    /**
     * function(shouldRemember): void
     * 
     * You can ignore shouldRemember parameter if you are not using canRemember.
     */
    cancel: PropTypes.func,
    /**
     * function(ConfirmModal): Array\<JSX>
     */
    getAdditionalPrimaryButtons: PropTypes.func,    
    /**
     * function(ConfirmModal): Array\<JSX>
     */
    getAdditionalSecondaryButtons: PropTypes.func,    
    /**
     * function(ConfirmModal): Array\<JSX>
     */
    getAdditionalTertiaryButtons: PropTypes.func,    
    canRemember: PropTypes.bool,
    title: PropTypes.string,
    submitButtonProps: PropTypes.object,
    cancelButtonProps: PropTypes.object
}
