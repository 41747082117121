import * as React from 'react';
import PropTypes from 'prop-types';
import {Label} from './Label';
import {LayoutMode, LayoutModes} from '../utils/LayoutMode';
import '../style/FormItem.less';
import {ObjectUtils} from '@totalpave/object';
import {InfoIcon} from './InfoIcon';

export class FormItem extends React.Component {
    constructor(props) {
        super(props);
        this.$classNames = ["FormItem"];
    }

    _addClassName(name) {
        this.$classNames.push(name);
    }

    $getLayoutModeClassName() {
        switch (this.props.layoutMode) {
            case LayoutMode.LABEL_ABOVE:
            default:
                return 'label-above';
        }
    }
    
    $getClassName() {
        return `${this.$classNames} ${this.props.className} ${this.$getLayoutModeClassName()}`;
    }

    $isRequired() {
        return !ObjectUtils.isVoid(this.props.isRequired) || this.props.isRequired === true;
    }

    render() {
        return <div className={this.$getClassName()}>
            {
                <div className='label-container'>
                    <Label text={this.props.label} />
                    {this.$isRequired() ? <sup> *</sup> : null}
                    {this.props.toolTipMessage ? <InfoIcon>{this.props.toolTipMessage}</InfoIcon> : null}
                </div>
            }
            <div className='content'>
                {this.props.children}
            </div>
        </div>;
    }
}

FormItem.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    /**
     * Defaults to LABEL_ABOVE
     */
    layoutMode: PropTypes.oneOf(LayoutModes),
    label: PropTypes.string,
    toolTipMessage: PropTypes.string,
    /**
     * Defaults to false
     */
    isRequired: PropTypes.bool
}
