'use strict';

/**
 * public singleton Unit
 *
 *  Provides utility methods for setting or getting the unit value,
 *  as well as unit conversion methods.
 *
 *  This class is static.
 * 
 * @type {Unit}
 */
const Unit = {
    /**
     * @deprecated - Use @totalpave/math instead
     */
    meterToFeet : (meters) => {
        let value = parseFloat(meters) * 3.2808399;
        if (isNaN(value)) {
            return NaN;
        }

        return Unit.roundTo(value, 4);
    },

    /**
     * @deprecated - Use @totalpave/math instead
     */
    cmToInch: (cm) => {
        return Unit.roundTo(cm * 0.3937007874, 4);
    },

    /**
     * @deprecated - Use @totalpave/math instead
     */
    feetToMeter : function(feet) {
        let value = parseFloat(feet) * 0.3048;
        if (isNaN(value)) {
            return NaN;
        }

        return value;
    },

    /**
     * @deprecated - Use @totalpave/math instead
     */
    meterToMile: (value) => {
        return Unit.roundTo(value * 0.000621371, 4);
    },

    /**
     * @deprecated - Use @totalpave/math instead
     */
    meterToKM : (value) => {
        return Unit.roundTo(value * 0.001, 4);
    },

    /**
     * @deprecated - Use @totalpave/math instead
     */
    footSquaredToMeterSquared : function(value) {
        value = parseFloat(value) * 0.092903044;
        if (isNaN(value)) {
            return NaN;
        }
        return Unit.roundTo(value, 4);
    },

    /**
     * @deprecated - Use @totalpave/math instead
     */
    meterSquaredToFootSquared : function(value) {
        value = parseFloat(value) * 10.763910;
        if (isNaN(value)) {
            return NaN;
        }
        return Unit.roundTo(value, 4);  
    },

    /**
     * @deprecated - Use @totalpave/math instead
     */
    roundTo : function(value, decimalPlaces) {
        if (value === undefined || value === null) {
            return value;
        }

        let str = value.toString();
        if (str.indexOf('.') === -1) {
            return parseFloat(str);
        }

        let parts = str.split('.');
        if (parts[1].length > decimalPlaces) {
            return parseFloat(parseFloat(value).toFixed(decimalPlaces));
        }
        else {
            return parseFloat(parts.join('.'));
        }
    },

    /**
     * @deprecated - Use @totalpave/math instead
     */
    roundDownTo : function(value, decimalPlaces) {
        decimalPlaces++;
        value = value.toString();
        let dotPos = value.indexOf('.');
        let v = value.slice(0, dotPos + decimalPlaces);
        return parseFloat(v);
    },

    /**
     * @deprecated - Use @totalpave/math instead
     */
    getUnitLinearText : function(unit) {
        switch (unit) {
            case Unit.METRIC:
                return 'm';
            case Unit.IMPERIAL:
                return 'ft'
            case Unit.SLAB:
                return 'slabs';
            case Unit.NO_UNIT:
                return '';
            default:
                return 'units';
        }
    }
}

/**
 * @deprecated - Use @totalpave/math instead
 */
Unit.METRIC         = 0;
/**
 * @deprecated - Use @totalpave/math instead
 */
Unit.IMPERIAL       = 1;
/**
 * @deprecated - Use @totalpave/math instead (COUNT UNIT)
 */
Unit.SLAB           = 2;
/**
 * @deprecated
 */
Unit.NO_UNIT        = 3;

Unit.ROUND_POINT    = 1;

export { Unit };
