'use strict';

class NodeGeometry {
    constructor() {
        throw new Error('NodeGeometry is static');
    }

    static getAbsoluteX(node) {
        let x = node.offsetLeft;
        let parent = node.offsetParent;
        while (parent) {
            x += parent.offsetLeft;
            parent = parent.offsetParent;
        }

        return x;
    }

    static getAbsoluteY(node) {
        let y = node.offsetTop;
        let parent = node.offsetParent;
        while (parent) {
            y += parent.offsetTop;
            parent = parent.offsetParent;
        }

        return y;
    }

    static getScreenPosition(node) {
        return {
            x: NodeGeometry.getAbsoluteX(node),
            y: NodeGeometry.getAbsoluteY(node)
        };
    }

    /**
     * Gets node screen geometry including CSS transformations.
     * @param {HTMLElement} node 
     * @returns 
     */
    static getBoundingRect(node) {
        let bounds = node.getBoundingClientRect();
        return {
            x: bounds.x,
            y: bounds.y,
            w: bounds.width,
            h: bounds.height,
            x2: bounds.right,
            y2: bounds.bottom
        };
    }

    /**
     * Gets node screen geometry. Excludes transformations.
     * Use `getBoundingRect` for the same information with CSS transformations included.
     * @param {HTMLElement} node 
     * @returns 
     */
    static getScreenGeometry(node) {
        let w = NodeGeometry.getWidth(node);
        let h = NodeGeometry.getHeight(node);
        let pos = this.getScreenPosition(node);
        return {
            ...pos,
            w: w,
            h: h,
            x2: pos.x + w,
            y2: pos.y + h
        };
    }

    static getScrollX(node) {
        let x = node.scrollLeft;
        let parent = node.parentNode;
        while (parent) {
            if (parent.scrollLeft) {
                x += parent.scrollLeft;
            }
            parent = parent.parentNode;
        }

        return x;
    }

    static getScrollY(node) {
        let y = node.scrollTop;
        let parent = node.parentNode;
        while (parent) {
            if (parent.scrollTop) {
                y += parent.scrollTop;
            }
            parent = parent.parentNode;
        }

        return y;
    }

    static getWidth(node) {
        return node.clientWidth;
    }

    static getHeight(node) {
        return node.clientHeight;
    }

    static getSize(node) {
        return {
            width: NodeGeometry.getWidth(node),
            height: NodeGeometry.getHeight(node)
        };
    }

    static getRelativeX(node, relativeNode) {
        let r = NodeGeometry.getAbsoluteX(relativeNode);
        let n = NodeGeometry.getAbsoluteX(node);
        return Math.abs(r - n);
    }

    static getRelativeY(node, relativeNode) {
        let r = NodeGeometry.getAbsoluteY(relativeNode);
        let n = NodeGeometry.getAbsoluteY(node);
        return Math.abs(r - n);
    }
}

export { NodeGeometry };
