'use strict';

import {SlowNetwork as SN} from '@totalpave/network';
/**
 * @deprecated Import directly from totalpave/network instead.
 */
const SlowNetwork = SN.getInstance();
export default SlowNetwork;
/**
 * @deprecated Import directly from totalpave/network instead.
 */
export { SlowNetwork };
