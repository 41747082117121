'use strict';

//Polyfills for IE10 support
import 'core-js/features/map';
import 'core-js/features/set';
import 'raf/polyfill';

import * as React from 'react';
import { render} from 'react-dom';

import {AppLoader} from 'tp-app-common';

class AppBooter extends React.Component {
    constructor(props) {
        super();

        this.state = {
            dots : 0
        };

        this._stopLoop = false;

        this._loop = this._loop.bind(this);
    }

    componentDidMount() {
        this.startLoadLoop();
        require.ensure(['./boot'], (require) => {
            require('./boot');
        }, (error) => {
            console.error('WEBPACK ERROR', error);
            setTimeout(() => {
                location.reload();
            }, 5000);
        });
    }

    startLoadLoop() {
        window.requestAnimationFrame(this._loop);
    }

    stopLoop() {
        this._stopLoop = true;
    }

    _loop() {
        window.setTimeout(() => {
            if (this._stopLoop) {
                return;
            }

            if (this.state.dots > 4) {
                this.setState({
                    dots: 0
                });
            }
            else {
                this.setState({
                    dots : this.state.dots + 1
                });
            }
            window.requestAnimationFrame(this._loop);
        }, 250);
    }

    componentWillUnmount() {
        this.stopLoop();
    }

    _printDots() {
        return new Array(this.state.dots).join('.');
    }

    render() {
        return <AppLoader />;
    }
}

render(<AppBooter />, document.getElementById('tpApp'));
