'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';
import { Searchfield } from '../components/Searchfield';
import { BaseList} from '../components/BaseList';

import { ListRowBuilder } from '../builders/ListRowBuilder';

import { 
    List as VirtualizedList,
    AutoSizer as VirtualizedAutoSizer
} from 'react-virtualized';
import 'react-virtualized/styles.css';

import '../style/List.less';

const SEARCH_FIELD_CONTAINER_STYLES = {
    paddingTop: 10,
    paddingBottom: 10,
    boxSizing: "border-box"
};
const SEARCH_FIELD_STYLES = {
    height: 36
};
const SEARCH_FIELD_HEIGHT = SEARCH_FIELD_CONTAINER_STYLES.paddingBottom + SEARCH_FIELD_STYLES.height + SEARCH_FIELD_CONTAINER_STYLES.paddingTop;
const SEARCH_FIELD_ITEM = 'virtualized-list-search-field-item-that-is-probably-safe-enough';
// const NO_ROWS_RENDERED_ITEM = 'no-rows-rendered-item-that-is-probably-safe-enough';
const BUFFER_SPACE_ITEM = "virtualized-list-buffer-space-item-that-is-probably-safe-enough";

class List extends BaseList {
    constructor(props) {
        super(props);

        this._searchField = React.createRef();
        this._node = React.createRef();
        // this._virtualizedListNode = React.createRef();
        // Mimick React.createRef to keep things similar.
        this._virtualizedListDOMNode = {
            current: null
        };
        this._virtualizedListNode = React.createRef();

        let items = props.items.slice();
        if (props.onSearch) {
            items.unshift(SEARCH_FIELD_ITEM);
        }
        if (props.bufferSpace) {
            items.push(BUFFER_SPACE_ITEM);
        }
        // if (props.items.length === 0) {
        //     items.push(NO_ROWS_RENDERED_ITEM);
        // }

        this.state = {
            items: items,
            isFiltering: false,
            query: ''
        };

        this._listItemRefs = [];

        this._renderBufferSpace = this._renderBufferSpace.bind(this);
        this._rowRenderer = this._rowRenderer.bind(this);
        this._getRowHeight = this._getRowHeight.bind(this);
        this._noRowsRenderer = this._noRowsRenderer.bind(this);
        this._onRowsRendered = this._onRowsRendered.bind(this);
        this._onScroll = this._onScroll.bind(this);

        this._getHeight = this._getHeight.bind(this);
        this._getScrollToAlignment = this._getScrollToAlignment.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let items = nextProps.items.slice();
        if (nextProps.onSearch) {
            items.unshift(SEARCH_FIELD_ITEM);
        }
        // if (nextProps.items.length === 0) {
        //     items.push(NO_ROWS_RENDERED_ITEM);
        // }
        if (nextProps.bufferSpace) {
            items.push(BUFFER_SPACE_ITEM);
        }

        return { items: items };
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        if (this.props.bufferSpace) {
            // This check works for BUFFER_SPACE_ITEM because it always goes at the bottom of the List.
            if (prevState.items.length !== this.state.items.length) {
                // BUFFER_SPACE_ITEM is in a different location. Recompute both locations.
                if (this._virtualizedListNode.current) {
                    // Don't try to simplify this by calling it all the time. We should avoid calling recomputeRowHeights; because, 
                    // it internally calls forceUpdate.
                    this._virtualizedListNode.current.recomputeRowHeights(prevState.items.length);
                    this._virtualizedListNode.current.recomputeRowHeights(this.state.items.length);
                }
            }
        }
        return null;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.bufferSpace !== this.props.bufferSpace) {
            this._virtualizedListNode.current.recomputeRowHeights(this.state.items.indexOf(BUFFER_SPACE_ITEM));
        }

        // Required for getSnapshotBeforeUpdate to work properly,
        // even if it does nothing.
    }

    getListItemRefs() {
        return this._listItemRefs;
    }

    getScrollableElement() {
        return this._virtualizedListDOMNode;
    }

    _rowRenderer({
        key, // key for rendering array item
        index, // index for array
        isScrolling,
        isVisible,
        style
    } = {}) {
        if (this.props.onSearch && this.state.items[index] === SEARCH_FIELD_ITEM) {
            return this._renderSearchField(key, style);
        } 
        else if (this.props.bufferSpace && this.state.items[index] === BUFFER_SPACE_ITEM) {
            return this._renderBufferSpace(key, style);
        }
        // else if (this.state.items[index] === NO_ROWS_RENDERED_ITEM) {
        //     // See comments in render for why this is here
        //     return (
        //         <div
        //             key={key}
        //             style={style}
        //         >
        //             {this._noRowsRenderer()}
        //         </div>
        //     );
        // }
        else {
            this._listItemRefs[index] = React.createRef();
            return this.props.builder.build(
                this.state.items[index], 
                {
                    key: key,
                    ref: this._listItemRefs[index],
                    index: index,
                    style: style,
                    className: `${isScrolling ? " virtualizedIsScrolling" : ""}${isVisible ? " virtualizedIsVisible" : ""}`,
                    isScrolling: isScrolling, 
                    isVisible: isVisible,
                    onRemoveItem: this.props.onRemoveItem ? this.props.onRemoveItem : null,
                    list: this
                }
            );
        }
    }

    // componentDidMount() {
    //     Apparently if the User doesn't specifiy width/height; then, we can't get the element during componentDidMount. Moving this code down to ref seems to work.
    //     this._virtualizedListNode.current = document.getElementById(this.props.id);
    // }

    _noRowsRenderer() {
        // return [
        //     this._renderSearchField("searchfield"), 
        //     this.props.noRowsRenderer ? this.props.noRowsRenderer() : null
        // ];
        // return this.props.noRowsRenderer ? this.props.noRowsRenderer() : null;

        return (
            <div 
                style={{
                    top: this.props.onSearch ? SEARCH_FIELD_HEIGHT : 0
                }}
                className="no-content-container"
            >
                {this.props.noRowsRenderer ? this.props.noRowsRenderer() : null}
            </div>
        );
    }

    /**
        opts: {
            overscanStartIndex, 
            overscanStopIndex,
            startIndex,
            stopIndex
        }
    */
    _onRowsRendered(opts) {
        this.props.onRowsRendered && this.props.onRowsRendered(opts);
    }

    /**
        opts: {
            clientHeight,
            scrollHeight,
            scrollTop
        }
    */
    _onScroll(opts) {
        if (this._searchField.current) {
            this._searchField.current.blur();
        }
        this.props.onScroll && this.props.onScroll(opts);
    }

    _getWidth() {
        if (this._node.current) {
            let style = window.getComputedStyle(this._node.current);
            return parseInt(style.width.substring(0, style.width.indexOf("px")));
        }
        else {
            return this.props.width;
        }
    }

    _getHeight() {
        if (this._node.current) {
            let style = window.getComputedStyle(this._node.current);
            return parseInt(style.height.substring(0, style.height.indexOf("px")));
        }
        else {
            return this.props.height;
        }
    }

    _getScrollToAlignment() {
        return this.props.scrollToAlignment;
    }

    _renderSearchField(key, style = {}) {
        style = {
            ...SEARCH_FIELD_CONTAINER_STYLES,
            ...style
        };
        return (
            <div 
                key={key}
                style={style}
                className={`search-container`}
            >
                <Searchfield 
                    ref={this._searchField}
                    value={this.state.query}
                    // style={SEARCH_FIELD_STYLES}
                    onSearch={(value) => {
                        this.setState({
                            isFiltering: value !== '',
                            query: value
                        });
                        this.props.onSearch && this.props.onSearch(value);
                    }}
                />
            </div>
        );
    }

    _renderBufferSpace(key, style = {}) {
        return (
            <div
                key={key}
                style={style}
                className="list-buffer-space"
            >
            </div>
        );
    }

    _getRowHeight({index} = {}) {
        switch (this.state.items[index]) {
            case SEARCH_FIELD_ITEM:
                return SEARCH_FIELD_HEIGHT;
            case BUFFER_SPACE_ITEM:
                return this.props.bufferSpace;
            // case NO_ROWS_RENDERED_ITEM:
            //     return this._getHeight() - SEARCH_FIELD_HEIGHT;
            default:
                return this.props.builder.getRowHeight();
        }
    }

    _getContent(props) {
        let list;

        if (props.autoSizeMode) {
            // AutoSizer makes the list expand to fill space.
            list = <VirtualizedAutoSizer>
                {({height, width}) => {
                    return <VirtualizedList
                        width={this._getWidth() ? this._getWidth() : width}
                        height={this._getHeight() ? this._getHeight() : height}
                        {...props}
                    />
                }}
            </VirtualizedAutoSizer>;
        }
        else {
            list = <VirtualizedList
                width={this._getWidth()}
                height={this._getHeight()}
                {...props}
            />;
        }

        return list;
    }

    getClassName() {
        return 'List';
    }

    getRowCount() {
        return this.props.items.length;
    }

    getEstimatedRowSize() {
        return this.props.builder.getRowHeight()
    }

    render() {
        let autoSizeMode = !this._getWidth() || !this._getHeight();
        let rowCount = this.getRowCount();
        let estimatedRowSize = this.getEstimatedRowSize();
        // if (!rowCount) {
        //     rowCount++; //no rows rendered row
        // }
        if (this.props.onSearch) {
            rowCount++;
            // estimatedRowSize += SEARCH_FIELD_HEIGHT;
        }
        if (this.props.bufferSpace) {
            rowCount++;
            // estimatedRowSize += this.props.bufferSpace;
        }

        let listProps = {
            id: this.props.id,
            ref: (r) => { 
                this._virtualizedListNode.current = r;
                // Since we can't get element by id during componentDidMount if AutoSizer is being used we are using the ref prop to determine
                // when it is safe to get the element.
                this._virtualizedListDOMNode.current = document.getElementById(this.props.id); 

                if (listProps.refCallback) {
                    listProps.refCallback(r);
                }
            },
            // ref: this._virtualizedListNode,
            rowHeight: this._getRowHeight,
            rowRenderer: this._rowRenderer,
            // When using noRowsRendered with the Searchfield, if the user searchs for something that causes all the rows to disapper it creates
            // really bad behaviour where the Searchfield is cleared, blurred, and all items are left filtered out. The List is essentially in an broken state.
            // For that reason we are going to handle no rows rendered scenario in the List component.
            // noRowsRenderer: this._noRowsRenderer,
            // Estimated row size will be off a little due to Searchfield height being different; but, that should be fine. It's only an estimate.
            estimatedRowSize: estimatedRowSize,
            onRowsRendered: this._onRowsRendered,
            onScroll: this._onScroll,
            overscanRowCount: this.props.overscanRowCount,
            rowCount: rowCount,
            scrollToAlignment: this._getScrollToAlignment(),
            autoSizeMode: autoSizeMode
        };

        return (
            <div
                className={`${this.getClassName()}${this.props.className ? ' ' + this.props.className : ''}${this.props.items.length === 0 ? ' no-content' : ''}`}
                ref={this._node}
            >
                {this._getContent(listProps)}
                {this.props.items.length === 0 && !this.state.isFiltering ? this._noRowsRenderer() : null}
            </div>
        );
    }
}

List.SCROLL_ALIGNMENT = {
    AUTO: "auto",
    START: "start",
    END: "end"
};

List.propTypes = {
    id: PropTypes.string.isRequired,
    // height/width are not required because of AutoSizer support.
    height: PropTypes.number, // Height constraint for list. Determines how many rows are rendered.
    width: PropTypes.number, // Width constraint for list.
    items: PropTypes.array.isRequired,
    builder: PropTypes.instanceOf(ListRowBuilder).isRequired,
    // bufferSpace is an additional row at the bottom of the List that is just empty space. 
    bufferSpace: PropTypes.number,
    // Number of rows to display above/below the visible rows. You should try to keep this number as low as possible. 
    // The higher the number is the more the List needs to do - negating the performance benefits of using this List.
    overscanRowCount: PropTypes.number, 
    scrollToAlignment: PropTypes.oneOf([
        List.SCROLL_ALIGNMENT.AUTO, 
        List.SCROLL_ALIGNMENT.START,
        List.SCROLL_ALIGNMENT.END
    ]),
    // Should return an jsx node WITH a key property.
    noRowsRenderer: PropTypes.func,
    onRowsRendered: PropTypes.func,
    onScroll: PropTypes.func,
    className: PropTypes.string,
    onRemoveItem: PropTypes.func,

    // WARNING: This property shouldn't be dynamically changing.
    // The onSearch property is used also used to determine if we should display the Searchfield. If the prop is there, display it, if not, don't display it.
    // In order to display the Searchfield and allow it be scrolled away, it is being placed in react-virtualized's rows with an fixed height.
    // React-virtualized seems to cache rowheights even with using the function option. If you need to change the row height for an given index; then,
    // you need to call recomputeRowHeights(index). This is not currently setup and will be difficult to setup property. In addition to this, recomputeRowHeights
    // calls forceUpdate internally. 
    // Since the List component is not setup to use recomputeRowHeights, if you were to dynamically change whether or not the List is using onSearch; then, 
    // the row with index 0 will have the wrong row height.
    onSearch: PropTypes.func
};

export { List };
