'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';
import Math, {
    UnitMode,
    Unit,
    UnitUtils,
    UnitConverter
} from '@totalpave/math';
import "../style/Textfield.less";
import {Measurement} from '@totalpave/interfaces';
import {ObjectUtils} from '@totalpave/object';

class UnitValue extends React.Component {
    shouldComponentUpdate(nextProps) {
        if (this.props.value !== nextProps.value) {
            return true;
        }

        if (this.props.unit !== nextProps.unit) {
            return true;
        }

        if (this.props.measurement !== nextProps.measurement) {
            return true;
        }

        if (this.props.noConvert !== nextProps.noConvert) {
            return true;
        }

        if (this.props.formatNumbers !== nextProps.formatNumbers) {
            return true;
        }

        return false;
    }

    render() {
        let value = parseFloat(this.props.value);
        let decimals = ObjectUtils.isVoid(this.props.decimals) ? 1 : this.props.decimals;

        if (this.props.unit === UnitMode.IMPERIAL && !this.props.noConvert) {
            switch (this.props.measurement) {
                case Measurement.LINEAR:
                    value = UnitConverter.convert(value, Unit.METER, Unit.FOOT, decimals);
                    break;
                case Measurement.SQUARE:
                    value = UnitConverter.convert(value, Unit.METER_SQUARED, Unit.FOOT_SQUARED, decimals);
                    break;
                case Measurement.CM_INCH:
                    value = UnitConverter.convert(value, Unit.CENTIMETER, Unit.INCH, decimals);
                    break;
                case Measurement.KM_MILES:
                    value = UnitConverter.convert(value, Unit.KILOMETER, Unit.MILE, decimals);
                    break;
            }
        }
        else {
            if (this.props.measurement === Measurement.KM_MILES) {
                // Adjust decimals
                value = UnitConverter.convert(value, Unit.KILOMETER, Unit.KILOMETER, decimals);
            }
            else {
                value = Math.round(value, decimals);
            }
        }

        if (isNaN(value)) {
            value = 0;
        }

        if (this.props.formatNumbers) {
            value = value.toLocaleString('en-US');
        }

        let unitDisplay = '';
        if (this.props.shouldDisplayUnit === false) {
            unitDisplay = '';
        }
        else {
            switch (this.props.measurement) {
                case Measurement.LINEAR:
                    unitDisplay = UnitUtils.getUnitText(this.props.unit === UnitMode.IMPERIAL ? Unit.FOOT : Unit.METER);
                    break;
                case Measurement.SQUARE:
                    unitDisplay = UnitUtils.getUnitText(this.props.unit === UnitMode.IMPERIAL ? Unit.FOOT_SQUARED : Unit.METER_SQUARED);
                    break;
                case Measurement.CM_INCH:
                    unitDisplay = UnitUtils.getUnitText(this.props.unit === UnitMode.IMPERIAL ? Unit.INCH : Unit.CENTIMETER);
                    break;
                case Measurement.KM_MILES:
                    unitDisplay = UnitUtils.getUnitText(this.props.unit === UnitMode.IMPERIAL ? Unit.MILE : Unit.KILOMETER);
                    break;
            }
        }

        if (this.props.shouldAddSpaceBeforeUnit === true && unitDisplay !== '') {
            unitDisplay = ' ' + unitDisplay;
        }
        
        return (
            <span className="UnitValue">
                {value}{unitDisplay}
            </span>
        );
    }
}

UnitValue.propTypes = {
    value: PropTypes.number,
    unit: PropTypes.any,
    measurement: PropTypes.any,
    noConvert: PropTypes.bool,
    formatNumbers: PropTypes.bool,
    decimals: PropTypes.number,
    shouldDisplayUnit: PropTypes.bool,
    shouldAddSpaceBeforeUnit: PropTypes.bool
};

export { UnitValue };
