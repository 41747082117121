'use strict';

import {Action} from './Action';
import {OrganizationClient} from '../net/OrganizationClient';
import {TPError} from '@totalpave/error';
import {ObjectUtils} from '@totalpave/object';

class DeleteOrganizationPreferenceSingleton extends Action {
    getTag() {
        return 'delete_organization_preference';
    }

    _shouldDispatchOnError() {
        return false;
    }

    _execute({preferenceKey, version} = {}) {
        return new Promise((resolve, reject) => {
            if (ObjectUtils.isVoid(version)) {
                version = 1;
            }

            OrganizationClient.getInstance().deletePreference(preferenceKey, version).then(() => {
                resolve({
                    key: preferenceKey,
                    version: version
                });
            }).catch((ex) => {
                let error = new TPError(ex);
                error.dispatch();
                reject(error);
            });
        });
    }
}

const DeleteOrganizationPreference = new DeleteOrganizationPreferenceSingleton();

export { DeleteOrganizationPreference };
