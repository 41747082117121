'use strict';

import * as React from 'react';
import {MenuItem} from '../components/MenuItem';

export class MenuBuilder {
    constructor(template) {
        this._templateItem = template || MenuItem;
    }

    setTemplate(template) {
        this._templateItem = template;
    }

    build(props) {
        let TemplateMenuItem = this._getTemplate();
        return <TemplateMenuItem {...props} />;
    }

    _getTemplate() {
        return this._templateItem;
    }
}
