'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';
import {Browser} from '../utils/Browser';
import {StylesheetManager} from '../utils/StylesheetManager';
import {ClassName} from '../utils/ClassName';
import {Logger} from '../utils/Logger';
import {ModalStore} from '../store/ModalStore';
import {NetworkStore} from '@totalpave/network';
import {ActionStore} from '../store/ActionStore';
import {DragStore} from '../store/DragStore';
import {MenuStore} from '../store/MenuStore';
import {MessageStore} from '../store/MessageStore';
import {ToolTipStore} from '../store/ToolTipStore';
import {MetadataStore} from '../store/MetadataStore';
import {MouseStore} from '../store/MouseStore';
import {ViewportStore} from '../store/ViewportStore';
import {UserStore} from '../store/UserStore';
import {PreferenceStore} from '../store/PreferenceStore';
import {LocalizationStore} from '../store/LocalizationStore';
import {ModalContainer} from '../components/ModalContainer';
import {MessageContainer} from '../components/MessageContainer';
import {LogoutComponent} from '../components/LogoutComponent';
import {TPError} from '@totalpave/error';
import {ApplicationInstance} from '@totalpave/application-instance';
import {NotificationFactory} from '../factories/NotificationFactory';
import {UIFactory} from '../factories/UIFactory';
import {DateFactory} from '../factories/DateFactory';
import {DefaultNotificationFactory} from '../factories/DefaultNotificationFactory';
import {JSDateFactory} from '../factories/JSDateFactory';
import {AppSupportClient} from '../net/AppSupportClient';
import {WebStorageStrategy} from '../strategies/WebStorageStrategy';
import {DefaultUIFactory} from '../factories/DefaultUIFactory';
// import {SafeAreaInsetMeasurement} from '../utils/SafeAreaInsetMeasurement';
import '../style/Application.less';
import { RequestLogoutAction } from '../actions/RequestLogoutAction';
import { LoadCurrencyLocalization } from '../actions/LoadCurrencyLocalization';
import { LoadOrganizationPreferences } from '../actions/LoadOrganizationPreferences';
import {ToolTipContainer} from '../components/ToolTipContainer';
import {Localization, LoadLocaleAction} from '@totalpave/localization';
import {FakeSentryClient} from '@totalpave/sentry';
import { InitSentry } from '../actions/InitSentry';
import { InsetStrategy } from '../strategies/InsetStrategy';
import { AppLoader } from '../components/AppLoader';
import {ScreenOrientationFactory} from '../utils/ScreenOrientationFactory';
import UpdateOrientation from '../actions/UpdateOrientation';
import {
    Currency,
    DiagnosticType
} from '@totalpave/interfaces';

let _instance = null;

class Application extends React.Component {
    constructor(props) {
        super(props);

        this.$sentryClient = this._createSentryClient();
        this.$sentryClient.setAppName(this._getAppId());

        if (_instance) { // would only be possible to hit if application is loaded constructed more than once
            console.error('There is already an active instance. Application should only be loaded once.');
            this.getSentryClient().captureException(new Error('There is already an active instance. Application should only be loaded once.'));
        }

        _instance = this;
        // TPError.setApplication(this);
        ApplicationInstance.setInstance(this);

        this._initDocumentElementStyle(document.documentElement.style);

        this.config = this._initConfig();
        this.logger = this._initLogger();

        if (localStorage.uncaughtError) {
            try {
                let uncaughtError = JSON.parse(localStorage.uncaughtError);
                // uncaughtError properties message and stack are saved from the error received in componentDidCatch.
                // Yes, we are re-assigning stack, this is reconstructing the error from that time.
                // Modifications to the message is to clarify the event and also aggregate the componentStack data.
                let error = new Error(
                    "Application recorded an uncaught error in componentDidCatch\n"+
                    "========================================================================\n"+
                    uncaughtError.message + "\n"+
                    "========================================================================\n"+
                    uncaughtError.componentStack
                );
                error.stack = uncaughtError.stack;
                this.logger.log(error);
                this.getSentryClient().captureException(error);
                delete localStorage.uncaughtError;
            }
            catch (ex) {
                this.logger.log("Failed to log uncaughtError");
                this.logger.log(ex);
                this.logger.log(localStorage.uncaughtError);
                this.getSentryClient().captureException(ex);
                delete localStorage.uncaughtError;
            }
        }
        
        // eslint-disable-next-line
        console.log(`React Runtime: ${process.env.NODE_ENV || 'development'}`);

        this._stylesheetManager = new StylesheetManager();

        this.state = {
            loggingOut: false,
            modalVisible: false,
            hasError: false,
            ready: false,
            $hasProgressViewTransitionTimerRan: false
        };

        this.$screenOrientation = new ScreenOrientationFactory().create();
        this.$screenOrientation.register(async (orientation) => {
            try {
                await UpdateOrientation.execute({
                    orientation: orientation
                });
            }
            catch (ex) {
                console.error('Failed to update orientation');
                this.$sentryClient.captureException(ex);
            }
        });

        this._onModalUpdate = this._onModalUpdate.bind(this);
        this._onNetworkUpdate = this._onNetworkUpdate.bind(this);
        this._onUserUpdate = this._onUserUpdate.bind(this);
        this._resetLogoutState = this._resetLogoutState.bind(this);
        this._getAdditionalDiagnosticData = this._getAdditionalDiagnosticData.bind(this);
        this.sendDiagnostics = this.sendDiagnostics.bind(this);
        this._onLocaleUpdate = this._onLocaleUpdate.bind(this);
        // this._runButtonHeightHack = this._runButtonHeightHack.bind(this);

        /*
            This event is fired when an Promise Reject is fired without a Reject Handler.
            developer.mozilla says only Chrome 49+ supports it; but, caniuse says Chrome 49+, Safari 11.1+, and a few others support it.
            It's possible that Babel also polyfills the event.

            Don't rely on this event for important debugging code. If it gets called great. If not, oh well.
        */
        window.addEventListener("unhandledrejection", (event) => { 
            // sometimes event.reason is undefined, for example unhandledrejection. So in those types we will use event.type, which would be something like "unhandledrejection"
            let error = new TPError({
                message: "unhandled rejection detected. Please handle this rejection.",
                details: event.reason ? event.reason : event.type
            }); // Don't dispatch the error. Just try to log it.
            this.getSentryClient().captureException(error);
        });

        // So we need SafeAreaInsetMeasurement to run the button height hack now; but, the inset 
        // can't be properly prepared in the constructor due to a race condition that is outside of our control.
        // We're going to try putting the button height hack somewheres else.
        //Size will differ a little depending if the StatusBar is being shown on start up or not
        //Right now it is assumed that the StatusBar will be shown on startup.
        // this._runButtonHeightHack();

        this.calledLogoutAction = false;

        this.setNotificationFactory(new DefaultNotificationFactory());
        this.setDateFactory(new JSDateFactory());
        this.setStorageStrategy(this._getDefaultStorageStrategy());
        this.setUIFactory(this._getDefaultUIFactory());
        this.setGeolocationAPI(this._getDefaultGeolocationAPI());

        // Used for platform specific styling.
        this._platform = Browser.getOS().toLowerCase();

        this.$progressViewTransitionTimer = null;
    }

    _initDocumentElementStyle(style) {
        style.height = "100%";
        style.width = "100%";
    }

    _createSentryClient() {
        // concrete classes should create and return a real concrete client
        // however if the application doesn't use sentry, a fake one will be used
        // to avoid the complexity of checking if a client exist anywheres.
        console.warn('Sentry is not configured.');
        return new FakeSentryClient();
    }

    _getDefaultUIFactory() {
        return new DefaultUIFactory();
    }

    _getDefaultGeolocationAPI() {
        return navigator.geolocation;
    }

    _updateSafeAreaInsetStyles(insets) {
        if (Browser.getOS() === Browser.ANDROID) {

            let r = document.querySelector(':root');
            r.style.setProperty('--android-inset-top', `${insets.top}px`);
            r.style.setProperty('--android-inset-bottom', `${insets.bottom}px`);
            r.style.setProperty('--android-inset-left', `${insets.left}px`);
            r.style.setProperty('--android-inset-right', `${insets.right}px`);
        }
    }

    // override this to inject more stores into the init process. Should return an array of store CLASSES (e.x: Store), not instances (e.x: Store.getInstance()).
    _getStores() {
        return [];
    }

    // This is not an exaustive list of stores. This is only meant to be used in the init process.
    getStores() {
        return [
            UserStore,
            PreferenceStore,
            ActionStore,
            DragStore,
            MenuStore,
            MessageStore,
            MetadataStore,
            ModalStore,
            MouseStore,
            NetworkStore,
            ViewportStore,
            LocalizationStore,
            ToolTipStore,
            ...this._getStores()
        ];
    }

    _getDefaultStorageStrategy() {
        return new WebStorageStrategy();
    }

    setStorageStrategy(strategy) {
        this._storageStrategy = strategy;
    }

    getStorageStrategy() {
        return this._storageStrategy;
    }

    async _createInsetStrategy() {
        return new InsetStrategy();
    }

    getInsetStrategy() {
        return this.$insetsStrategy;
    }

    setGeolocationAPI(api) {
        this._geolocationAPI = api;
    }

    getGeolocationAPI() {
        return this._geolocationAPI;
    }

    getVersion() {
        throw new Error("Application.getVersion is abstract. Import package.json and override this function to return the version from package.json.");
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            error: error
        };
    }

    _getAdditionalDiagnosticData() {
        return Promise.resolve({});
    }

    async _attachDiagnosticData(data) {}

    async sendDiagnostics(extraCallback) {
        let data = new FormData();
        try {
            data.append("localStorage", JSON.stringify(window.localStorage, null, 4));
        }
        catch (ex) {
            data.append("localStorage", new TPError(ex).getMessage());
        }

        try {
            data.append("sessionStorage", JSON.stringify(window.sessionStorage, null, 4));
        }
        catch (ex) {
            data.append("sessionStorage", new TPError(ex).getMessage());
        }

        try {
            data.append(DiagnosticType.FILES, new Blob([JSON.stringify(await this.getStorageStrategy().getDiagnosticData())], {type: "application/json"}), "storageStrategy.json");
        }
        catch (ex) {
            data.append("storageStrategy-error", new TPError(ex).getMessage());
        }

        // backwards compatibility
        try {
            data.append("other", JSON.stringify(await this._getAdditionalDiagnosticData(), null, 4)); 
        }
        catch (ex) {
            data.append("other", new TPError(ex).getMessage());
        }

        try {
            await this._attachDiagnosticData(data);
        }
        catch (ex) {
            data.append("_attachDiagnosticData", new TPError(ex).getMessage());
        }

        if (extraCallback) {
            try {
                await extraCallback(data);
            }
            catch (ex) {
                data.append("extraCallback", new TPError(ex).getMessage() + "\n\n\nWARNING: extraCallback has an unhandled error.");
            }
        }

        try {
            await AppSupportClient.getInstance().sendDiagnostics(data);
        }
        catch (ex) {
            new TPError(ex);
            throw new Error("Send Diagnostics failed. Please check your internet connection and try again.");
        }
    }

    // breaks circular dependency involving DispatchMessageAction, Action, and TPError
    getErrorClass() {
        return TPError;
    }

    isMobile() {
        if (Browser.isMobile()) {
            if (this._forceDesktopStyle()) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            if (this._forceMobileStyle()) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    // _runButtonHeightHack() {
    //     // && Browser.getBrowserCode() === Browser.SAFARI && Browser.getVersion().major == 9
    //     if (Browser.isMobile()) {
    //         let id = "ios9_button_height_fix";
    //         if (this._stylesheetManager.hasSheet(id)) {
    //             return;
    //         }

    //         let height = (window.screen.height - SafeAreaInsetMeasurement.getTop() - SafeAreaInsetMeasurement.getBottom()) / 10;

    //         this._stylesheetManager.removeSheet(id);
    //         this._stylesheetManager.addSheet(
    //             id, 
    //             {    //this needs to be more specific than .mobile .Button
    //                 ".mobile .Button" : {
    //                     "height" : height + "px"
    //                 }
    //             }
    //         );
    //     }
    // }

    getApplicationStyle() {
        return null;
    }

    getSentryClient() {
        return this.$sentryClient;
    }

    _onLocalizationInit(localization) {}

    async componentDidMount() {

        // this.getSentryClient().init();
        //here to support super on inheriting classes

        //Size will differ a little depending if the StatusBar is being shown on start up or not
        //Right now it is assumed that the StatusBar will be shown on startup.
        // this._runButtonHeightHack();
        
        _instance = this;
        let style = this.getApplicationStyle();
        if (style) {
            style.use();
        }

        let localization = Localization.getInstance();
        localization.addLangSupport('en-US');
        this._onLocalizationInit(localization);
        let preferredLanguage = this.getPreferredLanguage();
        try {
            await LoadLocaleAction.getInstance().execute({lang: preferredLanguage});
        }
        catch (ex) {
            console.warn('Unable to load Locale table', ex);
        }

        localization.register(this._onLocaleUpdate);

        let initContext = {
            steps: []
        };

        let warnTimer = window.setTimeout(() => {
            this.getSentryClient().captureException(new Error('App Initialize Timeout'), {
                extra: initContext
            });
        },  30000);

        if (window.gtag) {
            // If google analytics is enabled then attach URL listener to notify of page changes.
            this.props.router.addURLChangeCallback((url) => {
                window.gtag('set', {
                    // Google docs are inconsistent, in some places they mention page_path, in other places they mention page_location... e.g:
                    // - https://developers.google.com/tag-platform/gtagjs/reference/events#page_view
                    // - https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications
                    // page_path doesn't appear to work
                    'page_path': url,
                    'page_location': url
                });
                window.gtag('set', )
                window.gtag('event', 'page_view', {
                    page_location: url
                });
            });
        }

        this.initialize().then(() => {
            initContext.steps.push('initialize() finished');
            return this._onInitialized();
        }).then(() => {
            initContext.steps.push('onInitialized() finished');
            this._updateSafeAreaInsetStyles(this.getInsetStrategy().execute());
            initContext.steps.push('_updateSafeAreaInsetStyles() finished');
            return new Promise((resolve, reject) => {
                let callback = () => {
                    if (this.props.router.getLocation() === this.getHomeURL() || this.props.router.getLocation() === this.getLoginURL()) {
                        this._onPostInitialized().then(resolve).catch(reject);
                        this.props.router.removeViewMountCallback(callback);
                    }
                }

                initContext.steps.push('view mount callback added');
                this.props.router.addViewMountCallback(callback);

                if (this.isUserLoggedIn() && UserStore.getInstance().getAccessToken()) {
                    initContext.steps.push('has logged in user');
                    let user = UserStore.getInstance().getUser();
                    if (this._validateUser(user)) {
                        initContext.steps.push('is validated');
                        this.getSentryClient().setUser(user.id);
                        this.getSentryClient().setOrganizationID(user.organization.id);
                        this.getSentryClient().setOrganizationName(user.organization.name);
                        if (this.shouldRedirectToHome(this.props.url)) {
                            initContext.steps.push('is redirecting to home');
                            this.props.router.replaceState(this.getHomeURL());
                        }
                        else {
                            initContext.steps.push('not doing any navigation');
                        }
                    }
                    else {
                        initContext.steps.push('NOT validated');
                        this.getSentryClient().setUser(null);
                        this.getSentryClient().setOrganizationID(null);
                        this.getSentryClient().setOrganizationName(null);
                        initContext.steps.push('Logging out');
                        RequestLogoutAction.execute();
                    }
                }
                else {
                    initContext.steps.push('not a valid authenticated user');
                    this.getSentryClient().setUser(null);
                    this.getSentryClient().setOrganizationID(null);
                    this.getSentryClient().setOrganizationName(null);
                    let hash = window.location.hash;
                    if (!hash || (hash && hash.indexOf(this.getLoginURL()) === -1)) {
                        initContext.steps.push('navigating to login url');
                        this.props.router.replaceState(this.getLoginURL());
                    }
                    else {
                        initContext.steps.push('Not doing any navigation');
                    }
                }

                initContext.steps.push('setting app ready state to true');
                this.setState({
                    ready: true
                }, () => {
                    initContext.steps.push('ready state is now true');
                    window.clearTimeout(warnTimer);
                });
            });
        }).catch((error) => {
            let tperror = new TPError({
                message: "Application failed to initialize.",
                details: error
            });
            initContext.steps.push('has errored');
            initContext.steps.push(tperror.getMessage());
            tperror.dispatch();
            this.getSentryClient().captureException(tperror);
        });
    }

    async initialize() {
        // Initialize storage strategy before everything else. 
        await this.getStorageStrategy().initialize();

        // Initialize stores defined in getStores
        let storePromises = this.getStores().map((store) => {
            return store.getInstance().initialize();
        });

        await Promise.all(storePromises);

        // Migrate stores before doing extending class' _initialize
        await Promise.all([
            PreferenceStore.getInstance().migrateStorageSystem(),
            UserStore.getInstance().migrateStorageSystem()
        ]);

        await Promise.all(this._initialize()); // extending class' _initialize

        await InitSentry.execute(this.getSentryClient());

        this.$insetsStrategy = await this._createInsetStrategy();
    }

    _validateUser(user) {
        return true;
    }

    async _onInitialized() {
        ModalStore.getInstance().register(this._onModalUpdate);
        NetworkStore.getInstance().register(this._onNetworkUpdate);
        UserStore.getInstance().register(this._onUserUpdate);

        if (this.isUserLoggedIn() && UserStore.getInstance().getAccessToken()) {
            if (this._validateUser(UserStore.getInstance().getUser())) {
                LoadOrganizationPreferences.execute().catch((e) => { /* swallow */ });
                new LoadCurrencyLocalization().execute(UserStore.getInstance().getOrganizationCurrency() || Currency.US_DOLLAR).catch((e) => {
                    this.getSentryClient().captureException(e);
                });
            }
        }
        return Promise.resolve();
    }

    _onPostInitialized() {
        return Promise.resolve();
    }

    // Should return array of promises
    _initialize() {
        return [];
    }

    static getInstance() {
        return _instance;
    }

    //Can override to set limit on number of messages that can be displayed. If overriding, this method should return an integer greater than 0.
    _getMessageLimit() {
        return 3;
    }

    _initConfig() {
        throw new Error('_initConfig is abstract');
    }

    _initLogger() {
        return new Logger(this.getConfig());
        //throw new Error('_initLogger is abstract');
    }

    getLogger() {
        return this.logger;
    }

    getConfig() {
        return this.config;
    }

    getUIFactory() {
        return this._uiFactory;
    }

    getDateFactory() {
        return this._dateFactory;
    }

    getNotificationFactory() {
        return this._notificationFactory;
    }

    setDateFactory(factory) {
        if (!(factory instanceof DateFactory)) {
            throw new Error('setDateFactory expects an instance of DateFactory');
        }
        this._dateFactory = factory;
    }

    setUIFactory(factory) {
        if (!(factory instanceof UIFactory)) {
            throw new Error('setUIFactory expects an instance of UIFactory');
        }
        this._uiFactory = factory;
    }

    setNotificationFactory(factory) {
        if (!(factory instanceof NotificationFactory)) {
            throw new Error('setNotificationFactory expects an instance of NotificationFactory');
        }
        this._notificationFactory = factory;
    }

    componentDidCatch(error, info) {
        // eslint-disable-next-line
        console.log(error, info);
        localStorage.uncaughtError = JSON.stringify({
            message: error.message,
            stack: error.stack,
            componentStack: info.componentStack
        });
        this.props.router.replaceState('/error');
    }

    isUserLoggedIn() {
        return !!UserStore.getInstance().getUser();
    }

    //The url that the user be redirected to after logging in.
    getHomeURL() {
        return '/home';
    }

    getLoginURL() {
        return '/login';
    }

    // eslint-disable-next-line
    shouldRedirectToHome(url) {
        return true;
    }

    componentWillUnmount() {
        let style = this.getApplicationStyle();
        if (style) {
            style.unuse();
        }
        Localization.getInstance().unregister(this._onLocaleUpdate);
        _instance = null;
    }

    _onModalUpdate() {
        this.setState({
            modalVisible : ModalStore.getInstance().isModalVisible()
        });
    }

    _onNetworkUpdate() {
        this.setState({
            slowNetwork : true
        });
        setTimeout(() => {
            this.setState({
                slowNetwork : false
            });
        }, 5000);
    }

    _onUserUpdate() {
        if (this.state.ready) {
            if (!this.state.loggingOut && UserStore.getInstance().shouldLogout()) {
                this.setState({
                    loggingOut: true
                }, () => {
                    this._willLogout();
                });
            }
        }
    }

    _willLogout() {}

    //should return array of jsx nodes
    _preRender() {
        return [];
    }

    //should return array of jsx nodes
    _postRender() {
        return [];
    }

    _getClassName() {
        return `${this._platform} ${this._className}`;
    }

    _getAppId() {
        return null;
    }

    _forceDesktopStyle() {
        return false;
    }

    _forceMobileStyle() {
        return false;
    }

    isLoggingOut() {
        return this.state.loggingOut;
    }

    _resetLogoutState() {
        this.setState({
            loggingOut: false
        });
    }

    // Abstracted so extending applications can override
    _getLogoutComponent() {
        return <LogoutComponent done={this._resetLogoutState} />;
    }

    render() {
        let className = `${this.state.modalVisible ? 'modal-opened' : ''} ${this._getClassName()} `;
        
        let localization = Localization.getInstance();
        let supportedLangs = localization.getSupportedLanguages();
        for (let i = 0; i < supportedLangs.length; i++) {
            this.removeBodyClass(supportedLangs[i]);
        }

        let isPhysicalLandscape = this.$screenOrientation.isPhysicalLandscape();
        if (isPhysicalLandscape) {
            this.removeBodyClass('portrait');
        }
        else {
            this.removeBodyClass('landscape');
        }

        this.removeBodyClasses([
            'primary',
            'secondary'
        ]);
        
        let bodyClasses = ClassName.execute({
            // These are two opposite environment descriptors
            // Only one will be present at the same time
            'site': Browser.isSite(),
            'app': Browser.isApplication(),

            // These are form factor descriptors
            // mobile is a convenience descriptior for phone + tablet
            'phone': Browser.isPhone(),
            'mobile': Browser.isMobile(),
            'desktop': Browser.isDesktop(),
            'tablet': Browser.isTablet(),

            'landscape': this.$screenOrientation.isPhysicalLandscape(),
            'portrait': this.$screenOrientation.isPhysicalPortrait(),
            'primary': this.$screenOrientation.isPhysicalPrimary(),
            'secondary': this.$screenOrientation.isPhysicalSecondary(),

            // Language descriptor
            [localization.getActiveLanguage()]: true
        }, [
            // Adds the browser code + browser major version, e.g: `chrome chrome110`
            Browser.getBrowserCode(),
            `${Browser.getBrowserCode()}${Browser.getVersion().major}`
        ]);

        this.addBodyClasses(bodyClasses.split(' '));

        let progressView = this._renderProgressView();
        if (progressView) {
            progressView = (
                <div className='progress-view'>
                    {progressView}
                </div>
            );
        }
        else {
            // Perhaps a default loading indicator which is only shown while
            // ready state is false?
            progressView = <AppLoader />;
        }

        let view;
        if (this.state.ready) {
            view = (
                <div key={this._getAppId()} id={this._getAppId()} className={ClassName.execute({
                    ready: this.state.ready
                }, [className])}>
                    {this._preRender()}
                    <ModalContainer />
                    <MessageContainer limit={this._getMessageLimit()} />
                    <ToolTipContainer key="tooltip-container" />
                    {progressView}
                    {this.props.children}
                    {this.state.loggingOut ? this._getLogoutComponent() : null}
                    {this._postRender()}
                </div>
            );
        }
        else {
            view = (
                <div key={this._getAppId()} id={this._getAppId()} className={className}>
                    {progressView}
                </div>
            );
        }

        return view;
    }

    _startProgressViewTransitionTimer() {
        this.setState({
            $hasProgressViewTransitionTimerRan: false
        })
        this.$progressViewTransitionTimer = setTimeout(() => {
            this.setState({
                $hasProgressViewTransitionTimerRan: true
            });
        }, 500);
    }

    _hasProgressViewTransitionTimeRan() {
        return this.state.$hasProgressViewTransitionTimerRan;
    }

    _renderProgressView() {
        return null;
    }

    addBodyClasses(classNames) {
        document.body.classList.add(...classNames);
        // let bodyClasses = document.body.getAttribute('class');
        // if (!bodyClasses) {
        //     bodyClasses = '';
        // }
        // bodyClasses = bodyClasses.trim().split(' ');

        // for (let i = 0; i < classNames.length; i++) {
        //     let cn = classNames[i].trim();
        //     if (bodyClasses.indexOf(cn) === -1) {
        //         bodyClasses.push(cn);
        //     }
        // }

        // document.body.setAttribute('class', bodyClasses.join(' '));
    }

    addBodyClass(className) {
        document.body.classList.add(className);
        // this.addBodyClasses([className]);
    }

    removeBodyClass(className) {
        document.body.classList.remove(className);
        // let bodyClasses = document.body.getAttribute('class');
        // if (!bodyClasses) {
        //     bodyClasses = '';
        // }
        // bodyClasses = bodyClasses.trim().split(' ');

        // let index = bodyClasses.indexOf(className);
        // if (index > -1) {
        //     bodyClasses.splice(index, 1);
        // }

        // document.body.setAttribute('class', bodyClasses.join(' '));
    }

    removeBodyClasses(classNames) {
        document.body.classList.remove(...classNames);
    }

    getUserStore() {
        return UserStore.getInstance();
    }

    requestLogout() {
        return RequestLogoutAction.execute();
    }

    getPreferredLanguage() {
        return Localization.getInstance().getPreferredSupportedLanguage();
    }

    _onLocaleUpdate() {
        this.forceUpdate();
    }
}

Application.propTypes = {
    children: PropTypes.node,
    router: PropTypes.any,
    url: PropTypes.string
};

export {Application};
