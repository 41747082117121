
import {NumberFormatTextfieldPropsBuilder} from './NumberFormatTextfieldPropsBuilder';
import {InputType} from '../utils/InputType';

class CurrencyTextfieldPropsBuilder extends NumberFormatTextfieldPropsBuilder {

    _getInputType() {
        return InputType.CURRENCY;
    }

    _getAdditionalDefaultProps() {
        let props = super._getAdditionalDefaultProps();
        props.allowNegative = true;
        // Let CurrencyTextfield default this property
        props.fixedDecimalScale = null;

        props.defaultValue = null;

        return props;
    }
}

export {CurrencyTextfieldPropsBuilder};
