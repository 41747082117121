'use strict';

import {Action} from './Action';
import {OrganizationClient} from '../net/OrganizationClient';
import {TPError} from '@totalpave/error';
import {ObjectUtils} from '@totalpave/object';

class SaveOrganizationPreferenceSingleton extends Action {
    getTag() {
        return 'save_organization_preference';
    }

    _shouldDispatchOnError() {
        return false;
    }

    _execute({preferenceKey, value, version, serializer} = {}) {
        return new Promise((resolve, reject) => {
            if (ObjectUtils.isVoid(version)) {
                version = 1;
            }

            if (typeof value !== 'string') {
                try {
                    value = (serializer) ? serializer(value) : JSON.stringify(value);
                }
                catch (ex) {
                    let error = new TPError(ex);
                    error.dispatch();
                    return;
                }
            }

            OrganizationClient.getInstance().savePreference(preferenceKey, value, version).then(() => {
                resolve({
                    key: preferenceKey,
                    value: value,
                    version: version
                });
            }).catch((ex) => {
                let error = new TPError(ex);
                error.dispatch();
                reject(error);
            });
        });
    }
}

const SaveOrganizationPreference = new SaveOrganizationPreferenceSingleton();

export default SaveOrganizationPreference;
export { SaveOrganizationPreference };
