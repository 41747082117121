'use strict';

/**
 * @deprecated Use @totalpave/interface IDateFactory instead.
 */
class DateFactory {
    // create will accept any number of arguments using the arguments key word.
    // These arguments should be forwarded to the Date's constructor like so:
    // new Date(...arguments)
    create() {
        throw new Error('DateFactory.create is abstract.');
    }
}

export { DateFactory };
