'use strict';

import { ComparisonStrategy } from '@totalpave/comparisons';

export class FilterStrategy extends ComparisonStrategy {
    
    _getValue(data, column) {
        return data[column];
    }

    filter(datas, rules) {
        return datas.filter((data) => {
            let keepItem = true;
            for (let i = 0, length = rules.length; i < length; ++i) {
                let rule = rules[i];
                keepItem = this.compare(data, rule);

                // if we have determined that we are filtering this data row; then, stop looping through rules.
                if (!keepItem) {
                    break;
                }
            }

            return keepItem;
        });
    }
}
