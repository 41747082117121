'use strict';

const HTTPConstants = {
    HEAD        : 'HEAD',
    GET         : 'GET',
    POST        : 'POST',
    PUT         : 'PUT',
    DELETE      : 'DELETE',

    OK                      : 200,
    OK_NO_CONTENT           : 204,

    BAD_REQUEST             : 400,
    UNAUTHORIZED            : 401,
    FORBIDDEN               : 403,
    NOT_FOUND               : 404,

    INTERNAL_SERVER_ERROR   : 500,
    BAD_GATEWAY             : 502,
    SERVICE_UNAVAILABLE     : 503
};

export { HTTPConstants };
