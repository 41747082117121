import {NumberFormatTextfield} from './NumberFormatTextfield';
import {LocalizationStore} from '../store/LocalizationStore';
import {ObjectUtils} from '@totalpave/object';
import BigNumber from "bignumber.js";
import PropTypes from 'prop-types';

class CurrencyTextfield extends NumberFormatTextfield {
    constructor() {
        super();

        this.state = {
            ...this.state,
            ...this._getCurrencyFormat()
        };

        this._onLocalizationStoreUpdate = this._onLocalizationStoreUpdate.bind(this);
    }

    _getCurrencyFormat() {
        let state = {};
        let format = LocalizationStore.getInstance().getCurrencyFormat();
        let iterator = format.iterator();
        while (iterator.hasNext()) {
            let item = iterator.next();
            if (item.key === "decimalSeparator" && item.value === "") {
                item.value = null;
            }
            state[item.key] = item.value;
        }
        return state;
    }

    _parseValue(event) {
        return new BigNumber(super._parseValue(event));
    }

    _getInternalProps(props) {
        props = super._getInternalProps(props);

        props.className += " CurrencyTextfield";

        if (ObjectUtils.isVoid(props.thousandSeparator)) {
            props.thousandSeparator = this.state.groupSeparator;
        }

        if (ObjectUtils.isVoid(props.thousandsGroupStyle)) {
            props.thousandsGroupStyle = this.state.groupStyle;
        }

        if (ObjectUtils.isVoid(props.decimalSeparator)) {
            props.decimalSeparator = this.state.decimalSeparator;
        }

        if (ObjectUtils.isVoid(props.decimalScale)) {
            props.decimalScale = this.state.decimalScale;
        }

        if (ObjectUtils.isVoid(props.fixedDecimalScale)) {
            props.fixedDecimalScale = true;
        }

        if (ObjectUtils.isVoid(props.prefix)) {
            props.prefix = this.state.prefixSymbol;
        }

        if (ObjectUtils.isVoid(props.suffix)) {
            props.suffix = this.state.suffixSymbol;
        }

        if (!ObjectUtils.isVoid(props.value)) {
            // Webpack/babel is breaking instanceof checks here
            // if (props.value instanceof BigNumber) {
            if (props.value.toNumber) {
                props.value = props.value.toNumber();
            }
            else if (typeof props.value === 'string') {
                props.value = parseFloat(props.value);
            }
        }
        if (isNaN(props.value) || props.value === '') {
            props.value = null;
        }

        props.isNumericString = false;

        return props;
    }

    _onLocalizationStoreUpdate() {
        this.setState({ 
            ...this._getCurrencyFormat()
        });
    }

}

CurrencyTextfield.propTypes = {
    defaultValue: PropTypes.instanceOf(BigNumber),
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.instanceOf(BigNumber)
    ])
}

export {CurrencyTextfield}
