
import * as React from 'react';
import {ApplicationInstance} from '@totalpave/application-instance';
import {AbstractCrumbBuilder} from './AbstractCrumbBuilder';
import {IconFactory} from '../factories/IconFactory';

export class TextualCrumbBuilder extends AbstractCrumbBuilder {
    _build(options, activeIndex, breadcrumb) {
        let crumbs = [];

        for (let i = 0; i < options.length; i++) {
            let option = options[i];
            let text = '';
            if (option && option.text) {
                text = option.text;
            }
            else {
                ApplicationInstance.getInstance().getLogger().warn('Missing text attribute on index', activeIndex);
            }

            let crumb = <span
                key={i}
                className={`crumb ${activeIndex === i ? 'active' : ''} ${option.clickable ? 'clickable' : ''}`}
                data-index={i}
                onClick={option.clickable ? (event) => {
                    let index = event.currentTarget.getAttribute("data-index");
                    if (isNaN(index)) {
                        throw new Error(`clickable Crumb's data-index attribute returned NaN value: ${index}`);
                    }
                    index = parseInt(index);
                    breadcrumb.setIndex(index);
                } : null}
            >
                {text}
            </span>;
            if (crumbs.length > 0) {
                crumbs.push(IconFactory.create(IconFactory.Icons.CHEVRON_RIGHT, {
                    key: `${i}-arrow`
                }));
            }

            crumbs.push(crumb);
        }

        return crumbs;
    }
}
