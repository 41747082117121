
import * as React from 'react';
import TPLogoResource from '../assets/embed/logo.svg';
import '../style/TPLogo.less';

export class TPLogo extends React.Component {
    public render(): React.ReactNode {
        return (
            <div className="TPLogo">
                <TPLogoResource />
            </div>
        );
    }
}
