'use strict';

import {NetworkClient} from './NetworkClient';

let _instance = null;

class OrganizationClient extends NetworkClient {
    constructor() {
        super();
    }

    static getInstance() {
        if (!_instance) {
            _instance = new OrganizationClient();
        }

        return _instance;
    }

    _getServiceBase() {
        return 'customers';
    }

    updateSettings(settings) {
        return this.post('updateSettings', {settings:settings});
    }

    /**
     * Saves multiple preferences.
     * 
     * @param {Array<IDictionary>} preferences [ { key: string, value: string, version: number } ]
     */
    savePreferences(preferences) {
        return this.post('preferences/list/save', {
            preferences: preferences
        });
    }

    /**
     * Save a single preference
     * 
     * @param {string} key 
     * @param {string} value 
     * @param {number} version 
     */
    savePreference(key, value, version) {
        let url = `preferences/preference/${key}`;

        if (version !== undefined) {
            url += `/${version}`;
        }

        return this.post(url, {
            preference : value
        });
    }

    getPreference(key, version) {
        let url = `preferences/preference/${key}`;

        if (version !== undefined) {
            url += `/${version}`;
        }

        return this.get(url);
    }

    deletePreference(key, version) {
        let url = `preferences/preference/${key}`;

        if (version !== undefined) {
            url += `/${version}`;
        }

        return this.del(url);
    }

    getPreferences() {
        return this.get('preferences/listv2');
    }

    getCountryList() {
        return this.get('country/list');
    }

    getCurrencyList() {
        return this.get('currency/list');
    }
}

export { OrganizationClient };
