
/**
 * Copyright 2023-2024 Total Pave Inc.
 * All Rights Reserved.
 */

import * as React from 'react';
import {ClassName} from '../utils/ClassName';
import style from '../style/components/Icon.less';

/**
 * @since 1.0.0
 */
export interface IIconProps<TValue = unknown> {
    title?: string;
    value?: TValue;
    className?: string;
    enabled?: boolean;
    style?: React.CSSProperties;
    onRefReady?: (node: HTMLDivElement) => void;
    onClick?: (e: React.MouseEvent) => void;
    onMouseDown?: (e: React.MouseEvent) => void;
    onLongPress?: (e: React.MouseEvent) => void;
}

/**
 * An abstract component class for rendering Icons
 * 
 * @since 1.0.0
 */
export abstract class Icon<TProps extends IIconProps = IIconProps> extends React.Component<TProps> {
    protected _node: HTMLDivElement;

    public constructor(props: TProps) {
        super(props);
        this._onClick = this._onClick.bind(this);
        this._onMouseDown = this._onMouseDown.bind(this);
    }

    public override componentDidMount(): void {
        style.use();
    }

    public override componentWillUnmount(): void {
        style.unuse();
    }

    public abstract getAdditionalCSSClass(): string;
    protected abstract _render(): React.ReactNode;

    public isEnabled(): boolean {
        return this.props.enabled === undefined || !!this.props.enabled;
    }

    protected _onClick(e: React.MouseEvent): void {
        if (this.isEnabled()) {
            if (this.props.onClick) {
                e.stopPropagation();
                this.props.onClick(e);
            }
        }
    }

    protected _onMouseDown(e: React.MouseEvent): void {
        this.props.onMouseDown && this.props.onMouseDown(e);
    }

    public render(): React.ReactNode {
        let defaultClasses: string[] = [
            'Icon',
            this.getAdditionalCSSClass()
        ];

        if (this.props.className) {
            defaultClasses.push(this.props.className)
        }

        if (typeof this.props.value === 'string' && this.props.value.indexOf('data:') === -1) {
            defaultClasses.push(this.props.value);
        }

        let className: string = ClassName.execute({
            'clickable': !!this.props.onClick
        }, defaultClasses);

        let hotspot: React.ReactNode = null;
        if (this.props.onClick || this.props.onMouseDown) {
            let eventHandlers: Record<string, React.MouseEventHandler<HTMLDivElement>> = {};
            if (this.props.onLongPress) {
                let longPressTimeout: number | null = null;
                let isLongPress: boolean = false;
                eventHandlers.onTouchStart = (e: React.MouseEvent) => {
                    e.stopPropagation();
                    e.persist();
                    longPressTimeout = window.setTimeout(() => {
                        isLongPress = true;
                        this.props.onLongPress && this.props.onLongPress(e);
                    }, 500);
                };
                eventHandlers.onTouchEnd = (e: React.MouseEvent) => {
                    window.clearTimeout(longPressTimeout);
                    if (!isLongPress) {
                        isLongPress = false;
                        this._onClick(e);
                    }
                };
            }
            else {
                eventHandlers.onClick = this._onClick;
            }

            hotspot = (
                <div
                    className="hotspot"
                    {...eventHandlers}
                ></div>
            );
        }

        return (
            <div
                ref={(node: HTMLDivElement) => {
                    this._node = node;
                    if (this.props.onRefReady) {
                        this.props.onRefReady(this._node);
                    }
                }}
                className={className}
                title={this.props.title}
                style={this.props.style}
                onClick={this._onClick}
                onMouseDown={this._onMouseDown}
            >
                {hotspot}
                {this._render()}
            </div>
        );
    }

    public getNode(): HTMLDivElement {
        return this._node;
    }
}
