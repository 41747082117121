'use strict';

class Iterator {
    constructor(data) {
        this._data = data;
        this._cursor = -1;
    }

    hasNext() {
        let cursor = this._cursor + 1;
        return cursor >= 0 && cursor < this._data.length;
    }

    hasPrevious() {
        let cursor = this._cursor;
        return cursor >= 0 && cursor < this._data.length;
    }

    next() {
        this._cursor++;
        return this._data[this._cursor];
    }

    previous() {
        return this._data[this._cursor--];
    }

    iterate(fn) {
        this._data.forEach(fn);
    }

    hasReachedEnd() {
        return this._cursor > this._data.length;
    }

    reset() {
        this._cursor = -1;
    }
}

export { Iterator };
