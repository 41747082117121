/**
 * Copyright 2024-2024 Total Pave Inc.
 * All Rights Reserved.
 */

import * as React from 'react';
import {ClassName} from '../utils/ClassName';
import {ObjectUtils} from '@totalpave/object';
import style from '../style/components/Button.less';

export type TButtonRenderWaitIndicator = () => React.ReactNode;
export type TButtonOnClickWaitForCallback = (e: React.MouseEvent, cb?: () => void) => void;

export interface IButtonProps {
    text: string;

    className?: string;
    preIcon?: React.ReactNode;
    postIcon?: React.ReactNode;

    noBorderRadius?: boolean;
    noTopBorderRadius?: boolean;
    enabled?: boolean;
    waitForCallback?: boolean;
    loadingIndicatorDelay?: number;
    renderWaitIndicator?: TButtonRenderWaitIndicator;
    standard?: boolean;
    title?: string;
    style?: React.CSSProperties;

    onClick?: TButtonOnClickWaitForCallback;
    onDisabledClick?: (e: React.MouseEvent) => void;
    onMouseDown?: (e: React.MouseEvent) => void;
    onMouseUp?: (e: React.MouseEvent) => void;
    onTouch?: (e: React.TouchEvent) => void;
    onTouchEnd?: (e: React.TouchEvent) => void;

    onAnimationStart?: (e: React.AnimationEvent) => void;
    onAnimationIteration?: (e: React.AnimationEvent) => void;
    onAnimationEnd?: (e: React.AnimationEvent) => void;
    onTransitionEnd?: (e: React.TransitionEvent) => void;
}

interface IButtonState {
    active: boolean;
    waiting: boolean;
    loadingIndicatorDelayExceeded: boolean;
}

export class Button extends React.Component<IButtonProps, IButtonState> {
    private $node: HTMLDivElement;
    private $loadingIndicatorTimeout: number;

    public constructor(props: IButtonProps) {
        super(props);

        this.state = {
            active: false,
            waiting: false,
            loadingIndicatorDelayExceeded: false
        };

        this.$onClick = this.$onClick.bind(this);
        this.$onMouseDown = this.$onMouseDown.bind(this);
        this.$onMouseUp = this.$onMouseUp.bind(this);
        this.$onTouchStart = this.$onTouchStart.bind(this);
        this.$onTouchEnd = this.$onTouchEnd.bind(this);
    }

    public override componentDidMount(): void {
        style.use();
    }

    public override componentWillUnmount(): void {
        style.unuse();
    }

    private $buildCallbackContext() {
        return () => {
            clearTimeout(this.$loadingIndicatorTimeout);
            this.setState({
                waiting: false,
                loadingIndicatorDelayExceeded: false
            });
        };
    }

    private $renderWaitingIndicator(): React.ReactNode {
        if (this.props.renderWaitIndicator) {
            return this.props.renderWaitIndicator();
        }

        // animDuration of 1s for 100px width Button works well. If Button width is different, the animation duration should be adjusted.
        // For example, Button width of 50px, 0.5s animation duration actually works well. 
        let indicator = (
            <div className='loading-bar-container'>
                <div
                    className='loading-bar'
                    style={{animationDuration: `${parseInt(getComputedStyle(this.$node).width) / 100}s`}}
                />
            </div>
        );

        return indicator;
    }

    public isEnabled(): boolean {
        return ObjectUtils.isVoid(this.props.enabled) ? true : this.props.enabled;
    }

    //onTouch / onMouseDown
    private $onActive<T>(prop: (e: T) => void, event: T): void {
        if (this.isEnabled() && !this.state.waiting) {
            this.setState({
                active: true
            });
            prop && prop(event);
        }
    }

    private $onRelease<T>(prop: (e: T) => void, event: T): void {
        if (this.isEnabled() && !this.state.waiting) {
            this.setState({
                active: false
            });
            prop && prop(event);
        }
    }

    private $onMouseDown(event: React.MouseEvent): void {
        this.$onActive(this.props.onMouseDown, event);
    }

    private $onMouseUp(event: React.MouseEvent): void {
        this.$onRelease(this.props.onMouseUp, event);
    }

    private $onTouchStart(event: React.TouchEvent): void {
        this.$onActive(this.props.onTouch, event);
    }

    private $onTouchEnd(event: React.TouchEvent): void {
        this.$onRelease(this.props.onTouchEnd, event);
    }

    private $onClick(event: React.MouseEvent): void {
        if (this.isEnabled() && !this.state.waiting) {
            let callbackContext: () => void = null;
            if (this.props.waitForCallback) {
                let timeoutDelay = 300;
                if (!ObjectUtils.isVoid(this.props.loadingIndicatorDelay)) {
                    timeoutDelay = this.props.loadingIndicatorDelay;
                }

                this.$loadingIndicatorTimeout = window.setTimeout(() => {
                    this.setState({
                        loadingIndicatorDelayExceeded: true
                    });
                }, timeoutDelay);
                callbackContext = this.$buildCallbackContext();
                this.setState({
                    waiting: true
                });
            }
            this.props.onClick && this.props.onClick(event, callbackContext);
        }
        else {
            this.props.onDisabledClick && this.props.onDisabledClick(event);
        }
    }

    private $renderIcon(icon: React.ReactNode): React.ReactNode {
        if (!icon) {
            return null;
        }

        return (
            <div className="icon-container">
                {icon}
            </div>
        );
    }

    public override render(): React.ReactNode {
        let preIcon, postIcon, loadingbar = null;

        if (!this.state.waiting) {
            preIcon = this.props.preIcon;
        }

        if (!this.state.waiting) {
            postIcon = this.props.postIcon;
        }

        if (this.state.waiting && this.state.loadingIndicatorDelayExceeded) {
            loadingbar = this.$renderWaitingIndicator();
        }

        let text = this.props.text || '';

        return (
            <div
                className={ClassName.execute({
                    'wait-support': !!this.props.waitForCallback,
                    'no-border-radius': this.props.noBorderRadius,
                    'no-top-border-radius': this.props.noTopBorderRadius,
                    'disabled': !this.isEnabled() || this.state.waiting,
                    'waiting': this.state.waiting,
                    'active': this.state.active,
                    // 'safeInsetModeTop': this.props.safeInsetMode ? this.props.safeInsetMode.top : false,
                    // 'safeInsetModeRight': this.props.safeInsetMode ? this.props.safeInsetMode.right : false,
                    // 'safeInsetModeBottom': this.props.safeInsetMode ? this.props.safeInsetMode.bottom : false,
                    // 'safeInsetModeLeft': this.props.safeInsetMode ? this.props.safeInsetMode.left : false,
                    'standard': ObjectUtils.isVoid(this.props.standard) ? true : this.props.standard
                }, [
                    'Button',
                    this.props.className
                ])}
                ref={(c) => { this.$node = c; }}
                style={this.props.style}
                onMouseDown={this.$onMouseDown}
                onMouseUp={this.$onMouseUp}
                onTouchStart={this.$onTouchStart}
                onTouchEnd={this.$onTouchEnd}
                onClick={this.$onClick}
                title={this.props.title}
                onAnimationEnd={this.props.onAnimationEnd}
                onAnimationStart={this.props.onAnimationStart}
                onAnimationIteration={this.props.onAnimationIteration}
                onTransitionEnd={this.props.onTransitionEnd}
            >
                {this.$renderIcon(preIcon)}
                <span className="button-label">
                    {text}
                </span>
                {loadingbar}
                {this.$renderIcon(postIcon)}
            </div>
        );
    }
}
