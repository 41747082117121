import * as React from 'react';
import {MenuStore} from '../store/MenuStore';
import {ClearMenusAction} from '../actions/ClearMenusAction';
import {Entities} from '../utils/Entities';
import {KeyCode} from '../utils/KeyCode';
import {KeyboardListener} from '../utils/KeyboardListener';
import '../style/MenuContainer.less';
import {Browser} from '../utils/Browser';

/** @deprecated Use @totalpave/ui-menu */
class MenuContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menus: []
        };

        this._onUpdate = this._onUpdate.bind(this);
        this._clearMenus = this._clearMenus.bind(this);
        this._onKeyUp = this._onKeyUp.bind(this);
    }

    componentDidMount() {
        MenuStore.getInstance().register(this._onUpdate);
        KeyboardListener.register(KeyboardListener.E_KEYUP, this._onKeyUp);
    }

    componentWillUnmount() {
        MenuStore.getInstance().unregister(this._onUpdate);
        KeyboardListener.unregister(KeyboardListener.E_KEYUP, this._onKeyUp);
    }

    _onUpdate() {
        this.setState({
            menus : MenuStore.getInstance().getMenus()
        });
    }

    _onKeyUp(e) {
        if (e.keyCode === KeyCode.ESCAPE) {
            this._clearMenus();
        }
    }

    _clearMenus() {
        ClearMenusAction.execute();
    }

    hasActiveMenu() {
        for (let i = 0; i < this.state.menus.length; i++) {
            let menu = this.state.menus[i];
            if (menu.props.active) {
                return true;
            }
        }

        return false;
    }

    render() {
        let clickCaptureStyle = {
            pointerEvents: 'auto'
        };

        if (!this.hasActiveMenu()) {
            clickCaptureStyle.pointerEvents = 'none';
            if (Browser.isIE()) {
                //IE 10 while accepts and supports pointer-events... it contains buggy behaviour
                clickCaptureStyle.display = 'none';
            }
        }

        return (
            <div className="MenuContainer">
                <div className="click-capture" style={clickCaptureStyle} onClick={this._clearMenus}>{Entities.NBSP}</div>
                {this.state.menus}
            </div>
        );
    }
}

export { MenuContainer };
