import * as React from 'react';
import {Breadcrumb} from './Breadcrumb';
import {FormModal} from './FormModal';
import {Form} from './Form';
import "../style/BreadcrumbModal.less";

export class BreadcrumbModal extends FormModal {
    constructor(props) {
        super(props);
        this._addClassName('BreadcrumbModal');
    }

    /**
     * 
     * @returns Array of bread crumb options. In addition you may specify the property className to apply a className to the Form component.
     */
    _getBreadcrumbProps() {
        throw new Error('BreadcrumbModal._getBreadcrumbProps is abstract.');
    }

    $getBreadcrumbProps() {
        let props = this._getBreadcrumbProps();
        let options = props.options.map((originalOption) => {
            let option = {...originalOption};
            let className = option.className;
            option.render = () => {
                return <Form
                    className={className}
                    content={originalOption.render()}
                />;
            };
            return option;
        });
        // Be careful to not allow reference bugs
        return {
            ...props,
            options: options
        };
    }

    _renderContent() {
        return <Breadcrumb
            {...this.$getBreadcrumbProps()}
        />;
    }
    
    _renderContentContainer() {
        return <div
            className='content-container'
            ref={this._containerNode}
        >
            {this._renderContent()}
            <div className='footer'>
                {this._getFooterContent()}
            </div>
        </div>;
    }
}

