'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';
import {IDGenerator} from '@totalpave/idgenerator';
import {IconFactory} from '../factories/IconFactory';
import {Menu} from './Menu';
import {AddMenuAction} from '../actions/AddMenuAction';
import {DeleteMenuAction} from '../actions/DeleteMenuAction';
import "../style/Select.less";
import {Option} from '../utils/Option';
import {ObjectUtils} from '@totalpave/object';

class Select extends React.Component {
    constructor(props) {
        super(props);

        this._node = React.createRef();

        this._onChange = this._onChange.bind(this);
        this._onItemClick = this._onItemClick.bind(this);
        this._toggleMenu = this._toggleMenu.bind(this);
        this._id = IDGenerator.generate();
        
        this._nullOption = new Option(this.props.nullOptionText || 'Select one', null);
    }

    _getSelectedOption() {
        let option = this.props.value;
        if (ObjectUtils.isVoid(option)) {
            option = this._nullOption;
        }
        return option;
    }

    _getOptionByValue(value) {
        let option = Select._getOptionByValue(this.props.options, value);
        if (option.getValue() === null) {
            return this.state.nullOption;
        }
        else {
            return option;
        }
    }

    static _getOptionByValue(options, value) {
        for (let i = 0; i < options.length; i++) {
            let option = options[i];
            if (value === option.getValue()) {
                return option;
            }
        }

        return null;
    }

    _toggleMenu() {
        if (this._node.current) {
            let options;
            if (this.props.allowSelectNullOption) {
                options = [this._nullOption].concat(this.props.options);
            }
            else {
                options = this.props.options;
            }

            AddMenuAction.execute({menu: <Menu
                key={this._id}
                id={this._id}
                className={this.props.menuClassName}
                scrollable={this.props.scrollable}
                width={this.getMenuWidth()}
                relativeTo={this._node.current}
                alignment={this.props.menuAlignment || Menu.ALIGNMENT_BOT_LEFT}
                onClick={this._onItemClick}
                options={options}
            />});
        }
        else {
            console.warn("Select._toggleMenu can't toggle menu; because, _node ref is not set.");
        }
    }

    _onItemClick(option) {
        this._onChange(option);
    }

    _onChange(option) {
        DeleteMenuAction.execute({key: this._id});

        if (this.props.onChange) {
            this.props.onChange(option);
        }
    }

    getMenuWidth() {
        if (this.props.menuWidth) {
            return this.props.menuWidth;
        }
        else {
            return this._node.current.offsetWidth;
        }
    }

    getProposedWidth() {
        if (this.props.width === Select.WIDTH_OPTIONS.UNSET) {
            return null;
        }
        else {
            return this.props.width || Select.WIDTH_OPTIONS.DEFAULT
        }
    }

    render() {
        let selectedOption = this._getSelectedOption();

        if (!(selectedOption instanceof Option)) {
            throw new Error('Selected option is not an Option instance.');
        }

        return (
            <div className={`Select${this.props.className ? ' ' + this.props.className : ''}`}
                onChange={this.props.readonly ? null : this._onChange} 
                onClick={this.props.readonly ? null : this._toggleMenu}
                style={{width:this.getProposedWidth()}}
                ref={this._node}
            >
                <div className="select-container">
                    <span>{selectedOption.getText()}</span>
                    {IconFactory.create('caret-down')}
                </div>
            </div>
        );
    }
}

Select.WIDTH_OPTIONS = {
    /**
     * Unset will remove width inline styling.
     */
    UNSET: 'unset',
    DEFAULT: 100
};

Select.propTypes = {
    value: PropTypes.instanceOf(Option),
    readonly: PropTypes.bool,
    nullOptionText: PropTypes.string,
    allowSelectNullOption: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.instanceOf(Option)).isRequired,
    onChange: PropTypes.func.isRequired, //instance of Option is pass through
    menuClassName: PropTypes.string,
    className: PropTypes.string,
    scrollable: PropTypes.bool,
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.oneOf([
            Select.WIDTH_OPTIONS.AUTO,
            Select.WIDTH_OPTIONS.DEFAULT
        ])
    ]),
    menuWidth: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    menuAlignment: PropTypes.oneOf([
        Menu.ALIGNMENT_BOT_LEFT,
        Menu.ALIGNMENT_BOT_RIGHT,
        Menu.ALIGNMENT_TOP_LEFT,
        Menu.ALIGNMENT_TOP_RIGHT,
        Menu.ALIGNMENT_LEFT_TOP,
        Menu.ALIGNMENT_RIGHT_TOP
    ])
};

export { Select };
