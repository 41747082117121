import * as React from 'react';
import PropTypes from 'prop-types';
import '../style/Form.less';

export class Form extends React.Component {
    constructor(props) {
        super(props);
        this.$classNames = ["Form"];
    }

    _addClassName(name) {
        this.$classNames.push(name);
    }

    $getClassName() {
        return `${this.$classNames} ${this.props.className}`;
    }
    
    render() {
        return <div className={this.$getClassName()}>
            <div className='content'>
                {this.props.content}
            </div>
            <div className='footer'>
                {this.props.footer}
            </div>
        </div>
    }
}

Form.propTypes = {
    className: PropTypes.string,
    // Should be either FormItem (or extending classes) or FormGroup.
    content: PropTypes.node,
    footer: PropTypes.node
}
