
import {TextfieldPropsBuilder} from './TextfieldPropsBuilder';
import {InputType} from '../utils/InputType';

class PasswordTextfieldPropsBuilder extends TextfieldPropsBuilder {
    _getInputType() {
        return InputType.PASSWORD;
    }
}

export {PasswordTextfieldPropsBuilder};
