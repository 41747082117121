'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';
import {SplashSpinner} from './SplashSpinner';
import { Router } from '@breautek/router';
import {UserStore} from '../store/UserStore';
import {ActionStore} from '../store/ActionStore';
import {NetworkStore} from '@totalpave/network';
import {LogoutAction} from '../actions/LogoutAction';
import {TPError} from '@totalpave/error';
import '../style/LogoutComponent.less';

class LogoutComponent extends React.Component {
    constructor(props) {
        if (!props.done) {
            throw new Error("LogoutComponent done prop is required.");
        }

        super(props);

        this._logout = this._logout.bind(this);
        this.calledLogoutAction = false;
        this.transitionDuration = 500; //milliseconds. This should be the same as the value in CSS.
        this.logoutInterval = window.setInterval(this._logout, 1000);
        Router.getInstance().clear();
        Router.getInstance().replaceState('/login');
    }

    _logout() {
        if (
            UserStore.getInstance().shouldLogout() &&
            !this.calledLogoutAction &&
            !ActionStore.getInstance().isActionsPending() &&
            NetworkStore.getInstance().getActiveConnections() === 0
        ) {
            this.calledLogoutAction = true;
            window.clearInterval(this.logoutInterval);
            LogoutAction.execute().then(() => {
                this.setState({
                    fadeOut: true
                }, () => {
                    // done is a required prop. don't waste time checking for existance
                    // set timeout to wait for the transition to finish
                    window.setTimeout(this.props.done, this.transitionDuration);
                });
            }).catch((e) => {
                new TPError({
                    message: "Application LogoutAction threw an error?",
                    details: {
                        error: e
                    }
                });
            });
        }
    }

    componentDidMount() {
        if (this.props.fadeOut) {
            window.setTimeout(() => {
                this.props.onFadeOutEnd && this.props.onFadeOutEnd();
            }, this.transitionDuration);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fadeOut !== this.props.fadeOut && this.props.fadeOut) {
            window.setTimeout(() => {
                this.props.onFadeOutEnd && this.props.onFadeOutEnd();
            }, this.transitionDuration);
        }
    }

    render() {
        return (
            <div className={"LogoutComponent" + (this.props.fadeOut ? " fadeOut" : "")}>
                <SplashSpinner />
            </div>
        );
    }
}

LogoutComponent.propTypes = {
    fadeOut: PropTypes.bool,
    onFadeOutEnd: PropTypes.func,
    done: PropTypes.func.isRequired
};

export { LogoutComponent };
