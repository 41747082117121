'use strict';

import {Store} from '@totalpave/store';
import {ViewportSizeUpdate} from '../actions/ViewportSizeUpdate';
import {UpdateOrientation} from '../actions/UpdateOrientation';
import { OrientationType } from '../utils/OrientationType';
import { IActionData } from '@totalpave/finterfaces';

/**
 * @deprecated Use OrientationType enum instead
 */
const ORIENTATION = {
    PORTRAIT_PRIMARY: OrientationType.PORTRAIT_PRIMARY,
    PORTRAIT_SECONDARY: OrientationType.PORTRAIT_SECONDARY,
    LANDSCAPE_PRIMARY: OrientationType.LANDSCAPE_PRIMARY,
    LANDSCAPE_SECONDARY: OrientationType.LANDSCAPE_SECONDARY
};

export class ViewportStore extends Store {
    private $orientation: OrientationType;
    private $width: number;
    private $height: number;

    private static $instance: ViewportStore;

    /**
     * @deprecated Use OrientationType enum instead
     */
    public static readonly ORIENTATION: Record<string, OrientationType> = ORIENTATION;

    private constructor() {
        super();

        this.$orientation = null; // TODO default this properly
        this.$width = document.body.clientWidth;
        this.$height = document.body.clientHeight;

        let _transitionDisableTimer: number = null;

        window.addEventListener('resize', () => {
            if (document.body.className.indexOf('disable-transitions') === -1) {
                let classes = document.body.className.split(' ');
                classes.push('disable-transitions');
                document.body.className = classes.join(' ');
            }

            window.requestAnimationFrame(() => {
                ViewportSizeUpdate.execute();

                window.clearTimeout(_transitionDisableTimer);
                _transitionDisableTimer = window.setTimeout(() => {
                    let classes = document.body.className.split(' ');
                    let index = classes.indexOf('disable-transitions');
                    if (index > -1 ){
                        classes.splice(index, 1);
                    }

                    document.body.className = classes.join(' ');
                }, 250);
            });
        });



    }

    public static getInstance(): ViewportStore {
        if (!ViewportStore.$instance) {
            ViewportStore.$instance = new ViewportStore();
        }
        return ViewportStore.$instance;
    }

    public getWidth(): number {
        return this.$width;
    }

    public getHeight(): number {
        return this.$height;
    }

    public getOrientation(): OrientationType {
        return this.$orientation;
    }

    public isLandscape(): boolean {
        return this.$orientation === OrientationType.LANDSCAPE_PRIMARY || this.$orientation === OrientationType.LANDSCAPE_SECONDARY;
    }

    public isPortrait(): boolean {
        return this.$orientation === OrientationType.PORTRAIT_PRIMARY || this.$orientation === OrientationType.PORTRAIT_SECONDARY;
    }

    protected override _update(actionData: IActionData): boolean {
        if (UpdateOrientation.checkType(actionData)) {
            this.$orientation = actionData.getData();
            return true;
        }
        else if (ViewportSizeUpdate.checkType(actionData)) {
            if (this.$width !== document.body.clientWidth || this.$height !== document.body.clientHeight) {
                this.$width = document.body.clientWidth;
                this.$height = document.body.clientHeight;
                return true;
            }
            return false;
        }
        return false;
    }
}
