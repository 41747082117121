'use strict';

import {Formatter} from './Formatter';
import moment from 'moment';

/**
 * @deprecated Use `@totalpave/dates` version instead
 */
class DateFormatter extends Formatter {
    constructor(format) {
        super();
        this._format = format || DEFAULT_FORMAT;
    }

    format(date) {
        return moment(date).format(this._format);
    }

    static format(date, format) {
        return moment(date).format(format || DEFAULT_FORMAT);
    }
}

DateFormatter.SHORT_DATE = 'MMM D, YYYY';
DateFormatter.LONG_DATE = 'MMMM D, YYYY';

const DEFAULT_FORMAT = DateFormatter.SHORT_DATE;

export { DateFormatter };
