
// TODO: Move to finterfaces

export const LayoutMode = {
    LABEL_ABOVE: 1
};
// If ever desired, it should be quite easy to add LABEL_LEFT, LABEL_RIGHT, or LABEL_BELOW.

// This is primarily for prop-types but can also be used if you ever need to make a list or dropdown of layoutmodes.
export const LayoutModes = [LayoutMode.LABEL_ABOVE];
