'use strict';

import * as React from 'react';
import { ModalPopAction } from '../actions/ModalPopAction';
import { TPError } from '@totalpave/error';
import {ApplicationInstance } from '@totalpave/application-instance';
import { NotificationFactory } from '../factories/NotificationFactory';
import { AuthClient } from '../net/AuthClient';
import { Modal } from '../components/Modal';
import { Button } from '../components/Button';
import { Textfield } from '../components/Textfield';
import "../style/ResetPasswordModal.less";
import {Localization} from '@totalpave/localization';

class ResetPasswordModal extends Modal {
    constructor(props) {
        super(props);
        this._addClassName('ResetPasswordModal');
        this.state = {
            email: ""
        };
        this._running = false;
        this._resetPassword = this._resetPassword.bind(this);
    }

    getTitle() {
        return Localization.getInstance().resolve('tp-app-common/ResetPasswordModal/title');
    }
    
    isHeaderCentered() {
        return true;
    }

    _resetPassword() {
        if (this._running) {
            return;
        }

        this._running = true;
        let email = this.state.email;
        let localization = Localization.getInstance();

        AuthClient.getInstance().requestPasswordReset(email).then(() => {
            return ApplicationInstance.getInstance().getNotificationFactory().create(
                NotificationFactory.ALERT, 
                {
                    message: localization.resolve('tp-app-common/ResetPasswordModal/reset-success')
                }
            );
        }).then(() => {
            ModalPopAction.execute();
        }).catch((error) => {
            let err = new TPError({
                message: error.message ? error.message : localization.resolve('tp-app-common/ResetPasswordModal/reset-error'),
                error: error
            });
            
            ApplicationInstance.getInstance().getNotificationFactory().create(
                NotificationFactory.ALERT, 
                {
                    message: err.getMessage()
                }
            );
            this._running = false;
        });
    }


    _render() {
        let localization = Localization.getInstance();

        return [
            <div key="reset-password-modal-container" className="ResetPasswordModalContainer">
                <div className="field-label">
                    {localization.resolve('tp-app-common/ResetPasswordModal/email-label')}
                </div>
                <div className="field-value">
                    <Textfield
                        onChange={(value) => {
                            this.setState({
                                email: value
                            });
                        }}
                        placeholder={localization.resolve('tp-app-common/ResetPasswordModal/email-input-placeholder')}
                    />
                </div>
            </div>,
            <Button
                key="reset-button"
                text={localization.resolve('tp-app-common/ResetPasswordModal/submit-button-label')}
                color="green"
                onTap={this._resetPassword}
                noTopBorderRadius
            />
        ];
    }
}

export {ResetPasswordModal};
