'use strict';

let top = null;
let right = null;
let bottom = null;
let left = null;

// ========================================================================================================================
// WARNING
// If your app supports both portait and landscape mode; then, you can't safely use this util. See the TO-DO comment below.
// ========================================================================================================================
// TO-DO: Utils currently only gets the values in the portait/landscape value that the app opened in. 
// Detect portait -> landscape and vice-versa to update the SafeAreaInsetMeasurement so that values will be accurate when searching between the 2 modes.

class SafeAreaInsetMeasurement {
    static update() {
        let div = document.createElement('div');
        // If this value is invalid; then, div.style.paddingTop will become empty string ( "" ). 
        // We'll do a check to see if constant is an valid value. If not we'll go straight to env.
        div.style.paddingTop = "constant(safe-area-inset-top)"; 

        if (div.style.paddingTop === "constant(safe-area-inset-top") {
            // Need to use constant
            div.style.paddingRight = "constant(safe-area-inset-right)";
            div.style.paddingBottom = "constant(safe-area-inset-bottom)";
            div.style.paddingLeft = "constant(safe-area-inset-left)";
        }
        else {
            // Need to use env
            div.style.paddingTop = "env(safe-area-inset-top)"
            div.style.paddingRight = "env(safe-area-inset-right)";
            div.style.paddingBottom = "env(safe-area-inset-bottom)";
            div.style.paddingLeft = "env(safe-area-inset-left)";
        }

        document.body.appendChild(div);

        let style = window.getComputedStyle(div);
        top    = parseInt(style.paddingTop.substring(0, style.paddingTop.indexOf("px")));
        right  = parseInt(style.paddingRight.substring(0, style.paddingRight.indexOf("px")));
        bottom = parseInt(style.paddingBottom.substring(0, style.paddingBottom.indexOf("px")));
        left   = parseInt(style.paddingLeft.substring(0, style.paddingLeft.indexOf("px")));

        document.body.removeChild(div);
    }

    static getTop() {
        // if (top === null) {
        //     SafeAreaInsetMeasurement.update();
        // }
        SafeAreaInsetMeasurement.update();
        return top;
    }

    static getRight() {
        // if (right === null) {
        //     SafeAreaInsetMeasurement.update();
        // }
        SafeAreaInsetMeasurement.update();
        return right;
    }

    static getBottom() {
        // if (bottom === null) {
        //     SafeAreaInsetMeasurement.update();
        // }
        SafeAreaInsetMeasurement.update();
        return bottom;
    }

    static getLeft() {
        // if (left === null) {
        //     SafeAreaInsetMeasurement.update();
        // }
        SafeAreaInsetMeasurement.update();
        return left;
    }

    // The has functions determine if there is or is not an inset.
    static hasTop() {
        // if (top === null) {
        //     SafeAreaInsetMeasurement.update();
        // }
        SafeAreaInsetMeasurement.update();
        return top > 0;
    }

    static hasRight() {
        // if (right === null) {
        //     SafeAreaInsetMeasurement.update();
        // }
        SafeAreaInsetMeasurement.update();
        return right > 0;
    }

    static hasBottom() {
        // if (bottom === null) {
        //     SafeAreaInsetMeasurement.update();
        // }
        SafeAreaInsetMeasurement.update();
        return bottom > 0;
    }

    static hasLeft() {
        // if (left === null) {
        //     SafeAreaInsetMeasurement.update();
        // }
        SafeAreaInsetMeasurement.update();
        return left > 0;
    }
}

export { SafeAreaInsetMeasurement };
