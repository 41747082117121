
import * as React from 'react';
import {SplashSpinner} from './SplashSpinner';
import '../style/AppLoader.less';

export class AppLoader extends React.Component {
    componentDidMount() {}

    componentWillUnmount() {}

    render() {
        return (
            <div className="AppLoader">
                <SplashSpinner />
            </div>
        );
    }
}
