'use strict';

import {TPError} from  '@totalpave/error';
import {Task, TaskEvents} from '../queue/Task';

//Task for deleting files. This task is meant to be used internally in FileSystem.
class ReadFileTask extends Task {
    constructor(id, tpFileEntry) {
        super(id);
        this._forceExecute = true;
        this.tpFileEntry = tpFileEntry;
    }

    execute(){
        return new Promise((resolve, reject) => {
            new Promise((resolve, reject) => {
                this.tpFileEntry.file((file) => {
                    resolve(file);
                }, (error) => {
                    reject(error);
                });
            }).then((file) => {
                return new Promise((resolve, reject) => {
                    let reader = new FileReader();
                    reader.onload = (evt) => {
                        resolve(evt.target.result);
                    }
                    reader.onerror = (error) => {
                        reject(error);
                    }
                    reader.readAsText(file);
                });
            }).then((result) => {
                this.emit(TaskEvents.ON_SUCCESS, result);
                this.emit(TaskEvents.ON_FINISH, result);
                resolve(result);
            }).catch((error) => {
                this.emit(TaskEvents.ON_FAIL, error);
                this.emit(TaskEvents.ON_FINISH, error);
                reject(
                    new TPError(
                        {
                            message: "Failed to Read File.",
                            details: {
                                function: "TPFileEntry._readFile", 
                                tPFileEntry: this.tpFileEntry.toJSON(),
                                error: error
                            }
                        },
                        true
                    )
                );
            });
        });
    }
}

export { ReadFileTask };
