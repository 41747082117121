
import * as React from 'react';
import PropTypes from 'prop-types';
import { Textfield } from "./Textfield";
import NumberFormat from 'react-number-format';
import {NumberGroupStyle} from '../utils/NumberGroupStyle';
import "../style/NumberFormatTextfield.less";

class NumberFormatTextfield extends Textfield {
    constructor() {
        super();
    }

    _getClassName() {
        return 'NumberFormatTextfield';
    }

    _getInternalProps(props) {
        props.thousandSeparator = this.props.groupSeparator;
        props.thousandsGroupStyle = this.props.groupStyle;
        props.decimalSeparator = this.props.decimalSeparator;
        props.decimalScale = this.props.decimalScale;
        props.fixedDecimalScale = this.props.fixedDecimalScale;
        props.allowNegative = this.props.allowNegative;
        props.prefix = this.props.prefixSymbol;
        props.suffix = this.props.suffixSymbol;
        props.displayType = this.props.displayType;
        props.getInputRef = this.props.getInputRef || props.getInputRef;
        props.isNumericString = this.props.isNumericString;
        props.isAllowed = this.props.isAllowed;
        props.type = 'text';

        if (props.onChange) {
            props.onValueChange = props.onChange;
            props.onChange = undefined;
        }
        return props;
    }

    _parseValue(event) {
        return event.value;
    }

    _render(props) {
        return <NumberFormat {...props} />;
    }

    _getRefPropName() {
        return 'getInputRef';
    }
}

NumberFormatTextfield.DisplayType = {
    INPUT: "input",
    SPAN: "text"
};

NumberFormatTextfield.propTypes = {
    ...Textfield.propTypes,
    displayType: PropTypes.oneOf([
        NumberFormatTextfield.DisplayType.INPUT,
        NumberFormatTextfield.DisplayType.SPAN
    ]),
    allowNegative: PropTypes.bool,
    groupSeparator: PropTypes.string,
    groupStyle: PropTypes.oneOf([
        NumberGroupStyle.THOUSAND,
        NumberGroupStyle.LAKH,
        NumberGroupStyle.WAN
    ]),
    decimalSeparator: PropTypes.string,
    decimalScale: PropTypes.number,
    fixedDecimalScale: PropTypes.bool,
    prefixSymbol: PropTypes.string,
    suffixSymbol: PropTypes.string,
    getInputRef: PropTypes.func,
    isAllowed: PropTypes.func
}

export { NumberFormatTextfield };
