'use strict';

import { ApplicationInstance } from '@totalpave/application-instance';

const ObjectUtils = {
    //Does a shallow merge of 2 object literals.
    //Protocol (type of ObjectUtils.MERGE_PROTOCOL) determines how the function handles merge conflict. o2 will always be the victor if protocol would be changing content. 
    //For example, if protocol is ObjectUtils.OVERWRITE; then, o2 will overwrite o1.
    //NOTE: Objects must be clonable using ObjectUtils.clone.
    merge : (o1, o2, protocol) => {
        let error;
        let merged = ObjectUtils.clone(o1);

        for (let i in o2) {
            if (merged[i]) {
                switch (protocol) {
                    case ObjectUtils.MERGE_PROTOCOL.OVERWRITE:
                        merged[i] = ObjectUtils.clone(o2[i]);
                        break;
                    case ObjectUtils.MERGE_PROTOCOL.IGNORE:
                        break;
                    case ObjectUtils.MERGE_PROTOCOL.THROW:
                        error = new Error();
                        new ApplicationInstance.getErrorClass({
                            message: "Merge Conflict in ObjectUtils.merge.",
                            details: {
                                callstack: error.stack,
                                protocol: protocol,
                                objectOne: o1,
                                objectTwo: o2
                            }
                        });
                        break;
                    default:
                        error = new Error("Merge Protocol \"" + protocol + "\" is not recognized. Please use the ObjectUtils.MERGE_PROTOCOL enum.");
                        new ApplicationInstance.getErrorClass({
                            message: "Merge Protocol \"" + protocol + "\" is not recognized. Please use the ObjectUtils.MERGE_PROTOCOL enum.",
                            details: {
                                callstack: error.stack
                            }
                        });

                        //App should not be reaching production if this error occurs. To help make sure that never happens, throw the error.
                        throw error;
                }
            } 
            else {
                merged[i] = ObjectUtils.clone(o2[i]);
            }
        }

        return merged;
    },

    /*
        Accepts simple objects, literals, and Clonable Classes. These must be maintained no matter if the value is inside an object or the root object itself.

        Valid Examples:
            {//simple object that is pased into clone
                myArr: [], //simple object that is an instance of an array
                myObj: {}, //another simple object
                myClonableClass: new ClonableClass() //an Clonable Class of some sort
            }

        Invalid Examples:
            {
                nonClonableClass: new NonClonableClass() 
            }

            var myClass = new NonClonableClass();       

        Literals include Strings, Numbers, Booleans, and falsy values. 
        Simple Objects include non-class (where classes are defined by the Class keyword) objects and arrays.
            Examples:
            { 
                bob: 'is blue'
            }

            [{
                bob: 'is blue'
            }]

            map["bob"] = 'is blue'


        Clonable Classes are objects that created with the keywords Class and new. These classes MUST define a function called 'clone'.
            Examples:
            Class MyClass {
                constructor() {}

                clone() {
                    return new MyClass();
                }
            }
            var me = new MyClass();


        ========= WARNING =========
            If an Class does NOT define the clone method it WILL be treated like an Simple Object. 
            This means that you will NOT receive an instance of Class; but, instead you will receive and simple object.

            var myClass = new NonClonableClass();
            var clone = ObjectUtils.clone(myClass);

            myClass instanceof NonClonableClass //true
            clone instanceof NonClonableClass //false
        ========= WARNING =========
    */
    clone : (o) => {
        //Check for pass-by-val types
        if (!o || o === true || typeof o === 'string' || typeof o === 'number') {
            return o;
        }

        if (typeof o.clone === 'function') {
            throw new Error("ObjectUtils.clone only accepts Dictionary, HashMap or Array. Classes should implement their own clone function and use it directly.");
            //return o.clone();
        }

        let isArray = o instanceof Array;
        let obj = isArray ? [] : {};

        for (let i in o) {
            let value = o[i];

            if (!value || value === true || typeof value === 'string' || typeof value === 'number') { //typeof null === 'object'
                //Don't do anything for pass-by-val types
            }
            else if (value instanceof Array){
                value = ObjectUtils.clone(value);
            }
            else if (value instanceof Blob) {
                //Blobs, unlike Arrays, have a type property.
                //blob.slice is NOT used here; because, slice will return a blob without a type.
                value = new Blob([value], {"type":value.type});
            }
            else if (value instanceof Date){
                value = ApplicationInstance.getInstance().getDateFactory().create(value.getTime());
            }
            else if (typeof value === 'object') {
                if (value !== null && typeof value.clone === 'function') {
                    value = value.clone();
                }
                else if (value !== null) {
                    value = ObjectUtils.clone(value);
                }
            }

            obj[i] = value;
        }

        return obj;
    },

    //Converts simple object to array. Primarily intended for uses with maps/dictionaries and as a alternative to Object.values which is not supporting in Chrome 53.
    toArray(object) {
        let array = [];
        for (let prop in object) {
            array.push(object[prop]);
        }
        return array;
    },

    /*
    clone : (o) => {
        var obj = {};

        for (var i in o) {
            var value = o[i];

            if (value instanceof Array) {
                value = value.slice();
            }
            else if (typeof value === 'object') {
                if (value !== null && typeof value.clone === 'function') {
                    value = value.clone();
                }
                else if (value !== null) {
                    value = ObjectUtils.clone(value);
                }
            }

            obj[i] = value;
        }

        return obj;
    },*/

    //Checks if 2 simple objects are equal by value.
    //This method is not intended to be used with objects that contain recursive properties and functions.
    //Ultimately, if the objects are friendly with JSON.stringify it should be fine to use this function with them.
    /*isEqual : (o1, o2) => {
        return JSON.stringify(o1) === JSON.stringify(o2);
    },*/

    //Does non-reference shallow comparison between 2 objects.
    isEqual(objOne, objTwo) {
        if (ObjectUtils.isVoid(objOne) || ObjectUtils.isVoid(objTwo)) {
            return objOne === objTwo;
        }
        
        if (Object.keys(objOne).length !== Object.keys(objTwo).length){ 
            return false;
        }

        for (let key in objOne) {
            if (objOne[key] !== objTwo[key]){
                return false;
            }
        }

        return true;
    },

    isVoid(value) {
        return (value === undefined || value === null);
    },

    keySearch(o, regex) {
        if (!o) {
            return [];
        }

        if (!regex) {
            return [];
        }

        let keys = Object.keys(o);
        let results = [];

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (regex.test(key)) {
                results.push(key);
            }
        }

        return results;
    },

    //Checks if Object has properties
    //{} would return false
    //{rawr: 1} would return true
    hasProps(obj) {
        for (let key in obj) {
            return true;
        }
        return false;
    }
};

ObjectUtils.MERGE_PROTOCOL = {
    'OVERWRITE': 0, 
    'THROW': 1,
    'IGNORE': 2
};

export { ObjectUtils };
