'use strict';

import {Iterator} from './Iterator';

class Deque {
    constructor() {
        this._data = {};
        this._lowerBound = 0;
        this._upperBound = 0;
    }

    first() {
        let iterator = this.iterator();
        let item = null;
        do {
            item = iterator.next();
        } while (!iterator.hasReachedEnd() && (item === null || item === undefined));

        return item;
    }

    last() {
        let iterator = this.reverseIterator();
        let item = null;
        do {
            item = iterator.next();
        } while (!iterator.hasReachedEnd() && (item === null || item === undefined));

        return item;
    }

    getLowerBound() {
        return this._lowerBound;
    }

    getUpperBound() {
        return this._upperBound;
    }

    unshift(item) {
        this._lowerBound--;
        this._data[this._lowerBound] = item;
        return this;
    }

    push(item) {
        this._upperBound++;
        this._data[this._upperBound] = item;
        return this;
    }

    count() {
        return Object.keys(this._data).length;
    }

    length() {
        return Math.abs(this._lowerBound - this._upperBound);
    }

    set(index, item) {
        index = parseInt(index);
        if (isNaN(index)) {
            throw new Error('Index must be an integer.');
        }

        this._data[index] = item;
        this._syncBoundaries();

        return this;
    }

    has(index) {
        return this._data[index] !== undefined;
    }

    get(index) {
        return this._data[index];
    }

    shift() {
        let data = this._data[this._lowerBound];
        delete this._data[this._lowerBound];
        this._lowerBound--;
        return data;
    }

    pop() {
        let data = this._data[this._upperBound];
        delete this._data[this._upperBound];
        this._upperBound--;
        return data;
    }

    iterate(fn) {
        if (!fn) return null;

        for (let i = this.getLowerBound(); i <= this.getUpperBound(); i++) {
            fn(this.get(i), i);
        }
    }

    reverseIterate(fn) {
        if (!fn) return null;

        for (let i = this.getUpperBound(); i >= this.getLowerBound(); i--) {
            fn(this.get(i), i);
        }
    }

    iterator() {
        let arr = [];

        for (let i = this._lowerBound; i <= this._upperBound; i++) {
            arr.push(this._data[i]);
        }

        return new Iterator(arr);
    }

    reverseIterator() {
        let arr = [];

        for (let i = this._lowerBound; i <= this._upperBound; i++) {
            arr.push(this._data[i]);
        }

        return new Iterator(arr.reverse());
    }

    _syncBoundaries() {
        let keys = Object.keys(this._data);

        let lowest = Infinity, highest = -Infinity;

        for (let i = 0; i < keys.length; i++) {
            let index = parseInt(keys[i]);

            if (index < lowest) {
                lowest = index;
            }

            if (index > highest) {
                highest = index;
            }
        }

        this._lowerBound = lowest;
        this._upperBound = highest;
    }

    toArray(recursive) {
        let arr = [];

        for (let i = this.getLowerBound(); i <= this.getUpperBound(); i++) {
            if (recursive) {
                let item = this.get(i);
                if (item instanceof Deque) {
                    arr.push(item.toArray(recursive));
                }
                else {
                    arr.push(item);
                }
            }
            else {
                arr.push(this.get(i));
            }
        }

        return arr;
    }
}

export { Deque };
