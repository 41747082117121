'use strict';

/**
 * @deprecated Use @totalpave/interfaces instead
 */
const SurfaceType = {
    ACC : 0,
    PCC : 1,
    UNSURFACED: 2
};

export { SurfaceType };
