'use strict';

class AbstractEditorFactory {
    constructor() {}

    // eslint-disable-next-line
    create(type, defaultValue, onChangeCallback) {
        throw new Error('AbstractEditorFactory.create is abstract.');
    }
}

export { AbstractEditorFactory };
