import * as React from 'react';
import PropTypes from 'prop-types';
import {FormItem} from './FormItem';
import {ToggleGroup} from './ToggleGroup';
import '../style/FormItemToggleGroup.less';

export class FormItemToggleGroup extends React.Component {
    constructor(props) {
        super(props);
        this.$classNames = ["FormItemToggleGroup"];
    }

    _addClassName(name) {
        this.$classNames.push(name);
    }

    render() {
        return <FormItem
            label={this.props.label}
            isRequired={this.props.isRequired}
            className={`${this.props.className} ${this.$classNames} ${this.props.hasError ? 'error' : ''}`}
            toolTipMessage={this.props.toolTipMessage}
        >
            <ToggleGroup {...this.props.contentProps} />
        </FormItem>;
    }
}

FormItemToggleGroup.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    toolTipMessage: PropTypes.string,
    /**
     * Defaults to false
     */
    isRequired: PropTypes.bool,
    /**
     * Defaults to false
     */
    hasError: PropTypes.bool,
    contentProps: PropTypes.exact(ToggleGroup.propTypes)
}
