'use strict';

import {Batcher} from './Batcher';

class StylesheetManager {
    constructor() {
        this._sheets = {};
        this._batcher = new Batcher();
    }

    addSheet(id, sheet) {
        let rules = this._compileSheet(sheet);
        let style = document.createElement('style');
        this._sheets[id] = style;
        document.head.appendChild(style);

        for (let i = 0; i < rules.length; i++) {
            style.sheet.insertRule(rules[i]);
        }
    }

    hasSheet(id) {
        return !!this._sheets[id];
    }

    getBatcher() {
        return this._batcher;
    }

    removeSheet(id) {
        let style = this._sheets[id];

        if (!style) {
            return;
        }

        document.head.removeChild(style);
        delete this._sheets[id];
    }

    _compileSheet(sheet) {
        let contents = [];

        for (let selector in sheet) {
            let props = sheet[selector];
            let rule = '';
            rule += selector + ' {';
            for (let key in props) {
                rule += key + ':' + props[key] + ";";
            }

            rule += '}';
            contents.push(rule)
        }

        return contents;
    }

    destroy() {
        let keys = Object.keys(this._sheets);
        for (let i = 0; i < keys.length; i++) {
            this.removeSheet(keys[i]);
        }
    }
}

export { StylesheetManager };
