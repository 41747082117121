'use strict';

import * as React from 'react';
import {Component} from 'react';
import PropTypes from 'prop-types';
import {IDGenerator} from '@totalpave/idgenerator';
import {SnapUtils} from '../utils/SnapUtils';
import {ShowToolTip} from '../actions/ShowToolTip';
import Transition from 'react-transition-group/Transition';
import '../style/ToolTip.less';

export class ToolTip extends Component {
    constructor(props) {
        super(props);
        this._node = null;
        this._id = props.id || IDGenerator.generate();
        this.state = {
            active : false
        };
    }

    componentDidMount() {
        let anchor = this.props.anchor;
        if (!anchor || !this._node) {
            return;
        }

        SnapUtils.snap({
            subject : this._node,
            target: anchor,
            alignment: this.props.alignment || SnapUtils.ALIGNMENT_BOT_CENTER,
            distance: this.props.distance,
            pointerOpts: {
                size: this.props.pointerSize
            }
        });

        this.setState({
            active: true
        });
    }

    render() {
        const transStyle = {
            entering: {opacity: 0},
            entered : {opacity: 1}
        };
        
        return (
            <Transition
                in={this.state.active === undefined ? true : this.state.active}
                timeout={{
                    enter: 1,
                    exit: 100
                }}
                appear
            >
                {(state) => {
                    return this._renderTip(transStyle[state]);
                }}
            </Transition>
        );
    }

    _renderTip(style) {
        if (!style) {
            style = {};
        }

        let initStyle = {
            transition: `opacity ${100}ms ease-in-out`,
            opacity: 0
        };

        return (
            <div className={`ToolTip ${this.props.className}`} style={{
                ...initStyle,
                ...style
            }} ref={(n) => {this._node = n; }} onMouseLeave={() => {
                this.setState({
                    active: false
                }, () => {
                    setTimeout(() => {
                        ShowToolTip.execute({toolTip: null});
                    }, 100);
                });
            }}>
                {/*Padding nodes are invisible nodes to help the transition between the anchor node and the tooltip*/}
                <div className="padding top"></div>
                <div className="padding bottom"></div>
                <div className="padding left"></div>
                <div className="padding right"></div>
                {this.props.children}
            </div>
        );
    }
}

ToolTip.propTypes = {
    id: PropTypes.string,
    children: PropTypes.node,
    anchor: PropTypes.any,
    alignment: PropTypes.string,
    className: PropTypes.string,
    pointerSize: PropTypes.number,
    distance: PropTypes.number
};
