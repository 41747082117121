'use strict';

import { NetworkClient } from './NetworkClient';

let _instance = null;

class FileStoreClient extends NetworkClient {
    constructor() {
        super();
    }

    static getInstance() {
        if (!_instance) {
            _instance = new FileStoreClient();
        }

        return _instance;
    }

    _getServiceBase() {
        return 'file';
    }

    getById(id){
        return this.get("resource/get", {"id":id}, null, {"blob":true});
    }
}

export { FileStoreClient };
