'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';
import { IDGenerator } from '@totalpave/idgenerator';
import {ComponentCache} from '../utils/ComponentCache';
import {EventEmitter} from 'events';

/**
 * @deprecated Do not use - leaks memory and promotes bad react habits
 */
class TPComponent extends React.Component {
    constructor(props) {
        super(props);
        this._id = null;
        this.setID(props.id ? props.id : this._generateID());
        ComponentCache.cache(this);
        this.state = this._initState(props);
        this._tempState = {};
        this._stateChangePending = false;
        this._node = null;
        this._emitter = null;

        if (this.willEmitEvents()) {
            this._emitter = new EventEmitter();
        }
    }

    _getEmitter() {
        if (!this._emitter) {
            this._emitter = new EventEmitter();
        }

        return this._emitter;
    }

    _register(eventName, fn) {
        this._getEmitter().addListener(eventName, fn);
    }

    _unregister(eventName, fn) {
        this._getEmitter().removeListener(eventName, fn);
    }

    _emit(eventName, data) {
        this._getEmitter().emit(eventName, data);
    }

    willEmitEvents() {
        return false;
    }

    _setNode(node) {
        this._node = node;
    }

    getNode() {
        return this._node;
    }

    getStylesheet() {
        return null;
    }

    componentDidMount() {
        let sheet = this.getStylesheet();
        if (sheet) {
            sheet.use();
        }
    }

    componentWillUnmount() {
        let sheet = this.getStylesheet();
        if (sheet) {
            sheet.unuse();
        }   
    }

    // eslint-disable-next-line
    _initState(props) {
        return {};
    }

    setID(id) {
        this._id = id;
    }

    getID() {
        return this._id;
    }

    _generateID() {
        return IDGenerator.generate();
    }

    setState(state, callback) {
        this._stateChangePending = true;

        for (let i in state) {
            this._tempState[i] = state[i];
        }

        return new Promise((resolve) => {
            super.setState(state, () => {
                this._stateChangePending = false;
                callback && callback();
                resolve();
            });
        });
    }

    getTempState() {
        return this._tempState;
    }

    getStateVariable(key) {
        if (this._stateChangePending) {
            if (this._tempState[key] !== undefined) {
                return this._tempState[key];
            }
            else {
                return this.state[key];
            }
        }
        else {
            return this.state[key];
        }
    }
}

TPComponent.propTypes = {
    id: PropTypes.string
};

export {TPComponent};
