'use strict';

import Fuse from 'fuse.js';

/** @deprecated use @totalpave/search */
export class FuzzySearch {
    constructor(values, keys) {
        this._api = new Fuse(values, {
            shouldSort: true,
            threshold: 0.6,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: keys
        })
    }

    get(term) {
        return this.search(term)[0] || null;
    }

    search(term) {
        return this._api.search(term) || [];
    }
}
