'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';

import { BaseList } from '../components/BaseList';

import '../style/SimpleList.less';

class SimpleList extends BaseList {
    constructor(props) {
        super(props);

        this.state = {
            isScrolling: false
        }

        this._scrollableElementNode = React.createRef();
        this._listItemRefs = [];

        this._onScrollTimeout = null;
        this._onScroll = this._onScroll.bind(this);
        
        this._rowRenderer = this._rowRenderer.bind(this);
        this._noRowsRenderer = this._noRowsRenderer.bind(this);
    }

    getListItemRefs() {
        return this._listItemRefs;
    }

    getScrollableElement() {
        return this._scrollableElementNode;
    }

    _onScroll() {
        window.clearTimeout((this._onScrollTimeout));
        if (!this.state.isScrolling) {
            this.setState({
                isScrolling: true
            });
        }
        this._onScrollTimeout = setTimeout(() => {
            this.setState({
                isScrolling: false
            });
        }, 100);
    }

    _rowRenderer({
        key, // key for rendering array item
        index, // index for array
        isScrolling,
        isVisible,
        style
    } = {}) {
        return this.props.builder.build(
            this.props.items[index], 
            {
                key: key,
                ref: this._listItemRefs[index],
                index: index,
                style: style,
                className: `${isScrolling ? " simpleListIsScrolling" : ""}${isVisible ? " simpleListIsVisible" : ""}`,
                isScrolling: this.state.isScrolling, 
                isVisible: isVisible,
                onRemoveItem: this.props.onRemoveItem ? this.props.onRemoveItem : null,
                list: this
            }
        );
    }
    _noRowsRenderer() {
        return (
            <div className="no-content-container">
                {this.props.noRowsRenderer ? this.props.noRowsRenderer() : null}
            </div>
        );
    }

    render() {
        let rows = new Array(this.props.items.length);
        for (let i = 0, length = this.props.items.length; i < length; ++i) {
            rows[i] = this._rowRenderer({
                key: this.props.getKey ? this.props.getKey(this.props.items[i]) : 'row' + i,
                index: i, 
                isScrolling: false,
                isVisible: true,
                style: {
                    height: this.props.builder.getRowHeight() + 'px'
                }
            });
        }

        return (
            <div
                ref={this.getScrollableElement()}
                className={`SimpleList${this.props.className ? ' ' + this.props.className : ''}${this.props.items.length === 0 ? ' no-content' : ''}`}
                onScroll={this._onScroll}
            >
                {rows}
                {this.props.items.length === 0 ? this._noRowsRenderer() : null}
            </div>
        );
    }
}

SimpleList.propTypes = {
    /**
     * Provide this prop to control the key prop on the list items.
     * 
     * If not provided, SimpleList will generate an key based on the array index.
     * 
     * If you are performing add or delete actions on these list items, it is recommended to set this prop. 
     * Not setting this prop can cause side effects where react component instances are unexpected re-used
     * due to using array indexes in the key values.
     * 
     * @param {Object} item An item from the items array.
     * @returns String
     */
    getKey: PropTypes.func
};

export { SimpleList };
