'use strict';

import {UserStore} from '../store/UserStore';
import {JL} from 'jsnlog';
import {inspect} from 'util';
import { ApplicationInstance } from '@totalpave/application-instance';
import {Serializer} from './Serializer';

class Logger {
    constructor(config) {
        if (!config) {
            throw new Error('Application Config parameter is required.');
        }

        this._beforeSend = this._beforeSend.bind(this);
        this._serializer = new Serializer();

        JL.setOptions({
            serialize: (data) => { 
                if (!(data instanceof Error) && (data instanceof Array || typeof data === "object")) {
                    data = inspect(data, {
                        depth: Infinity,
                        maxArrayLength: Infinity,
                        compact: false
                    });
                }

                return this._serializer.serialize(data);
            }
        });

        this.config = config;
        this.$logger = JL(this.config.name);
        // By default, 1 AjexAppender is created with default options. Just use that one.
        this.$logger.appenders[0].setOptions({
            url : this.config.base + 'jsnlog.logger',
            ...(this.config.logger && this.config.logger.appender ? this.config.logger.appender : {}),
            beforeSend: this._beforeSend
        });

        // if (window.JL) {
        //     this.$logger = JL(this.config.name);
        //     for (var i = 0; i < this.$logger.appenders.length; i++) {
        //         this.$logger.appenders[i].setOptions({
        //             url : this.config.base + 'jsnlog.logger'
        //         });
        //     }
        // }
        // else {
        //     // eslint-disable-next-line
        //     console.warn('JL not found. Cannot send log messages to the server.');
        // }

        this.info = this.info.bind(this);
        this.warn = this.warn.bind(this);
        this.error = this.error.bind(this);
        this.log = this.log.bind(this);
        this.deprecate = this.deprecate.bind(this);
        this.abstract = this.abstract.bind(this);
        this.deprecated = this.deprecated.bind(this);
    }

    setSerializer(serializer) {
        this._serializer = serializer;
    }

    // Directly from the jsnlog docs:
    // JSNLog uses the onreadystatechange and status properties of the XMLHttpRequest object to detect whether the response to a log request was received. 
    // That allows is to deal with losing the Internet connection.
    // Your beforeSend method will be called after jsnlog.js has set its own onreadystatechange handler. This means that if you decide to set your own onreadystatechange 
    // handler, make it call the original onreadystatechange handler in addition to your own functionality, so jsnlog.js can continue handling connection lost situations.
    _beforeSend(xhr/*, json*/) {
        xhr.setRequestHeader('X-BT-AUTH', UserStore.getInstance().getAccessToken());
        xhr.setRequestHeader('X-TP-SOURCE-TARGET', this.config.appType);
        xhr.setRequestHeader('X-TP-SOURCE-VERSION', ApplicationInstance.getInstance().getVersion());
        
        // So it's actually not really possible, as far as I know, to zip up the logs before sending them while using jsnlog.
        // The data returned by Zipper.zip has to be placed in formData in order to be sent. It also seems like we can't place the FormData in json.lg.
        // In order to FormData I think we need to control sending the actual request.
        // var formData = new FormData();
        // formData.append("data", Zipper.zip(JSON.stringify(json.lg)));
        // json.lg = formData;
    }

    info(msg) {
        this.log(msg);
    }

    warn(msg) {
        this.log(msg);
    }

    error(msg) {
        this.log(msg);
    }

    log(msg) {
        if (msg instanceof Error) {
            console.error(msg.stack);
        }
        else {
            console.log(msg);
        }

        if (this.$logger) {
            this.$logger.info(msg);
        }

        if (this.config.DEBUG) {
            if (msg instanceof Error) {
                msg = msg.toString();
            }
            else if (typeof msg !== 'string') {
                try {
                    msg = JSON.stringify(msg);
                }
                catch (ex) {
                    msg = msg.toString();
                }
            }

            alert(msg);
        }
    }

    _getDeprecatedClass(e) {
        let stack = e.stack.split('\n')[2].replace(/^\s+at\s+(.+?)\s.+/g, '$1');
        let obj = 'Method';
        if (stack === "new") {
            stack = e.stack.split('\n')[2].replace(/^\s+at new\s+(.+?)\s.+/g, '$1');
            obj = 'Class';
        }

        return `${obj} ${stack}`;
    }

    deprecate(className, methodName, altClass, altMethod) {
        let e = new Error();
    
        if (!className) {
            className = this._getDeprecatedClass(e);
        }
        
        this.log(`${className}${methodName ? '.' : ''}${methodName ? methodName : ''} is deprecated and will be removed in a future release.`);
        if (altClass) {
            this.log(`Use ${altClass}${altMethod ? '.' : ''}${altMethod ? altMethod : ''} instead.`);
        }
        this.log('See call stack below for usage path.');
        this.log(new Error().stack);
    }

    abstract(className, methodName) {
        return `${className}.${methodName} is abstract.`;
    }

    deprecated(message) {
        this.log('A deprecated method has been invoked, see call stack below.');
        if (message) {
            this.log(message);
        }
        this.log(new Error().stack);
    }
}

export { Logger };
