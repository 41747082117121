'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';

// import { BaseList } from '../components/BaseList'; // This is a circular Dependency

class ListItem extends React.Component {
    constructor(props) {
        super(props);

        // Note, extending classes will have access to extra props. See the propTypes below for more information.

        this._render = this._render.bind(this);
        this._getClassName = this._getClassName.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentWillUnmount = this.componentWillUnmount.bind(this);

        this._onTap = this._onTap.bind(this);
        this._remove = this._remove.bind(this);
    }
    
    // Define functions for children to call super on.
    componentDidMount() {}
    componentWillUnmount() {}

    _onTap(event) {
        // Override this if you want an tap event
    }

    _getClassName() {
        throw new Error("ListItem._getClassName is abstract.");
    }

    _render() {
        throw new Error('ListItem._render is abstract.');
    }

    // Call this function to have onRemoveItem fire. It is up to the user to handle the event by passing in the necessary props to List.
    _remove() {
        // Don't pass in the index. 
        // Passing in the index can get into messy territory if we were to edit the array, such as slicing it. 
        // Let's just avoid that altogether - give them the item and let them figure out where it is in the array.
        this.props.onRemoveItem && this.props.onRemoveItem(this.props.item);
    }

    render() {
        return <div
            className={`ListItem${this.props.className ? ' ' + this.props.className : ''}${this._getClassName() ? ' ' + this._getClassName() : ''}`}
            style={this.props.style}
            onClick={this._onTap}
        >
            {this._render()}
        </div>;
    }
}

ListItem.propTypes = {
    // Props that will be passed in from List/ListRowBuilder
    //key Key is handled by List/ListRowBuilder. Don't set it manually.
    index: PropTypes.number, // array indez
    style: PropTypes.object,
    className: PropTypes.string,
    isScrolling: PropTypes.bool,
    isVisible: PropTypes.bool,
    item: PropTypes.any,
    onRemoveItem: PropTypes.func,
    // The parent list that holds the list item
    
    // TODO: While instanceOf is the correct way of doing this...
    // this is introducing a circular dependency and breaking builds
    // list: PropTypes.instanceOf(BaseList)
    list: PropTypes.any
    

    // Other Props
};

export { ListItem };
