'use strict';

import Bowser from '@totalpave/bowser';

let browser = Bowser.getParser(window.navigator.userAgent);

window.tpbrowser = browser;

/**
 * @deprecated IE is completely deprecated
 */
const INTERNET_EXPLORER = 'Internet Explorer';
const CHROME = 'Chrome';
const FIREFOX = 'Firefox';
const SAFARI = 'Safari';
const OPERA = 'Opera';
const EDGE = 'Microsoft Edge';

const parseVersion = function(v) {
    let p = v.toString().split('.');
    let major = p[0];
    let minor = p[1];
    return {
        major : parseInt(major),
        minor : parseInt(minor)
    };
}

class Browser {
    static setDeprecation(browser, version) {
        deprecatedVersions[browser] = version;
    }

    static isDeprecated() { 
        let version = Browser.getVersion();

        switch (Browser.getBrowserCode()) {
            case Browser.CHROME:
                if (version.major <= 21) {
                    return 'https://support.google.com/chrome/answer/95414?co=GENIE.Platform%3DDesktop&hl=en';
                }
                break;
            case Browser.SAFARI:
                if (version.major <= 5) {
                    return 'https://support.apple.com/en-ca/HT204416';
                }
                break;
            case Browser.IE:
                if (version.major <= 11) {
                    return 'https://www.microsoft.com/en-us/edge';
                }
                break;
            case Browser.OPERA:
                if (version.major < 12 || (version.major === 12 && version.minor <= 17)) {
                    return 'https://techhelpkb.com/how-to-update-opera-web-browser/';
                }
                break;
            case Browser.FIREFOX:
                if (version.major <= 25) {
                    return 'https://support.mozilla.org/en-US/kb/update-firefox-latest-version';
                }
                break;
        }

        return false;
    }

    static _isBelowVersion(version, targetVersion) {
        if (version === null) {
            return false;
        }

        let vParts = Browser._getVersionParts(version);
        let tParts = Browser._getVersionParts(targetVersion);

        if (vParts.major <= tParts.major) {
            return true;
        }
        else if (vParts.major === tParts.major) {
            if (vParts.minor <= tParts.minor) {
                return true;
            }
            else if (vParts.minor === tParts.minor && vParts.patch && tParts.patch) {
                if (vParts.patch <= tParts.patch) {
                    return true;
                }
            }
        }

        return false;
    }

    static _getVersionParts(version) {
        let parts = version.split('.');
        let major = parseInt(parts[0]);
        let minor = parseInt(parts[1]);
        let patch = parseInt(parts[2]);
        let details = {
            major: major,
            minor: minor
        };

        if (!isNaN(patch)) {
            details.patch = patch;
        }

        return details;
    }
    
    static overrideUserAgent(userAgent) {
        browser = Bowser.getParser(userAgent);
    }

    static getOS() {
        return browser.getOSName();
    }

    /**
     * @deprecated - IE is not supported by any means anymore
     * @returns true if IE.
     */
    static isIE() {
        return browser.getBrowserName() === INTERNET_EXPLORER;
    }

    /**
     * Returns true if desktop or desktop like.
     */
    static isDesktop() {
        return browser.getPlatformType() === 'desktop';
    }

    /**
     * Returns true if tablet
     */
    static isTablet() {
        return browser.getPlatformType() === 'tablet';
    }

    /**
     * Returns true if mobile phone
     */
    static isPhone() {
        return browser.getPlatformType() === 'mobile';
    }


    /**
     * Returns true if mobile phone OR tablet.
     */
    static isMobile() {
        return Browser.isTablet() || Browser.isPhone();
    }

    /**
     * Returns true if running in a native application shell
     * Currently this tests for the presence of cordova,
     * but this may test for other things in the future
     */
    static isApplication() {
        return !!window.cordova;
    }

    /**
     * Returns true if running as a website.
     * This is effectively the opposite of isApplication()
     */
    static isSite() {
        return !Browser.isApplication();
    }

    /**
     * @deprecated Use isApplication instead
     * @returns Returns true if cordova environment
     */
    static isCordova() {
        return !!window.cordova;
    }

    static getBrowserCode() {
        switch (browser.getBrowserName()) {
            case INTERNET_EXPLORER: return Browser.IE;
            case CHROME: return Browser.CHROME;
            case OPERA: return Browser.OPERA;
            case SAFARI: return Browser.SAFARI;
            case FIREFOX: return Browser.FIREFOX;
            case EDGE: return Browser.EDGE;
            default: return Browser.UNKNOWN;
        }
    }

    static getVersion() {
        let v = browser.getBrowserVersion();

        if (!v) {
            // browser doesn't supply a version for safari since it is the same as the iOS OS version.
            v = browser.getOSVersion();
        }

        if (!v) {
            // If we still don't have a version, default to 0.0.0
            v = '0.0.0';
        }
        
        return parseVersion(v);
    }
}

/**
 * @deprecated IE is completely deprecated
 */
Browser.IE          = 'msie';
Browser.CHROME      = 'chrome';
Browser.OPERA       = 'opera';
Browser.EDGE        = 'msedge';
Browser.SAFARI      = 'safari';
Browser.FIREFOX     = 'firefox';
Browser.UNKNOWN     = 'unknown';

Browser.WINDOWS     = 'Windows';
Browser.ANDROID     = 'Android';
Browser.IOS         = 'iOS';
Browser.LINUX       = 'Linux';
Browser.MAC         = 'macOS';

const deprecatedVersions = {
    /**
     * @deprecated IE is completely deprecated
     */
    [Browser.IE]: '10.0.0',
    [Browser.CHROME]: '21.0.0',
    [Browser.OPERA]: '12.17.0',
    [Browser.EDGE]: null,
    [Browser.SAFARI]: '5.0.0',
    [Browser.FIREFOX]: '25.0.0'
}

export {Browser};
