'use strict';

/*
    Modularization Note

    Both app common and mobile common have a FileSystem API.
    mobile common's copy will have been better maintained and more up-to-date.
    Issue Tracker seems to be using app common's FileSystem API and it will probably be a bit of work to update issue tracker.
*/

/*
TODO:
    - Refactor all comment blocks to use the standard docblockr style comment blocks
 */


/*
    ===========WARNING=============
    FileSystem uses a Cordova API. FileSystem is an app-only functionality.
    ===========WARNING=============
*/

/*
    Note - FileSystem has a few internal classes, the biggest being TPFileEntry.
    TPFileEntry in particular had a circular dependency with FileSystem.

    Lately we have been solving Circular Dependencies by using the Application. For example the Application would hold a reference to FileSystem and then everything would go to Application
    for FileSystem.
    In the case of FileSystem and TPFileEntry I am just moving the TPFileEntry class to FileSystem. TPFileEntry is an internal FileSystem class and should not be imported by any except FileSystem.
    It is not even exported by tp-app-common.

    TPFileEntry can be found at the bottom of this file, after the export statement.
*/

/*
    FileSystem Imports
*/
import {UserStore} from '../store/UserStore';
import {RequestLogoutAction} from '../actions/RequestLogoutAction';
import {DispatchMessage, TPError} from '@totalpave/error';
import { MessageType } from '@totalpave/finterfaces';
import {Browser} from '../utils/Browser';

/* 
    TPFileEntry Imports
*/
import {TaskEvents} from '../queue/Task';
import {Queue} from '../queue/Queue';
import { UpdateFileTask } from './UpdateFileTask';
import { DeleteFileTask } from './DeleteFileTask';
import { ReadFileTask } from './ReadFileTask';

let Paths = [];
let FileEntries = {};
// eslint-disable-next-line
let Files = [];

const TP_FILE_ENTRY_NOT_FOUND = "Could not find TPFileEntry in helded entries.";

//Used to instanceof comparisons 
class TPFileError {
    //An clean up error is an error that occurred while trying to correct potentail invalid state that was caused by another error.
    //For example, if we fail to write the contents of a newly added file; then, there will be an empty file without the contents it was post to have.
    //If we then fail to delete that newly added file we would have an clean up error.
    constructor(error, cleanUpError) {
        this.error = error;
        this.message = TPFileError._codeToMessage(error.code);

        if (cleanUpError) {
            this.cleanUpError = cleanUpError;
            this.cleanUpErrorMessage = TPFileError._codeToMessage(cleanUpError.code);
        }
    }

    //Generates generic FileError message for the corresponding FileError code.
    static _codeToMessage(fileError) {
        switch (fileError) {
            case FileError.NOT_FOUND_ERR:
                return "File/Directory Not Found";
            case FileError.SECURITY_ERR:
                /*
                    Files might be unsafe for access within a Web application, too many calls are being made on file resources, or other unspecified errors.
                */
                return "File/Directory Access Denied";
            case FileError.ABORT_ERR:
                return "File/Directory access was aborted";
            case FileError.NOT_READABLE_ERR:
                return "File/Directory not readable"; //This could be related to permission problems or the file is locked due to other programs accessing the file
            case FileError.ENCODING_ERR:
                //URL is not complete or otherwise invalid
                return "Malformed URL";
            case FileError.NO_MODIFICATION_ALLOWED_ERR:
                return "File/Directory not writable";
            case FileError.INVALID_STATE_ERR:
                //The state of the interface objects has been changed singe it was last read from disk
                return "Invalid state. Please refresh the FileEntry/DirectoryEntry";
            case FileError.SYNTAX_ERR:
                //I don't know what this means...
                return "Syntax Error";
            case FileError.INVALID_MODIFICATION_ERR:
                //Operation would result in invalid state. Such as moving a directory into a child directory.
                return "Invalid operation";
            case FileError.QUOTA_EXCEEDED_ERR:
                return "Quota exceeded";
            case FileError.TYPE_MISMATCH_ERR:
                //App is mixing up FileEntries and DirectoryEntries. For example, asks for X file as a FileEntry; but, it is a DirectoryEntry.
                return "Type mismatch";
            case FileError.PATH_EXISTS_ERR:
                return "Path exists";
            case TP_FILE_ENTRY_NOT_FOUND:
                return TP_FILE_ENTRY_NOT_FOUND;
            default:
                return "Unknown Error";
        }
    }
}

//TO DO: Add support for multiple files in the same directory
//Actually a struct.
class FileSystemPath {
    /*
        path: String,       required
        name: String,       optional. Required if content is set.
        content: String,    optional. Required if name is set.
    */
    constructor(path, name, content) {

        if (path.indexOf(".") !== -1) {
            throw new Error("path can not contain the . character");
        }
        else {
            this.path = path;
        }

        if (name && !content) {
            throw new Error("content must be set if name is set.");
        }
        else if (!name && content) {
            throw new Error("name must be set if content is set.");
        }
        else if (name.indexOf(".") === -1) {
            throw new Error("name must have the . character.");
        }
        else {
            this.name = name;
            this.content = content;
        }

        this.fullPath = this.path + (this.name ? "/" + this.name : "");
    }
}

/*
    Provides access to user's file system in a persistent dictory.

    Requires access to the User's organization.
*/
class FileSystemSingleton {

    constructor() {}
    
    //Unwraps Cordova FileEntry/DirectoryEntry to a JSON object. This method is used to break a Circular Dependency on the entry's FileSystem property.
    //The Entry's FileSystem property will NOT be included in the JSON Object.
    entryToJSON(entry) {
        let result;

        //This is intended to be used for debugging, so we want to make this error-safe
        if (entry instanceof DirectoryEntry || entry instanceof FileEntry) {
            result = { 
                fullPath: entry.fullPath,
                isDirectory: entry.isDirectory,
                isFile: entry.isFile,
                name: entry.name,
                nativeURL: entry.nativeURL
            };
        }
        else {
            //Don't just set null. Entry should be a DirectoryEntry or FileEntry; but, if a particular method takes in a value that is not either one then it's probably a bug.
            //This can help catch the bug.
            result = entry;
        }

        return result;
    }

    _getFileEntry(path) {
        if (FileEntries[path]) {
            return FileEntries[path];
        }
        else {
            throw new TPFileError(TP_FILE_ENTRY_NOT_FOUND);
        }
    }

    _addFileEntry(tpfe) {
        if (FileEntries[tpfe.getFileEntry().fullPath]) {
            FileEntries[tpfe.getFileEntry().fullPath].setFileEntry(tpfe.getFileEntry(), tpfe.getDirectoryEntry());
        }
        else {
            FileEntries[tpfe.getFileEntry().fullPath] = tpfe;
        }
    }

    _removeFileEntry(tpfe) {
        delete FileEntries[tpfe.getFileEntry().fullPath];
    }

    /*
        Sets the file paths that are expected to exist. Use the "/" character to indicate a new directory.

        In all cases, the "." character is an invalid character for directory names. 
    */
    setPaths(paths){
        Paths = paths;
    }

    /*
        Ensures certain files are created with default content. If the file already exists; then, the existing contents of the file will NOT be over written.

        All files MUST contain the "." character. If a file does
    */
    setFiles(files) {
        // eslint-disable-next-line
        Files = files;
    }

    /*
        Checks if a directory exists. Resolves with true/false. Rejects if error other than FileError.NOT_FOUND_ERR is thrown.

        Arguments:

        path            :   string          The directory path. This path should NOT start with "/" if DirectoryEntry is provided.
        dir             :   DirectoryEntry  Optional. If provided, will check if the path exists starting from this directory. If not provided, the root directory will be used.
    */
    doesDirectoryExist(path, dir){
        return new Promise((resolve, reject) => {
            this.getDirectory(path, dir).then(() => {
                resolve(true);
            }).catch((tpError) => {
                if (tpError.error.code === FileError.NOT_FOUND_ERR) {
                    resolve(false);
                }
                else {
                    reject(tpError);
                }
            });
        });
    }

    /*
        Checks if a file exists. Resolves with true/false. Rejects if error other than FileError.NOT_FOUND_ERR is thrown.

        Arguments:

        dir             :   DirectoryEntry  The directory to look in.
        name            :   string          The file name.
    */
    doesFileExist(dir, name){
        return new Promise((resolve, reject) => {
            this.getFile(dir, name).then(() => {
                resolve(true);
            }).catch((tpError) => {
                if (tpError.error.code === FileError.NOT_FOUND_ERR) {
                    resolve(false);
                }
                else {
                    reject(tpError);
                }
            });
        });
    }

    /*
        WARNING: 
        Unfix bug.
        This function uses plugin functions that move contents of a directory. 
        If this moves files that TPFileEntries point to; then, 
        it will invalidate the state of some TPFileEntries.

        This function is currently not in use; but, if it ever gets use, 
        this bug will have to be fixed.



        Moves a DirentryEntry to another location.

        Arguments:

        dir             :   DirectoryEntry      The directory to move.
        parentDir       :   DirectoryEntry      The new parent directory.
        newName         :   string              Optional. A new name for the directory.
    moveDirectory(dir, parentDir, newName){
        return new Promise((resolve, reject) => {
            dir.moveTo(parentDir, newName, (dir) => {
                resolve(dir);
            }, (error) => {
                reject(new TPFileError(error));
            });
        });
    }
    */

    /*
        Gets a Cordova File from the Phone's temporary directory. The file will be wrapped in a Promise.

        Arguments:

        path        :   string          The file's full path.

        Error Conditions:

        The full path must exist.
    */
    getTemporaryFile(path){
        /*
            iOS WKWebView is using a server. So the camera saves the image and returns a http path.

            In Android we get a normal local file path.

            This creates a platform problem. iOS needs the LocalFileSystem.TEMPORARY; while, Android needs to
            use window.resolveLocalFileSystemURL. As a result, we need to do a platform check.
        */
        //if(OS.getOS() === OS_PLATFORMS.IOS) {
        if (Browser.getOS() === Browser.IOS) {
            return this._getFileSystem(LocalFileSystem.TEMPORARY).then((fs) => {
                return this.getFile(fs.root, path.split('\\').pop().split('/').pop());
            });
        }
        else {
            return new Promise((resolve, reject) => {
                window.resolveLocalFileSystemURL(path, (fileEntry) => {
                    resolve(fileEntry);
                }, (error) => {
                    reject(new TPFileError(error));
                });
            });
        }
    }


    /*
        Gets a Cordova FileSystem object wrapped in a Promise. 

        Arguments:

        localFileSystem     :   LocalFileSystem     Cordova File Plugin constant, LocalFileSystem
    */
    _getFileSystem(localFileSystem){
        return new Promise((resolve, reject) => {
            window.requestFileSystem(localFileSystem, 0, (fs) => {
                resolve(fs);
            }, (error) => {
                reject(new TPFileError(error));
            });
        });
    }

    /*
        Gets the current User's Organization's Root directory.

        Arguments:

        localFileSystem     :   LocalFileSystem     Cordova File Plugin constant, LocalFileSystem
    */
    _getRoot(localFileSystem){
        return new Promise((resolve, reject) => {
            this._getFileSystem(localFileSystem).then((fs) => {
                fs.root.getDirectory(UserStore.getInstance().getUser().organization.id.toString(), {'create': false}, (dir) => {
                    resolve(dir);
                }, (error) => {
                    return Promise.reject(new TPFileError(error));
                });
            }).catch((error) => {
                reject(error);
            });
        });
    }

    /*
        Gets a Cordova File Directory object wrapped in a promise. 

        Arguments:

        path        :   string      Full file path starting from root or, if provided, dir. Expects all directories to already exist. See Paths constants for predefined paths.
        dir         :   DirectoryEntry  Optional. If provided, will use this directory. If not provided, the root directory will be used.

        Error Conditions:

        The entire path must already exist.
    */
    getDirectory(path, dir){
        return new Promise((resolve, reject) => {
            new Promise((resolve, reject) => {
                if (dir) {
                    resolve(dir);
                }
                else {
                    this._createDirectories().then(() => {
                        return this._getRoot(LocalFileSystem.PERSISTENT);
                    }).then((dir) => {
                        resolve(dir);
                    }).catch((error) => {
                        reject(error);
                    });
                }
            }).then((dir) => {
                return new Promise((resolve, reject) => {
                    dir.getDirectory(path, {'create': false}, (dir) => {
                        resolve(dir);
                    }, (error) =>{
                        reject(new TPFileError(error));
                    });
                });
            }).then((dir) => {
                resolve(dir);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    readDirectory(dir){
        return new Promise((resolve, reject) => {
            dir.createReader().readEntries((dirContents) => {
                resolve(dirContents);
            }, (error) => {
                reject(new TPFileError(error));
            });
        });
    }

    /*
        Gets a TPFileEntry object wrapped in a promise.
    
        Arguments:

        dir         :   DirectoryEntry  Directory to start from. See FileSystem.getDirectory(path).
        name        :   string          The file's name.
        
        Error Conditions:

        The file with the specified name must exist.
    */
    getFile(dir, name){
        return new Promise((resolve, reject) => {
            this.getCordovaFile(dir, name).then((fileEntry) => {
                this._addFileEntry(new TPFileEntry(fileEntry, dir));
                resolve(this._getFileEntry(fileEntry.fullPath));
            }).catch(reject);
        });
    }

    /*
        For Internal Use Only

        Gets a Cordova File Entry object.
    */
    getCordovaFile(dir, name) {
        return new Promise((resolve, reject) => {
            dir.getFile(name, {'create': false}, (fileEntry) => {
                resolve(fileEntry);
            }, (error) => {
                reject(new TPFileError(error));
            });
        });
    }

    /*
        Converts File to Blob. Blob will be wrapped in Promise.

        Arguments:

        fileEntry       :   FileEntry       A Cordova File Plugin FileEntry Object. See FileSystem.getFile(dir, name) or FileSystem.getTemporaryFile(path).
        blobOptions     :   object  
    */
    readBlobFile(fileEntry, blobOptions){
        return new Promise((resolve, reject) => {
            new Promise((resolve, reject) => {
                fileEntry.file((file) => {
                    resolve(file);
                }, (error) => {
                    reject(new TPFileError(error));
                });
            }).then((file) => {
                return this.readArrayBuffer(file);
            }).then((buffer) => {
                resolve(new Blob([buffer], blobOptions)); 
            }).catch(reject);
        });
    }

    /*
        Reads File as Array Buffer. Will be wrapped in Promise.

        Arguments:

        obj     :   object      Object to read as array buffer.
    */
    readArrayBuffer(obj){
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = (result) => { 
                resolve(result.target.result); 
            };
            reader.onerror = (error) => { 
                reject(new TPError(error));
            };
            reader.readAsArrayBuffer(obj);
        });
    }

    /*
        Creates a new directory in the directory specified. 

        Arguments:

        dir         :   DirectoryEntry  Directory to start from. See FileSystem.getDirectory(path).
        name        :   string          The new directory's name.

        Error Conditions:

        The directory name must NOT exist.
    */
    addDirectory(dir, name){
        return new Promise((resolve, reject) => {
            dir.getDirectory(name, {'create': true, 'exclusive': true}, (dir) => {
                resolve(dir);
            }, (error) => {
                reject(new TPFileError(error));
            });
        });
    }

    /*
        Creates a new file in the directory specified.

        Arguments:

        dir         :   DirectoryEntry  Directory to start from. See FileSystem.getDirectory(path).
        name        :   string          The new file's name.
        contents    :   string/blob     Content to write to file. Can be either a string or binary data.

        Error Conditions:

        The file name must NOT exist.
    */
    addFile(dir, name, contents){
        return new Promise((resolve, reject) => {//For rethrowing catch
            new Promise((resolve, reject) => {//Add File Promise
                dir.getFile(name, {'create': true, 'exclusive': true}, (fileEntry) => {
                    //TPFileEntry is created later so that the file will have it contents before a TPFileEntry reference is created.
                    resolve(fileEntry);
                }, (error) => {
                    //This reject does not create TPFileError due to clean up error functionality
                    reject(error);
                });
            }).then((fileEntry) => {
                return new Promise((resolve, reject) => {
                    fileEntry.createWriter((writer) => {
                        writer.onwrite = () => {
                            resolve(fileEntry);
                        }
                        writer.onerror = (error) => {
                            //This reject does not create TPFileError due to clean up error functionality
                            reject(error);
                        }
                        writer.write(contents);
                    }, (error) => {
                        //This reject does not create TPFileError due to clean up error functionality
                        reject(error);
                    });
                });
            }).then((fileEntry) => {
                this._addFileEntry(new TPFileEntry(fileEntry, dir));
                resolve();
            }).catch((error) => {
                //Cleans up files if the write fails. 
                this.getFile(dir, name).then((fileEntry) => {
                    //If we can get the file; then, we need to delete it.
                    return this.deleteFile(fileEntry);
                }).then(() => {
                    //Delete was successful. Reject original error
                    reject(new TPFileError(error));
                }).catch((cleanUpError) => {
                    if (cleanUpError.code === FileError.NOT_FOUND_ERR) {
                        // NOT FOUND error occurred. There is no file to delete. Reject original error.
                        reject(new TPFileError(error));
                    }
                    else {
                        //We got the file; but, could not delete it. Reject both errors
                        reject(new TPFileError(error, cleanUpError));
                    }
                });
            });
        });
    }

    /*
        Updates a file in the directory specified. The file's contents will be replaced by the new contents.
        This replaces the entire file.

        Arguments:

        file        :   FileEntry       The file to update. FileSystem.getFile(dir, name).
        contents    :   string/blob     Content to write to file. Can be either a string or binary data.

    */
    updateFile(fileEntry, contents){
        return new Promise((resolve, reject) => {
            fileEntry.updateFile(contents).then(resolve).catch((error) => {
                reject(new TPFileError(error));
            });
        });
    }

    /*
        Deletes a file from the specified directory.

        Arguments:

        fileEntry   :   FileEntry       The file to delete. See FileSystem.getFile(dir, name).
    */
    deleteFile(fileEntry){
        return new Promise((resolve, reject) => {
            fileEntry.deleteFile().then((result) => {
                this._removeFileEntry(fileEntry);
                resolve(result);
            }).catch((error) => {
                reject(new TPFileError(error));
            });
        });
    }

    /*
        WARNING: 
        Unfix bug.
        This function uses plugin functions that delete contents of directories. 
        If this deletes files that TPFileEntries point to; then, 
        it will invalidate the state of some TPFileEntries.

        This function is currently not in use; but, if it ever gets use, 
        this bug will have to be fixed.


        Deletes a directory. If removeContents is true; then, also deletes the directory's contents.

        Arguments:

        dir                 :   DirectoryEntry      The Directory to delete. See FileSystem.getDirectory(path).
        removeContents      :   boolean             If true, also deletes Directory's contents.

        Error Conditions:
        If removeContents is false; then, the directory must be empty to be deleted.
    deleteDirectory(dir, removeContents){
        return new Promise((resolve, reject) => {
            new Promise((resolve, reject) => {
                if(removeContents){
                    dir.removeRecursively(() => { 
                        resolve(); 
                    }, (error) => {
                        reject(error);
                    });
                }else{
                    dir.remove(() => {
                        resolve(); 
                    }, (error) => {
                        reject(error);
                    });
                }
            }).then(() => { 
                resolve();
            }).catch((error) => {
                reject(new TPFileError(error));
            })
        });
    }
    */

    /*
        Reads a FileEntry's content as text.

        Arguments:

        fileEntry   :   TPFileEntry     The file to read. See FileSystem.getFile(dir, name).
    */
    readFile(fileEntry){
        return new Promise((resolve, reject) => {
            fileEntry.readFile().then(resolve).catch((error) => {
                reject(new TPFileError(error));
            });
        });
    }

    /*
        Creates all expected directories and files if they do not already exist.
    */
    _createDirectories(){
        return new Promise((resolve, reject) => {
            this._getFileSystem(LocalFileSystem.PERSISTENT).then((fs) => {
                return this._createOrganizationPath(fs);
            }).then((dir) => {
                if (Paths.length > 0){
                    return Promise.all(
                        Paths.map((fsPath) => {
                            return this._createDirectory(fsPath.fullPath, dir, fsPath.content);
                        })
                    );
                }
                else {
                    return Promise.resolve();
                }
            }).then(resolve).catch(reject);
        });
    }

    _createDirectory(path, dir, content){
        let separatorIndex = path.indexOf("/");
        let name = (separatorIndex > -1 ? path.substring(0, separatorIndex) : path);

        return new Promise((resolve, reject) => {
            if (name.indexOf(".") > -1) {
                FileSystem.doesFileExist(dir, name).then((exists) => {
                    if (exists) {
                        resolve();
                    }
                    else {
                        return FileSystem.addFile(dir, name, content);
                    }
                }).then(resolve).catch(reject);
            }
            else {
                dir.getDirectory(name, {'create': true}, (newDir) => {
                    if (separatorIndex > -1) {
                        path = path.substring(separatorIndex + 1);
                        /*
                            If we have a path like...
                            /part1/part2/part3

                            Each part of a directory must be created individually.

                            Trying to skip to creating part3 would cause an error; because, part1 and part2 has not be created yet.
                        */
                        this._createDirectory(path, newDir, content).then(resolve).catch(reject);
                    }
                    else {
                        resolve();
                    }
                }, (error) => {
                    let holder = error;
                    if (!(holder instanceof TPFileError)) {
                        holder = new TPFileError(error);
                    }
                    reject(holder);
                });
            }
        });
    }

    _createOrganizationPath(fs){
        return new Promise((resolve, reject) => {
            if (!UserStore.getInstance().getUser()) {
                let message = "Missing User data.";
                DispatchMessage.getInstance().execute({
                    message: message,
                    type: MessageType.ERROR
                });
                RequestLogoutAction.execute();
                reject(message);
            }
            else {
                fs.root.getDirectory(UserStore.getInstance().getUser().organization.id.toString(), {'create': true}, (dir) => {
                    resolve(dir);
                }, (error) => {
                    //fs.root is a DirectoryEntry which contains recursive properties.
                    //fs.root = this.entryToJSON(fs.root);
                    reject(new TPFileError(error));
                });
            }
        });
    }
}

const FileSystem = new FileSystemSingleton();

export { FileSystem, FileSystemPath };

class TPFileEntry {
    constructor(fileEntry, dir) {
        this.setFileEntry(fileEntry, dir);
        this.queue = new Queue(true);
        this.isDeleted = false;

        this.writer = null;
    }

    _raiseDeletedException() {
        throw new Error("File has been deleted.");
    }

    toJSON() {
        return {
            fe: FileSystem.entryToJSON(this.fe),
            de: FileSystem.entryToJSON(this.de),
            queue: this.queue
        }
    }

    //For Internal Use Only
    refresh() { 
        if (this.isDeleted) {
            this._raiseDeletedException();
        }
        //If refresh fails; then, the TPFileEntry will likely have an invalid instance of FileEntry in this.fe. We don't actually do anything in this event other than log the error.
        //Theroritically it shouldn't ever fail unless something really bad happens with the native phone's file system.
        return new Promise((resolve, reject) => {
            FileSystem.getCordovaFile(this.de, this.fe.name).then((fileEntry) => {
                this.setFileEntry(fileEntry, this.de);
                resolve();
            }).catch((error) => {
                reject(new TPError({
                    message: "Failed to refresh TPFileEntry.",
                    details: {
                        function: "TPFileEntry.refresh",
                        this: this.toJSON(),
                        error: error
                    }
                }));
            });
        });
    }

    //For Internal Use Only
    setFileEntry(fileEntry, dir) {
        if (this.isDeleted) {
            this._raiseDeletedException();
        }

        if (!(fileEntry instanceof FileEntry)) {
            throw new Error("File Entry must be an instance of Cordova File Entry");
        }

        this.de = dir;
        this.fe = fileEntry;
    }

    //For Internal Use Only
    getFileEntry() {
        /*if(this.isDeleted) {
            this._raiseDeletedException();
        }*/
        return this.fe;
    }

    //For Internal Use Only
    getDirectoryEntry() {
        /*if(this.isDeleted) {
            this._raiseDeletedException();
        }*/
        return this.de;
    }

    //For Internal Use Only
    file(success, fail) {
        if (this.isDeleted) {
            this._raiseDeletedException();
        }
        this.fe.file(success, fail);
    }

    //For Internal Use Only
    remove(success, fail) {
        if (this.isDeleted) {
            this._raiseDeletedException();
        }
        this.fe.remove(
            (result) => {
                this.isDeleted = true;
                success(result);
            }, 
            fail
        );
    }

    //For Internal Use Only
    createWriter(success, fail) {
        if (this.isDeleted) {
            this._raiseDeletedException();
        }

        if (this.writer) {
            success(this.writer);
        }
        else {
            this.fe.createWriter((writer) => {
                this.writer = writer;
                success(this.writer);
            }, fail);
        }
    }
    
    _scheduleTask(task) {
        return new Promise((resolve, reject) => {
            /*
                Successful
                Result
                Fail
            */
            let s = (r) => {
                task.unregister(TaskEvents.ON_SUCCESS, s);
                resolve(r);
            };
            let f = (r) => {
                task.unregister(TaskEvents.ON_FAIL, f);
                reject(r);
            };
            task.register(TaskEvents.ON_SUCCESS, s);
            task.register(TaskEvents.ON_FAIL, f);
            this.queue.schedule(task);
        });
    }

    //For Internal Use Only
    updateFile(contents) {
        if (this.isDeleted) {
            this._raiseDeletedException();
        }
        return this._scheduleTask(new UpdateFileTask(undefined, this, contents));
    }

    //For Internal Use Only
    deleteFile() {
        if (this.isDeleted) {
            this._raiseDeletedException();
        }
        return this._scheduleTask(new DeleteFileTask(undefined, this));
    }

    //For Internal Use Only
    readFile() {
        if (this.isDeleted) {
            this._raiseDeletedException();
        }
        return this._scheduleTask(new ReadFileTask(undefined, this));
    }
}
