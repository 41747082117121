'use strict';

import {Action} from './Action';

class SetDropzoneSingleton extends Action {
    getTag() {
        return 'set-dropzone';
    }

    _shouldDispatchOnError() {
        return false;
    }

    _execute(dropzone) {
        return Promise.resolve(dropzone);
    }
}

const SetDropzone = new SetDropzoneSingleton();

export { SetDropzone };
