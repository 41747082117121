'use strict';

import {Action} from './Action';
import {UserStore} from '../store/UserStore';
import {TPError} from '@totalpave/error';

//Tells the app it's time to start the logout process
class RequestLogoutActionSingleton extends Action {
    constructor() {
        super();
    }

    getTag() {
        return 'request_logout_action';
    }

    _shouldDispatchOnError() {
        return false;
    }

    /**
     *    This will start the logout process
     * 
     * @return {void} 

     */
    _execute() {
        return new Promise((resolve) => {
        
            // requestlogoutaction can't really find out if the data in user store is valid. So at best we 
            // can only log scenarios where we are requesting logging out while already logged out.
            
            // We can't simply stop this scenario; because, we don't want to stop a niche bug case and create 
            // more niche bug cases; because, the app can't log out due to invalid state.
        

            if (!UserStore.getInstance().getUser()) {
                //message is not that useful... How can I get some useful information to help debug this scenario's root cuase?
                new TPError({
                    message: "RequestLogoutAction was called when User is already logged out.",
                    stack: new Error().stack
                });
            }

            resolve();
        });
    }
}

const RequestLogoutAction = new RequestLogoutActionSingleton();

export { RequestLogoutAction };
