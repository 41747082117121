'use strict';

import * as React from 'react';
import { FormModal } from './FormModal';
import {FormItem} from './FormItem';
import '../style/DeleteModal.less';
import PropTypes from 'prop-types';

class DeleteModal extends FormModal {
    constructor(props) {
        super(props);
        this._addClassName('DeleteModal');
    }

    getTitle() {
        return 'Confirm Delete';
    }

    shouldShowCancelButton() {
        return true;
    }

    onCancel() {
        this.props.cancel && this.props.cancel();
    }

    _onSubmit() {
        return this.props.confirm();
    }

    _renderContent() {
        return (
            <FormItem className="message">
                {this.props.message} This action cannot be undone.
            </FormItem>
        );
    }
}

DeleteModal.propTypes = {
    cancel: PropTypes.func,
    confirm: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired
};

export { DeleteModal };
