'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';
import {ApplicationInstance} from '@totalpave/application-instance';
import {IconFactory} from '../factories/IconFactory';
import '../style/ToggleGroup.less';
import {ObjectUtils} from '@totalpave/object';

class ToggleGroup extends React.Component {
    constructor(props) {
        super(props);

        this._onTap = this._onTap.bind(this);
        this._onMouseDown = this._onMouseDown.bind(this);

        this.state = {
            value : props.defaultValue
        };

        if (props.value !== undefined) {
            this.state.value = props.value;
        }

        if (this.state.value === undefined) {
            this.state.value = null;
        }

        if (props.stopEventPropagation !== undefined) {
            ApplicationInstance.getInstance().getLogger().warn('ToggleGroup prop "stopEventPropagation" is deprecated. Replace with "stopClickPropagation"');
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.value !== undefined && state.value !== props.value) {
            state.value = props.value;
        }

        if (props.stopEventPropagation !== undefined) {
            ApplicationInstance.getInstance().getLogger().warn('ToggleGroup prop "stopEventPropagation" is deprecated. Replace with "stopClickPropagation"');
        }

        return state;
    }

    $isEnabled() {
        if (!ObjectUtils.isVoid(this.props.disabled)) {
            ApplicationInstance.getInstance().getLogger().deprecate("ToggleGroup", "disabled property", null, "enabled property");
            return !this.props.disabled;
        }
        return ObjectUtils.isVoid(this.props.enabled) || this.props.enabled;
    }

    _onTap(option, event) {
        if (!this.$isEnabled()) {
            return;
        }
        
        let oldValue = this.state.value;
        let value = option.value;
        if (value === 'true') {
            value = true;
        }
        else if (value === 'false') {
            value = false;
        }
        else if (typeof value !== 'boolean' && !isNaN(value)) {
            value = parseFloat(value);
        }

        if (this.props.multiselect) {
            let newValue = oldValue.slice();
            if (oldValue.indexOf(value) > -1) {
                let index = oldValue.indexOf(value);
                newValue.splice(index, 1);
            }
            else {
                newValue.push(value);
            }
            value = newValue;
        }
        else {
            if (this.props.allowDeselect && value === this.state.value) {
                value = null;
            }
        }

        if (this.props.stopClickPropagation || this.props.stopEventPropagation) {
            event.stopPropagation();
        }

        if (this.props.onChange && oldValue !== value) {
            this.props.onChange(value);
        }
    }

    _onMouseDown(e) {
        if (this.props.stopMouseDownPropagation) {
            e.stopPropagation();
        }
    }

    render() {
        let options = [];

        if (this.props.options && this.props.options.length > 0) {
            for (let i = 0; i < this.props.options.length; i++) {
                let option = this.props.options[i];
                options.push(this._buildButton(option));
            }
        }

        return (
            <div 
                className={`ToggleGroup ${this.props.className ? this.props.className : ''} ${this.$isEnabled() ? '' : 'disabledtogglegroup'}`} 
                onMouseDown={this._onMouseDown}
            >
                {options}
            </div>
        );
    }

    _buildButton(option) {
        let className = 'toggle-item';
        let isSelected = false;

        if (this.props.multiselect) {
            if (this.state.value && this.state.value.indexOf(option.value) > -1) {
                isSelected = true;
            }
        }
        else {
            if (option.value === this.state.value) {
                isSelected = true;
            }
        }

        if (isSelected) {
            className += ' selected';
        }

        let deleteIcon;
        if (option.onDelete) {
            deleteIcon = IconFactory.create(IconFactory.Icons.CANCEL_CIRCLE, {
                className: 'delete-icon',
                onClick: () => {
                    option.onDelete();
                }
            });
        }

        return (
            <div
                disabled={!this.$isEnabled()}
                title={option.title}
                className={className}
                key={`option-${option.value}`}
                data-value={option.value}
                role="group"
                aria-label=""
                onClick={(e) => {
                    this._onTap(option, e);
                }}
            >
                <span>{option.text}</span>
                {deleteIcon}
            </div>
        );
    }
}

ToggleGroup.propTypes = {
    defaultValue: PropTypes.any,
    value: PropTypes.any,
    stopEventPropagation: PropTypes.bool,
    stopClickPropagation: PropTypes.bool,
    enabled: PropTypes.bool,
    disabled: PropTypes.bool,
    multiselect: PropTypes.bool,
    allowDeselect: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    stopMouseDownPropagation: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.any),
    className: PropTypes.string
};

export { ToggleGroup };
