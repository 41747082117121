'use strict';

let height = null;
let width = null;

class ScrollbarMeasurement {
    static getSize() {
        if (height === null) {
            let dim = ScrollbarMeasurement._getDimensions();

            height = dim.height;
            width = dim.width;
        }

        return {
            width : width,
            height: height
        }
    }

    static getWidth() {
        return ScrollbarMeasurement.getSize().width;
    }

    static getHeight() {
        return ScrollbarMeasurement.getSize().height;
    }

    static _getDimensions() {
        let div = document.createElement('div');
        div.style.height = "100px";
        div.style.width = "100px";
        div.style.position = 'fixed';
        div.style.left = '-100000px';
        div.style.top = '-100000px';
        div.style.overflow = 'scroll';

        document.body.appendChild(div);

        let clientHeight = div.clientHeight;
        let clientWidth = div.clientWidth;
        let offsetWidth = div.offsetWidth;
        let offsetHeight = div.offsetHeight;

        document.body.removeChild(div);
        div = null;

        return {
            width : offsetWidth - clientWidth,
            height : offsetHeight - clientHeight
        };
    }
}

export { ScrollbarMeasurement };
