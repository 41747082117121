'use strict';

import * as React from 'react';
import "../style/Pipe.less";

class Pipe extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div className="Pipe">&nbsp;</div>;
    }
}

export { Pipe }; 
