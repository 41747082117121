'use strict';

export class SectionUtils {
    
    /**
     * @deprecated - Use proper quad trees
     */
    static calculateLatLngQuad(value, step, decimalAccuracy) {
        let acc = Math.pow(10, decimalAccuracy);                 //dividing by acc means we will be working with floats... Which is dangerous when we need to work with exact values. So instead let's work with integers.
        return ((Math.floor(Math.floor(value * acc) / step) * step)/* / acc*/);//.toFixed(decimalAccuracy);
    }

    /**
     * @deprecated - Use proper quad trees
     */
    static calculateQuadBounds(latLngBounds, step, decimalAccuracy) {
        let bounds = {
            nlng: SectionUtils.calculateLatLngQuad(latLngBounds.northeast.lng, step, decimalAccuracy),
            nlat: SectionUtils.calculateLatLngQuad(latLngBounds.northeast.lat, step, decimalAccuracy),
            slng: SectionUtils.calculateLatLngQuad(latLngBounds.southwest.lng, step, decimalAccuracy),
            slat: SectionUtils.calculateLatLngQuad(latLngBounds.southwest.lat, step, decimalAccuracy)
        };

        bounds.topRight = `${bounds.nlng},${bounds.nlat}`;
        bounds.bottomRight = `${bounds.nlng},${bounds.slat}`;
        bounds.bottomLeft = `${bounds.slng},${bounds.slat}`;
        bounds.topLeft = `${bounds.slng},${bounds.nlat}`;
        return bounds;
    }
}
