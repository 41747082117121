
/**
 * Copyright 2023-2024 Total Pave Inc.
 * All Rights Reserved.
 */

import * as React from 'react';
import {ClassName} from '../utils/ClassName';
import style from '../style/components/Label.less';

export interface ILabelProps {
    onClick?: (e: React.MouseEvent) => void;
    style?: React.CSSProperties;
    text?: string;
    className?: string;
}

/**
 * A component that represents a simple textual label.
 * @since 0.1.2
 */
export class Label extends React.Component<ILabelProps> {
    public constructor(props: ILabelProps) {
        super(props);
    }

    public override componentDidMount(): void {
        style.use();
    }

    public override componentWillUnmount(): void {
        style.unuse();
    }

    public getClassName(): string {
        let classes: string[] = [ 'Label' ];
        if (this.props.className) {
            classes.push(this.props.className);
        }

        return ClassName.execute({}, classes);
    }

    public getText(): string {
        let text: string = '';
        if (this.props.text !== null && this.props.text !== undefined) {
            text = this.props.text;
        }
        return text;
    }

    private $onClick(event: React.MouseEvent) {
        this.props.onClick && this.props.onClick(event);
    }

    public override render(): React.ReactNode {
        return (
            <span
                onClick={(e: React.MouseEvent) => {
                    this.$onClick(e);
                }}
                className={this.getClassName()}
                style={this.props.style}
            >
                {this.getText()}
            </span>
        );
    }
}
