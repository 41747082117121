'use strict';

/**
 * @deprecated Use @totalpave/interfaces instead
 */
const MAX_SLAB_SIZE = 8; // in meters

/**
 * @deprecated Use @totalpave/pci instead
 */
const SlabUtils = {
    calculateImaginaryGridDimensions : (slabWidth, slabLength) => {
        let gridDimensions = {
            rows : 0,
            columns : 0,
            slabLength : 0,
            slabWidth : 0 
        };

        let division;

        if (slabWidth > MAX_SLAB_SIZE) {
            division = 2;

            while (slabWidth / division > MAX_SLAB_SIZE) {
                division++;
            }

            gridDimensions.slabWidth = slabWidth / division;
            gridDimensions.columns = division;
        }

        if (slabLength > MAX_SLAB_SIZE) {
            division = 2;

            while (slabLength / division > MAX_SLAB_SIZE) {
                division++;
            }

            gridDimensions.slabLength = slabLength / division;
            gridDimensions.rows = division;
        }

        if (gridDimensions.rows !== 0 && gridDimensions.columns === 0) {
            gridDimensions.columns = 1;
            gridDimensions.slabWidth = slabWidth;
        }

        if (gridDimensions.rows === 0 && gridDimensions.columns !== 0) {
            gridDimensions.rows = 1;
            gridDimensions.slabLength = slabLength;
        }

        return gridDimensions;
    }
};

export { SlabUtils };
