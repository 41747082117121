'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';
import {MouseStore} from '../store/MouseStore';
import {MouseButton} from '../utils/MouseButton';
import "../style/ResizableComponent.less";

const MIN_WIDTH  = 20;
const MIN_HEIGHT = 20;

const OVERFLOW_THRESHOLD = 15;
const OVERFLOW_STEP = 10;

class ResizableComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            x : props.defaultX  || 0,
            x2: props.defaultX2 || 0,
            y : props.defaultY  || 0,
            y2: props.defaultY2 || 0,
            previewX : props.defaultX  || 0,
            previewX2: props.defaultX2 || 0,
            previewY : props.defaultY  || 0,
            previewY2: props.defaultY2 || 0,
            relativeX : props.defaultX  || 0,
            relativeX2: props.defaultX2 || 0,
            relativeY : props.defaultY  || 0,
            relativeY2: props.defaultY2 || 0,
            showPreview : false,
            mouseX : 0,
            mouseY : 0,
            northResizeActive : false,
            southResizeActive : false,
            eastResizeActive : false,
            westResizeActive : false,
            nwResizeActive : false,
            neResizeActive : false,
            swResizeActive : false,
            seResizeActive : false,
            lastBrowserWidth: document.body.clientWidth,
            lastBrowserHeight: document.body.clientHeight
        };

        this._node = null;
        this.onNorthResizerMouseDown = this.onNorthResizerMouseDown.bind(this);
        this.onSouthResizerMouseDown = this.onSouthResizerMouseDown.bind(this);
        this.onEastResizerMouseDown = this.onEastResizerMouseDown.bind(this);
        this.onWestResizerMouseDown = this.onWestResizerMouseDown.bind(this);
        this.onNWResizerMouseDown = this.onNWResizerMouseDown.bind(this);
        this.onNEResizerMouseDown = this.onNEResizerMouseDown.bind(this);
        this.onSWResizerMouseDown = this.onSWResizerMouseDown.bind(this);
        this.onSEResizerMouseDown = this.onSEResizerMouseDown.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        // this.onWindowResize = this.onWindowResize.bind(this);
        this._onTransitionEnd = this._onTransitionEnd.bind(this);
        this._onMouseUpdate = this._onMouseUpdate.bind(this);
        this._edgeCheck = this._edgeCheck.bind(this);
    }

    isActive() {
        return (
            this.state.northResizeActive ||
            this.state.southResizeActive ||
            this.state.eastResizeActive  ||
            this.state.westResizeActive  ||
            this.state.nwResizeActive    ||
            this.state.neResizeActive    ||
            this.state.swResizeActive    ||
            this.state.seResizeActive
        );
    }

    _onMouseUpdate() {
        if (this.isActive() && MouseStore.getInstance().isButtonPressed(MouseButton.LEFT)) {
            window.requestAnimationFrame(this._edgeCheck);
        }
    }

    _edgeCheck() {
        let x = MouseStore.getInstance().getX();
        // let y = MouseStore.getY();

        let w = document.body.clientWidth;
        // let h = document.body.clientHeight;

        //todo check height wise if required

        let lBounds = w - OVERFLOW_THRESHOLD;

        if (x >= lBounds) {
            this.setState({
                previewX2 : this.state.previewX2 + OVERFLOW_STEP
            }, () => {
                if (this.props.onResize) {
                    this.props.onResize({
                        x : this.state.previewX,
                        y : this.state.previewY,
                        width : this.state.previewX2,
                        height: this.state.previewY2
                    }, {
                        x : this.state.x,
                        y : this.state.y,
                        width: this.state.x2,
                        height : this.state.y2
                    });
                }
            });
        }

        setTimeout(() => {
            if (this.isActive() && MouseStore.getInstance().isButtonPressed(MouseButton.LEFT)) {
                window.requestAnimationFrame(this._edgeCheck);
            }
        }, 1000)
    }

    getMinWidth() {
        return this.props.minWidth || MIN_WIDTH;
    }

    getMinHeight() {
        return this.props.minHeight || MIN_HEIGHT;
    }

    static getDerivedStateFromProps(nextProps, state) {
        let dirty = false;
        if (nextProps.x && nextProps.x !== state.x) {
            dirty = true;
            state.x = nextProps.x;
        }
        if (nextProps.width && nextProps.width !== state.x2) {
            dirty = true;
            state.x2 = nextProps.width;
        }
        if (nextProps.y && nextProps.y !== state.y) {
            dirty = true;
            state.y = nextProps.y;
        }
        if (nextProps.height && nextProps.height !== state.y2) {
            dirty = true;
            state.y2 = nextProps.height;
        }

        if (dirty) {
            return state;
        }

        return null;
    }

    componentDidMount() {
        MouseStore.getInstance().register(this._onMouseUpdate);
        document.body.addEventListener('mouseup', this.onMouseUp, true);
        document.body.addEventListener('mousemove', this.onMouseMove, true);
    }

    componentWillUnmount() {
        MouseStore.getInstance().unregister(this._onMouseUpdate);
        document.body.removeEventListener('mouseup', this.onMouseUp, true);
        document.body.removeEventListener('mousemove', this.onMouseMove, true);
    }

    recordRelative() {
        this.setState({
            relativeX : this.state.x,
            relativeX2: this.state.x2,
            relativeY : this.state.y,
            relativeY2: this.state.y2
        });
    }

    onNorthResizerMouseDown() {
        this.showPreview();
        this.setState( {
            northResizeActive : true
        });
        this.recordRelative();
    }

    onSouthResizerMouseDown() {
        this.showPreview();
        this.setState( {
            southResizeActive : true
        });
        this.recordRelative();
    }

    onEastResizerMouseDown() {
        this.showPreview();
        this.setState( {
            eastResizeActive : true
        });
        this.recordRelative();
    }

    onWestResizerMouseDown() {
        this.showPreview();
        this.setState( {
            westResizeActive : true
        });
        this.recordRelative();
    }

    onNWResizerMouseDown() {
        this.showPreview();
        this.setState( {
            nwResizeActive : true
        });
        this.recordRelative();
    }

    onNEResizerMouseDown() {
        this.showPreview();
        this.setState( {
            neResizeActive : true
        });
        this.recordRelative();
    }

    onSWResizerMouseDown() {
        this.showPreview();
        this.setState( {
            swResizeActive : true
        });
        this.recordRelative();
    }

    onSEResizerMouseDown() {
        this.showPreview();
        this.setState( {
            seResizeActive : true
        });
        this.recordRelative();
    }

    onNorthResize() {
        let y = this.state.relativeY;
        let diffY = this.state.mouseY - y;
        let newY = y + diffY;
        let newY2 = this.state.relativeY2 - diffY;
        let minHeight = this.getMinHeight();

        if (minHeight > newY2) {
            newY = this.state.relativeY + this.state.relativeY2 - minHeight;
            newY2 = minHeight;
        }

        this.setState({
            previewY : newY,
            previewY2: newY2
        });
    }

    onSouthResize() {
        let diffY = this.state.mouseY - (this.state.relativeY + this.state.relativeY2);
        let newY = this.state.relativeY2 + diffY;
        let minHeight = this.getMinHeight();

        if (minHeight > newY) {
            newY = minHeight;
        }

        this.setState({
            previewY2: newY
        });
    }

    onWestResize() {
        let x = this.state.relativeX;
        let diffX = this.state.mouseX - x;
        let newX = x + diffX;
        let newX2 = this.state.relativeX2 - diffX;
        let minWidth = this.getMinWidth();

        if (minWidth > newX2) {
            newX = this.state.relativeX + this.state.relativeX2 - minWidth;
            newX2 = minWidth;
        }

        this.setState({
            previewX: newX,
            previewX2: newX2
        });
    }

    onEastResize() {
        let diffX = this.state.mouseX - (this.state.relativeX + this.state.relativeX2);
        let newX = this.state.relativeX2 + diffX;
        let minWidth = this.getMinWidth();

        if (minWidth > newX) {
            newX = minWidth;
        }

        this.setState({
            previewX2: newX
        });
    }

    onNEResize() {
        this.onNorthResize();
        this.onEastResize();
    }

    onNWResize() {
        this.onNorthResize();
        this.onWestResize();
    }

    onSEResize() {
        this.onSouthResize();
        this.onEastResize();
    }

    onSWResize() {
        this.onSouthResize();
        this.onWestResize();
    }

    onMouseMove(e) {
        if (!this.isActive()) {
            return;
        }
        
        this.setState({
            mouseX : e.clientX,
            mouseY : e.clientY
        });

        if (this.state.northResizeActive) {
            this.onNorthResize();
        }
        else if (this.state.southResizeActive) {
            this.onSouthResize();
        }
        else if (this.state.westResizeActive) {
            this.onWestResize();
        }
        else if (this.state.eastResizeActive) {
            this.onEastResize();
        }
        else if (this.state.nwResizeActive) {
            this.onNWResize();
        }
        else if (this.state.neResizeActive) {
            this.onNEResize();
        }
        else if (this.state.swResizeActive) {
            this.onSWResize();
        }
        else if (this.state.seResizeActive) {
            this.onSEResize();
        }

        if (this.props.activeResize) {
            window.requestAnimationFrame(() => {
                if (this.props.onResize) {
                    this.props.onResize({
                        x : this.state.previewX,
                        y : this.state.previewY,
                        width: this.state.previewX2,
                        height: this.state.previewY2
                    }, {
                        x : this.state.x,
                        y : this.state.y,
                        width: this.state.x2,
                        height: this.state.y2
                    });
                }
            });
        }
    }

    onMouseUp() {
        if (!this.state.showPreview) {
            //This means resizing has not triggered.
            return;
        }

        this.hidePreview();
        this.setState({
            northResizeActive : false,
            southResizeActive : false,
            westResizeActive: false,
            eastResizeActive : false,
            nwResizeActive : false,
            neResizeActive : false,
            swResizeActive : false,
            seResizeActive : false,
            x : this.state.previewX,
            y : this.state.previewY,
            x2: this.state.previewX2,
            y2: this.state.previewY2
        });
        
        if (this.props.onResize) {
            this.props.onResize({
                x : this.state.previewX,
                y : this.state.previewY,
                width : this.state.previewX2,
                height: this.state.previewY2
            }, {
                x : this.state.x,
                y : this.state.y,
                width: this.state.x2,
                height : this.state.y2
            });
        }
    }

    relayout() {
        let browserWidth = document.body.clientWidth;
        let browserHeight = document.body.clientHeight;

        let originalX  = this.state.x;
        let originalX2 = this.state.x + this.state.x2;
        let originalY  = this.state.y;
        let originalY2 = this.state.y + this.state.y2

        let originalWidth = originalX2 - originalX;
        let originalHeight = originalY2 - originalY;

        let widthPercent = (originalWidth / this.state.lastBrowserWidth);
        // let heightPercent = (originalHeight / this.state.lastBrowserHeight);

        let newWidth = browserWidth * widthPercent;
        // let newHeight = browserHeight * heightPercent;

        this.setState({
            x2 : this.state.x + newWidth
        });

        if (this.props.anchor === 'bottom') {
            this.setState({
                y : browserHeight - originalHeight
            });
        }

        this.setState({
            lastBrowserWidth: document.body.clientWidth,
            lastBrowserHeight: document.body.clientHeight
        });

        if (this.props.onResize) {
            this.props.onResize({
                x : this.state.x,
                y : browserHeight - originalHeight,
                width: this.state.x + newWidth,
                height: this.state.y2
            }, {
                x : this.state.x,
                y : this.state.y,
                width: this.state.x2,
                height: this.state.y2
            });
        }
    }

    showPreview() {
        this.setState({
            previewX : this.state.x,
            previewX2: this.state.x2,
            previewY : this.state.y,
            previewY2: this.state.y2,
            showPreview : true
        });
    }

    hidePreview() {
        this.setState({
            showPreview : false
        });
    }

    setX(x) {
        this.setState({
            x : x,
            previewX : x
        });
    }

    setY(y) {
        this.setState({
            y : y,
            previewY : y
        });
    }

    setWidth(w) {
        this.setState({
            x2: w,
            previewX2: w
        });
    }

    setHeight(h) {
        this.setState({
            y2: h,
            previewY2: h
        });
    }

    getHeight() {
        return this.state.y2;
    }

    getY() {
        return this.state.y;
    }

    _onTransitionEnd(event) {
        if (this.props.onTransitionEnd) {
            this.props.onTransitionEnd(event);
        }
    }

    render() {
        let componentStyle = {
            left : this.state.x,
            width: this.state.x2,
            top: this.state.y,
            height: this.state.y2
        };

        let previewStyle = {
            display: this.state.showPreview && !this.props.disablePreview ? 'block' : 'none',
            left: this.state.previewX,
            width: this.state.previewX2,
            top: this.state.previewY,
            height: this.state.previewY2
        };

        return <div className={`ResizableComponent ${this.props.className ? this.props.className : ''}`} style={componentStyle} onTransitionEnd={this._onTransitionEnd}>
            <div className="preview" style={previewStyle}></div>
            <div style={{display:this.props.disableNW ? 'none' : 'block'}} className="resizer corner nw" onMouseDown={this.onNWResizerMouseDown}></div>
            <div style={{display:this.props.disableNE ? 'none' : 'block'}} className="resizer corner ne" onMouseDown={this.onNEResizerMouseDown}></div>
            <div style={{display:this.props.disableSW ? 'none' : 'block'}} className="resizer corner sw" onMouseDown={this.onSWResizerMouseDown}></div>
            <div style={{display:this.props.disableSE ? 'none' : 'block'}} className="resizer corner se" onMouseDown={this.onSEResizerMouseDown}></div>
            <div style={{display:this.props.disableN  ? 'none' : 'block'}} className="resizer edge n" onMouseDown={this.onNorthResizerMouseDown}></div>
            <div style={{display:this.props.disableW  ? 'none' : 'block'}} className="resizer edge w" onMouseDown={this.onWestResizerMouseDown}></div>
            <div style={{display:this.props.disableE  ? 'none' : 'block'}} className="resizer edge e" onMouseDown={this.onEastResizerMouseDown}></div>
            <div style={{display:this.props.disableS  ? 'none' : 'block'}} className="resizer edge s" onMouseDown={this.onSouthResizerMouseDown}></div>
            {this.props.children}
        </div>;
    }
}

ResizableComponent.propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    defaultX: PropTypes.number,
    defaultX2: PropTypes.number,
    defaultY: PropTypes.number,
    defaultY2: PropTypes.number,
    onResize: PropTypes.func,
    onTransitionEnd: PropTypes.func,
    width: PropTypes.number,
    height: PropTypes.number,
    children: PropTypes.node,
    className: PropTypes.string,
    disablePreview: PropTypes.bool,
    disableNW: PropTypes.bool,
    disableNE: PropTypes.bool,
    disableSW: PropTypes.bool,
    disableSE: PropTypes.bool,
    disableN: PropTypes.bool,
    disableW: PropTypes.bool,
    disableE: PropTypes.bool,
    disableS: PropTypes.bool,
    activeResize: PropTypes.bool,
    anchor: PropTypes.string,
    minWidth: PropTypes.number,
    minHeight: PropTypes.number
};

export { ResizableComponent };
