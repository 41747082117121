let _cache = {};

/**
 * @deprecated Do not use - leaks memory and promotes bad react habits
 */
class ComponentCache {
    constructor() {
        throw new Error('ComponentCache is static and cannot be instantiated');
    }

    static cache(component) {
        _cache[component.getID()] = component;
    }

    static getComponent(id) {
        return _cache[id];
    }
}

export {ComponentCache};
