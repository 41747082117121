'use strict';

import * as React from 'react';

class ComponentContainer extends React.Component {
    constructor(props) {
        super(props);

        this._className = `ComponentContainer ${this.getCssClass()}`;
        this._store = this.getStore();
        this._node = null;

        this.onUpdate = this.onUpdate.bind(this);
    }

    getCssClass() {
        throw new Error('getCssClass is abstract.');
    }

    componentDidMount() {
        this._store.getInstance().register(this.onUpdate);
    }

    componentWillUnmount() {
        this._store.getInstance().unregister(this.onUpdate);
    }

    getStore() {
        throw new Error('getStore is abstract.');
    }

    onUpdate() {
        throw new Error('onUpdate is abstract.');
    }

    _render() {
        throw new Error('_render is abstract.');
    }

    render() {
        return (
            <div className={this._className} ref={(c) => {this._node = c; }}>
                {this._render()}
            </div>
        );
    }
}

export { ComponentContainer };
