
import {TextfieldPropsBuilder} from './TextfieldPropsBuilder';
import {InputType} from '../utils/InputType';

class DecimalTextfieldPropsBuilder extends TextfieldPropsBuilder {
    _getInputType() {
        return InputType.DECIMAL;
    }
}

export {DecimalTextfieldPropsBuilder};
