
import * as React from 'react';
import {TPLogo} from './TPLogo';
import '../style/SplashSpinner.less';

export class SplashSpinner extends React.Component {
    public render(): React.ReactNode {
        return (
            <div className="SplashSpinner">
                <TPLogo />
                <div className="logoutSpinner lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        )
    }
}
