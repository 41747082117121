'use strict';

/* eslint-disable */
const LOWERCASE = [
    'a','b','c','d','e','f','g','h','i','j',
    'k','l','m','n','o','p','q','r','s','t',
    'u','v','w','x','y','z'
];

const UPPERCASE = [
    'A','B','C','D','E','F','G','H','I','J',
    'K','L','M','N','O','P','Q','R','S','T',
    'U','V','W','X','Y','Z'
];

const NUMBERS = [
    '0','1','2','3','4',
    '5','6','7','8','9'
];
/* eslint-enable */

class PasswordValidator {
    constructor() {
        throw new Error('PasswordValidator is static.');
    }

    static isValid(password) {
        let result = PasswordValidator.validate(password);
        return result === PasswordValidator.REASON_OK;
    }

    static validate(password) {
        if (typeof password !== 'string') {
            return PasswordValidator.REASON_UNKNOWN;
        }

        if (!password) {
            return PasswordValidator.REASON_INCORRECT_LENGTH;
        }

        if (password.length < 6 || password.length > 16) {
            return PasswordValidator.REASON_INCORRECT_LENGTH;
        }

        if (password.indexOf(' ') > -1) {
            return PasswordValidator.REASON_CONTAINS_SPACE;
        }

        let hasLowerCase = false;
        let hasUpperCase = false;
        let hasNumber    = false;
        let hasSpecial   = false;

        for (let i = 0; i < LOWERCASE.length; i++) {
            let lowerCaseChar = LOWERCASE[i];
            if (password.indexOf(lowerCaseChar) > -1) {
                hasLowerCase = true;
                break;
            }
        }

        if (!hasLowerCase) return PasswordValidator.REASON_MISSING_LOWERCASE;

        for (let l = 0; l < UPPERCASE.length; l++) {
            let upperCaseChar = UPPERCASE[l];
            if (password.indexOf(upperCaseChar) > -1) {
                hasUpperCase = true;
                break;
            }
        }

        if (!hasUpperCase) return PasswordValidator.REASON_MISSING_UPPERCASE;

        for (let j = 0; j < NUMBERS.length; j++) {
            let numberChar = NUMBERS[j];
            if (password.indexOf(numberChar) > -1) {
                hasNumber = true;
                break;
            }
        }

        if (!hasNumber) return PasswordValidator.REASON_MISSING_NUMBER;

        for (let k = 0; k < password.length; k++) {
            let char = password[k];

            hasSpecial = (
                LOWERCASE.indexOf(char) === -1 &&
                UPPERCASE.indexOf(char) === -1 &&
                NUMBERS.indexOf(char) === -1
            );

            if (hasSpecial) {
                break;
            }
        }

        if (!hasSpecial) return PasswordValidator.REASON_MISSING_SPECIAL;

        return 0;
    }
}

PasswordValidator.REASON_OK                 = 0;
PasswordValidator.REASON_INCORRECT_LENGTH   = 1;
PasswordValidator.REASON_MISSING_LOWERCASE  = 2;
PasswordValidator.REASON_MISSING_UPPERCASE  = 3;
PasswordValidator.REASON_MISSING_NUMBER     = 4;
PasswordValidator.REASON_MISSING_SPECIAL    = 5;
PasswordValidator.REASON_CONTAINS_SPACE     = 6;
PasswordValidator.REASON_UNKNOWN            = 7;

export { PasswordValidator };
