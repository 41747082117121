'use strict';

import { Model } from './Model';

class Position extends Model {
    constructor(data) {
        super(data);
        if (!data) {
            this._data = {
                coords : {}
            };
        }
    }

    getLatitude() {
        return this._data.coords.latitude || 0;
    }

    getLongitude() {
        return this._data.coords.longitude || 0;
    }

    getAltitude() {
        return this._data.coords.altitude || 0;
    }

    getAccuracy() {
        return this._data.coords.accuracy || 0;
    }

    getHeading() {
        return this._data.coords.heading || 0;
    }

    getSpeed() {
        return this._data.coords.speed || 0;
    }

    getTimestamp() {
        return this._data.timestamp || 0;
    }

    isEqualTo(position) {
        if (!(position instanceof Position)) {
            return false;
        }

        return this.getLatitude() === position.getLatitude() &&
            this.getLongitude() === position.getLongitude() &&
            this.getAltitude() === position.getAltitude() &&
            this.getAccuracy() === position.getAccuracy() &&
            this.getHeading() === position.getHeading() && 
            this.getSpeed() === position.getSpeed() &&
            this.getTimestamp() === position.getTimestamp();
    }
}

export { Position };
