'use strict';

import React from 'react';
import {TPComponent} from './TPComponent';
import {IconFactory} from '../factories/IconFactory';
import PropTypes from 'prop-types';
import "../style/HandleBar.less";

const CHEVRON_DIRECTION = { // TO-DO test UP and DOWN and ensure they work properly.
    UP: IconFactory.Icons.CHEVRON_UP,
    RIGHT: IconFactory.Icons.CHEVRON_RIGHT,
    DOWN: IconFactory.Icons.CHEVRON_DOWN,
    LEFT: IconFactory.Icons.CHEVRON_LEFT
};

class HandleBar extends TPComponent {
    constructor(props) {
        super(props);

        this._onClick = this._onClick.bind(this);
    }

    _onClick(event) {
        this.props.onClick(event);
    }

    render() {
        let direction;
        switch (this.props.direction) {
            case CHEVRON_DIRECTION.UP:
                direction = 'up';
                break;
            case CHEVRON_DIRECTION.RIGHT:
                direction = 'right';
                break;
            case CHEVRON_DIRECTION.DOWN:
                direction = 'down';
                break;
            case CHEVRON_DIRECTION.LEFT:
                // fall through to default
            default:
                direction = 'left';
        }
        return <div className={`HandleBar${this.props.isOpen ? ' open' : ''} ${direction}`} onClick={this._onClick}>
            {IconFactory.create(`${this.props.direction ? this.props.direction : CHEVRON_DIRECTION.LEFT}`)}
        </div>;
    }
}

HandleBar.ChevronDirection = CHEVRON_DIRECTION;

HandleBar.propTypes = {
    onClick: PropTypes.func.isRequired,
    direction: PropTypes.string, // See static property ChevronDirection for values.
    isOpen: PropTypes.bool
};

export { HandleBar }
