'use strict';

import { NotificationFactory } from './NotificationFactory';
import { ModalPushAction } from '../actions/ModalPushAction';
import { ConfirmModal } from '../components/ConfirmModal';
import { AlertModal } from '../components/AlertModal';

class DefaultNotificationFactory extends NotificationFactory {
    constructor() { super(); }

    _create(type, props) {
        if (!props) {
            props = {};
        }

        return new Promise((resolve, reject) => {
            switch (type) {
                case NotificationFactory.NOTIFY:
                    // NOTIFY has not been implemented yet because it will require a new modal that accepts buttons dynamically based on array of button text (to match the Cordova based API).
                    // When notify is implemented, ALERT and CONFIRM should be updated to use notify's modal in order to match the cordova based API where ALERT and CONFIRM are just presets of the NOTIFY modal.
                    reject(new Error('DefaultNotificationFactory.NOTIFY is not implemented.'));
                    break;
                case NotificationFactory.ALERT:
                    ModalPushAction.execute({
                        modal: AlertModal,
                        props: {
                            title: props.title,
                            text: props.message,
                            persistent: true,
                            onHide: () => {
                                resolve();
                            }
                        }
                    });
                    break;
                case NotificationFactory.CONFIRM:
                    ModalPushAction.execute({
                        modal: ConfirmModal,
                        props: {
                            title: props.title,
                            text: props.message,
                            persistent: true,
                            cancel: () => {
                                resolve(NotificationFactory.ConfirmResult.DENIED);
                            },
                            confirm: () => {
                                resolve(NotificationFactory.ConfirmResult.CONFIRMED);
                                return Promise.resolve();
                            }
                        }
                    });
                    break;
                default:
                    reject(new Error('DefaultNotificationFactory does not support type: ' + type));
                    break;
            }
        });
    }
}

export { DefaultNotificationFactory };
