'use strict';

// TODO: Move to finterfaces

const MouseButton = {
    LEFT: 0,
    MIDDLE: 1,
    RIGHT : 2
};

export { MouseButton };
