'use strict';

import {Iterator} from './Iterator';
import {ObjectUtils} from './ObjectUtils';

class DictionaryIterator extends Iterator {
    constructor(data) {
        super(Object.keys(ObjectUtils.clone(data)));
        this.dict = data;
    }

    next() {
        let key = super.next();
        return {
            key: key,
            value : this.dict[key]
        };
    }

    previous() {
        let key = super.previous();
        return {
            key: key,
            value: this.dict[key]
        };
    }
}

export { DictionaryIterator };
