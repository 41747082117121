'use strict';

import * as React from 'react';
import {ComparisonParamsBuilder} from '../builders/ComparisonParamsBuilder';
import {Textfield, InputMode} from '../components/Textfield';

export class NumberComparisonParamsBuilder extends ComparisonParamsBuilder {
    // Abstraction for very simple and common UI
    _buildTextfieldAndSpan(value, onChange, editable) {
        if (editable) {
            // We don't validate to ensure the value is an number here. If they don't pass in an valid number; then, the comparison will return false.
            return <Textfield value={value} onChange={onChange} inputMode={InputMode.NUMBER} />
        }
        else {
            return <span>{value}</span>;
        }
    }

    _buildIsBetweenTextfieldAndSpan(start, end, onChange, editable) {
        if (editable) {
            let startField = <Textfield 
                value={start}
                onChange={(value) => {
                    onChange({
                        start: value,
                        end: end
                    });
                }}
                placeholder='start'
                inputMode={InputMode.NUMBER}
            />;
            let endField = <Textfield 
                value={end} 
                onChange={(value) => {
                    onChange({
                        start: start,
                        end: value
                    });
                }}
                placeholder='end'
                inputMode={InputMode.NUMBER}
            />;
            return <p>{startField}{endField}</p>;
        }
        else {
            return <span>{start} and {end}</span>;
        }
    }

    _buildIsEqualTo(value, onChange, editable) {
        return this._buildTextfieldAndSpan(value, onChange, editable);
    }

    _buildIsNotEqualTo(value, onChange, editable) {
        return this._buildTextfieldAndSpan(value, onChange, editable);
    }

    _buildIsLessThan(value, onChange, editable) {
        return this._buildTextfieldAndSpan(value, onChange, editable);
    }

    _buildIsNotLessThan(value, onChange, editable) {
        return this._buildTextfieldAndSpan(value, onChange, editable);
    }

    _buildIsLessThanOrEqualTo(value, onChange, editable) {
        return this._buildTextfieldAndSpan(value, onChange, editable);
    }

    _buildIsNotLessThanOrEqualTo(value, onChange, editable) {
        return this._buildTextfieldAndSpan(value, onChange, editable);
    }

    _buildIsGreaterThan(value, onChange, editable) {
        return this._buildTextfieldAndSpan(value, onChange, editable);
    }

    _buildIsNotGreaterThan(value, onChange, editable) {
        return this._buildTextfieldAndSpan(value, onChange, editable);
    }

    _buildIsGreaterThanOrEqualTo(value, onChange, editable) {
        return this._buildTextfieldAndSpan(value, onChange, editable);
    }

    _buildIsNotGreaterThanOrEqualTo(value, onChange, editable) {
        return this._buildTextfieldAndSpan(value, onChange, editable);
    }

    _buildIsBetween(start, end, onChange, editable) {
        return this._buildIsBetweenTextfieldAndSpan(start, end, onChange, editable);
    }

    _buildIsNotBetween(start, end, onChange, editable) {
        return this._buildIsBetweenTextfieldAndSpan(start, end, onChange, editable);
    }
}
