
import * as React from 'react';
import { Modal } from './Modal';
import {Calendar} from './Calendar';

import "../style/CalendarModal.less";

export class CalendarModal extends Modal {
    constructor(props) {
        super(props);
        this._addClassName('CalendarModal');
    }

    getTitle() {
        return 'Calendar';
    }

    showHeader() {
        return false;
    }

    _render() {
        return (
            <div className="calendar-modal-container">
                <Calendar
                    value={this.props.value}
                    target={this.props.target}
                    onClick={this.props.onClick}
                    onChange={this.props.onChange}
                    detached
                    onClose={() => {
                        this.close();
                    }}
                />
            </div>
        );
    }
}
