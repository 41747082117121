//snaputils rewrite and testing area:
//Rewrite's original implementation
//https://jsfiddle.net/rfjm9hkt/276/
//New Pointer Rewrite:
//https://jsfiddle.net/5Ljzq6ua/

import {ApplicationInstance} from '@totalpave/application-instance';
import {ObjectUtils} from '@totalpave/object';

class SnapUtils {
    static _flipAlignment(alignment) {
        switch (alignment) {
            default:
                ApplicationInstance.getInstance().getLogger().log(`Do not know how to flip alignment ${alignment}`);
                return alignment;
            case SnapUtils.ALIGNMENT_BOT_LEFT:
                return SnapUtils.ALIGNMENT_TOP_LEFT;
            case SnapUtils.ALIGNMENT_BOT_RIGHT:
                return SnapUtils.ALIGNMENT_TOP_RIGHT;
            case SnapUtils.ALIGNMENT_TOP_LEFT:
                return SnapUtils.ALIGNMENT_BOT_LEFT;
            case SnapUtils.ALIGNMENT_TOP_RIGHT:
                return SnapUtils.ALIGNMENT_BOT_RIGHT;
            case SnapUtils.ALIGNMENT_LEFT_TOP:
                return SnapUtils.ALIGNMENT_RIGHT_TOP;
            case SnapUtils.ALIGNMENT_RIGHT_TOP:
                return SnapUtils.ALIGNMENT_LEFT_TOP;
            case SnapUtils.ALIGNMENT_LEFT_BOT:
                return SnapUtils.ALIGNMENT_RIGHT_BOT;
            case SnapUtils.ALIGNMENT_RIGHT_BOT:
                return SnapUtils.ALIGNMENT_LEFT_BOT;
            case SnapUtils.ALIGNMENT_LEFT_CENTER:
                return SnapUtils.ALIGNMENT_RIGHT_CENTER;
            case SnapUtils.ALIGNMENT_RIGHT_CENTER:
                return SnapUtils.ALIGNMENT_LEFT_CENTER;
            case SnapUtils.ALIGNMENT_TOP_CENTER:
                return SnapUtils.ALIGNMENT_BOT_CENTER;
            case SnapUtils.ALIGNMENT_BOT_CENTER:
                return SnapUtils.ALIGNMENT_TOP_CENTER;
        }
    }
    
    static _createPointer(opts) {
        /*
            opts = {
                    size: int, //Required
                subject : HTMLNode, //Required. HTMLNode that this pointer is for.
                color: string //Optional
            }
        */

        if (!opts.size) {
            throw new Error("SnapUtils._createPointer expects size.");
        }
        if (!opts.subject) {
            throw new Error("SnapUtils._createPointer expects subject.");
        }

        let pointer = opts.subject.querySelector("div[data-id=pointer]");
        let createdNewNode = false;
        if (!pointer) {
            pointer = document.createElement("div");
            createdNewNode = true;
            pointer.setAttribute("data-id", "pointer");
            // pointer.style.display = "inline-block";
            // pointer.style.zIndex = "-1";
            // pointer.style.transform = "rotate(45deg)";
            // pointer.style.position = "absolute";
        }
        pointer.className = 'SnapUtils-pointer';
        pointer.style.width = opts.size + "px";
        pointer.style.height = opts.size + "px";
        if (opts.color) {
            pointer.style.backgroundColor = opts.color;
        }
        else {
            delete pointer.style.backgroundColor;
        }

        if (createdNewNode) {
            opts.subject.appendChild(pointer);
        }

        return pointer;
    }

    static _align(subject, target, alignment, distance, pointer) {
        let targetRect = target.getBoundingClientRect();
        let subjectRect = subject.getBoundingClientRect();
        let subjectParentPos = SnapUtils._calculateParentPos(subject);
        let pointerRect = null;

        let pos = {
            x: (0 - subjectParentPos.x), 
            y: (0 - subjectParentPos.y)
        };
        let pointerPos = null;

        if (pointer) {
            let width = window.getComputedStyle(pointer).width;
            let height = window.getComputedStyle(pointer).height;
            width = width.slice(0, width.length - 2);
            height = height.slice(0, height.length - 2);
            pointerRect = {
                width: width,
                height: height
            };
            pointerPos = {
                x: pos.x, 
                y: pos.y
            };
        }

        switch (alignment) {
            case SnapUtils.ALIGNMENT_BOT_LEFT:
                pos.x += targetRect.left;
                //Base
                pos.y += targetRect.top + targetRect.height + distance;
                //Pointer Offset
                if (pointer) {
                    pointerPos.x += targetRect.left + (pointerRect.width / 2);
                    pointerPos.y += targetRect.top + targetRect.height + distance;

                    pos.y += (pointerRect.height / 2);
                }
                break;
            case SnapUtils.ALIGNMENT_BOT_RIGHT:
                pos.x += targetRect.left + targetRect.width - subjectRect.width;
                pos.y += targetRect.top + targetRect.height + distance;
                if (pointer) {
                    pointerPos.x += targetRect.left + targetRect.width - pointerRect.width - (pointerRect.width / 2);
                    pointerPos.y += targetRect.top + targetRect.height + distance;
                    pos.y += (pointerRect.height / 2);
                }
                break;
            case SnapUtils.ALIGNMENT_TOP_RIGHT:
                pos.x += targetRect.left + targetRect.width - subjectRect.width;
                pos.y += targetRect.top - subjectRect.height - distance;

                if (pointer) {
                    pointerPos.x += targetRect.left + targetRect.width - pointerRect.width - (pointerRect.width / 2);
                    pointerPos.y += targetRect.top - pointerRect.height - distance - 2;

                    pos.y -= (pointerRect.height / 2);
                }
                break;
            case SnapUtils.ALIGNMENT_TOP_LEFT:
                pos.x += targetRect.left;
                pos.y += targetRect.top - subjectRect.height - distance;

                if (pointer) {
                    pointerPos.x += targetRect.left + (pointerRect.width / 2);
                    pointerPos.y += targetRect.top - pointerRect.height - distance - 2;

                    pos.y -= (pointerRect.height / 2);
                }
                break;
            case SnapUtils.ALIGNMENT_LEFT_TOP:
                pos.x += targetRect.left - subjectRect.width - distance;
                pos.y += targetRect.top;
                if (pointer) {
                    pointerPos.x += targetRect.left - pointerRect.width - distance - 2;
                    pointerPos.y += targetRect.top + (pointerRect.height / 2);
                    pos.x -= (pointerRect.width / 2);
                }
                break;
            case SnapUtils.ALIGNMENT_RIGHT_TOP:
                pos.x += targetRect.left + targetRect.width + distance;
                pos.y += targetRect.top;
                if (pointer) {
                    pointerPos.x += targetRect.left + targetRect.width + distance;
                    pointerPos.y += targetRect.top + (pointerRect.height / 2);
                    pos.x += (pointerRect.width / 2);
                }
                break;
            case SnapUtils.ALIGNMENT_LEFT_BOT:
                pos.x += targetRect.left - subjectRect.width - distance;
                pos.y += targetRect.top + targetRect.height - subjectRect.height;

                if (pointer) {
                    pointerPos.x += targetRect.left - pointerRect.width - distance - 2;
                    pointerPos.y += targetRect.top + targetRect.height - pointerRect.height - (pointerRect.height / 2);

                    pos.x -= (pointerRect.width / 2);
                }
                break;
            case SnapUtils.ALIGNMENT_RIGHT_BOT:
                pos.x += targetRect.left + targetRect.width + distance;
                pos.y += targetRect.top + targetRect.height - subjectRect.height;
                if (pointer) {
                    pointerPos.x += targetRect.left + targetRect.width + distance;
                    pointerPos.y += targetRect.top + targetRect.height - pointerRect.height - (pointerRect.height / 2);
                    pos.x += (pointerRect.width / 2);
                }
                break;
            case SnapUtils.ALIGNMENT_LEFT_CENTER:
                pos.x += targetRect.left - subjectRect.width - distance;
                pos.y += targetRect.top + (targetRect.height / 2) - (subjectRect.height / 2);
                if (pointer) {
                    pointerPos.x += targetRect.left - pointerRect.width - distance - 2;
                    pointerPos.y += targetRect.top + (targetRect.height / 2) - (pointerRect.height / 2);
                    pos.x -= (pointerRect.width / 2);
                }
                break;
            case SnapUtils.ALIGNMENT_RIGHT_CENTER:
                pos.x += targetRect.left + targetRect.width + distance;
                pos.y += targetRect.top + (targetRect.height / 2) - (subjectRect.height / 2);
                if (pointer) {
                    pointerPos.x += targetRect.left + targetRect.width + distance;
                    pointerPos.y += targetRect.top + (targetRect.height / 2) - (pointerRect.height / 2);
                    pos.x += (pointerRect.width / 2);
                }
                break;
            case SnapUtils.ALIGNMENT_BOT_CENTER:
                pos.x += targetRect.left + (targetRect.width / 2) - (subjectRect.width / 2);
                pos.y += targetRect.top + targetRect.height + distance;
                if (pointer) {
                    pointerPos.x += targetRect.left + (targetRect.width / 2) - (pointerRect.width / 2);
                    pointerPos.y += targetRect.top + targetRect.height + distance;

                    pos.y += (pointerRect.width / 2);
                }
                break;
            case SnapUtils.ALIGNMENT_TOP_CENTER:
                pos.x += targetRect.left + (targetRect.width / 2) - (subjectRect.width / 2);
                pos.y += targetRect.top - subjectRect.height - distance;
                if (pointer) {
                    pointerPos.x += targetRect.left + (targetRect.width / 2) - (pointerRect.width / 2);
                    pointerPos.y += targetRect.top - pointerRect.height - distance - 2;
                    pos.y -= (pointerRect.height / 2);
                }
                break;
            default:
                throw new Error('Alignment not implemented yet.');
        }

        if (pointerPos) {
            pointerPos.x -= pos.x;
            pointerPos.y -= pos.y;
        }

        return {
            subjectPos : pos,
            pointerPos : pointerPos,
            pointer : pointer
        };
    }

    static _isOutOfBounds(rect) {
        let result = {
            outOfBounds:false,
            top: false,
            right: false,
            bottom: false,
            left: false
        };

        if (rect.x < 0) {
            result.outOfBounds = true;
            result.left = true;
        }
        if (rect.x + rect.width > window.innerWidth) {
            result.outOfBounds = true;
            result.right = true;
        }
        if (rect.y < 0) {
            result.outOfBounds = true;
            result.top = true;
        }
        if (rect.y + rect.height > window.innerHeight) {
            result.outOfBounds = true;
            result.bottom = true;
        }
        return result;
    }

    //Calculates the Bounding Client Rect that a node would have after applying a pos object to it.
    static _calculateRect(pos, node) {
        let parentPos = SnapUtils._calculateParentPos(node);
        return {
            x: pos.x + parentPos.x,
            y: pos.y + parentPos.y,
            width: node.getBoundingClientRect().width,
            height: node.getBoundingClientRect().height
        };
    }

    static _snapTo(subject, target, alignment, distance, pointer, hasFlipped) {
        let subjectParentPos = SnapUtils._calculateParentPos(subject);
        let result = {
            subjectPos: {
                x: target.x,
                y: target.y
            },
            pointerPos: {
                x: null,
                y: null
            },
            pointer
        };
        let pointerRect = null;
        let pos = {
            x: (0 - subjectParentPos.x), 
            y: (0 - subjectParentPos.y)
        };
        let subjectRect = subject.getBoundingClientRect();

        if (pointer) {
            let width = window.getComputedStyle(pointer).width;
            let height = window.getComputedStyle(pointer).height;
            width = width.slice(0, width.length - 2);
            height = height.slice(0, height.length - 2);
            pointerRect = {
                width: width,
                height: height
            };
            result.pointerPos = {
                x: pos.x, 
                y: pos.y
            };
        }

        switch (alignment) {
            case SnapUtils.ALIGNMENT_BOT_LEFT:
                pos.x += target.x;
                pos.y += target.y + distance;
                if (pointer) {
                    result.pointerPos.x += target.x + (pointerRect.width / 2);
                    result.pointerPos.y += target.y + distance;
                    pos.y += pointerRect.height / 2;
                }
                break;
            case SnapUtils.ALIGNMENT_BOT_RIGHT:
                pos.x += target.x - subjectRect.width;
                pos.y += target.y + distance;
                if (pointer) {
                    result.pointerPos.x += target.x - pointerRect.width - (pointerRect.width / 2);
                    result.pointerPos.y += target.y + distance;
                    pos.y += pointerRect / 2;
                }
                break;
            case SnapUtils.ALIGNMENT_TOP_RIGHT:
                pos.x += target.x - subjectRect.width;
                pos.y += target.y - distance;
                if (pointer) {
                    result.pointerPos.x += target.x - pointerRect.width - (pointerRect.width / 2);
                    result.pointerPos.y += target.y - pointerRect.height - distance - 2;
                    pos.y -= pointerRect.height / 2;
                }
                break;
            case SnapUtils.ALIGNMENT_TOP_LEFT:
                pos.x += target.x;
                pos.y += target.y - subjectRect.height - distance;
                if (pointer) {
                    result.pointerPos.x += target.x + (pointerRect.width / 2);
                    result.pointerPos.y += target.y - pointerRect.height - distance - 2;
                    pos.y -= pointerRect.height / 2;
                }
                break;
            case SnapUtils.ALIGNMENT_LEFT_TOP:
                pos.x += target.x - subjectRect.width - distance;
                pos.y += target.y;
                if (pointer) {
                    result.pointerPos.x += target.x - pointerRect.width - distance - 2;
                    result.pointerPos.y += target.y + (pointerRect.height / 2);
                    pos.x += pointerRect.width / 2;
                }
                break;
            case SnapUtils.ALIGNMENT_RIGHT_TOP:
                pos.x += target.x + distance;
                pos.y += target.y;
                if (pointer) {
                    result.pointerPos.x += target.x + distance;
                    result.pointerPos.y += target.y + (pointerRect.height / 2);
                    pos.x += pointerRect.width / 2;
                }
                break;
            case SnapUtils.ALIGNMENT_LEFT_BOT:
                pos.x += target.x - subjectRect.width - distance;
                pos.y += target.y + subjectRect.height;
                if (pointer) {
                    result.pointerPos.x += target.x - pointerRect.width - distance - 2;
                    result.pointerPos.y += target.y + pointerRect.height - (pointerRect.height / 2);
                    pos.x -= pointerRect.width / 2;
                }
                break;
            case SnapUtils.ALIGNMENT_RIGHT_BOT:
                pos.x += target.x + distance;
                pos.y += target.y - subjectRect.height;
                if (pointer) {
                    result.pointerPos.x += target.x + distance;
                    result.pointerPos.y += target.y - pointerRect.height - (pointerRect.height / 2);
                    pos.x += pointerRect.width / 2;
                }
                break;
            case SnapUtils.ALIGNMENT_LEFT_CENTER:
                pos.x += target.x - subjectRect.width - distance;
                pos.y += target.y - (subjectRect.height / 2);
                if (pointer) {
                    result.pointerPos.x += target.x - pointerRect.width - distance - 2;
                    result.pointerPos.y += target.y - (pointerRect.height / 2);
                    pos.x -= pointerRect.width / 2;
                }
                break;
            case SnapUtils.ALIGNMENT_RIGHT_CENTER:
                pos.x += target.x + distance;
                pos.y += target.y - (subjectRect.height / 2);
                if (pointer) {
                    result.pointerPos.x += target.x + distance;
                    result.pointerPos.y += target.y - (pointerRect.height / 2);
                    pos.x += pointerRect.width / 2;
                }
                break;
            case SnapUtils.ALIGNMENT_BOT_CENTER:
                pos.x += target.x - (subjectRect.width / 2);
                pos.y += target.y + distance;
                if (pointer) {
                    result.pointerPos.x += target.x - (pointerRect.width / 2);
                    result.pointerPos.y += target.y + distance;
                    pos.y += pointerRect.width / 2;
                }
                break;
            case SnapUtils.ALIGNMENT_TOP_CENTER:
                pos.x += target.x - (subjectRect.width / 2);
                pos.y += target.y - subjectRect.height - distance;
                if (pointer) {
                    result.pointerPos.x += target.x - (pointerRect.width / 2);
                    result.pointerPos.y += target.y - pointerRect.height - distance - 2;
                    pos.y -= pointerRect.height / 2;
                }
                break;
            default: throw new Error('Alignment not supported.');
        }

        if (result.pointerPos) {
            result.pointerPos.x -= pos.x;
            result.pointerPos.y -= pos.y;
        }

        result.subjectPos = pos;

        let alignedVertically = alignment.indexOf(BOT) === 0 || alignment.indexOf(TOP) === 0;
        let screenPadding = 10;
        let rect = SnapUtils._calculateRect(result.subjectPos, subject)
        let oob = SnapUtils._isOutOfBounds(rect);
        let flipResult;
        let holder;

        if (alignedVertically) {
            if ((oob.top || oob.bottom) && !hasFlipped) {
                alignment = SnapUtils._flipAlignment(alignment);
                flipResult = SnapUtils._snapTo(subject, target, alignment, distance, pointer, true);

                result.subjectPos.y = flipResult.subjectPos.y;
                if (pointer) {
                    result.pointerPos.y = flipResult.pointerPos.y;
                }
            }

            if (oob.right) {
                holder = result.subjectPos.x;
                result.subjectPos.x = window.innerWidth - rect.width - subjectParentPos.x - screenPadding;
                if (pointer) {
                    result.pointerPos.x += (holder - result.subjectPos.x);
                }
            }
            if (oob.left) {
                holder = result.subjectPos.x;
                result.subjectPos.x = 0 - subjectParentPos.x + screenPadding;
                if (pointer) {
                    result.pointerPos.x += (holder - result.subjectPos.x);
                }
            }
        }
        else {
            if ((oob.right || oob.left) && !hasFlipped) {
                alignment = SnapUtils._flipAlignment(alignment);
                flipResult = SnapUtils._snapTo(subject, target, alignment, distance, pointer, true);
                result.subjectPos.x = flipResult.subjectPos.x;
                if (pointer) {
                    result.pointerPos.x = flipResult.pointerPos.x;
                }
            }
            
            if (oob.top) {
                holder = result.subjectPos.y;
                result.subjectPos.y = 0 - subjectParentPos.y + screenPadding;
                if (pointer) {
                    result.pointerPos.y += (holder - result.subjectPos.y);
                }

            }
            if (oob.bottom) {
                holder = result.subjectPos.y;
                result.subjectPos.y = window.innerHeight - rect.height - subjectParentPos.y - screenPadding;
                if (pointer) {
                    result.pointerPos.y += (holder - result.subjectPos.y);
                }
            }
        }

        return result;
    }

    static _calculateAlignmentPos(subject, target, alignment, distance, pointer) {
        let result = SnapUtils._align(subject, target, alignment, distance, pointer);
        let flipResult;
        let screenPadding = 10;
        let rect = SnapUtils._calculateRect(result.subjectPos, subject);
        let parentPos = SnapUtils._calculateParentPos(subject);
        let oob = SnapUtils._isOutOfBounds(rect);
        let alignedVertically = alignment.indexOf(BOT) === 0 || alignment.indexOf(TOP) === 0;
        let holder;

        if (alignedVertically) {
            if (oob.top || oob.bottom) {
                alignment = SnapUtils._flipAlignment(alignment);
                flipResult = SnapUtils._align(subject, target, alignment, distance, pointer);

                result.subjectPos.y = flipResult.subjectPos.y;
                if (pointer) {
                    result.pointerPos.y = flipResult.pointerPos.y;
                }
            }

            //push
            if (oob.right) {
                //right = renderedX + subjectWidth
                //We want right = viewportWidth.
                //renderedX = cssLeft + parentX
                //right = cssLeft + parentX + subjectWidth
                //We can change cssLeft
                //cssLeft = right - parentX - subjectwidth

                holder = result.subjectPos.x;
                result.subjectPos.x = window.innerWidth - rect.width - parentPos.x - screenPadding;
                if (pointer) {
                    result.pointerPos.x += (holder - result.subjectPos.x);
                }
            }
            if (oob.left) {
                //RenderedX = CSSLeft + SubjectParentDistanceFromLeftEdge
                //We want RenderedX = 0;
                //The only thing we can change is CSSLeft
                //CSSLeft = 0 - SubjectParentDistanceFromLeftEdge;
                //subjectPos.x = 0 - parentPos.x

                holder = result.subjectPos.x;
                result.subjectPos.x = 0 - parentPos.x + screenPadding;
                if (pointer) {
                    result.pointerPos.x += (holder - result.subjectPos.x);
                }
            }
        }
        else {
            //push
            if (oob.top) {
                holder = result.subjectPos.y;
                result.subjectPos.y = 0 - parentPos.y + screenPadding;
                if (pointer) {
                    result.pointerPos.y += (holder - result.subjectPos.y);
                }

            }
            if (oob.bottom) {
                holder = result.subjectPos.y;
                result.subjectPos.y = window.innerHeight - rect.height - parentPos.y - screenPadding;
                if (pointer) {
                    result.pointerPos.y += (holder - result.subjectPos.y);
                }
            }

            //flip
            if (oob.right || oob.left) {
                alignment = SnapUtils._flipAlignment(alignment);
                flipResult = SnapUtils._align(subject, target, alignment, distance, pointer);
                result.subjectPos.x = flipResult.subjectPos.x;
                if (pointer) {
                    result.pointerPos.x = flipResult.pointerPos.x;
                }
            }
        }

        return result;
    }

    static _calculateParentPos(node) {
        let pos = {x:0, y:0};
        let current = node.offsetParent;
        if (current) {
            do {
                pos.x += current.offsetLeft;
                pos.y += current.offsetTop;
                current = current.offsetParent;
            } while (current)
        }
        return pos;
    }
    
    static snap(opts) {
        /*
            opts = {
                subject : HTMLElement, //Required
                target : HTMLElement | {x:number,y:number},  //Required
                alignment : string, //Required. See SnapUtils ALIGNMENT constants
                distance : int, //Defaults to 0
                pointerOpts : { //Optional. 
                    color : string, //optional, any value valid for css background-color works here
                    size : int //optional
                    //Will hold properties specific to Pointers if ever required.
                }
            }
        */
        if (isNaN(opts.distance) || opts.distance === null) {
            opts.distance = 0;
        }

        if (opts.pointerOpts) {
            if (!opts.pointerOpts.size) {
                opts.pointerOpts.size = 10;
            }

            if (!opts.pointerOpts.color) {
                opts.pointerOpts.color = window.getComputedStyle(opts.subject).borderColor;
            }
            opts.pointerOpts.subject = opts.subject;
        }

        let result;
        let pointer = opts.pointerOpts ? SnapUtils._createPointer(opts.pointerOpts) : null; 
        if (opts.target instanceof HTMLElement) {
            result = SnapUtils._calculateAlignmentPos(opts.subject, opts.target, opts.alignment, opts.distance, pointer);
        }
        else {
            if (ObjectUtils.isVoid(opts.target.x) || ObjectUtils.isVoid(opts.target.y)) {
                throw new Error('Target must be HTMLElement, or an object with "x" and "y" properties as numbers.');
            }
            result = SnapUtils._snapTo(opts.subject, opts.target, opts.alignment, opts.distance, pointer);
        }
        opts.subject.style.left = Math.floor(result.subjectPos.x) + "px";
        opts.subject.style.top = Math.floor(result.subjectPos.y) + "px";
        opts.subject.style.position = "absolute";

        if (opts.pointerOpts) {
            result.pointer.style.left = Math.floor(result.pointerPos.x) + "px";
            result.pointer.style.top = Math.floor(result.pointerPos.y) + "px";
            result.pointer.style.position = "absolute";
        }
    }
}

const BOT = 'bot';
const TOP = 'top';
const LEFT = 'left';
const RIGHT = 'right';

SnapUtils.ALIGNMENT_BOT_LEFT = BOT + 'left';
SnapUtils.ALIGNMENT_BOT_RIGHT = BOT + 'right';
SnapUtils.ALIGNMENT_TOP_LEFT = TOP + 'left';
SnapUtils.ALIGNMENT_TOP_RIGHT = TOP + 'right';

SnapUtils.ALIGNMENT_LEFT_TOP = LEFT + 'top';
SnapUtils.ALIGNMENT_RIGHT_TOP = RIGHT + 'top';
SnapUtils.ALIGNMENT_LEFT_BOT = LEFT + 'bot';
SnapUtils.ALIGNMENT_RIGHT_BOT = RIGHT + 'bot';

SnapUtils.ALIGNMENT_LEFT_CENTER = LEFT + 'center';
SnapUtils.ALIGNMENT_RIGHT_CENTER = RIGHT + 'center';
SnapUtils.ALIGNMENT_BOT_CENTER = BOT + 'center';
SnapUtils.ALIGNMENT_TOP_CENTER = TOP + 'center';

export { SnapUtils };
