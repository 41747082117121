'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';

import { Progressbar as ProgressBar } from './Progressbar';

import '../style/CircleProgressBar.less';

// TODO: Can be moved into ui-progress

class CircleProgressBar extends React.Component {
    constructor(props) {
        super(props);
    }

    getClassName() {
        return `CircleProgressBar`;
    }

    render() {
        return <ProgressBar
            className={`${this.getClassName()} ${this.props.className} ${this.props.value === this.props.max ? 'completed' : ''}`}
            min={this.props.min}
            max={this.props.max}
            value={this.props.value}
            direction={ProgressBar.DIRECTIONS.UP}
            showWorkingIndicator={true}
        />;
    }
}

CircleProgressBar.propTypes = {
    className: PropTypes.string,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
}

export { CircleProgressBar };
