'use strict';

import {Store} from '@totalpave/store';

let _instance = null;

export class ToolTipStore extends Store {
    constructor() {
        super();
        this._tip = null;
    }

    static getInstance() {
        if (!_instance) {
            _instance = new ToolTipStore();
        }
        return _instance;
    }

    getTip() {
        return this._tip;
    }

    _update(action) {
        switch (action.getTag()) {
            case 'show-tool-tip':
                this._tip = action.getData();
                return true;
        }
        return false;
    }
}
