
import {AbstractScreenOrientation} from './AbstractScreenOrientation';
import { OrientationType } from './OrientationType';

/**
 * A Screen Orientation API that uses the standard browser W3C
 * implementation to source orientation data and updates.
 */
export class W3CScreenOrientation extends AbstractScreenOrientation {
    
    private static $instance: W3CScreenOrientation;

    private constructor() {
        super();
        console.log('Using W3C Orientation API');
    }

    public static isSupported(): boolean {
        return !!window.screen.orientation;
    }

    protected override _getPhysicalOrientation(): OrientationType {
        return <OrientationType>window.screen.orientation.type;
    }

    protected override _initListener(): void {
        let self: W3CScreenOrientation = this;
        window.screen.orientation.addEventListener('change', (e) => {
            self._notify((<any>e.target).type, false);
        });
    }

    public static getInstance(): W3CScreenOrientation {
        if (!W3CScreenOrientation.$instance) {
            W3CScreenOrientation.$instance = new W3CScreenOrientation();
        }

        return W3CScreenOrientation.$instance;
    }
}
