'use strict';

import {DateFactory} from './DateFactory';

/**
 * @deprecated Use @totalpave/dates JSDateFactory instead.
 */
class JSDateFactory extends DateFactory {
    create() {
        return new Date(...arguments);
    }
}

export { JSDateFactory };
