'use strict';

class UIFactory {
    // eslint-disable-next-line
    create(component, props) {
        throw new Error('UIFactory.create is abstract.');
    }
}

UIFactory.TABLE                         = 0x0001;
UIFactory.MESSAGE                       = 0x0002;
UIFactory.PAGINATED_TABLE               = 0x0003;

export { UIFactory };
export default UIFactory;
