
export class AnchorPosition {
    static TOP_RIGHT = 0;
    static TOP_LEFT = 1;
    static TOP_CENTER = 2;
    
    static LEFT_TOP = 3;
    static LEFT_CENTER = 4;
    static LEFT_BOTTOM = 5;

    static BOTTOM_LEFT = 6;
    static BOTTOM_CENTER = 7;
    static BOTTOM_RIGHT = 8;

    static RIGHT_TOP = 9;
    static RIGHT_CENTER = 10;
    static RIGHT_BOTTOM = 11;
}
