'use strict';

import * as React from 'react';
import {Component} from 'react';
import PropTypes from 'prop-types';
import {IconFactory} from '../factories/IconFactory';
import {SnapUtils} from '../utils/SnapUtils';
import {ShowToolTip} from '../actions/ShowToolTip';
import {ToolTip} from '../components/ToolTip';
import "../style/InfoIcon.less";

export class InfoIcon extends Component {
    constructor(props) {
        super(props);
        this._node = null;
    }

    isVisible() {
        return this.props.visible === undefined || this.props.visible;
    }

    render() {
        return (
            <div 
                className={`InfoIcon ${this.isVisible() ? 'visible' : ''}${this.props.className ? ' ' + this.props.className : ''}`}
                ref={(n) => { this._node = n; }}
                onMouseEnter={() => {
                    ShowToolTip.execute({
                        toolTip: (
                            <ToolTip
                                anchor={this.props.anchor || this._node}
                                alignment={this.props.tooltipAlignment || SnapUtils.ALIGNMENT_RIGHT_CENTER}
                                className={this.props.tooltipClassName}
                                pointerSize={this.props.tooltipPointerSize}
                                distance={this.props.tooltipDistance}
                            >
                                {this.props.children}
                            </ToolTip>
                        )
                    });
                }}
                onMouseLeave={(e) => {
                    if (!this._isInTooltip(e.relatedTarget)) {
                        ShowToolTip.execute({toolTip: null});
                    }
                }}
            >
                {IconFactory.create(this.props.icon || IconFactory.Icons.INFO)}
            </div>
        );
    }

    _isInTooltip(target) {
        do {
            if (target && target.className && target.className.indexOf('ToolTip') > -1) {
                return true;
            }
        } while (target = target.parentNode); // eslint-disable-line no-cond-assign
        return false;
    }
}

InfoIcon.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    tooltipAlignment: PropTypes.string,
    anchor: PropTypes.instanceOf(HTMLElement),
    tooltipClassName: PropTypes.string,
    tooltipPointerSize: PropTypes.number,
    tooltipDistance: PropTypes.number,
    icon: PropTypes.string, // Value should come from IconFactory.Icons
    visible: PropTypes.bool
};
