
import {DataType} from '@totalpave/interfaces';
import {ObjectUtils} from '@totalpave/object';

export class DataParser {
    constructor() {}

    static parse(type, value) {
        if (ObjectUtils.isVoid(value)) {
            return value;
        }

        switch (type) {
            case DataType.NUMBER: return parseFloat(value);
            case DataType.STRING: return value;
            case DataType.BOOLEAN: return this._parseBoolean(value);
            case DataType.DATE: return this._parseDate(value);
            case DataType.JSON: return JSON.parse(value);
        }
    }

    static _parseDate(value) {
        if (/^-?(0|[1-9]\d*)(\.\d+)?$/.test(value)) {
            return new Date(parseFloat(value));
        }
        else {
            return new Date(value);
        }
    }

    static _parseBoolean(value) {
        if (value.toLowerCase() === 'true') {
            return true;
        }
        else if (value.toLowerCase() === 'false') {
            return false;
        }
        else {
            return !!(parseInt(value));
        }
    }
}
