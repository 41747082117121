
import { InputType } from '../utils/InputType';

class TextfieldPropsBuilder {
    constructor() {
        this._props = {
            //// React
            // key no default value, setter will exist

            //// Generic
            // Handlers
            onSubmit: null,
            onFocus: null,
            onBlur: null,
            onChange: null,
            // Config
            autoSelect: false,
            autoFocus: false,
            className: null,
            title: null,
            placeholder: null,
            type: this._getInputType(),
            disabled: false,
            readonly: false,
            pattern: "",
            hidePlaceholderDefaultValue: true,
            maxLength: null,

            // Value
            value: "",
            defaultValue: "",

            //// Numeric
            step: null,

            ...this._getAdditionalDefaultProps()
        };
    }

    _getInputType() {
        return InputType.TEXT;
    }

    // Override to change or add default props.
    // You must provide necessary setters that modify this._props and return this when adding properties.
    _getAdditionalDefaultProps() {
        return {};
    }

    setKey(str) {
        this._props.key = str;
        return this;
    }

    setOnSubmit(func) {
        this._props.onSubmit = func;
        return this;
    }

    setOnFocus(func) {
        this._props.onFocus = func;
        return this;
    }

    setOnBlur(func) {
        this._props.onBlur = func;
        return this;
    }

    setOnChange(func) {
        this._props.onChange = func;
        return this;
    }

    setAutoSelect(bool) {
        this._props.autoSelect = bool;
        return this;
    }

    setAutoFocus(bool) {
        this._props.autoFocus = bool;
        return this;
    }

    setClassName(str) {
        this._props.className = str;
        return this;
    }

    setTitle(str) {
        this._props.title = str;
        return this;
    }

    setPlaceholder(str) {
        this._props.placeholder = str;
        return this;
    }

    setDisabled(bool) {
        this._props.disabled = bool;
        return this;
    }

    setReadonly(bool) {
        this._props.readonly = bool;
        return this;
    }

    setPattern(str) {
        this._props.pattern = str;
        return this;
    }

    setHidePlaceholderDefaultValue(bool) {
        this._props.hidePlaceholderDefaultValue = bool;
        return this;
    }

    setMaxLength(length) {
        this._props.maxLength = length;
        return this;
    }

    // Type depends on Textfield type, i.x numerical textfields would want a numerical value.
    setValue(val) {
        this._props.value = val;
        return this;
    }
    setDefaultValue(val) {
        this._props.defaultValue = val;
        return this;
    }

    // e.x: "0.01"
    setStep(str) {
        this._props.step = str;
        return this;
    }

    build() {
        return this._props;
    }
}

export {TextfieldPropsBuilder};
