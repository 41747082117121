
import {DataType} from '@totalpave/interfaces';
import {StringComparisonParamsBuilder} from '../builders/StringComparisonParamsBuilder';
import {DateComparisonParamsBuilder} from '../builders/DateComparisonParamsBuilder';
import {BooleanComparisonParamsBuilder} from '../builders/BooleanComparisonParamsBuilder';
import {NumberComparisonParamsBuilder} from '../builders/NumberComparisonParamsBuilder';
import {ListComparisonParamsBuilder} from '../builders/ListComparisonParamsBuilder';
import {SelectComparisonParamsBuilder} from '../builders/SelectComparisonParamsBuilder';

export class ComparisonUIBuilderFactory {
    create(type, extra) {
        switch (type) {
            default:
                throw new Error('Unhandled type: ' + type);
            case DataType.STRING:
                return new StringComparisonParamsBuilder(extra);
            case DataType.NUMBER:
                return new NumberComparisonParamsBuilder(extra);
            case DataType.DATE:
                return new DateComparisonParamsBuilder(extra);
            case DataType.BOOLEAN:
                return new BooleanComparisonParamsBuilder(extra);
            case DataType.LIST:
                return new ListComparisonParamsBuilder(extra);
            case DataType.SELECT:
                return new SelectComparisonParamsBuilder(extra);
        }
    }
}
