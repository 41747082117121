'use strict';

import {Action} from './Action';

class ClearProgressActionSingleton extends Action {
    constructor() {
        super();
    }

    getTag() {
        return 'clear_progress_action';
    }

    _shouldDispatchOnError() {
        return false;
    }

    _execute({id} = {}) {
        return Promise.resolve(id);
    }
}

const ClearProgressAction = new ClearProgressActionSingleton();

export { ClearProgressAction };
