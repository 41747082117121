
// Option is the original import but this would cause hard-to-debug code issue when usages forget to import Option as you can create an HTML Option Element by doing new Option.
// Option was renamed to SelectOption to work around this.
// Option is still available as an export for backwards compatibility.
// When we create a sub-module that will hold the SelectOption class, do NOT bring over the Option export. Only bring over the SelectOption export. 
import { SelectOption as Option} from './SelectOption';

/**
 * @deprecated Use SelectOption instead
 */
export { Option };
