
import React from 'react';
import PropTypes from 'prop-types';
import {TPComponent} from './TPComponent';
import {IconFactory} from '../factories/IconFactory';
import {Label} from './Label';
import {Calendar} from './Calendar';
import {DateUtils} from '../utils/DateUtils';
import {DateFormatter} from '../utils/DateFormatter';
import {ModalPushAction} from '../actions/ModalPushAction';
import {CalendarModal} from './CalendarModal';
import {ApplicationInstance} from '@totalpave/application-instance';
import {MouseStore} from '../store/MouseStore';
import "../style/Textfield.less";
import "../style/DatePicker.less";
import { Browser } from '../utils/Browser';

export class DatePicker extends TPComponent {
    constructor(props) {
        super(props);

        this._calendar = null;
        this._onCalendarChange = this._onCalendarChange.bind(this);
    }

    _initState(props) {
        let state = super._initState(props);
        let date = props.value || props.defaultValue || ApplicationInstance.getInstance().getDateFactory().create();

        if (!DateUtils.isValid(date)) {
            date = ApplicationInstance.getInstance().getDateFactory().create();
        }

        state.date = date;

        return state;
    }

    static getDerivedStateFromProps(nextProps) {
        if (nextProps.value && DateUtils.isValid(nextProps.value)) {
            return {
                date: nextProps.value
            };
        }

        return null;
    }

    shouldUseDetachedCalendar() {
        if (Browser.isPhone()) {
            return true;
        }

        return this.props.useDetachedCalendar;
    }

    showCalendar(activeDate) {
        if (this.shouldUseDetachedCalendar()) {
            ModalPushAction.execute({
                modal: CalendarModal,
                props: {
                    // target: this,
                    target: this._getTargetPos(),
                    value: activeDate,
                    onChange: this._onCalendarChange
                }
            });
        }
        else {
            this._calendar.open(this._getTargetPos());
        }
    }

    _getTargetPos() {
        return {
            x: MouseStore.getInstance().getX(),
            y: MouseStore.getInstance().getY()
        };
    }

    _onCalendarChange(date) {
        this.setState({
            date: date
        });
        this.props.onChange && this.props.onChange(date);
    }

    render() {
        let cal = null;
        if (!this.shouldUseDetachedCalendar()) {
            cal = (
                <Calendar 
                    ref={(n) => { this._calendar = n; }} 
                    target={this._getTargetPos()}
                    value={this.state.date}
                    onChange={this._onCalendarChange}
                />
            );
        }

        return (
            <div 
                className="DatePicker"
                ref={(n) => {this._setNode(n); }} 
                onClick={() => {
                    this.showCalendar(this.state.date);
                }}
            >
                <Label className="date-label" text={DateFormatter.format(this.state.date, this.props.dateFormat)} />
                {IconFactory.create(IconFactory.Icons.CALENDAR)}
                {cal}
            </div>
        );
    }
}

DatePicker.propTypes = {
    value: PropTypes.instanceOf(Date),
    defaultValue: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
    
    /**
     * If enabled, use a calendar modal rather than a snapped popup calendar.
     * Ignored and always enabled on phone devices.
     */
    useDetachedCalendar: PropTypes.bool,
    
    dateFormat: PropTypes.string
};
