'use strict';

import * as React from 'react';

import {MenuItem} from './MenuItem';
import {MenuBuilder} from '../builders/MenuBuilder';
import "../style/GroupedMenuItem.less";

export class GroupedMenuItem extends MenuItem {
    constructor(props) {
        super(props);
        this._builder = new MenuBuilder();
    }

    _getClassName() {
        return 'GroupedMenuItem';
    }

    _renderContent() {
        let option = this.props.option;

        let items = [];

        let options = option.getOptions();

        for (let i = 0; i < options.length; i++) {
            let o = options[i];
            items.push(this._builder.build({
                menu: this.props.menu,
                key: i,
                highlight: o.isHighlight(),
                menuID: this.props.id,
                width: this.props.width,
                option: o,
                onClick: this._onItemClick,
                parentMenuID: this.props.parentMenuID || null
            }));
        }

        return (
            <div className="grouped-container">
                {this.props.option.isHeaderVisible() ? <div className="menu-text">{this.props.option.getText()}</div> : null}
                <div className="grouped-items">
                    {items}
                </div>
            </div>
        );
    }
}
