
export class AbstractCrumbBuilder {
    constructor() {}

    _build(crumbs, activeIndex, breadcrumb) {
        throw new Error('AbstractCrumbBuilder._build is abstract');
    }

    build(crumbs, activeIndex, breadcrumb) {
        return this._build(crumbs, activeIndex, breadcrumb);
    }
}
