'use strict';

import React from 'react';
import {ComponentContainer} from '../components/ComponentContainer';
import {ModalStore} from '../store/ModalStore';
import "../style/ModalContainer.less";

class ModalContainer extends ComponentContainer {
    constructor(props) {
        super(props);
        this.state = {
            modals : []
        };
    }

    componentDidUpdate() {}

    getCssClass() {
        return 'ModalContainer';
    }

    getStore() {
        return ModalStore;
    }

    onUpdate() {
        this.setState({
            modals : ModalStore.getInstance().getModals()
        });
    }

    _render() {
        return this.state.modals.map((modal) => {
            let Modal = modal.modal;
            return <Modal key={modal.props.key} {...modal.props} />;
        });
    }
}

export { ModalContainer };
