'use strict';

import {Store} from '@totalpave/store';
import { Browser } from '../utils/Browser';
import { Version } from '../utils/Version';
import { ApplicationInstance } from '@totalpave/application-instance';

let _instance = null;

export class MetadataStore extends Store {
    constructor() {
        super();

        this._bootLocation = window.location.href;
        this._version = null; //new Version(B.version);
        this._latestVersion = null;
        this._packageName = null;
        this._debug = null;
        this._appName = null;
        this._versionCode = null;
        this._data = {}; //dictionaty of meta data.
        this._appGeometry = {
            width: 0,
            height: 0
        };
    }

    static getInstance() {
        if (!_instance) {
            _instance = new MetadataStore();
        }
        return _instance;
    }

    getBootLocation() {
        ApplicationInstance.getInstance().getLogger().deprecated();
        ApplicationInstance.getInstance().getLogger().warn("MetadataStore.getBootLocation is unsafe to use due to an flawed implementation. It is currently being deprecated in favor of use Application.getHomeURL and Application.getLoginURL. Please update your code immediately.");
        return this._bootLocation;
    }

    getAppGeometry() {
        //Return a new object
        return {
            width : this._appGeometry.width,
            height: this._appGeometry.height
        };
    }

    getCurrentVersion() {
        if (!this._version) {
            this._version = new Version(BuildInfo.version);
        }
        return this._version;
    }

    getLatestVersion() {
        return this._latestVersion;
    }

    getPackageID() {
        if (Browser.getOS() === Browser.IOS) {
            return 'id' + this._data["APPLE_ID"].APPLE_ID;
        }
        else {
            return this.getPackageName();
        }
    }

    getPackageName() {
        if (!this._packageName) {
            this._packageName = BuildInfo.packageName;
        }
        return this._packageName;
    }

    isDebugMode() {
        if (this._debugMode === null) {
            this._debugMode = BuildInfo.debug;
        }
        return this._debugMode;
    }

    getVersionCode() {
        if (!this._versionCode) {
            this._versionCode = BuildInfo.versionCode;
        }

        return this._versionCode;
    }

    getAppName() {
        if (!this._appName) {
            this._appName = BuildInfo.name;
        }

        return this._appName;
    }

    _update(actionData) {
        let data;
        switch (actionData.getTag()) {
            case 'version_check':
                data = actionData.getData();
                if (data.version) {
                    this._latestVersion = new Version(data.version);
                }
                else {
                    return false;
                }

                return true;
            case 'app_geometry_update':
                data = actionData.getData();
                this._appGeometry.width = data.width;
                this._appGeometry.height = data.height;
                return true;
            case 'set_metadata':
                data = actionData.getData();
                this._data[data.key] = data.value;
                return true;
        }

        return false;
    }
}
