'use strict';

import * as React from 'react';
import { BaseScreen } from './BaseScreen';
import { LoginForm } from './LoginForm';

class LoginScreen extends BaseScreen {
    constructor(props) {
        super(props);

        this._onLoginHandler = this._onLoginHandler.bind(this);
    }

    getClassName() {
        return 'LoginScreen';
    }

    // Override if needed
    _onLoginHandler() {}

    _render() {
        return <LoginForm {...this.props} onLoginHandler={this.props._onLoginHandler} />;
    }
}

export { LoginScreen };
