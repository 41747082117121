
import {Color} from '../utils/Color';
import {Unit} from '../utils/Unit';
import {UserStore} from '../store/UserStore';
import {PreferenceStore} from '../store/PreferenceStore';
import {SaveOrganizationPreference} from '../actions/SaveOrganizationPreference';
import {DeleteOrganizationPreference} from '../actions/DeleteOrganizationPreference';
import {DrivenDirection} from '@totalpave/interfaces';

const DEFAULT_FAIL          = new Color(152, 154, 152);
const DEFAULT_VERY_POOR     = new Color(159, 71, 71);
const DEFAULT_POOR          = new Color(241, 104, 101);
const DEFAULT_FAIR          = new Color(248, 172, 90);
const DEFAULT_SATISFACTORY  = new Color(238, 238, 143);
const DEFAULT_GOOD          = new Color(120, 195, 100);
const DEFAULT_VERY_GOOD     = new Color(98, 151, 83);
const PREF_IRI_SCALE = 'iri-scale';
const PREF_IRI_SCALE_VERSION = 1;

class IRIUtils {
    static getCollectionPeriod(iris, year) {
        let iriList = [];

        year = parseInt(year);

        if (iris === undefined) return iriList;

        for (let i = 0; i < iris.length; i++) {
            let iriYear = new Date(iris[i].date).getFullYear()
            if (iriYear === year) {
                iriList.push(iris[i]);
            }
        }

        iriList.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
        });

        return iriList;
    }

    static getAverageForCollectionPeriod(iris, year) {
        if (!year) {
            year = new Date().getFullYear();
        }

        if (iris === undefined) {
            return null;
        }

        let iriSum = 0;
        let numIris = 0;

        for (let i = 0; i < iris.length; i++) {
            let iriYear = new Date(iris[i].date).getFullYear();
            if (iriYear === year) {
                iriSum += iris[i].iri;
                numIris++;
            }
        }

        return numIris === 0 ? null : iriSum / numIris;
    }

    static directionToString(direction) {
        switch (direction) {
            default:
            case DrivenDirection.UNKNOWN: return '';
            case DrivenDirection.NB: return 'NB';
            case DrivenDirection.SB: return 'SB';
            case DrivenDirection.EB: return 'EB';
            case DrivenDirection.WB: return 'WB';
        }
    }

    static getDirectionTooltip(direction) {
        switch (direction) {
            default:
            case DrivenDirection.UNKNOWN: return 'Unknown';
            case DrivenDirection.NB: return 'Northbound';
            case DrivenDirection.SB: return 'Southbound';
            case DrivenDirection.EB: return 'Eastbound';
            case DrivenDirection.WB: return 'Westbound';
        }
    }

    static getAverageIRIByYear(section) {
        let years = [];

        for (let i = 0; i < section.iri.length; i++) {
            let year = new Date(section.iri[i].date).getFullYear();
            if (years.indexOf(year) === -1) {
                years.push(year);
            }
        }

        let avgs = [];

        for (let i = 0; i < years.length; i++) {
            avgs.push({
                year : years[i],
                iri: IRIUtils.getAverageForCollectionPeriod(section.iri, years[i])
            });
        }

        return avgs;
    }

    /**
     * @deprecated Use @totalpave/math
     */
    static metricToImperial(iri) {
        iri = parseFloat(iri);
        return iri * 63.36005;
    }

    /**
     * @deprecated Use @totalpave/math
     */
    static imperialToMetric(iri) {
        iri = parseFloat(iri);
        return iri / 63.36005;
    }

    static getDefaultIRIScale() {
        return [
            {
                color: DEFAULT_VERY_GOOD,
                low : UserStore.getInstance().getUnit() === Unit.METRIC ? '0' : '0',
                high: UserStore.getInstance().getUnit() === Unit.METRIC ? '2' : '150',
                lowLocked: true,
                isValid: true
            },
            {
                color: DEFAULT_GOOD,
                low : UserStore.getInstance().getUnit() === Unit.METRIC ? '2' : '150',
                high: UserStore.getInstance().getUnit() === Unit.METRIC ? '3' : '200',
                isValid: true
            },
            {
                color: DEFAULT_SATISFACTORY,
                low : UserStore.getInstance().getUnit() === Unit.METRIC ? '3' : '200',
                high: UserStore.getInstance().getUnit() === Unit.METRIC ? '4' : '250',
                isValid: true
            },
            {
                color: DEFAULT_FAIR,
                low : UserStore.getInstance().getUnit() === Unit.METRIC ? '4' : '250',
                high: UserStore.getInstance().getUnit() === Unit.METRIC ? '5' : '300',
                isValid: true
            },
            {
                color: DEFAULT_POOR,
                low : UserStore.getInstance().getUnit() === Unit.METRIC ? '5' : '300',
                high: UserStore.getInstance().getUnit() === Unit.METRIC ? '7' : '450',
                isValid: true
            },
            {
                color: DEFAULT_VERY_POOR,
                low : UserStore.getInstance().getUnit() === Unit.METRIC ? '7' : '450',
                high: UserStore.getInstance().getUnit() === Unit.METRIC ? '10' : '650',
                isValid: true
            },
            {
                color: DEFAULT_FAIL,
                low : UserStore.getInstance().getUnit() === Unit.METRIC ? '10' : '650',
                high: null,
                isValid: true
            }
        ];
    }

    static getColorCode(iri) {
        iri = parseFloat(iri);

        if (isNaN(iri)) {
            return new Color(0, 0, 0);
        }

        let scale = IRIUtils.getScale();

        for (let i = 0; i < scale.length; i++) {
            let item = scale[i];
            let low = parseFloat(item.low);
            let high = parseFloat(item.high);

            if (isNaN(high)) {
                if (iri >= low) {
                    return item.color;
                }
            }
            else {
                if (iri >= low && iri <= high) {
                    return item.color;
                }
            }
        }

        return new Color(0, 0, 0);
    }

    static getScale(autoConvertUnit = true) {
        let scale = PreferenceStore.getInstance().getOrganizationPreference(PREF_IRI_SCALE, PREF_IRI_SCALE_VERSION);

        if (scale) {
            scale = JSON.parse(scale);
            for (let i = 0; i < scale.length; i++) {
                let item = scale[i];
                item.color = Color.fromString(item.color);

                if (autoConvertUnit && UserStore.getInstance().getUnit() === Unit.IMPERIAL) {
                    if (item.low !== null) {
                        item.low = Unit.roundTo(IRIUtils.metricToImperial(item.low), 2);
                    }
                    
                    if (item.high !== null) {
                        item.high = Unit.roundTo(IRIUtils.metricToImperial(item.high), 2);
                    }
                }
                else {
                    if (item.low !== null) {
                        item.low = Unit.roundTo(item.low, 2);
                    }

                    if (item.high !== null) {
                        item.high = Unit.roundTo(item.high, 2);
                    }
                }
            }
        }
        else {
            scale = IRIUtils.getDefaultIRIScale();
        }

        return scale;
    }

    static async resetScaleToDefault() {
        return DeleteOrganizationPreference.execute({preferenceKey: PREF_IRI_SCALE, version: PREF_IRI_SCALE_VERSION});
    }

    static async saveScale(scale) {
        return SaveOrganizationPreference.execute({preferenceKey: PREF_IRI_SCALE, value: scale, version: PREF_IRI_SCALE_VERSION, serializer: (scale) => {
            scale = scale.slice();
            for (let i = 0; i < scale.length; i++) {
                let item = scale[i];
                item.color = item.color.toString();
                if (UserStore.getInstance().getUnit() === Unit.IMPERIAL) {
                    if (item.low !== null) {
                        item.low = IRIUtils.imperialToMetric(item.low);
                    }
                    if (item.high !== null) {
                        item.high = IRIUtils.imperialToMetric(item.high);
                    }
                }
            }

            return JSON.stringify(scale);
        }});
    }
}

export { IRIUtils };
export default IRIUtils;
