'use strict';

// eslint-disable-next-line
import * as React from 'react';
import {BaseScreen} from './BaseScreen';
import {Browser} from '../utils/Browser';
// import {MetadataStore} from '../store/MetadataStore';
// import {UserStore} from '../store/UserStore';
// import {ApplicationInstance} from '../app/ApplicationInstance';
// import {
//     getRouter
// } from '@breautek/router';

class ErrorScreen extends BaseScreen {
    getClassName() {
        return 'ErrorScreen';
    }

    render() {
        // eslint-disable-next-line
        window.localStorage.ungraceful_restart = 1;
        if (Browser.isMobile()) {
            window.navigator.splashscreen.show();
        }
        // let bootLocation = MetadataStore.getInstance().getBootLocation();
        // window.location.href = bootLocation;

        // getRouter().replaceState(UserStore.getInstance().getUser() ? ApplicationInstance.getInstance().getHomeURL() : ApplicationInstance.getInstance().getLoginURL());

        // The application has init processes now. The ErrorScreen can't redirect to home/login manually as it doesn't wait for 
        // the init process. Redirect to index and let the app handle figuring out where the user should go.
        // let app = ApplicationInstance.getInstance();
        // window.location.hash = app.isUserLoggedIn() ? app.getHomeURL() : app.getLoginURL();
        window.location.hash = '';
        window.location.reload();

        return null;
    }
}

export {ErrorScreen};
