'use strict';

import { SectionUtils } from '../utils/SectionUtils';

const DECIMAL_ACCURACY = 3;
const STEP = 5;

export class QuadBounds {
    constructor(latLngBounds) {
        this.bounds = {
            nlng: SectionUtils.calculateLatLngQuad(latLngBounds.northeast.lng, STEP, DECIMAL_ACCURACY),
            nlat: SectionUtils.calculateLatLngQuad(latLngBounds.northeast.lat, STEP, DECIMAL_ACCURACY),
            slng: SectionUtils.calculateLatLngQuad(latLngBounds.southwest.lng, STEP, DECIMAL_ACCURACY),
            slat: SectionUtils.calculateLatLngQuad(latLngBounds.southwest.lat, STEP, DECIMAL_ACCURACY)
        };

        this.bounds.topRight = `${this.bounds.nlng},${this.bounds.nlat}`;
        this.bounds.bottomRight = `${this.bounds.nlng},${this.bounds.slat}`;
        this.bounds.bottomLeft = `${this.bounds.slng},${this.bounds.slat}`;
        this.bounds.topLeft = `${this.bounds.slng},${this.bounds.nlat}`;
    }

    getTopRight() {
        return this.bounds.topRight;
    }

    getTopLeft() {
        return this.bounds.topLeft;
    }

    getBottomRight() {
        return this.bounds.bottomRight;
    }

    getBottomLeft() {
        return this.bounds.bottomLeft;
    }

    getQuads() {
        let quads = [];
        //We need to consider QuadBounds that only involves 1 lng value and/or 1 lat value
        //We also need to consider QuadBounds that has additional quads in between the corners.
        let nlng = parseInt(this.bounds.nlng);
        let nlat = parseInt(this.bounds.nlat);
        let slng = parseInt(this.bounds.slng);
        let slat = parseInt(this.bounds.slat);
        for (let r = slng; r <= nlng; r += STEP) {
            for (let c = slat; c <= nlat; c += STEP) {
                quads.push(`${r},${c}`);
            }
        }
        return quads;
    }
}
