'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';
import {IconFactory} from '../factories/IconFactory';
import {ObjectUtils} from '@totalpave/object';
import '../style/Checkbox.less';

class Checkbox extends React.PureComponent {
    constructor(props) {
        super(props);

        this._node;

        this.state = {
            value : Checkbox.adaptValue(props.value) || 0
        };

        this.onClick = this.onClick.bind(this);
    }

    static getDerivedStateFromProps(nextProps, state) {
        if (Checkbox.adaptValue(nextProps.value) !== state.value) {
            let value = Checkbox.adaptValue(nextProps.value) || 0;
            return {value:value};
        }
        return null;
    }

    static adaptValue(value) {
        if (value === true) {
            return Checkbox.VALUES.CHECKED;
        }
        else if (value === false) {
            return Checkbox.VALUES.UNCHECKED;
        }
        else {
            return value;
        }
    }

    getNode() {
        return this._node;
    }

    isUncheckingAllowed() {
        if (ObjectUtils.isVoid(this.props.allowUnchecking)) {
            return true;
        }
        else {
            return !!this.props.allowUnchecking;
        }
    }

    onClick(e) {
        e.stopPropagation();
        if (this.props.readonly) {
            this.props.onDisabledClick && this.props.onDisabledClick();
        }
        else {
            let value = this.state.value === 0 || this.state.value === 1 ? 2 : 0;
            if (value === 0) {
                if (this.isUncheckingAllowed()) {
                    if (this.props.onChange) {
                        this.props.onChange(value);
                    }
                }
            }
            else {
                if (this.props.onChange) {
                    this.props.onChange(value);
                }
            }
        }
    }

    render() {
        let icon = null;
        let css = 'not-checked';

        if (this.state.value === 1) {
            icon = IconFactory.create('minus');
            css = 'semi';
        }
        else if (this.state.value === 2) {
            icon = IconFactory.create('check');
            css = 'checked';
        }

        return (
            <div 
                className={`Checkbox ${css} ${this.props.readonly ? 'readonly' : ''}`}
                onClick={this.onClick}
                ref={(n) => { this._node = n; }}
            >
                {icon}
            </div>
        );
    }
}

Checkbox.VALUES = {
    UNCHECKED: 0,
    SEMI_CHECKED: 1,
    CHECKED: 2
};

Checkbox.propTypes = {
    value: PropTypes.oneOf([
        0,
        1,
        2,
        true,
        false
    ]),
    readonly: PropTypes.bool,
    onDisabledClick: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    allowUnchecking: PropTypes.bool
};

export { Checkbox };
