
export {ActionData} from '@totalpave/actions';

// 'use strict';

// /**
//  * public class ActionData
//  *
//  * ActionData represents a dataset from an Action that has completed their task.
//  *
//  * Check getTag() to know where the ActionData has came from and to know what type
//  * of data to expect from getData()
//  * 
//  */
// class ActionData {
//     constructor(tag, data) {
//         this._tag = tag;
//         this._data = data;
//     }

//     /**
//      * public getTag
//      *
//      *  The tag of the Action class that dispatched this dataset.
//      * 
//      * @return {String}
//      */
//     getTag() {
//         return this._tag;
//     }

//     /**
//      * public getData
//      *
//      *  The data result from the Action's completed task.
//      * 
//      * @return {Any}
//      */
//     getData() {
//         return this._data;
//     }
// }

// export { ActionData };
