'use strict';

import * as pako from 'pako';

class Zipper {
    constructor() {
        throw new Error("Zipper is a static class.");
    }

    static zip(data, synchronous) {
        if (typeof data !== "string") {
            throw new Error("Zipper.zip expects typeof string");
        }

        if (synchronous) {
            return new Blob([pako.gzip(data).buffer], {type: 'application/json'});
        }
        else {
            return new Promise((resolve) => {
                resolve(new Blob([pako.gzip(data).buffer], {type: 'application/json'}));
            });
        }
    }

    //Doesn't support synchronous option; because, of how we unzip the data.
    static unzip(data/*, synchronous*/) {
        if (!(data instanceof Blob)) {
            throw new Error("Zipper.unzip expects instanceof Uint8Array");
        }
        
        return new Promise((resolve) => {
            data.arrayBuffer().then((buffer) => {
                resolve(pako.ungzip(buffer, {to: "string"}));
            });
        });
    }
}

export { Zipper };
