
import * as React from 'react';
import {FormItem} from './FormItem';
import {FormModal} from './FormModal';
import {ApplicationInstance} from '@totalpave/application-instance';
import "../style/AlertModal.less";

export class AlertModal extends FormModal {
    constructor(props) {
        super(props);
        this._addClassName('AlertModal')
    }

    getTitle() {
        return this.props.title || ApplicationInstance.getInstance().getConfig().name;
    }

    isFlex() {
        return true;
    }

    isSmooth() {
        return false;
    }

    _onSubmit() {
        return Promise.resolve();
    }

    _getCancelButton() {
        return null;
    }

    _getSubmitButtonProps(props) {
        props.text = 'OK';
        return props;
    }

    _renderContent() {
        return (
            <FormItem className="alert-container">
                <pre className="use-tp-fonts">{this.props.text}</pre>
            </FormItem>
        );
    }
}
