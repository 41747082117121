// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Copyright 2023-2024 Total Pave Inc.
 * All Rights Reserved.
 */
/**
 * Copyright 2023-2024 Total Pave Inc.
 * All Rights Reserved.
 */
.Label {
  font-size: 95%;
}
`, "",{"version":3,"sources":["webpack://./src/style/components/Label.less"],"names":[],"mappings":"AAAA;;;EAGE;AACF;;;EAGE;AACF;EACI,cAAA;AACJ","sourcesContent":["\n/**\n * Copyright 2023-2024 Total Pave Inc.\n * All Rights Reserved.\n */\n \n@import \"../public/ui-core/constants.less\";\n\n.Label {\n    font-size: @tp-font-size-medium;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
