'use strict';

import * as React from 'react';
import "../style/Dropzone.less";
import {TPComponent} from './TPComponent';
import {DragStore} from '../store/DragStore';
import {ApplicationInstance} from '@totalpave/application-instance';
import {SetDropzone} from '../actions/SetDropzone';
import PropTypes from 'prop-types';

// eslint-disable-next-line
const DEFAULT_WILL_ACCEPT_PROP = (droppableComponent) => { return true; };

export class Dropzone extends TPComponent {
    constructor(props) {
        super(props);

        if (!props.onDrop) {
            throw new Error('onDrop is a required prop');
        }

        this.state = {
            readyForDrop: false
        };

        this._onMouseOver = this._onMouseOver.bind(this);
        this._onMouseLeave = this._onMouseLeave.bind(this);
    }

    _onMouseOver(e) {
        e.stopPropagation();
        if (DragStore.getInstance().getActiveDropzone() !== this) {
            SetDropzone.execute(this);  
        }
    }

    _onMouseLeave() {
        SetDropzone.execute(null);
        if (this.state.readyForDrop) {
            this.setState({
                readyForDrop: false
            });
        }
    }

    willAccept(droppable) {
        let _willAccept = this.props.willAccept || DEFAULT_WILL_ACCEPT_PROP;
        let result = _willAccept(droppable);
        if (result !== false && result !== true) {
            ApplicationInstance.getInstance().getLogger().warn('Dropzone willAccept should return a boolean value, got "' + typeof result + '" instead.');
        }

        if (result && this.state.readyForDrop !== result) {
            this.setState({
                readyForDrop: true
            });
        }

        return result;
    }

    drop(droppable) {
        this.props.onDrop(droppable);
    }

    render() {
        return (
            <div 
                className={`Dropzone ${this.props.className || ''} ${this.state.readyForDrop ? 'willing-to-accept' : ''}`}
                onMouseOver={this._onMouseOver}
                onMouseLeave={this._onMouseLeave}
            >
                {this.props.children}
            </div>
        );
    }
}

Dropzone.propTypes = {
    onDrop: PropTypes.func.isRequired,
    willAccept: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.node
};
