
/**
 * @deprecated - Use @totalpave/country instead
 */
export const Country = {
    AFGHANISTAN                 : "AF",
    ALBANIA                     : "AL",
    ALGERIA                     : "DZ",
    AMERICAN_SAMOA              : "AS",
    ANDORRA                     : "AD",
    ANGOLA                      : "AO",
    ANGUILLA                    : "AI",
    ANTARCTICA                  : "AQ",
    ANTIQUA_AND_BARBUDA         : "AG",
    ARGENTINA                   : "AR",
    ARMENIA                     : "AM",
    ARUBA                       : "AW",
    AUSTRALIA                   : "AU",
    AUSTRIA                     : "AT",
    AZERBAIJAN                  : "AZ",
    BAHAMAS                     : "BS",
    BAHRAIN                     : "BH",
    BANGLADESH                  : "BD",
    BARBADOS                    : "BB",
    BELARUS                     : "BY",
    BELGIUM                     : "BE",
    BELIZE                      : "BZ",
    BENIN                       : "BJ",
    BERMUDA                     : "BM",
    BHUTAN                      : "BT",
    BOLIVIA                     : "BO",
    BOSNIA_AND_HERZEGOVINA      : "BA",
    BOTSWANA                    : "BW",
    BRAZIL                      : "BR",
    BRITISH_INDIAN_OCEAN_TERRITORY : "IO",
    BRITISH_VIRGIN_ISLANDS      : "VG",
    BRUNEI                      : "BN",
    BULGARIA                    : "BG",
    BURKINA_FASO                : "BF",
    BURUNDI                     : "BI",
    CAMBODIA                    : "KH",
    CAMEROON                    : "CM",
    CANADA                      : "CA",
    CADO_VERDE                  : "CV",
    CAYMAN_ISLANDS              : "KY",
    CENTRAL_AFRICAN_REPUBLIC    : "CF",
    CHAD                        : "TD",
    CHILE                       : "CL",
    CHINA                       : "CN",
    CHRISTMAS_ISLAND            : "CX",
    COCOS_ISLANDS               : "CC",
    COLOMBIA                    : "CO",
    COOK_ISLANDS                : "CK",
    COSTA_RICA                  : "CR",
    CROATIA                     : "HR",
    CUBA                        : "CU",
    CURACAO                     : "CW",
    CYPRUS                      : "CY",
    CZECH_REPUBLIC              : "CZ",
    DEMOCRATIC_REPUBLIC_OF_THE_CONGO : "CD",
    DENMARK                     : "DK",
    DJIBOUTI                    : "DJ",
    DOMINICA                    : "DM",
    DOMINICAN_REPUBLIC          : "DO",
    EAST_TIMOR                  : "TL",
    ECUADOR                     : "EC",
    EGYPT                       : "EG",
    EL_SALVADOR                 : "SV",
    EQUATORIAL_GUINEA           : "GQ",
    ERITREA                     : "ER",
    ESTONIA                     : "EE",
    ETHIOPIA                    : "ET",
    FALKLAND_ISLANDS            : "FK",
    FAROE_ISLANDS               : "FO",
    FIJI                        : "FJ",
    FINLAND                     : "FI",
    FRANCE                      : "FR",
    FRENCH_POLYNESIA            : "PF",
    GABON                       : "GA",
    GAMBIA                      : "GM",
    GEORGIA                     : "GE",
    GHANA                       : "GH",
    GIBRALTAR                   : "GI",
    GREECE                      : "GR",
    GREENLAND                   : "GL",
    GRENADA                     : "GD",
    GUAM                        : "GU",
    GUATEMALA                   : "GT",
    GUERNSEY                    : "GG",
    GUINEA                      : "GN",
    GUINEA_BISSAU               : "GW",
    GUYANA                      : "GY",
    HAITI                       : "HT",
    HONDURAS                    : "HN",
    HONG_KONG                   : "HK",
    HUNGARY                     : "HU",
    ICELAND                     : "IS",
    INDIA                       : "IN",
    INDONESIA                   : "ID",
    IRAN                        : "IR",
    IRAQ                        : "IQ",
    IRELAND                     : "IE",
    ISLE_OF_MAN                 : "IM",
    ISRAEL                      : "IL",
    ITALY                       : "IT",
    IVORY_COAST                 : "CI",
    JAMAICA                     : "JM",
    JAPAN                       : "JP",
    JERSEY                      : "JE",
    JORDAN                      : "JO",
    KAZAKHSTAN                  : "KZ",
    KENYA                       : "KE",
    KIRIBATI                    : "KI",
    KOSOVO                      : "XK",
    KUWAIT                      : "KW",
    KYRGYZSTAN                  : "KG",
    LAOS                        : "LA",
    LATVIA                      : "LV",
    LEBANON                     : "LB",
    LESOTHO                     : "LS",
    LIBERIA                     : "LR",
    LIBYA                       : "LY",
    LIECHTENSTEIN               : "LI",
    LITHUANIA                   : "LT",
    LUXEMBOURG                  : "LU",
    MACAU                       : "MO",
    MACEDONIA                   : "MK",
    MADAGASCAR                  : "MG",
    MALAWI                      : "MW",
    MALAYSIA                    : "MY",
    MALDIVES                    : "MV",
    MALI                        : "ML",
    MALTA                       : "MT",
    MARSHALL_ISLANDS            : "MH",
    MAURITANIA                  : "MR",
    MAURITIUS                   : "MU",
    MAYOTTE                     : "YT",
    MEXICO                      : "MX",
    MICRONESIA                  : "FM",
    MOLDOVA                     : "MD",
    MONACO                      : "MC",
    MONGOLIA                    : "MN",
    MONTENEGRO                  : "ME",
    MONTSERRAT                  : "MS",
    MOROCCO                     : "MA",
    MOZAMBIQUE                  : "MZ",
    MYANMAR                     : "MM",
    NAMIBIA                     : "NA",
    NAURU                       : "NR",
    NEPAL                       : "NP",
    NETHERLANDS                 : "NL",
    NETHERLANDS_ANTILLES        : "AN",
    NEW_CALEDONIA               : "NC",
    NEW_ZEALAND                 : "NZ",
    NICARAGUA                   : "NI",
    NIGER                       : "NE",
    NIGERIA                     : "NG",
    NIUE                        : "NU",
    NORTH_KOREA                 : "KP",
    NORTHERN_MARIANA_ISLANDS    : "MP",
    NORWAY                      : "NO",
    OMAN                        : "OM",
    PAKISTAN                    : "PK",
    PALAU                       : "PW",
    PALESTINE                   : "PS",
    PANAMA                      : "PA",
    PAPUA_NEW_GUINEA            : "PG",
    PARAGUAY                    : "PY",
    PERU                        : "PE",
    PHILIPPINES                 : "PH",
    PITCAIRN                    : "PN",
    POLAND                      : "PL",
    PORTUGAL                    : "PT",
    PUERTO_RICO                 : "PR",
    QATAR                       : "QA",
    REPUBLIC_OF_THE_CONGO       : "CG",
    REUNION                     : "RE",
    ROMANIA                     : "RO",
    RUSSIA                      : "RU",
    RWANDA                      : "RW",
    SAINT_BARTHELEMY            : "BL",
    SAINT_HELENA                : "SH",
    SAINT_KITTS_AND_NEVIS       : "KN",
    SAINT_LUCIA                 : "LC",
    SAINT_MARTIN                : "MF",
    SAINT_PIERRE_AND_MIQUELON   : "PM",
    SAINT_VINCENT_AND_THE_GRENADINES : "VC",
    SAMOA                       : "WS",
    SAN_MARINO                  : "SM",
    SAO_TOME_AND_PRINCIPE       : "ST",
    SAUDI_ARABIA                : "SA",
    SENEGAL                     : "SN",
    SERBIA                      : "RS",
    SEYCHELLES                  : "SC",
    SIERRA_LEONE                : "SL",
    SINGAPORE                   : "SG",
    SINT_MAARTEN                : "SX",
    SLOVAKIA                    : "SK",
    SLOVENIA                    : "SI",
    SOLOMON_ISLANDS             : "SB",
    SOMALIA                     : "SO",
    SOUTH_AFRICA                : "ZA",
    SOUTH_KOREA                 : "KR",
    SOUTH_SUDAN                 : "SS",
    SPAIN                       : "ES",
    SRI_LANKA                   : "LK",
    SUDAN                       : "SD",
    SURINAME                    : "SR",
    SVALBARD_AND_JAN_MAYEN      : "SJ",
    SWAZILAND                   : "SZ",
    SWEDEN                      : "SE",
    SWITZERLAND                 : "CH",
    SYRIA                       : "SY",
    TAIWAN                      : "TW",
    TAJIKISTAN                  : "TJ",
    TANZANIA                    : "TZ",
    THAILAND                    : "TH",
    TOGO                        : "TG",
    TOKELAU                     : "TK",
    TONGA                       : "TO",
    TRINIDAD_AND_TOBAGO         : "TT",
    TUNISIA                     : "TN",
    TURKEY                      : "TR",
    TURKMENISTAN                : "TM",
    TURKS_AND_CAICOS_ISLANDS    : "TC",
    TUVALU                      : "TV",
    US_VIRGIN_ISLANDS           : "VI",
    UGANDA                      : "UG",
    UKRAINE                     : "UA",
    UNITED_ARAB_EMIRATES        : "AE",
    UNITED_KINGDOM              : "GB",
    UNITED_STATES               : "US",
    URUGUAY                     : "UY",
    UZBEKISTAN                  : "UZ",
    VANUATU                     : "VU",
    VATICAN                     : "VA",
    VENEZUELA                   : "VE",
    VIETNAM                     : "VN",
    WALLIS_AND_FUTUNA           : "WF",
    WESTERN_SAHARA              : "EH",
    YEMEN                       : "YE",
    ZAMBIA                      : "ZM",
    ZIMBABWE                    : "ZW"
};
