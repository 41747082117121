'use strict';

import {Store} from '@totalpave/store';

let _instance = null;

export class MessageStore extends Store {
    constructor() {
        super();
        this._msgLog = [];
    }

    static getInstance() {
        if (!_instance) {
            _instance = new MessageStore();
        }
        return _instance;
    }

    getMessages() {
        return this._msgLog.slice();
    }

    hasMessages() {
        return this._msgLog.length > 0;
    }

    getLastMessage() {
        if (this._msgLog.length > 0) {
            return this._msgLog[this._msgLog.length - 1];
        }
        else {
            return null;
        }
    }

    clearMessage(message) {
        this._msgLog.splice(this._msgLog.indexOf(message), 1);
    }

    clearMessages() {
        this._msgLog = [];
    }

    _update(actionData) {
        let message;
        switch (actionData.getTag()) {
            case 'dispatch_message':
                message = actionData.getData();

                for (let i = 0; i < this._msgLog.length; ++i) {
                    if (this._msgLog[i].getMessage() === message.getMessage() && this._msgLog[i].getType() === message.getType()) {
                        return true;
                    }
                }

                this._msgLog.push(message);
                return true;
            case 'clear_messages':
                this.clearMessages();
                return true;
            case 'clear_message':
                this.clearMessage(actionData.getData());
                return true;
        }

        return false;
    }
}
