'use strict';

import {
    TransitionStrategy,
    View
} from '@breautek/router';

export class TransitionContract extends TransitionStrategy {
    protected override async _execute(incomingView: View, exitingView: View): Promise<void> {
        return new Promise((resolve) => {
            let exitingNode: HTMLElement = exitingView.getNode();
            let transitionTimeout: number = null;
            let errorTimeout: number = null;

            let end = () => {
                exitingNode.removeEventListener('transitionend', end);
                window.clearTimeout(errorTimeout);

                exitingNode.style.paddingLeft = "";
                exitingNode.style.paddingRight = "";
                exitingNode.style.position = "";
                exitingNode.style.top = "";
                exitingNode.style.left = ""; //`-${left}px`; Doing it this way may cause 2 string concats instead of just passing in an -left. 
                exitingNode.style.width = "";
                exitingNode.style.height = "";
                exitingNode.style.marginTop = "";
                exitingNode.style.zIndex = "";
                exitingNode.style.border = "";
                exitingNode.style.borderRadius = "";
                exitingNode.style.overflow = "";
                exitingNode.style.transform = "";
                exitingNode.style.transition = "";

                resolve();
            }

            errorTimeout = window.setTimeout(() => {
                console.warn("TransitionContract hit error timeout.");
                window.clearTimeout(transitionTimeout);
                end();
            }, 1000);

            let left = (window.screen.height - window.screen.width) / 2;
            exitingNode.style.paddingLeft = `${left}px`;
            exitingNode.style.paddingRight = exitingNode.style.paddingLeft;
            exitingNode.style.position = "absolute";
            exitingNode.style.top = "0px";
            exitingNode.style.left = `${-1 * left}px`; //`-${left}px`; Doing it this way may cause 2 string concats instead of just passing in an -left. 
            exitingNode.style.boxSizing = "border-box"; // enforce border-box.
            exitingNode.style.width = "100vh"; // 100vh is NOT a typo. Width = screen.width + paddingLeft + paddingRight. These values add up to screen.height. Use 100vh to skip js calculations.
            exitingNode.style.height = "100vh";
            exitingNode.style.marginTop = "0px";
            exitingNode.style.zIndex = "1";
            exitingNode.style.border = "0px solid black";
            exitingNode.style.borderRadius = "0%";
            exitingNode.style.borderRadius = "100%";
            exitingNode.style.overflow = "hidden";
            exitingNode.style.transform = "scale(1, 1)";
            exitingNode.style.transition = "transform 0.5s, border-radius 0.1s";
            exitingNode.style.transitionTimingFunction = "ease-in-out";

            exitingNode.addEventListener('transitionend', end);

            transitionTimeout = window.setTimeout(() => {
                exitingNode.style.transform = "scale(0, 0)";
            }, 1);
        });
    }
}
