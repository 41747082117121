'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';
import '../style/Label.less';

/**
 * @deprecated
 * 
 * Use UI Core's Label component instead
 */
class Label extends React.Component {
    constructor(props) {
        super(props);

        this._className = 'Label';
        this._node;

        this._onTap = this._onTap.bind(this);
    }

    _onTap(event) {
        this.props.onTap && this.props.onTap(event);
    }

    render() {
        let className = this._className + ' ' + this.props.className;
        return <span onClick={this._onTap} ref={(c) => { this._node = c; }} className={className} style={this.props.style}>{this.props.text}</span>;
    }
}

Label.propTypes = {
    onTap: PropTypes.func,
    className: PropTypes.string,
    text: PropTypes.string,
    style: PropTypes.object
};

export { Label };
