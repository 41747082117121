'use strict';

import { ObjectUtils } from '../utils/ObjectUtils';
import { ApplicationInstance } from '@totalpave/application-instance';

/**
 * @deprecated Use `@totalpave/datalayer` instead
Generic model to interface complex datasets.
Should be subclassed to add proper getters.

getters should handle their own reference breaking where appriopriate.
 */
class Model {
    constructor(data) {
        this._data = data || {};

        if (!this._data.creationDate) {
            this._data.creationDate = ApplicationInstance.getInstance().getDateFactory().create();
        }
        else if (!(this._data.creationDate instanceof Date)){
            this._data.creationDate = ApplicationInstance.getInstance().getDateFactory().create(this._data.creationDate);
        }
        if (!this._data.lastModified) {
            this._data.lastModified = ApplicationInstance.getInstance().getDateFactory().create();
        }
        else if (!(this._data.lastModified instanceof Date)){
            this._data.lastModified = ApplicationInstance.getInstance().getDateFactory().create(this._data.lastModified);
        }
        if (!this._data.isDeleted) { //ensure property is defined
            this._data.isDeleted = false;
        }

        this.parent = null; //Override this value in the constructor or call setParent to start using Composite Pattern
    }

    sync() {
        if (!this._data.creationDate) {
            this._data.creationDate = ApplicationInstance.getInstance().getDateFactory().create();
        }
        else if (!(this._data.creationDate instanceof Date)){
            this._data.creationDate = ApplicationInstance.getInstance().getDateFactory().create(this._data.creationDate);
        }
        if (!this._data.lastModified) {
            this._data.lastModified = ApplicationInstance.getInstance().getDateFactory().create();
        }
        else if (!(this._data.lastModified instanceof Date)){
            this._data.lastModified = ApplicationInstance.getInstance().getDateFactory().create(this._data.lastModified);
        }
        if (!this._data.isDeleted) { //ensure property is defined
            this._data.isDeleted = false;
        }
    }

    getTag() { //Only required if Models are using Composite Pattern. It is recommend to add the tag as a static variable to the Model. 
        throw new Error("Model.getTag is abstract");
    }

    setParent(parent) {
        if (!(parent instanceof Model) && parent !== null && parent !== undefined) {
            throw new Error("Model parent must be instance of Model, or null/undefined.");
        }

        this.parent = parent;
    }

    getParent() {
        return this.parent;
    }

    findAncestor(tag) {
        let ancestor = this;
        do {
            ancestor = ancestor.getParent();
        } while (ancestor !== null && ancestor.getTag() !== tag);
        return ancestor;
    }

    getRoot() {
        let ancestor = this;
        while (ancestor.getParent()) {
            ancestor = ancestor.getParent();
        }
        return ancestor;
    }

    //returns _data object. Internal usage of _data should use this function. 
    _getData() {
        return this._data;
    }

    //returns a cloned copy of a data object. External usage of _data should use this function. 
    //WARNING: getData may be heavy depending on the size of the data.
    getData() {
        return ObjectUtils.clone(this._data);
    }

    getID() {
        return this._data.id;
    }

    setData(dictionary) {
        let explicitLastModified = false;
        for (let i in dictionary) {
            if (i === "lastModified") {
                explicitLastModified = true;
            }
            this._data[i] = dictionary[i];
        }

        if (!explicitLastModified) {
            this._data.lastModified = ApplicationInstance.getInstance().getDateFactory().create();
            dictionary.lastModified = this._data.lastModified;
        }
        this._onDataChange(dictionary);
    }

    //Overridable function that is called at the end of a setData call.
    //As a pre-caution, please call super when overriding this function. This is to prepare for the possibility of this function running code at the Model level in the future.
    // eslint-disable-next-line no-unused-vars
    _onDataChange(dictionary) { } //dictionary is the values from setData

    setDeleted(flag) {
        //setDeleted somehow got set to the function, Model.isDeleted.
        //For debugging and future safety purposes, a boolean type check has been added. 
        if (flag !== true && flag !== false) {
            throw new Error("setDeleted expects a boolean value.");
        }
        this.setData({
            isDeleted: flag
        });
    }

    isDeleted() {
        return this._getData().isDeleted;
    }

    setCreationDate(date) {
        if (!(date instanceof Date)) {
            throw new Error("Model.setCreationDate expects instanceof Date.");
        }

        this.setData({
            creationDate: date
        });
    }

    getCreationDate() {
        return ApplicationInstance.getInstance().getDateFactory().create(this._getData().creationDate.getTime());
    }

    // note: The following warning is not true anymore.
    //warning: This function by-passes setData onchange functionality
    setLastModified(date) {
        if (!(date instanceof Date)) {
            throw new Error("Model.setLastModified expects instanceof Date.");
        }

        //WARNING: Not using setData here WILL break tp-pci-client Models.
        this.setData({
            lastModified: date
        });
        // this._getData().lastModified = date;
    }

    getLastModified() {
        return ApplicationInstance.getInstance().getDateFactory().create(this._getData().lastModified.getTime());
    }

    //abstract function. Only required if your code will use the function. This function is NOT used internally.
    // eslint-disable-next-line no-unused-vars
    isEqualTo(model) {
        throw new Error("This Model Class does not support Model.isEqualTo.");
    }
}

export { Model };
