
import {AbstractScreenOrientation} from './AbstractScreenOrientation';
import { OrientationType } from './OrientationType';

/**
 * A Screen Orientation API that uses a DOM test to determine
 * screen orientation. This DOM test avoids challenging the viewport
 * and thus doesn't use CSS media queries or viewport metrics as they
 * may change depending if the keyboard is shown or if the viewport is zoomed.
 * 
 * This API should only be used on iOS 16.4 and earlier. iOS 17.0 and later
 * has full working API of the W3C Screen Orientation. This only tested on
 * iOS 15 - 16.4.
 * 
 * Note that iOS 16.4 **does** have a ScreenOrientation API implemented, it
 * however does not work properly and will always return portrait regardless
 * of orientation.
 * 
 * The DOM test consists of checking the screen.width and screen.height,
 * these 2 fields remains constant regardless of orientation and viewport size.
 * 
 * An invisible DOM node is then used to span across the width of the viewport.
 * The assumption here is a keyboard will only ever effect the height when shown
 * and never the width. If the DOM node's width matches screen.width, then
 * we are in portrait. If the node's width matches screen.height, then we
 * are in landscape.
 * 
 * To handle edge cases where the width does not match either screen dimensions,
 * we will fallback to the closest metric available.
 * 
 * This implementation cannot make a distinction between primary and secondary,
 * and thus will only ever report primary orientation type.
 * 
 * Note: Despite the name, this class is also used on MacOS Safari 15 which
 * also lacks a W3C ScreenOrientation API.
 */
export class IOSScreenOrientation extends AbstractScreenOrientation {
    
    private static $instance: IOSScreenOrientation;
    private $mask: HTMLDivElement;

    private constructor() {
        super();
        console.log('Using DOM Orientation Test API (iOS Fallback)');
        this.$mask = document.createElement('div');
        this.$mask.id = 'ios-screen-orientation-mask';
        this.$mask.style.pointerEvents = 'none';
        this.$mask.style.visibility = 'hidden';
        this.$mask.style.position = 'fixed';
        this.$mask.style.left = '0px';
        this.$mask.style.right = '0px';
        this.$mask.style.top = '-10000px';
        document.body.appendChild(this.$mask);
    }

    protected override _getPhysicalOrientation(): OrientationType {
        let sw: number = window.screen.width;
        let sh: number = window.screen.height;
        let mw: number = this.$mask.clientWidth;

        let orientation: OrientationType = null;
        if (mw === sw) {
            orientation = OrientationType.PORTRAIT_PRIMARY;
        }
        else if (mw === sh) {
            orientation = OrientationType.LANDSCAPE_PRIMARY;
        }
        else {
            // gracefully handle for when sizes doesn't match exactly.
            // Not sure how likely this would occur but we'll just use the closest
            // value
            let wdiff: number = Math.abs(sw - mw);
            let hdiff: number = Math.abs(sh - mw);
            if (wdiff < hdiff) {
                orientation = OrientationType.PORTRAIT_PRIMARY;
            }
            else {
                orientation = OrientationType.LANDSCAPE_PRIMARY;
            }
        }

        return orientation;
    }

    protected override _initListener(): void {
        let fireTimer: number = null;

        window.addEventListener('resize', () => {
            if (fireTimer !== null) {
                window.cancelAnimationFrame(fireTimer);
            }

            fireTimer = window.requestAnimationFrame(() => {
                fireTimer = null;
                this._notify(this._getPhysicalOrientation(), false);
            });
        });
    }

    public static getInstance(): IOSScreenOrientation {
        if (!IOSScreenOrientation.$instance) {
            IOSScreenOrientation.$instance = new IOSScreenOrientation();
        }

        return IOSScreenOrientation.$instance;
    }
}
