
import {EventEmitter} from 'events';
import { KeyCode } from '@totalpave/finterfaces';

export enum KeyboardListenerEvent {
    KEY_DOWN = 'keydown',
    KEY_PRESS = 'keypress',
    KEY_UP = 'keyup'
}

export interface IKeyboardListenerEvent {
    altKey: boolean;
    ctrlKey: boolean;
    shiftKey: boolean;
    keyCode: KeyCode;
    target: EventTarget;
    nativeEvent: KeyboardEvent;
}

export type TKeyboardListenerHandler = (event: IKeyboardListenerEvent) => void;

export class KeyboardListener extends EventEmitter {
    private static $instance: KeyboardListener;

    private constructor() {
        super();
        this.setMaxListeners(Infinity);

        this.$onKeyDown     = this.$onKeyDown.bind(this);
        this.$onKeyPress    = this.$onKeyPress.bind(this);
        this.$onKeyUp       = this.$onKeyUp.bind(this);

        window.addEventListener(KeyboardListenerEvent.KEY_DOWN, this.$onKeyDown);
        window.addEventListener(KeyboardListenerEvent.KEY_PRESS, this.$onKeyPress);
        window.addEventListener(KeyboardListenerEvent.KEY_UP, this.$onKeyUp);
    }

    public static getInstance(): KeyboardListener {
        if (!KeyboardListener.$instance) {
            KeyboardListener.$instance = new KeyboardListener();
        }
        return KeyboardListener.$instance;
    }

    private $createEventObject(event: KeyboardEvent): IKeyboardListenerEvent {
        return {
            altKey: event.altKey,
            ctrlKey : event.ctrlKey,
            shiftKey: event.shiftKey,
            keyCode: event.keyCode,
            target: event.target,
            nativeEvent: event
        };
    }

    private $onKeyDown(event: KeyboardEvent): void {
        this.emit(KeyboardListenerEvent.KEY_DOWN, this.$createEventObject(event));
    }

    private $onKeyPress(event: KeyboardEvent): void {
        this.emit(KeyboardListenerEvent.KEY_PRESS, this.$createEventObject(event));
    }

    private $onKeyUp(event: KeyboardEvent): void {
        this.emit(KeyboardListenerEvent.KEY_UP, this.$createEventObject(event));
    }

    public register(event: KeyboardListenerEvent, handler: TKeyboardListenerHandler): void {
        this.on(event, handler);
    }

    public unregister(event: KeyboardListenerEvent, handler: TKeyboardListenerHandler): void {
        this.removeListener(event, handler);
    }

    public isKeyCodeTextual(keyCode: KeyCode): boolean {
        if (keyCode >= KeyCode.A && keyCode <= KeyCode.Z) {
            return true;
        }

        if (keyCode >= KeyCode.ZERO && keyCode <= KeyCode.NINE) {
            return true;
        }

        if (keyCode >= KeyCode.NUMPAD_ZERO && keyCode <= KeyCode.NUMPAD_ADD) {
            return true;
        }

        if (keyCode >= KeyCode.NUMPAD_MINUS && keyCode <= KeyCode.NUMPAD_DIVIDE) {
            return true;
        }

        if (keyCode >= KeyCode.SEMICOLON && keyCode <= KeyCode.BACKQUOTE) {
            return true;
        }

        if (keyCode >= KeyCode.BRACKET_OPEN && keyCode <= KeyCode.QUOTE) {
            return true;
        }

        return false;
    }
}
