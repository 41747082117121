'use strict';

// eslint-disable-next-line
import * as React from 'react';
import {ComponentContainer} from './ComponentContainer';
import {MessageStore} from '../store/MessageStore';
import {ApplicationInstance} from '@totalpave/application-instance';
import {UIFactory} from '../factories/UIFactory';
import {MessageType} from '@totalpave/finterfaces';
import PropTypes from 'prop-types';

import "../style/MessageContainer.less";

class MessageContainer extends ComponentContainer {
    constructor(props) {
        super(props);
        /*
            props:
            {
                limit : int     Defines number of Messages that can be displayed at the same time. Defaults to no limit. If less than 1; then, defaults to no limit.
            }
        */

        this.state = {
            messages : []
        };
    }

    getCssClass() {
        return 'MessageContainer';
    }

    getStore() {
        return MessageStore;
    }

    onUpdate() {
        this.setState({
            messages : MessageStore.getInstance().getMessages()
        });
    }

    _buildMessages(messages) {
        let msgs = [];
        let limit = this.props.limit;
        if (!limit || limit < 1 || limit > messages.length) {
            limit = messages.length;
        }

        for (let i = 0; i < limit; i++) {
            let message = messages[i];
            msgs.push(this._buildMessage(message));
        }

        return msgs;
    }

    _buildMessage(message) {
        return ApplicationInstance.getInstance().getUIFactory().create(
            UIFactory.MESSAGE, 
            {
                key: message.getID(),
                message: message,
                timer: 3000,
                success: message.getType() === MessageType.SUCCESS
            }
        );
    }

    _render() {
        // TODO: Support more anchors somehow
        return (
            <div className="message-anchor top-right">
                {this._buildMessages(this.state.messages)}
            </div>
        );
    }
}

MessageContainer.propTypes = {
    limit: PropTypes.number
};

export { MessageContainer };
