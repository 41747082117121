
import * as React from 'react';
import PropTypes from 'prop-types';
import {List} from './List';
import {
    InfiniteLoader
} from 'react-virtualized';
import {ObjectUtils} from '@totalpave/object';

export class PaginatedList extends List {
    constructor(props) {
        super(props);
        this._onSectionRendered = this._onSectionRendered.bind(this);
        this._infiniteOnRowsRendered = null; // Special case, see InfiniteLoader usage.
    }

    getClassName() {
        return super.getClassName() + ' PaginatedList';
    }

    getRowCount() {
        return this.props.totalRowCount;
    }

    resetRowCache() {
        this._loaderAPI.resetLoadMoreRowsCache(true);
    }

    getEstimatedRowSize() {
        return this.props.builder.getRowHeight();
    }

    _onRowsRendered(params) {
        super._onRowsRendered(params);
        this._infiniteOnRowsRendered(params);
    }

    _onSectionRendered({ rowStartIndex, rowStopIndex }) {
        this._onRowsRendered({
            startIndex: rowStartIndex,
            stopIndex: rowStopIndex
        });
        this.props.onRowsRendered && this.props.onRowsRendered(rowStartIndex, rowStopIndex);
    }

    getTotalRowCount() {
        if (!ObjectUtils.isVoid(this.props.totalRowCount)) {
            return this.props.totalRowCount;
        }
        else {
            return this.getData().length;
        }
    }

    _getContent(propsData) {
        return (
            <InfiniteLoader
                ref={(n) => {
                    this._loaderAPI = n;
                }}
                isRowLoaded={(index) => {
                    return this.props.isRowLoaded(index);
                }}
                loadMoreRows={(startIndex, stopIndex) => {
                    return this.props.loadMoreRows(startIndex, stopIndex);
                }}
                rowCount={this.getTotalRowCount()}
                minimumBatchSize={this.props.minimumBatchSize}
            >
                {({onRowsRendered, registerChild}) => {
                    this._infiniteOnRowsRendered = onRowsRendered;
                    propsData.onSectionRendered = this._onSectionRendered;
                    propsData.refCallback = registerChild;
                    return super._getContent(propsData)
                }}
            </InfiniteLoader>
        );
    }
}

PaginatedList.propTypes = {
    loadMoreRows: PropTypes.func.isRequired,
    totalRowCount: PropTypes.number.isRequired,
    isRowLoaded: PropTypes.func.isRequired,
    minimumBatchSize: PropTypes.number
};
