'use strict';

import {Action} from './Action';
import {OrganizationClient} from '../net/OrganizationClient';

class GetCurrencyListSingleton extends Action {
    getTag() {
        return 'get-currency-list';
    }

    _shouldDispatchOnError() {
        return false;
    }

    _execute() {
        return new Promise((resolve, reject) => {
            OrganizationClient.getInstance().getCurrencyList().then(resolve).catch(reject);
        });
    }
}

export const GetCurrencyList = new GetCurrencyListSingleton();
