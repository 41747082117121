'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';
import { ToggleGroup } from './ToggleGroup';
import '../style/TabView.less';
import { ObjectUtils } from '../utils/ObjectUtils';

/*
    options should be an array of objects that contain the properties text, value, and render function.
*/

class TabView extends React.Component {
    constructor(props) {
        super(props);

        this._onChange = this._onChange.bind(this);
    }
    
    getClassName() {
        return "TabView";
    }

    getValue() {
        let value = null;

        if (ObjectUtils.isVoid(this.props.value)) {
            value = this.props.defaultValue;
        }
        else {
            value = this.props.value;
        }

        return value;
    }

    _onChange(value) {
        let beforeChangePromise = null;
        if (this.props.onBeforeChange) {
            beforeChangePromise = this.props.onBeforeChange(this.getValue(), value);
        }
        else {
            beforeChangePromise = Promise.resolve(true);
        }

        beforeChangePromise.then((shouldChange) => {
            if (shouldChange) {
                this._fireChange(value);
            }
        });
    }

    _fireChange(value) {
        this.props.onChange && this.props.onChange(value);
    }

    render() {
        let value = this.getValue();

        let options = this.props.options.map((option, index) => {
            let isSelected = value === option.value;

            return <div key={index} className={(isSelected ? "selected " : "") + "tabViewItem"}>
                {option.render()}
            </div>;
        });

        return (
            <div className={this.getClassName()}>
                <ToggleGroup className="tabview-togglegroup" value={value} options={this.props.options} onChange={this._onChange} />
                <div className="tabs">
                    {options}
                </div>
            </div>
        );
    }
}

TabView.propTypes = {
    defaultValue: PropTypes.any,
    onBeforeChange: PropTypes.func,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.any)
};

export { TabView };
