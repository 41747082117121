import * as React from 'react';
import PropTypes from 'prop-types';

import { ListRowBuilder } from '../builders/ListRowBuilder';

class BaseList extends React.Component {
    constructor(props) {
        super(props);
    }
    
    getListItemRefs() {
        throw new Error('BaseList.getListItemRefs is abstract.');
    }

    getScrollableElement() {
        throw new Error('BaseList.getScrollableElement is abstract.');
    }
}

BaseList.propTypes = {
    items: PropTypes.array.isRequired,
    builder: PropTypes.instanceOf(ListRowBuilder).isRequired,
    // Should return an jsx node WITH a key property.
    noRowsRenderer: PropTypes.func,
    className: PropTypes.string,
    onRemoveItem: PropTypes.func
};

export { BaseList };
