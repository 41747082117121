'use strict';

class StorageStrategy {
    // The storage should contain a root object. Keys should represent the key for the data inside that root object.
    // data should support anything; but, certain storage types may have limitations outside of the strategy's control. Such remaining space on disk.

    // dictionary is a key-value pair of data to be saved on the correct key.
    async save(dictionary) {
        throw new Error('StorageStrategy.save() is abstract.');
    }

    get(key) {
        throw new Error('StorageStrategy.get() is abstract.');
    }

    async delete(key) {
        throw new Error('StorageStrategy.delete() is abstract.');
    }

    // Returns a promise.
    initialize() {
        throw new Error('StorageStrategy.initialize() is abstract.');
    }

    // This function is abstract; because, it should be implemented to send use-able debugging data.
    // Returns Promise<any>
    getDiagnosticData() {
        throw new Error('StorageStrategy.getDiagnosticData() is abstract.');
    }
}

export { StorageStrategy };
