
import {TextfieldPropsBuilder} from './TextfieldPropsBuilder';
import {InputType} from '../utils/InputType';

class NumberTextfieldPropsBuilder extends TextfieldPropsBuilder {
    _getInputType() {
        return InputType.NUMBER;
    }
}

export {NumberTextfieldPropsBuilder};
