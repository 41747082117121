'use strict';

import {Store} from '@totalpave/store';
import {MouseButtonDownAction} from '../actions/MouseButtonDownAction';
import {MouseButtonUpAction} from '../actions/MouseButtonUpAction';
import {MouseMoveAction} from '../actions/MouseMoveAction';
import {MouseOutOfWindowEvent} from '../event/MouseOutOfWindowEvent';

let _instance = null;

export class MouseStore extends Store {
    constructor() {
        super();

        this._mouseButtons = [];
        this._mouseX = 0;
        this._mouseY = 0;
        this._eventsAttached = false;

        this._onMouseDown = this._onMouseDown.bind(this);
        this._onMouseUp = this._onMouseUp.bind(this);
        this._onMouseMove = this._onMouseMove.bind(this);
        this._onMouseOut = this._onMouseOut.bind(this);

        this._attachEvents();
    }

    static getInstance() {
        if (!_instance) {
            _instance = new MouseStore();
        }
        return _instance;
    }

    _attachEvents() {
        if (!this._eventsAttached) {
            this._eventsAttached = true;
            document.body.addEventListener('mousedown', this._onMouseDown, true);
            document.body.addEventListener('mousemove', this._onMouseMove, true);
            document.body.addEventListener('mouseup', this._onMouseUp, true);
            window.addEventListener('mouseout', this._onMouseOut);
        }
    }

    _deattachEvents() {
        this._eventsAttached = false;
        document.body.removeEventListener('mousedown', this._onMouseDown, true);
        document.body.removeEventListener('mousemove', this._onMouseMove, true);
        document.body.removeEventListener('mouseup', this._onMouseUp, true);
        window.removeEventListener('mouseout', this._onMouseOut);
    }

    _onMouseDown(e) {
        MouseButtonDownAction.execute({button: e.button});
    }

    _onMouseUp(e) {
        MouseButtonUpAction.execute({button: e.button});
    }

    _onMouseMove(e) {
        MouseMoveAction.execute({x: e.clientX, y: e.clientY});
    }

    _onMouseOut(e) {
        let wWidth = window.innerWidth;
        let wHeight = window.innerHeight;
        let x = e.clientX;
        let y = e.clientY;

        if (x <= 0 || y <= 0 || x >= wWidth || y >= wHeight) {
            let err = new MouseOutOfWindowEvent();
            err.trigger(window);
        }
    }

    isButtonPressed(button) {
        return this._mouseButtons.indexOf(button) > -1;
    }

    getX() {
        return this._mouseX;
    }

    getY() {
        return this._mouseY;
    }

    _update(actionData) {
        let data = actionData.getData();
        let index = null;
        switch (actionData.getTag()) {
            case 'mousebutton_down':
                if (!this.isButtonPressed(data.button)) {
                    this._mouseButtons.push(data.button);
                }
                return true;
            case 'mousebutton_up':
                index = this._mouseButtons.indexOf(data.button);
                if (index > -1) {
                    this._mouseButtons.splice(index, 1);
                }
                return true;
            case 'mouse_position_update':
                this._mouseX = data.x;
                this._mouseY = data.y;
                return true;
            case 'request_logout_action':
                this._deattachEvents();
                return false;
            case 'logout_action':
                this._attachEvents();
                return false;
        }
    }
}
