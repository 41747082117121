'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';
import {MessageType} from '@totalpave/finterfaces';
import {ClassName} from '../utils/ClassName';
import {ClearMessageAction} from '../actions/ClearMessageAction';
import {Browser} from '../utils/Browser';
import {NodeGeometry} from '../utils/NodeGeometry';
import "../style/Message.less";
import {MessageModel} from '../model/MessageModel';


const DEFAULT_TIMER = 6000;

class Message extends React.Component {
    constructor(props) {
        super(props);
        this.timer = null;

        this.state = {};
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    componentDidMount() {
        this.timer;
        this.timer = setTimeout(() => {
            ClearMessageAction.execute({message: this.props.message});
        }, this.props.timer || DEFAULT_TIMER);
    }

    _getClassName() {
        return ClassName.execute({
            'Message': true,
            'error': this.props.message.getType() === MessageType.ERROR,
            'success': this.props.message.getType() === MessageType.SUCCESS
        });
    }

    _getInlineStyles() {
        return {};
    }

    render() {
        return <div
            className={this._getClassName()}
            ref={(n) => {
                if (n) {
                    if (Browser.isIE()) {
                        n.style.left = '50%';
                        n.style.marginLeft = -(NodeGeometry.getWidth(n) / 2) + 'px';
                    }
                }
            }}
            style={this._getInlineStyles()}
        >{this.props.message.getMessage()}</div>;
    }
}

Message.propTypes = {
    message: PropTypes.instanceOf(MessageModel).isRequired,
    timer: PropTypes.number
};

export { Message };
