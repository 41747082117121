'use strict';

import {Action} from './Action';
import {AuthClient} from '../net/AuthClient';

class ModifyUsersSingleton extends Action {
    constructor() { super(); }

    getTag() {
        return 'modify_users';
    }

    _shouldDispatchOnError() {
        return false;
    }

    async _execute({users} = {}) {
        await AuthClient.getInstance().updateUsers(users);
        return {
            users: users
        };
    }
}

const ModifyUsers = new ModifyUsersSingleton();
export {ModifyUsers};
