'use strict';

const TARGET_FPS = 1000 / 60;

export class Scroller {
    constructor(node) {
        this._node = node;
        this._active = false;
        this._duration = null;
        this.setDuration(100);

        this._stepX = null;
        this._stepY = null;
        this._targetX = null;
        this._targetY = null;

        this._run = this._run.bind(this);
    }

    isActive() {
        return this._active;
    }

    setDuration(duration) {
        this._duration = duration / TARGET_FPS;
    }

    _setActive(flag) {
        this._active = flag;
        if (!flag ){
            this._stepX = null;
            this._stepY = null;
        }
    }

    scroll(x, y) {
        this._targetX = x;
        this._targetY = y;
        this._calculateStep(x, y);
        this._setActive(true);

        window.requestAnimationFrame(this._run);
    }

    _calculateStep(targetX, targetY) {
        let node = this._node;
        let currentScrollX = node.scrollLeft;
        let currentScrollY = node.scrollTop;
        let duration = this._duration;
        let diffX = targetX - currentScrollX;
        let diffY = targetY - currentScrollY;

        if (duration === 0) {
            this._stepX = diffX;
            this._stepY = diffY;
            return;
        }

        if (diffX === 0) {
            this._stepX = 0;
        }
        else {
            this._stepX = diffX / duration;
        }

        if (diffY === 0) {
            this._stepY = 0;
        }
        else {
            this._stepY = diffY / duration;
        }
    }

    _run() {
        if (this._stepX && this._node.scrollLeft !== this._targetX) {
            let scrollX = this._node.scrollLeft;
            scrollX += this._stepX;
            if (this._stepX > 0) {
                if (scrollX > this._targetX) {
                    scrollX = this._targetX;
                }
            }
            else if (this._stepX < 0) {
                if (scrollX < this._targetX) {
                    scrollX = this._targetX;
                }
            }
            this._node.scrollLeft = scrollX;
        }

        if (this._stepY && this._node.scrollTop !== this._targetY) {
            let scrollY = this._node.scrollTop;
            scrollY += this._stepY;
            if (this._stepY > 0) {
                if (scrollY > 0) {
                    if (scrollY > this._targetY) {
                        scrollY = this._targetY;
                    }
                }
                else if (this._stepY < 0) {
                    if (scrollY < this._targetY) {
                        scrollY = this._targetY;
                    }
                }
            }
            this._node.scrollTop = scrollY;
        }

        if (this._node.scrollLeft === this._targetX && this._node.scrollTop === this._targetY) {
            this._setActive(false);
        }
        else {
            window.requestAnimationFrame(this._run);
        }
    }
}
