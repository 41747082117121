'use strict';

import { ApplicationInstance } from '@totalpave/application-instance';

class NotificationFactory {

    constructor() {
        // $messageMap is a system to prevent duplicate dialogs from appearing at the same time.
        // It is a map of <message title>|<message message> to notification promise.
        // If something attempts to create a dialog with the same title and message as an currently displayed
        // dialog, the promise for the currently displayed dialog will be returned.
        //
        // In the event that we decide to merge the NotificationFactory and the DispatchMessage system
        // the $messageMap system can probably be removed as the MessageStore in DispatchMessage's system
        // should be able to handle this.
        this.$messageMap = new Map();
    }

    getDefaultTitle() {
        return ApplicationInstance.getInstance().getConfig().name;
    }

    /*
        Please keep in mind that these properties should be mostly indentical to the CordovaNotificationFactory, unless
        there is good reason for them to differ.

        type    :   Notification Type. 
        props   :   Object Literal
            {
                message     :   Required String. Message to be displayed to user. 
                title       :   Optional String. Defaults to Application Name found in Application's config.
                buttons     :   Optional Object Array. Defaults to Cordova's default buttons. At the time of writing, Cordova's default buttons are "OK" and "Cancel".
                    [
                        {
                            text    :   Required String. Text to be displayed in Button.
                        }
                    ]
            }
    */
    create(type, props) {
        let mapKey = `${props.title}|${props.message}`;
        if (this.$messageMap.has(mapKey)) {
            return this.$messageMap.get(mapKey);
        }
        else {
            let promise = this._create(type, props);
            this.$messageMap.set(mapKey, promise);
            promise.then((result) => {
                this.$messageMap.delete(mapKey);
                return Promise.resolve(result);
            }).catch((error) => {
                this.$messageMap.delete(mapKey);
                return Promise.reject(error);
            });
            return promise;
        }
    }

    _create(type, props) {
        throw new Error('_create() is abstract.');
    }
}

/**
 * The result of the Confirmation dialog. Used in conjunction with NotificationFactory.CONFIRM
 */
NotificationFactory.ConfirmResult = {
    CONFIRMED: 1,
    DENIED: 2
};

NotificationFactory.NOTIFY                      = 0x0001; //Generic dialogue box
NotificationFactory.ALERT                       = 0x0002; //OK box
NotificationFactory.CONFIRM                     = 0x0003; //Confirm-Cancel box

export { NotificationFactory };
