'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';
import {ClassName} from '../utils/ClassName';
import "../style/ToggleBar.less";

class ToggleBar extends React.Component {
    constructor(props) {
        super(props);

        this._onChange = this._onChange.bind(this);
    }

    getClassName() {
        return ClassName.execute({
            'ToggleBar': true,
            'enabled': this.props.enabled,
            'readonly': this.props.readonly
        });
    }

    _onChange() {
        if (!this.props.readonly) {
            this.props.onChange(!this.props.enabled);
        }
    }

    render() {
        return (
            <div
                className={this.getClassName()}
                onClick={this._onChange}
            >
                <div className="toggle">
                    <div className="toggle-knob">&nbsp;</div>
                </div>
            </div>
        );
    }
}

ToggleBar.propTypes = {
    enabled: PropTypes.bool.isRequired,
    /**
     * function(enabled: boolean): void { }
     */
    onChange: PropTypes.func.isRequired,

    readonly: PropTypes.bool
}

export { ToggleBar };
