'use strict';

import "../style/TextMeasurer.less";

const node: HTMLDivElement = document.createElement('div');
node.className = 'TextMeasurer';
document.body.appendChild(node);

const DEFAULT_FAMILY: string = 'open-sans';
const DEFAULT_SIZE: number = 14;
const DEFAULT_WEIGHT: number = 200;
const DEFAULT_ITALIC: string = 'none';

let cache: Record<string, number> = {};

export class TextMeasurer {
    private constructor() {}

    public static measure(text: string, fontFamily?: string, fontSize?: number | string, fontWeight?: number | string, italic?: string): number {
        if (!fontFamily) {
            fontFamily = DEFAULT_FAMILY;
        }

        if (!fontSize) {
            fontSize = DEFAULT_SIZE;
        }

        if (!fontWeight) {
            fontWeight = DEFAULT_WEIGHT;
        }

        if (!italic) {
            italic = DEFAULT_ITALIC;
        }

        let key: string = `${text}|${fontFamily}|${fontSize}|${fontWeight}|${italic}`;

        if (cache[key] === undefined) {
            node.style.fontFamily = fontFamily;
            node.style.fontSize = fontSize.toString();
            node.style.fontWeight = fontWeight.toString();
            node.style.fontStyle = italic;
            node.innerHTML = text;

            cache[key] = node.clientWidth;
        }

        return cache[key];
    }
}
