'use strict';

import * as React from 'react';
import {IIconProps, Icon} from './Icon';
import {HTMLEntity} from '@totalpave/interfaces';

export interface IFontAwesomeIconProps extends IIconProps<string> {
    isAnimated?: boolean;
    pulse?: boolean;
    size?: string;
}

/**
 * Component that represents a FontAwesome icon.
 * See http://fontawesome.io/icons/ for more icons.
 *
 * props:
 *  string value : the name of the icon (without "fa-")
 *  string size  : the icon size (without the "fa-" prefix)
 */
export class FontAwesomeIcon extends Icon<IFontAwesomeIconProps> {
    public constructor(props: IFontAwesomeIconProps) {
        super(props);
    }

    public override getAdditionalCSSClass(): string {
        return `FontAwesomeIcon ${this.props.value}`;
    }

    private $getAnimationMode(): string {
        if (this.props.isAnimated) {
            if (this.props.pulse) {
                return 'fa-pulse';
            }
            else {
                return 'fa-spin';
            }
        }
        else {
            return '';
        }
    }

    public override _render(): React.ReactNode {
        let className = 'fa fa-' + this.props.value + ' ' + this.$getAnimationMode();
        if (this.props.size) {
            className += ' fa-' + this.props.size;
        }

        return <span className={className}>{HTMLEntity.NBSP}</span>;
    }
}
