'use strict';

import * as React from 'react';
import {ComparisonParamsBuilder} from './ComparisonParamsBuilder';
import {Select} from '../components/Select';

const SELECT_WIDTH = '100%';

export class ListComparisonParamsBuilder extends ComparisonParamsBuilder {
    constructor(extra) {
        super();

        this.options = extra.options;
    }

    getDefaultValue() {
        return null;
    }

    $findOptionByValue(value) {
        for (let i = 0; i < this.options.length; i++) {
            let option = this.options[i];
            if (option.getValue() === value) {
                return option;
            }
        }

        return null;
    }

    _buildSelectAndSpan(value, onChange, editable) {
        let option = this.$findOptionByValue(value);
        if (editable) {
            return <Select
                value={option}
                allowSelectNullOption={false}
                options={this.options}
                onChange={(option) => {
                    onChange(option.getValue())
                }}
                scrollable={true}
                width={SELECT_WIDTH}
            />;
        }
        else {
            return <span>{option.getText()}</span>
        }
    }

    _buildContains(option, onChange, editable) {
        return this._buildSelectAndSpan(option, onChange, editable);
    }

    _buildDoesNotContain(option, onChange, editable) {
        return this._buildSelectAndSpan(option, onChange, editable);
    }
}
