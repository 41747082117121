'use strict';

import {Action} from './Action';
import {IDGenerator} from '@totalpave/idgenerator';

class ModalPushActionSingleton extends Action {
    constructor() {
        super();
    }

    getTag() {
        return 'modal_push';
    }

    _shouldDispatchOnError() {
        return false;
    }

    /**
     * public execute
     *
     *  Display a modal. This will hide the current displayed modal, if there is one already displayed.
     *  Using ModalPopAction will hide the displayed modal, and display the most recent modal.
     *
     * Modal is expected to be the Class itself, not an instance. Props is the key/value pair that 
     * belong to the modal.
     * 
     * @param  {Modal Class} modal 
     * @param  {Object} props 
     * @return {void}
     */
    _execute({modal, props} = {}) {
        if (!props) {
            props = {};
        }

        props.key = IDGenerator.generate();
        
        return Promise.resolve({
            modal : modal,
            props : props
        });
    }
}

const ModalPushAction = new ModalPushActionSingleton();

export { ModalPushAction };
