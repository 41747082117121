'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';
import {VirtualTable} from './VirtualTable';
import {
    InfiniteLoader
} from 'react-virtualized';
import { ApplicationInstance } from '@totalpave/application-instance';

export class VirtualPaginatedTable extends VirtualTable {
    constructor(props) {
        super(props);
        this._onSectionRendered = this._onSectionRendered.bind(this);
        this._onRowsRendered = null; // Special case, see InfiniteLoader usage.
    }

    getClassName() {
        return super.getClassName() + ' PaginatedVirtualTable';
    }

    _contentCellRenderer(props) {
        let row = this.getViewData()[props.rowIndex];
        if (row) {
            return super._contentCellRenderer(props);
        }
        else {
            return <div className='no-content-row' style={props.style} key={props.key}></div>
        }
    }

    isPaginated() {
        return true;
    }

    enableFiltering() {
        return false;
    }

    getRowCount() {
        if (this.props.showLoadingMock) {
            return (this.getViewData() || []).length;
        }
        
        return this.props.totalRowCount;
    }

    resetRowCache() {
        this._loaderAPI.resetLoadMoreRowsCache(true);
    }

    _getContent(propsData) {
        return (
            <InfiniteLoader
                ref={(n) => {
                    this._loaderAPI = n;
                }}
                isRowLoaded={(index) => {
                    // this._onLoadingStateChange(false);
                    return this.props.isRowLoaded(index);
                }}
                loadMoreRows={async (startIndex, stopIndex) => {
                    this._onLoadingStateChange(true);
                    await this.props.loadMoreRows(startIndex, stopIndex);
                    this._onLoadingStateChange(false);
                }}
                rowCount={this.getTotalRowCount()}
                minimumBatchSize={this.props.minimumBatchSize}
            >
                {({onRowsRendered, registerChild}) => {
                    this._onRowsRendered = onRowsRendered;
                    propsData.onSectionRendered = this._onSectionRendered;
                    propsData.refCallback = registerChild;
                    return super._getContent(propsData)
                }}
            </InfiniteLoader>
        );
    }

    _onSectionRendered({ rowStartIndex, rowStopIndex }) {
        this._onRowsRendered({
            startIndex: rowStartIndex,
            stopIndex: rowStopIndex
        });
        this.props.onRowsRendered && this.props.onRowsRendered(rowStartIndex, rowStopIndex);
    }

    /**
     * Disables filtering. Network APIs are responsible
     * for filtering the datasets
     */
    _applyFilterRules(data) {
        if (!data) {
            data = this.state.data;
        }

        this.setState({
            filteredData: data,
            view: data
        });
        return Promise.resolve();
    }

    /**
     * Do nothing, let the `onDelete` prop refresh the data
     * via a network request.
     */
    _onDelete(selectionContext) {
        this.props.onDelete(selectionContext);
        selectionContext.reset();
        this.setState({
            selectAllValue: selectionContext.getAllSelectionState()
        });
    }


    onBulkEdit(bulkResult) {
        if (this.props.onBulkEdit) {
            return this.props.onBulkEdit(bulkResult, this.getSelectionContext());
        }
        else {
            ApplicationInstance.getInstance().getLogger().warn('onBulkEdit prop is not implemented.');
            return Promise.resolve();
        }
    }
}

VirtualPaginatedTable.propTypes = {
    loadMoreRows: PropTypes.func.isRequired,
    totalRowCount: PropTypes.number.isRequired,
    isRowLoaded: PropTypes.func.isRequired,
    minimumBatchSize: PropTypes.number,
    onBulkEdit: PropTypes.func,
    onRowsRendered: PropTypes.func
};
