
/**
 * @deprecated - Use @totalpave/ui-core instead
 */
export class ClassName {
    private constructor() {}

    public static execute(classes: Record<string, boolean>, defaultClasses?: string[]): string {
        let classString: string = '';

        if (!classes) {
            classes = {};
        }

        for (let i in classes) {
            if (classes[i]) {
                if (classString === '') {
                    classString += i;
                }
                else {
                    classString += ' ' + i;
                }
            }
        }

        if (defaultClasses) {
            classString += ' ' + defaultClasses.join(' ');
        }

        return classString;
    }
}
