'use strict';

import { TaskStatus } from './Task';
import { EventEmitter } from 'events';
import { TPError } from '@totalpave/error';
import { UserStore } from '../store/UserStore';

/*
    ON_CHANGE : Generic event that happens whenever another event occurs. ON_CHANGE will always fire first.
    ON_SCHEDULE : Fired when an task is scheduled. The task that was scheduled will be passed in. 
    ON_TASK_START : Fired when an task is starts. The task that was started will be passed in. 
    ON_TASK_END : Fired when an task is ends. The task that was ended will be passed in. 
*/
const QueueEvents = {
    "ON_CHANGE":"onChange",
    "ON_SCHEDULE":"onSchedule",
    "ON_TASK_START":"onTaskStart",
    "ON_TASK_END":"onTaskEnd",
    "ON_QUEUE_IDLE":"onQueueIdle"
}

const LOGOUT_ERROR = "App is logging out. No Tasks can be scheduled at this time.";

class Queue extends EventEmitter {
    constructor(forceExecute) {
        super();
        this._tasks = [];
        this._isRunning = false;
        this._forceExecute = !!forceExecute; //forceExecute should not be used lightly. forceExecute will prevent the Queue from clearing and preventing taskes on logout.

        this._onSchedule = this._onSchedule.bind(this);
    }

    _onSchedule(task){ //Used to start running tasks if an task is scheduled and the Queue is not currently running.
        this.emit(QueueEvents.ON_CHANGE);
        this.emit(QueueEvents.ON_SCHEDULE, task);

        if (!this._isRunning){
            this._run();
        }
    }

    schedule(task){
        if (!this._forceExecute && UserStore.getInstance().shouldLogout()) { //We are logging out, scheduled tasks that are pending will be canceled.
            throw LOGOUT_ERROR;
        }

        this._tasks.push(task);
        task.setStatus(TaskStatus.PENDING);
        this._onSchedule(task);
    }

    isQueueRunning() {
        return this._isRunning;
    }

    /*
        Searchs queue for task with specified id. Returns true/false.
    */
    isInQueue(taskId){
        return !!this.getStatus(taskId);
    }

    getStatuses() {
        return this._tasks.map(this._taskToStatus);
    }

    _taskToStatus(task) {
        return {
            'id' : task.getId(),
            'status' : task.getStatus()
        };
    }

    getStatus(taskId) {
        let task = this._tasks.find((task) => {
            return task.getId() === taskId;
        });

        return task ? this._taskToStatus(task) : null;
    }

    /**
     * public register
     *
     *  Registers a handler to be invoked when the store is updated.
     * 
     * @param  {String} event name. See QueueEvents. 
     * @param  {Function} callback 
     * @return {void}
     */
    register(event, callback) {
        this.on(event, callback);
    }

    /**
     *  public unregister
     *
     *  Unregisters a handler that was listening for store updates.
     * 
     * @param  {String} event name. See QueueEvents. 
     * @param  {Function}
     * @return {void}
     */
    unregister(event, callback) {
        this.removeListener(event, callback);
    }

    _run(){
        this._isRunning = this._tasks.length > 0;
        if (this._isRunning){
            let task = this._tasks[0];
            if (!this._forceExecute && UserStore.getInstance().shouldLogout()) {
                //Cancel scheduled tasks. This is important; because, functionality that uses the queue generally has a lot of stuff to work with. 
                //Which means the queue may have a couple of minutes of tasks scheduled. If a task has an action, most actions can not be executed at this time anyways.
                task.setError(new TPError(LOGOUT_ERROR, true));
                this._endTask(task);
            }
            else {
                this._startTask(task);
            }
        }
        else {
            this.emit(QueueEvents.ON_QUEUE_IDLE);
        }
    }

    _startTask(task){
        window.setTimeout(() => {
            task.setStatus(TaskStatus.ACTIVE);

            this.emit(QueueEvents.ON_CHANGE);
            this.emit(QueueEvents.ON_TASK_START, task);

            task.execute().then(() => {
                task.setError(null); //In case if task was rescheduled
                this._endTask(task);
            }).catch((error) => {
                task.setError(error);
                this._endTask(task);
            });
        }, task.getDelay());
    }

    _endTask(task){
        task.setStatus(TaskStatus.DONE);
        this._tasks.shift();

        this.emit(QueueEvents.ON_CHANGE);
        this.emit(QueueEvents.ON_TASK_END, task);

        this._run();
    }
}

export { Queue, QueueEvents };
