'use strict';

import { DataType } from '@totalpave/interfaces';
import {ObjectUtils} from '../utils/ObjectUtils';

class TableDefinitionFactory {
    constructor() {
        this._init.apply(this, arguments);
        this._defs = this._createDefinitions();
    }

    _init() {}

    setDefinitions(defs) {
        this._defs = defs;
    }

    addDefinition(key, def) {
        this._defs[key] = def;
    }

    getDefinition(key) {
        return ObjectUtils.clone(this._defs[key]);
    }

    getDefinitions() {
        return ObjectUtils.clone(this._defs);
    }

    getAvailableHeaders() {
        return Object.keys(this._defs);
    }

    hasDefinition(key) {
        return !!this._defs[key];
    }

    _getDefs() {
        return this._defs;
    }

    create(key) {
        if (this.hasDefinition(key)) {
            return this._create(key);
        }
        else {
            throw new Error(`${key} is an unsupported table definition`);
        }
    }

    _create(key) {
        return this.getDefinition(key);
    }

    _createDefinitions() {
        throw new Error('TableDefinitionFactory._createDefinitions is abstract.');
    }

    /**
     * @since 20.2.0
     * Returns the table definitions for search queries
     */
    getQueryDefinitions(adapter) {
        let out = [];

        for (let i in (this._defs)) {
            let def = this._defs[i];

            let queryDefinition = {
                column: adapter.adapt(i),
                type: def.type
            };

            if (def.filterOptions) {
                queryDefinition.filterOptions = def.filterOptions;
            }

            if (def.type === DataType.LIST) {
                if (!queryDefinition.extra) {
                    queryDefinition.extra = {};
                }

                queryDefinition.extra.options = def.extra.options.map((v) => {
                    return v.serialize();
                });
            }

            out.push(queryDefinition);
        }

        return out;
    }
}

export { TableDefinitionFactory };
