'use strict';

/** @deprecated Use @totalpave/ui-menu */
export class SelectOption /*implements IFuzzySearchable*/ {
    constructor(text, value, options = {}) {
        this._text = text;
        this._value = value;
        this._group = null;
        this._onDelete = null;
        this._isHighlight = false;
        this._enabled = true;
        this._userClasses = options.userClasses || {};

        if (options.enabled !== undefined) {
            this.setEnabled(options.enabled);
        }
    }

    setUserClasses(userClasses) {
        this._userClasses = userClasses;
    }

    getUserClasses() {
        return this._userClasses;
    }

    setEnabled(enabled) {
        this._enabled = enabled;
    }

    isEnabled() {
        return this._enabled;
    }

    isHighlightable() {
        return true;
    }

    setHighlight(flag) {
        this._isHighlight = flag;
    }

    isHighlight() {
        return this._isHighlight;
    }

    setGroup(group) {
        this._group = group;
    }

    getGroup() {
        return this._group;
    }

    getText() {
        return this._text;
    }

    getTitle() {
        return this._title || this._text;
    }

    getValue() {
        return this._value;
    }

    setOnDelete(fn) {
        this._onDelete = fn;
    }

    getOnDelete() {
        return this._onDelete;
    }

    clone() {
        let opt = new SelectOption(this.getText(), this.getValue());
        opt.setOnDelete(this.getOnDelete());
        return opt;
    }

    getFuzzyValues() {
        return this.serialize();
    }

    /**
     * @since 20.2.0
     * @returns 
     */
    serialize() {
        return {
            text: this.getText(),
            value: this.getValue()
        };
    }

    getFuzzyKeys() {
        return SelectOption.getFuzzyKeys();
    }

    static getFuzzyKeys() {
        return [
            'text',
            'value'
        ];
    }
}
