'use strict';

const IS_OLDER    = -1;
const IS_EQUAL    =  0;
const IS_NEWER    =  1;

class Version {
    constructor(versionString) {
        this._version = versionString;
        this._parsed = this._parseVersion(this._version);
    }

    toString() {
        return this._version;
    }

    valueOf() {
        return this.toString();
    }

    getVersion() {
        return this._parsed;
    }

    getMajor() {
        return this._parsed.major;
    }

    getMinor() {
        return this._parsed.minor;
    }

    getPatch() {
        return this._parsed.patch;
    }

    isEqual(version) {
        return this.compare(version) === 0;
    }

    /**
     * public compare
     * @param  {Version} version 
     * @return {Integer}            -1 Means "this" version is less than (older) of the given version.
     *                              0  Means "this" version is equal to of the given version.
     *                              1  Means "this" version is greater (newer) of the given version.
     */
    compare(version) {
        if (this.getMajor() > version.getMajor() || this.getMajor() < version.getMajor()) {
            return (this.getMajor() > version.getMajor()) ? IS_NEWER : IS_OLDER;
        }
        else {
            //Major are equal
            if (this.getMinor() > version.getMinor() || this.getMinor() < version.getMinor()) {
                return (this.getMinor() > version.getMinor()) ? IS_NEWER : IS_OLDER;
            }
            else {
                //Minor are equal
                if (this.getPatch() > version.getPatch() || this.getPatch() < version.getPatch()) {
                    return (this.getPatch() > version.getPatch()) ? IS_NEWER : IS_OLDER;
                }
                else {
                    // All parts are equal
                    return IS_EQUAL;
                }
            }
        }
    }

    _parseVersion(version) {
        let parts = version.split('.');
        let major = 0, minor = 0, patch = 0;

        for (let i = 0; i < parts.length; i++) {
            switch (i) {
                case 0:
                    major = parseInt(parts[i]);
                    break;
                case 1:
                    minor = parseInt(parts[i]);
                    break;
                case 2:
                    patch = parseInt(parts[i]);
                    break;
            }
        }

        return {
            major : major,
            minor : minor,
            patch : patch
        };
    }
}

export { 
    Version,
    IS_NEWER,
    IS_OLDER,
    IS_EQUAL
};
