'use strict';

import { PreferenceStore } from "../store/PreferenceStore";

const TR_DEFAULT_MARKER_COLORS = [
    "#6F6FB5",
    "#61B761",
    "#B55858",
    "#54A6A6",
    "#D28AD2",
    "#DCDC62",
    "#F7B24D"
];
const TR_MARKER_COLOR_PREFERENCE_KEY = "tr_marker_colors";
const TR_MARKER_COLOR_PREFERENCE_VERSION = 0;

class TrackerUtils {
    static getMarkerColors() {
        let colors = PreferenceStore.getInstance().getOrganizationPreference(TR_MARKER_COLOR_PREFERENCE_KEY, TR_MARKER_COLOR_PREFERENCE_VERSION);

        if (colors) {
            colors = JSON.parse(colors);

            if (colors.length > 0) {
                return colors;
            }
        } 

        return TR_DEFAULT_MARKER_COLORS.slice();
    }
}

TrackerUtils.TR_MARKER_COLOR_PREFERENCE_KEY = TR_MARKER_COLOR_PREFERENCE_KEY;
TrackerUtils.TR_MARKER_COLOR_PREFERENCE_VERSION = TR_MARKER_COLOR_PREFERENCE_VERSION;

export { TrackerUtils };
