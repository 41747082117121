'use strict';

import {Store} from '@totalpave/store';
import {AddBodyClass} from '../actions/AddBodyClass';
import {RemoveBodyClass} from '../actions/RemoveBodyClass';
import {NodeGeometry} from '../utils/NodeGeometry';
import {MouseButton} from '../utils/MouseButton';

let _instance = null;

export class DragStore extends Store {
    constructor() {
        super();

        this._draggingItem = null;
        this._draggingNode = null;
        this._dropzone = null;

        this._mouseX = 0;
        this._mouseY = 0;
        this._offsetX = 0;
        this._offsetY = 0;
    }

    static getInstance() {
        if (!_instance) {
            _instance = new DragStore();
        }
        return _instance;
    }

    getDraggingItem() {
        return this._draggingItem;
    }

    getActiveDropzone() {
        return this._dropzone;
    }

    _update(actionData) {
        let data = actionData.getData();
        switch (actionData.getTag()) {
            case 'mouse_position_update':
                this._mouseX = data.x;
                this._mouseY = data.y;
                if (this._draggingNode) {
                    this._draggingNode.style.left = `${this._mouseX - this._offsetX}px`;
                    this._draggingNode.style.top = `${this._mouseY - this._offsetY}px`;
                    if (this.getActiveDropzone()) {
                        this.getActiveDropzone().willAccept(this.getDraggingItem());
                    }
                    return true;
                }
                return false;
            case 'set-dropzone':
                this._dropzone = data;
                return true;
            case 'start-dnd':
                this._draggingItem = data;
                this._draggingNode = data.getDragNode();

                this._offsetX = Math.abs(this._draggingItem.getX() - this._mouseX - NodeGeometry.getScrollX(this._draggingItem.getNode()));
                this._offsetY = Math.abs(this._draggingItem.getY() - this._mouseY - NodeGeometry.getScrollY(this._draggingItem.getNode()));

                this._draggingNode.style.left = `${this._mouseX - this._offsetX}px`;
                this._draggingNode.style.top = `${this._mouseY - this._offsetY}px`;

                document.body.appendChild(this._draggingNode);
                AddBodyClass.execute('dragging');
                return true;
            case 'mousebutton_up':
                if (this.getActiveDropzone() && this.getDraggingItem() && this.getActiveDropzone().willAccept(this.getDraggingItem())) {
                    this.getActiveDropzone().drop(this.getDraggingItem());
                }

                //cleanup
                if (data.button === MouseButton.LEFT && this._draggingNode) {
                    document.body.removeChild(this._draggingNode);
                    this._draggingNode = null;
                }

                RemoveBodyClass.execute('dragging');
                this._draggingItem = null;
                this._offsetX = 0;
                this._offsetY = 0;
                return true;
            case 'dnd-move':
                return true;
            default: 
                return false;
        }
    }
}
