
class CustomEvent {
    constructor(detail) {
        this._e = document.createEvent('Event');

        this._initEvent(this._e, detail);
    }

    getEventName() {
        throw new Error('getEventName is abstract');
    }

    canBubble() {
        return true;
    }

    isCancelable() {
        return true;
    }

    _initEvent(e, detail) {
        !!detail 
            ? e.initCustomEvent(this.getEventName(), this.canBubble(), this.isCancelable(), detail)
            : e.initEvent(this.getEventName(), this.canBubble(), this.isCancelable(), detail);
    }

    trigger(target) {
        target.dispatchEvent(this._e);
    }
}

export {CustomEvent}
