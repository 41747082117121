'use strict';

import {Task, TaskEvents} from '../queue/Task';
import {TPError} from '@totalpave/error';

//Task for deleting files. This task is meant to be used internally in FileSystem.
class DeleteFileTask extends Task {
    constructor(id, tpFileEntry) {
        super(id);
        this._forceExecute = true;
        this.tpFileEntry = tpFileEntry;
    }

    execute(){
        return new Promise((resolve, reject) => {
            this.tpFileEntry.remove(() => { 
                this.emit(TaskEvents.ON_SUCCESS);
                this.emit(TaskEvents.ON_FINISH);
                resolve(); 
            }, (error) => { 
                this.emit(TaskEvents.ON_FAIL, error);
                this.emit(TaskEvents.ON_FINISH, error);
                reject(
                    new TPError(
                        {
                            message: "Oops! Could not delete file.", 
                            details: { 
                                function: "DeleteFileTask.execute", 
                                error: error, 
                                tpFileEntry: this.tpFileEntry.toJSON()
                            }
                        },
                        true
                    )
                );
            });
        });
    }
}

export { DeleteFileTask };
