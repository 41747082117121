
let _instance = null;

/**
 * @deprecated - Use standard dom.classList instead
 */
class CSSClassUtils {
    constructor() {}

    static getInstance() {
        if (!_instance) {
            _instance = new CSSClassUtils();
        }

        return _instance;
    }

    contains(node, className) {
        if (!node || (node && !node.getAttribute)) {
            return false;
        }

        let classNames = node.getAttribute('class');
        if (!classNames) {
            return false;
        }

        let names = classNames.split(' ');
        return names.indexOf(className) > -1;
    }
}

export {CSSClassUtils};
