'use strict';

/**
 * @deprecated - An abstract "Factory" like this has no purpose
 */
class Factory {
    constructor() {}

    // eslint-disable-next-line
    create(value, props) {
        throw new Error('create() is abstract.');
    }
}

export { Factory };
