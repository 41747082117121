'use strict';

import {Action} from './Action';
import { OrientationType } from '../utils/OrientationType';

export interface IUpdateOrientationKWargs {
    orientation: OrientationType;
}

class UpdateOrientationSingleton extends Action<IUpdateOrientationKWargs, OrientationType> {

    public override getTag(): string {
        return 'update_orientation';
    }

    protected override _shouldDispatchOnError(): boolean {
        return false;
    }

    protected override async _execute(args: IUpdateOrientationKWargs) {
        return Promise.resolve(args.orientation);
    }
}

const UpdateOrientation = new UpdateOrientationSingleton();

export default UpdateOrientation;
export { UpdateOrientation };
