'use strict';

const mergeConfig = (a, b) => {
    let config = {};

    for (let i in a) {
        config[i] = a[i];
    }

    for (let j in b) {
        config[j] = b[j];
    }

    return config;
};

class ConfigLoader {
    constructor() {
        throw new Error('ConfigLoader is static and cannot be instantiated.');
    }

    static load(mainConfig, localConfig) {
        return mergeConfig(mainConfig, localConfig);
    }
}

export {ConfigLoader};
