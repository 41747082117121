'use strict';

import {Action} from './Action';

class ViewportSizeUpdateSingleton extends Action {
    protected override _shouldDispatchOnError(): boolean {
        return false;
    }

    public override getTag(): string {
        return 'viewport_size_update';
    }

    protected override async _execute() {
        return Promise.resolve();
    }
}

const ViewportSizeUpdate = new ViewportSizeUpdateSingleton();

export { ViewportSizeUpdate };
