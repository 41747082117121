'use strict';

import {Action} from './Action';

class ShowToolTipSingleton extends Action {
    getTag() {
        return 'show-tool-tip';
    }

    _shouldDispatchOnError() {
        return false;
    }

    _execute({toolTip} = {}) {
        return Promise.resolve(toolTip);
    }
}

const ShowToolTip = new ShowToolTipSingleton();

export {ShowToolTip};
