'use strict';

import * as React from 'react';
import {ApplicationInstance} from '@totalpave/application-instance';
import {ClassName} from '../utils/ClassName';
import {IconFactory} from '../factories/IconFactory';
import {Grid, ScrollSync} from 'react-virtualized';
import {Checkbox} from './Checkbox';
import {BulkEditModal} from './BulkEditModal';
import {ModalPushAction} from '../actions/ModalPushAction';
import {ScrollbarMeasurement} from '../utils/ScrollbarMeasurement';
import {Table} from './Table';
import {Scroller} from '../utils/Scroller';
import PropTypes from 'prop-types';
import {BulkEditIcon} from './BulkEditIcon';

const ROW_HEIGHT = 40;

//This is to correct the height since the library incorrectly determines the available space when
//flex nodes are present
const __HACK_HEIGHT_FIX = ROW_HEIGHT;

//Proxy to react-virtualized library
class VirtualTable extends Table {
    constructor(props) {
        super(props);
        this._renderScrollSync = this._renderScrollSync.bind(this);
        this.cellRangeRenderer = this.cellRangeRenderer.bind(this);
        this._onEditNodeTransitionEnd = this._onEditNodeTransitionEnd.bind(this);
        this._rowCache = {};
        this._lastScrollPosition = {x:0, y:0};
    }

    getScrollingNode() {
        //Hack (because of accessing an internal variable) to get scrolling container
        return this.getDataGrid()._scrollingContainer;
    }

    _onEditNodeTransitionEnd() {
        this.recompute();
    }

    getClassName() {
        return 'VirtualTable';
    }

    //Forked from https://github.com/bvaughn/react-virtualized/blob/master/source/Grid/defaultCellRangeRenderer.js
    cellRangeRenderer({
        cellCache,
        cellRenderer,
        columnSizeAndPositionManager,
        columnStartIndex,
        columnStopIndex,
        deferredMeasurementCache,
        horizontalOffsetAdjustment,
        isScrolling,
        parent, // Grid (or List or Table)
        rowSizeAndPositionManager,
        rowStartIndex,
        rowStopIndex,
        styleCache,
        verticalOffsetAdjustment,
        visibleColumnIndices,
        visibleRowIndices
    }) {
        let renderedCells = [];
        let renderedRows = [];

        // Browsers have native size limits for elements (eg Chrome 33M pixels, IE 1.5M pixes).
        // User cannot scroll beyond these size limitations.
        // In order to work around this, ScalingCellSizeAndPositionManager compresses offsets.
        // We should never cache styles for compressed offsets though as this can lead to bugs.
        // See issue #576 for more.
        const areOffsetsAdjusted = columnSizeAndPositionManager.areOffsetsAdjusted() || rowSizeAndPositionManager.areOffsetsAdjusted();
        const canCacheStyle = !isScrolling && !areOffsetsAdjusted;

        for (let rowIndex = rowStartIndex; rowIndex <= rowStopIndex; rowIndex++) {
            let rowDatum = rowSizeAndPositionManager.getSizeAndPositionOfCell(rowIndex);

            for (let columnIndex = columnStartIndex; columnIndex <= columnStopIndex; columnIndex++) {
                let columnDatum = columnSizeAndPositionManager.getSizeAndPositionOfCell(columnIndex);
                let isVisible = columnIndex >= visibleColumnIndices.start && columnIndex <= visibleColumnIndices.stop && rowIndex >= visibleRowIndices.start && rowIndex <= visibleRowIndices.stop;
                let key = `${rowIndex}-${columnIndex}`;
                let style;

                // Cache style objects so shallow-compare doesn't re-render unnecessarily.
                if (canCacheStyle && styleCache[key]) {
                    style = styleCache[key];
                } 
                else {
                    // In deferred mode, cells will be initially rendered before we know their size.
                    // Don't interfere with CellMeasurer's measurements by setting an invalid size.
                    if (deferredMeasurementCache && !deferredMeasurementCache.has(rowIndex, columnIndex)) {
                        // Position not-yet-measured cells at top/left 0,0,
                        // And give them width/height of 'auto' so they can grow larger than the parent Grid if necessary.
                        // Positioning them further to the right/bottom influences their measured size.
                        style = {
                            height: 'auto',
                            left: 0,
                            position: 'absolute',
                            top: 0,
                            width: 'auto'
                        };
                    } 
                    else {
                        style = {
                            height: rowDatum.size,
                            left: columnDatum.offset + horizontalOffsetAdjustment,
                            position: 'absolute',
                            top: rowDatum.offset + verticalOffsetAdjustment,
                            width: columnDatum.size
                        };

                        styleCache[key] = style;
                    }
                }

                let cellRendererParams = {
                    columnIndex,
                    isScrolling,
                    isVisible,
                    key,
                    parent,
                    rowIndex,
                    style
                };

                let renderedCell;

                // Avoid re-creating cells while scrolling.
                // This can lead to the same cell being created many times and can cause performance issues for "heavy" cells.
                // If a scroll is in progress- cache and reuse cells.
                // This cache will be thrown away once scrolling completes.
                // However if we are scaling scroll positions and sizes, we should also avoid caching.
                // This is because the offset changes slightly as scroll position changes and caching leads to stale values.
                // For more info refer to issue #395
                if (isScrolling && !horizontalOffsetAdjustment && !verticalOffsetAdjustment) {
                    if (!cellCache[key]) {
                        cellCache[key] = cellRenderer(cellRendererParams);
                    }

                    renderedCell = cellCache[key];

                    // If the user is no longer scrolling, don't cache cells.
                    // This makes dynamic cell content difficult for users and would also lead to a heavier memory footprint.
                } 
                else {
                    renderedCell = cellRenderer(cellRendererParams);
                }

                if (renderedCell === null || renderedCell === false) {
                    continue;
                }

                if (process.env.NODE_ENV !== 'production') {
                    this._warnAboutMissingStyle(parent, renderedCell);
                }

                renderedCells.push(renderedCell);
            }

            if (isScrolling && !horizontalOffsetAdjustment && !verticalOffsetAdjustment) {
                if (!this._rowCache[rowIndex]) {
                    this._rowCache[rowIndex] = this.rowRenderer(rowIndex, rowDatum.size, renderedCells);
                }

                renderedRows.push(this._rowCache[rowIndex]);
            }
            else {
                this._rowCache = {};
                renderedRows.push(this.rowRenderer(rowIndex, rowDatum.size, renderedCells));
            }

            renderedCells = [];
        }

        // return renderedCells;
        return renderedRows;
    }

    rowRenderer(rowIndex, height, renderedCells) {
        return <div key={rowIndex} className="table-row" style={{height: height}}>{renderedCells}</div>;
    }

    _warnAboutMissingStyle(parent, renderedCell) {
        if (process.env.NODE_ENV !== 'production') {
            if (renderedCell) {
                // If the direct child is a CellMeasurer, then we should check its child
                // See issue #611
                if (renderedCell.type && renderedCell.type.__internalCellMeasurerFlag) {
                    renderedCell = renderedCell.props.children;
                }

                if (renderedCell && renderedCell.props && renderedCell.props.style === undefined && parent.__warnedAboutMissingStyle !== true) {
                    parent.__warnedAboutMissingStyle = true;

                    ApplicationInstance.getInstance().getLogger().warn('Rendered cell should include style property for positioning.');
                }
            }
        }
    }

    _getRenderNode() {
        return this._tableContainerNode;
    }

    _renderScrollSync({onScroll, scrollLeft}) {
        const rowHeight = ROW_HEIGHT;
        const overscanColumnCount = Infinity;
        let columnCount = this.getColumnCount();
        let width = 0;
        let height = 0;
        this.setAutoWidthPerColumn(this.calculateAutoWidths());

        if (!this.props.width && this.getNode()) {
            width = this.getNode().clientWidth;
        }
        else if (this.props.width) {
            width = this.props.width;
        }
        else {
            width = 0;
        }

        if (this._getComparisonComponentNode()) {
            width -= this._getComparisonComponentNode().getNode().clientWidth;
        }

        if (!this.props.height && this.getNode()) {
            height = this.getNode().clientHeight;
        }
        else if (this.props.height) {
            height = this.props.height;
        }
        else {
            height = 0;
        }

        let flatButtons = null;
        if (this.isEditing()) {
            flatButtons = [
                <div key="fl1" className="flat-button save-button" onClick={() => {
                    this._onEditSubmission()
                }}>
                    {IconFactory.create('save')}
                    <span>Save</span>
                </div>,
                <div key="fl2" className="flat-button cancel-button" onClick={() => {
                    this.revertChanges()
                }}>
                    {IconFactory.create('cancel')}
                    <span>Cancel</span>
                </div>
            ];
        }
        else {
            flatButtons = [];

            if (this.isEditable()) {
                flatButtons.push(<div key="fl1" className="flat-button edit-button" onClick={() => {
                    this.setEditingState(true);
                }}>
                    {IconFactory.create('edit')}
                    <span>Edit</span>
                </div>);
            }

            if (this.isBulkEditable()) {
                flatButtons.push(<div key="fl2" className="flat-button bulk-edit-button" onClick={() => {
                    ModalPushAction.execute({
                        modal: BulkEditModal,
                        props: {
                            definitionFactory: this.props.definitionFactory,
                            editorFactory: this.getTableEditorFactory(),
                            onSubmit: (data) => {
                                return this.onBulkEdit(data);
                            }
                        }
                    });
                }}>
                    {/* {IconFactory.create('edit')} */}
                    <BulkEditIcon />
                    <span>Bulk Edit</span>
                </div>);
            }

            if (this.isDeletable()) {
                flatButtons.push(<div key="fl3" className="flat-button delete-button" onClick={() => {
                    this.showDeletePrompt()
                }}>
                    {IconFactory.create('delete')}
                    <span>Delete</span>
                </div>);
            }
        }


        let editingUI = (
            <div className={ClassName.execute({
                'edit-ui': true,
                'expanded': this.getSelectionCount() > 0
            })} ref={(node) => {
                if (node) {
                    this._editingUINode = node;
                    this._editingUINode.addEventListener('transitionend', this._onEditNodeTransitionEnd);
                }
                else {
                    if (this._editingUINode) {
                        this._editingUINode.removeEventListener('transitionend', this._onEditNodeTransitionEnd);
                        this._editingUINode = null;
                    }
                }
            }}>
                <Checkbox value={this.getSelectionContext().getAllSelectionState()} readonly={this.isEditing()} onChange={this._onSelectAllChange} />
                {IconFactory.create('arrow-right')}
                <span className="selection-count-label">{this.getSelectionCount()} selected</span>
                <div className="flat-buttons">
                    {flatButtons}
                </div>
            </div>
        );

        let headerUI = <Grid
            ref={(c) => { this.setHeaderGrid(c); }}
            cellRenderer={this._headerCellRenderer}
            width={width - ScrollbarMeasurement.getWidth()}
            height={rowHeight}
            rowHeight={rowHeight}
            columnWidth={this.calculateColumnWidth}
            estimatedColumnSize={100}
            rowCount={1}
            containerStyle={{backgroundColor:'rgb(12, 128, 195)'}}
            columnCount={columnCount}
            scrollLeft={scrollLeft}
            overscanRowCount={0}
            overscanColumnCount={overscanColumnCount}
            className="heading-table no-overflow"
        />;

        return (
            <div className="table-container" ref={(node) => {
                this._tableContainerNode = node;
            }}>
                <div className={ClassName.execute({
                    'headers': true
                })}>
                    {editingUI}
                    {headerUI}
                </div>
                <div className="content-table">
                    {this._getContent({
                        onScroll: onScroll,
                        overscanColumnCount: overscanColumnCount,
                        width: width,
                        height: height,
                        rowHeight: rowHeight,
                        columnCount: columnCount
                    })}
                </div>
            </div>
        );
    }
    /**
     * 
     * @param {any} propData 
     * {
     *  onScroll, overscanColumnCount, width, height, rowHeight, columnCount, onSectionRendered, refCallback
     * }
     */
    _getContent(propData) {
        return <Grid 
            key={this.props.dataTableKey || 'data-table'}
            ref={(c) => {
                this.setDataGrid(c);
                propData.refCallback && propData.refCallback(c);
            }}
            onSectionRendered={propData.onSectionRendered}
            onScroll={propData.onScroll} 
            overscanRowCount={this.props.overscanRowCount || 0}
            overscanColumnCount={this.props.overscanColumnCount || propData.overscanColumnCount || Infinity}
            cellRenderer={this._contentCellRenderer}
            containerStyle={{
                backgroundColor: 'white'
            }}
            width={propData.width}
            height={propData.height - __HACK_HEIGHT_FIX - (this.getSelectionCount() > 0 ? propData.rowHeight : 0)}
            estimatedColumnSize={100}
            columnWidth={this.calculateColumnWidth}
            rowHeight={propData.rowHeight}
            columnCount={propData.columnCount}
            rowCount={this.getRowCount()}
            className="body-table"
            noContentRenderer={this.props.noContentRenderer || (() => {
                return <div className="no-content-table-data-view">No content available</div>;
            })}
        />
    }

    _onEditStateChange(state) {
        if (!this._scroller) {
            let node = this.getScrollingNode();
            this._scroller = new Scroller(node);
            this._scroller.setDuration(0);
        }
        
        if (state) {
            this._originalScrollY = this.getScrollingNode().scrollTop;
            this._scroller.scroll(0, 0);
        }
        else {
            this._scroller.scroll(0, this._originalScrollY);
        }
    }

    _render() {
        return (
            <ScrollSync>
                {
                    (scrollSyncData) => {
                        return this._renderScrollSync(scrollSyncData);
                    }
                }
            </ScrollSync>
        );
    }
}

VirtualTable.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    overscanRowCount: PropTypes.number,
    overscanColumnCount: PropTypes.number,
    noContentRenderer: PropTypes.func
};

export { VirtualTable };
export default VirtualTable;
