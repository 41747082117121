'use strict';

import {Action} from './Action';

class MouseMoveActionSingleton extends Action {
    getTag() {
        return 'mouse_position_update';
    }

    _shouldDispatchOnError() {
        return false;
    }

    _execute({x, y} = {}) {
        return Promise.resolve({x: x, y: y});
    }
}

const MouseMoveAction = new MouseMoveActionSingleton();

export { MouseMoveAction };
