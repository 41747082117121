'use strict';

import * as React from 'react';
import {ComparisonParamsBuilder} from '../builders/ComparisonParamsBuilder';
import {Textfield, InputMode} from '../components/Textfield';

export class StringComparisonParamsBuilder extends ComparisonParamsBuilder {
    // Abstraction for very simple and common UI
    _buildTextfieldAndSpan(value, onChange, editable) {
        if (editable) {
            return <Textfield value={value} onChange={onChange} inputMode={InputMode.TEXT} />
        }
        else {
            return <span>{value}</span>;
        }
    }

    _buildIsEqualTo(value, callback, editable) {
        return this._buildTextfieldAndSpan(value, callback, editable);
    }

    _buildIsNotEqualTo(value, callback, editable) {
        return this._buildTextfieldAndSpan(value, callback, editable);
    }

    _buildContains(value, callback, editable) {
        return this._buildTextfieldAndSpan(value, callback, editable);
    }

    _buildDoesNotContain(value, callback, editable) {
        return this._buildTextfieldAndSpan(value, callback, editable);
    }
}
