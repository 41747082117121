'use strict';

import { EventEmitter } from 'events';

const TaskStatus = {
    "INACTIVE":0,
    "PENDING":1,
    "ACTIVE":2,
    "DONE":3
};

/*
    WARNING : 
        Events are emitted based on the Subclass's implementation.
        Emitting Events is not enforced.


    ON_SUCCESS : Intended to be fired just before a Task finishes successfully.
    ON_FAIL : Intended to be fired just before a Task finishes in failure.
    ON_FINISH : Intended to be fired just before a Task finishes in Success or Failure.
*/
const TaskEvents = {
    ON_SUCCESS: "onSucces",
    ON_FAIL: "onFail",
    ON_FINISH: "onFinish"
}

//Base class used by Queue. You should create another class that extends Task.
class Task extends EventEmitter {
    //Override constructor and call super in order to get parameters for your execute.
    constructor(id) {
        super();
        this._status = TaskStatus.INACTIVE;
        this._error;
        this._id = id;
        this._delay = 0;
    }

    setId(id) {
        this._id = id;
    }

    getId() {
        return this._id;
    }

    //delay in milliseconds before this task will execute.
    setDelay(num) {
        if (isNaN(num)) {
            throw new Error("delay must be a number.");
        }
        this._delay = num;
    }

    getDelay() {
        return this._delay;
    }

    setError(error) {
        this._error = error;
    }

    getError() {
        return this._error;
    }

    setStatus(status) {
        this._status = status;
    }

    getStatus() {
        return this._status;
    }

    /**
     * public register
     *
     *  Registers a handler to be invoked when the store is updated.
     * 
     * @param  {String} event name. See QueueEvents. 
     * @param  {Function} callback 
     * @return {void}
     */
    register(event, callback) {
        this.on(event, callback);
    }

    /**
     *  public unregister
     *
     *  Unregisters a handler that was listening for store updates.
     * 
     * @param  {String} event name. See QueueEvents. 
     * @param  {Function}
     * @return {void}
     */
    unregister(event, callback) {
        this.removeListener(event, callback);
    }

    //Must return promise. If parameters are required, send them through your class' constructor.
    execute() {
        throw new Error("Task Execute has not been implemented yet.");
    }
}

export { Task, TaskStatus, TaskEvents };
