'use strict';

import {NetworkClient} from './NetworkClient';

let _instance = null;

class PCIClient extends NetworkClient {
    constructor() {
        super();
    }

    static getInstance() {
        if (!_instance) {
            _instance = new PCIClient();
        }

        return _instance;
    }

    _getServiceBase() {
        return 'pci';
    }

    getPCI(id) {
        return this.get('getPCI', {
            id : id
        });
    }

    addDistress(sampleID, distress) {
        distress.sampleID = sampleID;
        return this.post('distress/add', {
            distress: distress
        });
    }

    deleteDistresses(ids) {
        return this.post('distress/delete/list', {
            distresses: ids
        });
    }

    deletePCIs(ids) {
        return this.post('delete/list', {
            ids : ids
        });
    }

    getHistoryRange() {
        return this.get('history/range');
    }

    getInspectionsBySectionID(sectionID) {
        return this.get('section/inspections', {
            id : sectionID
        });
    }

    updateDistresses(distresses) {
        return this.post('distress/update/list', {
            distresses: distresses
        });
    }

    updateSamples(samples) {
        return this.post('sample/update/list', {
            samples : samples
        });
    }

    // deletePCI(sectionID) {
    //     return this.post('delete', {
    //         sectionID : sectionID
    //     });
    // }

    // updateSample(sampleID, name, width, length, status) {
    //     return this.post('sample/update', {
    //         sampleID : sampleID,
    //         name : name,
    //         width : width,
    //         length : length,
    //         status : status
    //     });
    // }

    // deleteSample(sampleID) {
    //     return this.post('sample/delete', {
    //         sampleID : sampleID
    //     });
    // }

    // // deleteSamples(ids) {
    // //  return this.post('', {
    // //      ids : ids
    // //  });
    // // }

    // getDistressesBySlabID(slabID) {
    //     return this.get('slab/distresses', {
    //         id : slabID
    //     });
    // }

    // updateSlabDistresses(slabID, distresses) {
    //     return this.post('slab/update', {
    //         slabID : slabID,
    //         distresses : distresses
    //     });
    // }

    // getDistressesBySampleID(sampleID) {
    //     return this.get('getSample', {
    //         id : sampleID
    //     });
    // }

    // deleteSlab(slabID) {
    //     return this.post('slab/delete', {
    //         slabID : slabID
    //     });
    // }

    // addPCI(sectionID) {
    //     return this.post('add', {
    //         sectionID: sectionID
    //     });
    // }

    // addImaginarySlab(slabID, row, column) {
    //     return this.post('slab/imaginary/add', {
    //         slabID : slabID,
    //         row : row,
    //         column : column
    //     });
    // }

    // createImaginarySlab(sampleID, parentPos, row, column) {
    //     return this.post('slab/imaginary/create', {
    //         sampleID : sampleID,
    //         parentPos : parentPos,
    //         row : row,
    //         column : column
    //     });
    // }

    // addSlab(sampleID, row, column) {
    //     return this.post('slab/add', {
    //         sampleID : sampleID,
    //         row : row,
    //         column : column
    //     });
    // }

    // addSample(pciID, name, width, length) {
    //     return this.post('sample/add', {
    //         pciID : pciID,
    //         length : length,
    //         width : width,
    //         name : name
    //     });
    // }

    // markSampleCompleted(sampleID) {
    //     return this.post('sample/update', {
    //         sampleID : sampleID,
    //         "status": "complete"
    //     });
    // }

    // deleteDistress(distressID) {
    //     return this.post('distress/delete', {
    //         distressID : distressID
    //     });
    // }

    // updateDistress(distressID, sampleID, type, severity, quantity, slabID) {
    //     return this.post('distress/update', {
    //         distress : {
    //             sampleID : sampleID,
    //             type : type,
    //             severity : severity,
    //             quantity : quantity,
    //             distressID : distressID,
    //             slabID: slabID
    //         }
    //     });
    // }
}

export { PCIClient };
