'use strict';

import {NetworkClient} from './NetworkClient';

let _instance = null;

class AppSupportClient extends NetworkClient {
    constructor() {
        super();
    }

    static getInstance() {
        if (!_instance) {
            _instance = new AppSupportClient();
        }

        return _instance;
    }

    _getServiceBase() {
        return 'app-support';
    }

    _useClientVersion() {
        return false;
    }

    // Don't call this directly. Go through Application.sendDiagnostics.
    sendDiagnostics(data) {
        return this.post('v2/diagnostics', data);
    }
}

export { AppSupportClient };
