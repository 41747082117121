'use strict';

import {IDGenerator} from '@totalpave/idgenerator';

const WAIT_DELAY = 5;

class Batcher {
    constructor() {
        this._operations = [];
        this._waitTimer;
    }

    execute(fn, delay) { //delay is in milliseconds
        let id = IDGenerator.generate();
        this._operations.push({
            id : id,
            fn : fn
        });

        if (!this._waitTimer) {
            this._waitTimer = setTimeout(() => {
                this._executeOps();
            }, delay === undefined ? WAIT_DELAY : delay);
        }
    }

    cancel(id) {
        for (let i = 0; i < this._operations.length; i++) {
            let op = this._operations[i];
            if (op.id === id) {
                this._operations.splice(i, 1);
                return true;
            }
        }
        return false;
    }

    _executeOps() {
        let ops = this._operations.slice();
        this._operations = [];

        this._waitTimer = null;

        for (let i = 0; i < ops.length; i++) {
            ops[i].fn();
        }
    }
}

export { Batcher };
export default Batcher;
