
import { Currency } from '@totalpave/interfaces';

export class LocaleTools {
    public static adaptCurrencyCode(code: Currency): string {
        switch (code) {
            case Currency.LEK:
                return 'sq';
            case Currency.AZERBAIJANIAN_MANAT:
                return 'az';
            case Currency.AUSTRALIAN_DOLLAR:
                return 'en-AU';
            case Currency.ARUBAN_FLORIN:
                return 'nl-AW';
            case Currency.ARMENIAN_DRAM:
                return 'hy';
            case Currency.ARGENTINE_PESO:
                return 'es-AR';
            case Currency.EURO:
                return 'en-GB';
            case Currency.ALGERIAN_DINAR:
                return 'ar-DZ';
            case Currency.US_DOLLAR:
                return 'en';
            case Currency.KWANZA:
                return 'pt-AO';
            case Currency.EAST_CARRIBBEAN_DOLLAR:
                // Used by several carribbean countries
                return 'en-DM';
            case Currency.AFGHANI:
                return 'fa-AF';
            case Currency.BOLIVAR:
                return 'es-VE';
            case Currency.BELARUSSIAN_RUBLE:
                return 'be';
            case Currency.BARBADOS_DOLLAR:
                return 'en-BB';
            case Currency.TAKA:
                return 'bn';
            case Currency.BAHRAINI_DINAR:
                return 'ar-BH';
            case Currency.BAHAMIAN_DOLLAR:
                return 'en-BS';
            case Currency.BELIZE_DOLLAR:
                return 'en-BZ';
            case Currency.CFA_FRANC_BCEAO:
                // Note this is actually used by 8 different countries
                return 'fr-NE';
            case Currency.BERMUDIAN_DOLLAR:
                return 'en-BM';
            case Currency.NGULTRUM:
                return 'dz';
            case Currency.CONVERTIBLE_MARK:
                return 'hr-BA';
            case Currency.INDIAN_RUPEE:
                return 'en-IN';
            case Currency.MVDOL:
            case Currency.BOLIVIANO:
                return 'es-BO';
            case Currency.NORWEGIAN_KRONE:
                return 'nn';
            case Currency.PULA:
                return 'en-BW';
            case Currency.BRAZILIAN_REAL:
                return 'pt';
            case Currency.CANADIAN_DOLLAR:
                return 'en-CA';
            case Currency.BRUNEI_DOLLAR:
                return 'ms-BN';
            case Currency.BULGARIAN_LEV:
                return 'bg';
            case Currency.UNIDAD_DE_FOMENTO:
                return 'es-CL';
            case Currency.BURUNDI_FRANC:
                return 'fr-BI';
            case Currency.CABO_VERDE_ESCUDO:
                return 'pt-CV';
            case Currency.RIEL:
                return 'km';
            case Currency.CAYMAN_ISLANDS_DOLLAR:
                return 'en-KY';
            case Currency.CHILEAN_PESO:
                return 'es-CL';
            case Currency.YUAN_RENMINBI:
                return 'zh-Hans';
            case Currency.COLOMBIAN_PESO:
                return 'es-CO';
            case Currency.UNIDAD_DE_VALOR_REAL:
                return 'pt-PT';
            case Currency.COMORO_FRANC:
                return 'ar-KM';
            case Currency.CONGOLESE_FRANC:
                return 'fr-CD';
            case Currency.NEW_ZEALAND_DOLLAR:
                return 'en-NZ';
            case Currency.COSTA_RICAN_COLON:
                return 'es-CR';
            case Currency.KUNA:
                return 'hr';
            case Currency.PESO_CONVERTIBLE:
            case Currency.CUBAN_PESO:
                return 'es-CU';
            case Currency.NETHERLANDS_ANTILLEAN_GUILDER:
                return 'nl';
            case Currency.CZECH_KORUNA:
                return 'cs';
            case Currency.DANISH_KRONE:
                return 'da';
            case Currency.DJIBOUTI_FRANC:
                return 'ar-DJ';
            case Currency.DOMINICAN_PESO:
                return 'es-DO';
            case Currency.EGYPTIAN_POUND:
                return 'ar-EG';
            case Currency.EL_SALVADOR_COLON:
                return 'es-SV';
            case Currency.CFA_FRANC_BEAC:
                return 'en-CM';
            case Currency.NAKFA:
                return 'en-ER';
            case Currency.ETHIOPIAN_BIRR:
                return 'am';
            case Currency.FALKLAND_ISLANDS_POUND:
                return 'en-FK';
            case Currency.FIJI_DOLLAR:
                return 'en-FJ';
            case Currency.CFP_FRANC:
                return 'fr-PF';
            case Currency.DALASI:
                return 'en-GM';
            case Currency.LARI:
                return 'ka';
            case Currency.GHANA_CEDI:
                return 'en-GH';
            case Currency.GIBRALTAR_POUND:
                return 'en-GI';
            case Currency.QUETZAL:
                return 'es-GT';
            case Currency.POUND_STERLING:
                return 'en-GB';
            case Currency.GUINEA_FRANC:
                return 'fr-GN';
            case Currency.GUYANA_DOLLAR:
                return 'en-GY';
            case Currency.GOURDE:
                return 'fr-HT';
            case Currency.LEMPIRA:
                return 'es-HN';
            case Currency.HONG_KONG_DOLLAR:
                return 'zh-Hans-HK';
            case Currency.FORINT:
                return 'hu';
            case Currency.ICELAND_KRONA:
                return 'is';
            case Currency.RUPIAH:
                return 'id';
            case Currency.SDR:
                console.warn('SDR is not supported');
                return 'en';
            case Currency.IRANIAN_RIAL:
                return 'fa';
            case Currency.IRAQI_DINAR:
                return 'ar-IQ';
            case Currency.NEW_ISRAELI_SHEQEL:
                return 'he';
            case Currency.JAMAICAN_DOLLAR:
                return 'en-JM';
            case Currency.YEN:
                return 'ja';
            case Currency.JORDANIAN_DINAR:
                return 'ar-JO';
            case Currency.KENYAN_SHILLING:
                return 'en-KE';
            case Currency.NORTH_KOREAN_WON:
                return 'ko-KP';
            case Currency.WON:
                return 'ko';
            case Currency.KUWAITI_DINAR:
                return 'ar-KW';
            case Currency.SOM:
                return 'ru-KG';
            case Currency.LEBANESE_POUND:
                return 'ar-LB';
            case Currency.LOTI:
                return 'en-LS';
            case Currency.LIBERIAN_DOLLAR:
                return 'en-LR';
            case Currency.LIBYAN_DINAR:
                return 'ar-LY';
            case Currency.PAKISTAN_RUPEE:
                return 'en-PK';
            case Currency.PATACA:
                return 'pt-MO';
            case Currency.DENAR:
                return 'mk';
            case Currency.MALAGASY_ARIARY:
                return 'en-MG';
            case Currency.KWACHA:
                return 'en-MW';
            case Currency.MALAYSIAN_RINGGIT:
                return 'ms';
            case Currency.RUFIYAA:
                console.warn('RUFIYAA is not supported');
                return 'en';
            case Currency.OUGUIYA:
                return 'ar-MR';
            case Currency.MAURITIUS_RUPEE:
                return 'en-MU';
            case Currency.RAND:
            case Currency.ADB_UNIT_OF_ACCOUNT:
                return 'en-ZA';
            case Currency.MEXICAN_PESO:
            case Currency.MEXICAN_UNIDAD_DE_INVERSION:
                return 'es-MX';
            case Currency.MOLDOVAN_LEU:
                return 'ro-MD';
            case Currency.TUGRIK:
                return 'mn';
            case Currency.MOROCCAN_DIRHAM:
                return 'ar-MA';
            case Currency.MOZAMBIQUE_METICAL:
                return 'pt-MZ';
            case Currency.KYAT:
                return 'my';
            case Currency.NAMIBIA_DOLLAR:
                return 'en-NA';
            case Currency.NEPALESE_RUPEE:
                return 'ne';
            case Currency.CORDOBA_ORO:
                return 'es-NI';
            case Currency.RIAL_OMANI:
                return 'ar-OM';
            case Currency.BALBOA:
                return 'es-PA';
            case Currency.KINA:
                return 'en-PG';
            case Currency.GUARANI:
                return 'es-PY';
            case Currency.NUEVO_SOL:
                return 'es-PE';
            case Currency.PHILIPPINE_PESO:
                return 'en-PH';
            case Currency.ZLOTY:
                return 'pl';
            case Currency.QATARI_RIAL:
                return 'ar-QA';
            case Currency.ROMANIAN_LEU:
                return 'ro';
            case Currency.RUSSIAN_RUBLE:
                return 'ru';
            case Currency.RWANDA_FRANC:
                return 'en-RW';
            case Currency.SAINT_HELENA_POUND:
                return 'en-SH';
            case Currency.TALA:
                return 'en-WS';
            case Currency.DOBRA:
                return 'pt-ST';
            case Currency.SAUDI_RIYAL:
                return 'ar-SA';
            case Currency.SERBIAN_DINAR:
                return 'sr-Cyrl';
            case Currency.SEYCHELLES_RUPEE:
                return 'en-SC';
            case Currency.LEONE:
                return 'en-SL';
            case Currency.SINGAPORE_DOLLAR:
                return 'en-SG';
            case Currency.SUCRE:
                return 'es-EC';
            case Currency.SOLOMON_ISLANDS_DOLLAR:
                return 'en-SB';
            case Currency.SOMALI_SHILLING:
                return 'ar-SO';
            case Currency.SOUTH_SUDANESE_POUND:
                return 'en-SS';
            case Currency.SRI_LANKA_RUPEE:
                return 'si';
            case Currency.SUDANESE_POUND:
                return 'ar-SD';
            case Currency.SURINAM_DOLLAR:
                return 'nl-SR';
            case Currency.LILANGENI:
                return 'en-SZ';
            case Currency.SWEDISH_KRONA:
                return 'sv';
            case Currency.WIR_EURO:
            case Currency.SWISS_FRANC:
            case Currency.WIR_FRANC:
                return 'de-CH';
            case Currency.SYRIAN_POUND:
                return 'ar-SY';
            case Currency.NEW_TAIWAN_DOLLAR:
                return 'zh-Hant';
            case Currency.SOMONI:
                console.warn('Somoni is not supported');
                return 'en';
            case Currency.TANZANIAN_SHILLING:
                return 'en-TZ';
            case Currency.BAHT:
                return 'th';
            case Currency.PAANGA:
                return 'en-TO';
            case Currency.TRINIDAD_AND_TOBAGO_DOLLAR:
                return 'en-TT';
            case Currency.TUNISIAN_DINAR:
                return 'ar-TN';
            case Currency.TURKMENISTAN_NEW_MANAT:
                return 'tk';
            case Currency.UGANDA_SHILLING:
                return 'en-UG';
            case Currency.HRYVNIA:
                return 'uk';
            case Currency.UAE_DIRHAM:
                return 'ar-AE';
            case Currency.US_DOLLAR_NEXT:
                return 'en';
            case Currency.PESO_URUGUAYO:
            case Currency.URUGUAY_PESO_EN_UNIDADES_INDEXADAS:
                return 'es-UY';
            case Currency.UZBEKISTAN_SUM:
                return 'uz';
            case Currency.VATU:
                return 'fr-VU';
            case Currency.YEMENI_RIAL:
                return 'ar-YE';
            case Currency.ZAMBIAN_KWACHA:
                return 'en-ZM';
            case Currency.ZIMBABWE_DOLLAR:
                return 'en-ZW';
            default: throw new Error('Hit default case for ' + code);
        }
    }
}
