'use strict';

const searchObject = function(obj, query, compare) {
    for (let i in obj) {
        let item = obj[i];
        if (compare(item, query)) {
            return true;
        }
    }

    return false;
};

const compare = function(item, query) {
    if (typeof item === 'string') {
        return item.indexOf(query) > -1;
    }
    else if (item instanceof Date) {
        let month = months[item.getMonth()];
        let day = weekdays[item.getDay()];
        let date = item.getDate().toString();
        let year = item.getFullYear().toString();

        return (
            year.indexOf(query) > -1
            || date.indexOf(query) > -1
            || day.indexOf(query.toLowerCase())
            || month.indexOf(query.toLowerCase())
        );
    }
    else if (typeof item === 'object') {
        for (let i in item) {
            let it = item[i];
            if (compare(it, query)) {
                return true;
            }
        }
        return false;
    }
    else {
        item = JSON.stringify(item);
        return item.indexOf(query) > -1;
    }
};

const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
];

const weekdays = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
];

class QueryFilter {
    static execute(data, query, compareFn) {
        if (!data || (data && !(data instanceof Array) || (data && data.length === 0))) {
            return [];
        }

        if (!compareFn) {
            compareFn = compare;
        }

        let filtered = [];

        for (let i = 0; i < data.length; i++) {
            let item = data[i];
            if (item instanceof Array) {
                filtered = filtered.concat(QueryFilter.execute(item, query, compareFn));
            }
            else if (typeof item === 'object' && searchObject(item, query, compareFn)) {
                filtered.push(item);
            }
            else {
                if (compareFn(item, query)) {
                    filtered.push(item);
                }
            }
        }

        return filtered;
    }
}

export { QueryFilter };
