'use strict';

import {TPError} from '@totalpave/error';
import {Task, TaskEvents} from '../queue/Task';

//Task for updating files. This task is meant to be used internally in FileSystem.
class UpdateFileTask extends Task {
    constructor(id, tpFileEntry, contents) {
        super(id);
        this._forceExecute = true;
        this.tpFileEntry = tpFileEntry;
        this.contents = contents;
    }

    execute(){
        return new Promise((resolve, reject) => {
            new Promise((resolve, reject) => {
                this.tpFileEntry.createWriter((writer) => {
                    writer.onwrite = () => {

                        // Change the writer's onwrite so that it will resolve after we write the new contents.
                        writer.onwrite = () => {
                            resolve();
                        }
                        writer.write(this.contents);
                    }
                    writer.onerror = (error) => {
                        reject(error);
                    }
                    // Truncate file for 2 reasons
                    // 1-FileWriters are cached. Truncating will effectively reset cursor.
                    // 2-Ensures that when updating, the entire file is overwritten.
                    //   This helps avoid cases where the new contents is not as long as
                    //   the old content, and some of the old content remains after the update.
                    writer.truncate(0);
                }, (error) => {
                    reject(error);
                });
            }).then(() => {
                //Update TPFileEntry
                return this.tpFileEntry.refresh(); 
            }).then(() => {
                this.emit(TaskEvents.ON_SUCCESS);
                this.emit(TaskEvents.ON_FINISH);
                resolve();
            }).catch((error) => {
                this.emit(TaskEvents.ON_FAIL, error);
                this.emit(TaskEvents.ON_FINISH, error);
                reject(
                    new TPError(
                        {
                            message: "Oops! Could not update file.",
                            details: {
                                function: "UpdateFileTask.execute", 
                                contents: this.contents,
                                tpFileEntry: this.tpFileEntry.toJSON(),
                                error: error
                            }
                        },
                        true
                    )
                );
            });
        });
    }
}

export { UpdateFileTask };
