'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';
import Transition from 'react-transition-group/Transition';
import {DeleteMenuAction} from '../actions/DeleteMenuAction';
import {ApplicationInstance} from '@totalpave/application-instance';
import {MenuBuilder} from '../builders/MenuBuilder';
import {Option} from '../utils/Option';
import {OptionGroup} from '../utils/OptionGroup';
import {KeyboardListener} from '../utils/KeyboardListener';
import {KeyCode} from '../utils/KeyCode';
import {TPComponent} from '../components/TPComponent';
import {FuzzySearch} from '../utils/FuzzySearch';
import "../style/Menu.less";

const LOOP_CONTINUE = 1;
const LOOP_BREAK    = 2;
const LOOP_RETURN   = 3;

/** @deprecated Use @totalpave/ui-menu */
class Menu extends TPComponent {
    constructor(props) {
        super(props);

        this._appendingHighlightValue = false;
        this._runConstraints = this._runConstraints.bind(this);
        this._onClick = this._onClick.bind(this);
        this._onKeyDown = this._onKeyDown.bind(this);
        this._fuzzySearch = null;
    }

    _initState(props) {
        return {
            x : props.x || 0,
            y : props.y || 0,
            relativeTo: props.relativeTo || null,
            highlight: '',
            highlightIndex: null
        };
    }

    getParentMenuID() {
        return this.props.parentMenuID;
    }

    static getDerivedStateFromProps(nextProps, state) {
        if (state.x !== nextProps.x) {
            state.x = nextProps.x;
        }
        
        if (state.y !== nextProps.y) {
            state.y = nextProps.y;
        }

        if (state.relativeTo !== nextProps.relativeTo) {
            state.relativeTo = nextProps.relativeTo;
        }

        return state;
    }

    _resetFuzzySearch() {
        let values = [];
        let keys = Option.getFuzzyKeys();
        for (let i = 0; i < this.props.options.length; i++) {
            let option = this.props.options[i];
            if (option instanceof Option) {
                values.push(option.getFuzzyValues());
            }
            else {
                values = values.concat(option.getFuzzyValues());
            }
        }
        this._fuzzySearch = new FuzzySearch(values, keys);
    }

    // componentDidMount() {
    //     super.componentDidMount();
    //     this.reposition();
    //     this._resetFuzzySearch();
    //     KeyboardListener.register(KeyboardListener.E_KEYDOWN, this._onKeyDown);
    // }

    componentWillUnmount() {
        super.componentWillUnmount();
        KeyboardListener.unregister(KeyboardListener.E_KEYDOWN, this._onKeyDown);
    }

    componentDidUpdate() {
        this._resetFuzzySearch();
    }

    getMenuBuilder() {
        return this.props.builder || new MenuBuilder();
    }

    _onKeyDown(event) {
        if (event.keyCode >= KeyCode.ARROW_LEFT && event.keyCode <= KeyCode.ARROW_DOWN) {
            event.nativeEvent.preventDefault();
        }

        let fuzzySearchMode = null;
        switch (event.keyCode) {
            case KeyCode.ESCAPE:
                DeleteMenuAction.execute(this.props.id);
                DeleteMenuAction.execute(this._id);
                break;
            case KeyCode.ENTER:
                this._selectHighlight();
                break;
            case KeyCode.ARROW_UP:
                fuzzySearchMode = false;
                this.navigate(-1);
                break;
            case KeyCode.ARROW_DOWN:
                fuzzySearchMode = false;
                this.navigate(1);
                break;
            default:
                fuzzySearchMode = true;
                if (KeyboardListener.isKeyCodeTextual(event.keyCode)) {
                    this._appendHighlight(event.keyCode);
                }
                break;
        }
        if (fuzzySearchMode !== null) {
            this.setState({
                fuzzySearchMode: fuzzySearchMode
            });
        }

    }

    getFlatOptionList() {
        let list = [];
        if (this.props.options.length > 0) {
            let option = this.props.options[0];
            if (option instanceof OptionGroup) {
                for (let i = 0; i < this.props.options.length; i++) {
                    let og = this.props.options[i];
                    list = list.concat(og.getOptions());
                }
            }
            else {
                list = this.props.options;
            }
        }
        return list;
    }

    lengthOfOptions() {
        if (this.props.options.length > 0) {
            let option = this.props.options[0];
            if (option instanceof OptionGroup) {
                let length = 0;
                for (let i = 0; i < this.props.options.length; i++) {
                    let og = this.props.options[i];
                    length += og.getOptions().length;
                }
                return length;
            }
            else {
                return this.props.options.length;
            }
        }
        else {
            return 0;
        }
    }

    _appendHighlight(keyCode) {
        if (this._appendHighlightTimer) {
            clearTimeout(this._appendHighlightTimer);
        }

        if (this._appendingHighlightValue) {
            let highlight = this.state.highlight + String.fromCharCode(keyCode).toLowerCase();
            let highlightOption = this.getHighlightOption(highlight);
            this.setState({
                highlight : highlight,
                highlightIndex: this.props.options.indexOf(highlightOption)
            });
        }
        else {
            let highlightOption = this.getHighlightOption(String.fromCharCode(keyCode).toLowerCase());
            this.setState({
                highlight: String.fromCharCode(keyCode).toLowerCase(),
                highlightIndex: this.props.options.indexOf(highlightOption)
            });
        }

        if (!this._appendingHighlightValue) {
            this._appendingHighlightValue = true;
        }

        this._appendHighlightTimer = setTimeout(() => {
            this._appendingHighlightValue = false;
        }, 500);
    }

    navigate(step) {
        // If stepping backwards, we want the first selected node to
        // be the last node. Otherwise, the first selected node 
        // should should be the first option.
        let index = this.getStateVariable('highlightIndex');
        if (index === null) {
            index = step < 0 ? 0 : -1;
        }

        index = index + step;
        let options = this.getFlatOptionList();
        let length = options.length;

        // Do wrapping if index is out of range
        while (index < 0 || index >= length) {
            if (index < 0) {
                let diff = Math.abs(index);
                index = length - diff;
            }
            else if (index >= length) {
                let diff = index - length;
                index = diff;
            }
        }

        let newOption = options[index];
        if (!newOption) {
            return;
        }

        this._resetHighlightOptions();
        newOption.setHighlight(true);

        this._appendingHighlightValue = false;
        this.setState({
            highlightIndex: index
        });
    }

    _resetHighlightOptions() {
        for (let i = 0; i < this.props.options.length; i++) {
            let option = this.props.options[i];
            option.setHighlight(false);
        }
    }

    getHighlightOption(highlight) {
        if (!highlight) {
            highlight = this.getStateVariable('highlight');
        }

        if (!highlight) {
            return null;
        }

        let highlightedOption = null;
        let fuzzySearchResult = this._fuzzySearch.get(highlight);

        this.iterateOptions((option, index) => {
            option.setHighlight(false);

            if (highlightedOption) {
                return LOOP_CONTINUE;
            }

            if (fuzzySearchResult && option.getText().toLowerCase() === fuzzySearchResult.text.toLowerCase()) {
                highlightedOption = option;
                highlightedOption.setHighlight(true);
                this.forceUpdate();
            }
        });

        return highlightedOption;
    }

    iterateOptions(fn) {
        for (let i = 0; i < this.props.options.length; i++) {
            let o = this.props.options[i];
            if (o instanceof OptionGroup) {
                let options = o.getOptions();
                for (let j = 0; j < options.length; j++) {
                    let option = options[j];
                    let result = fn(option, j);
                    if (result === LOOP_BREAK) break;
                    if (result === LOOP_CONTINUE) continue;
                    if (result === LOOP_RETURN) return;
                }
            }
            else {
                let result = fn(o, i);
                if (result === LOOP_BREAK) break;
                if (result === LOOP_CONTINUE) continue;
                if (result === LOOP_RETURN) return;
            }
        }
    }

    indexOfOption(option) {
        let hasOptionGroups = false;
        if (this.props.options[0] && this.props.options[0] instanceof OptionGroup) {
            hasOptionGroups = true;
        }

        if (!hasOptionGroups) {
            return this.props.options.indexOf(option);
        }
        else {
            let retVal = {
                groupIndex: null,
                index: null
            };
            for (let i = 0; i < this.props.options.length; i++) {
                let options = this.props.options[i].getOptions();
                if (options.indexOf(option) > -1) {
                    retVal.groupIndex = i;
                    retVal.index = options.indexOf(option);
                    break;
                }
            }
            return retVal;
        }
    }

    _selectHighlight() {
        let highlightedOption = null;
        
        if (this.getStateVariable("fuzzySearchMode")) {
            highlightedOption = this.getHighlightOption();
        }
        else {
            highlightedOption = this.props.options[this.getStateVariable("highlightIndex")];
        }

        if (highlightedOption) {
            highlightedOption.setHighlight(true);
            this._onClick(highlightedOption);
        }
    }

    _runConstraints(node, x, y) {
        let docWidth = window.innerWidth;
        let docHeight = window.innerHeight;

        let nodeWidth = node.clientWidth;
        let nodeHeight = node.clientHeight;

        if (x < 0) {
            if (this.props.allowFlip) {
                x = this._alignMenu(this._getBounds(this.state.relativeTo), this.props.alignment || Menu.ALIGNMENT_BOT_LEFT, true).x;
            }
            else {
                x = 0;
            }
        }
        if (y < 0) {
            if (this.props.allowFlip) {
                y = this._alignMenu(this._getBounds(this.state.relativeTo), this.props.alignment || Menu.ALIGNMENT_BOT_LEFT, true).y;
            }
            else {
                y = 0;
            }
        }

        if (x + nodeWidth > docWidth) {
            if (this.props.allowFlip) {
                x = this._alignMenu(this._getBounds(this.state.relativeTo), this.props.alignment || Menu.ALIGNMENT_BOT_LEFT, true).x;
            }
            else {
                x = x - (Math.abs((x + nodeWidth) - docWidth));
            }
        }
        if (y + nodeHeight > docHeight) {
            if (this.props.allowFlip) {
                y = this._alignMenu(this._getBounds(this.state.relativeTo), this.props.alignment || Menu.ALIGNMENT_BOT_LEFT, true).y;
            }
            else {
                y = y - (Math.abs((y + nodeHeight) - docHeight));
            }
        }

        return {
            x : x,
            y : y
        };
    }

    _getBounds(node) {
        let bounds = node.getBoundingClientRect();
        return bounds;
    }

    reposition(node) {
        let x = this.state.x || 0;
        let y = this.state.y || 0;
        let pos = null;
        if (!node) {
            node = this._node;
        }

        if (this.props.width && this.props.width !== 'match') {
            node.style.width = (typeof this.props.width === 'number') ? (this.props.width + 'px') : this.props.width;
        }
        else {
            node.style.width = '';
        }

        if (this.state.relativeTo) {
            let relativeToBounds = this._getBounds(this.state.relativeTo);

            let alignment = this.props.alignment || Menu.ALIGNMENT_BOT_LEFT;

            pos = this._alignMenu(relativeToBounds, alignment);
            x = pos.x;
            y = pos.y;
        }

        pos = this._runConstraints(node, x, y);
        node.style.left = pos.x + 'px';
        node.style.top = pos.y + 'px';
    }

    _flipAlignment(alignment) {
        switch (alignment) {
            default:
                ApplicationInstance.getInstance().getLogger().log(`Do not know how to flip alignment ${alignment}`);
                return alignment;
            case Menu.ALIGNMENT_BOT_LEFT:
                return Menu.ALIGNMENT_BOT_RIGHT;
            case Menu.ALIGNMENT_BOT_RIGHT:
                return Menu.ALIGNMENT_BOT_LEFT;
            case Menu.ALIGNMENT_TOP_LEFT:
                return Menu.ALIGNMENT_TOP_RIGHT;
            case Menu.ALIGNMENT_TOP_RIGHT:
                return Menu.ALIGNMENT_BOT_LEFT;
            case Menu.ALIGNMENT_RIGHT_TOP:
                return Menu.ALIGNMENT_LEFT_TOP;
            case Menu.ALIGNMENT_LEFT_TOP:
                return Menu.ALIGNMENT_RIGHT_TOP;
        }
    }

    _alignMenu(relativeToBounds, alignment, shouldFlip) {
        let x = 0, y = 0;

        if (shouldFlip) {
            alignment = this._flipAlignment(alignment);
        }

        switch (alignment) {
            case Menu.ALIGNMENT_BOT_LEFT:
                x = relativeToBounds.left;
                y = relativeToBounds.top + relativeToBounds.height;
                break;
            case Menu.ALIGNMENT_BOT_RIGHT:
                x = relativeToBounds.left + relativeToBounds.width - this._node.clientWidth;
                y = relativeToBounds.top + relativeToBounds.height;
                break;
            case Menu.ALIGNMENT_TOP_RIGHT:
                x = relativeToBounds.left + relativeToBounds.width;
                y = relativeToBounds.top;
                break;
            case Menu.ALIGNMENT_TOP_LEFT:
                x = relativeToBounds.left - this._node.clientWidth;
                y = relativeToBounds.top;
                break;
            case Menu.ALIGNMENT_RIGHT_TOP:
                x = relativeToBounds.left + relativeToBounds.width;
                y = relativeToBounds.top;
                break;
            case Menu.ALIGNMENT_LEFT_TOP:
                x = relativeToBounds.left - this._node.clientWidth;
                y = relativeToBounds.top;
                break;
            default:
                throw new Error('Alignment not implemented yet.');
        }

        return {
            x : x,
            y : y
        };
    }

    _onClick(option) {
        if (option instanceof OptionGroup) {
            return;
        }

        if (this.props.onClick) {
            this.props.onClick(option);
        }

        let value = option.getValue();
        if (!(value instanceof Array)) {
            this._close();
        }
    }

    _close () {
        DeleteMenuAction.execute({key: this.props.id});
        DeleteMenuAction.execute({key: this._id});
    }

    render() {
        let self = this;

        if (this._node) {
            this.reposition();
        }

        let items = [];
        let options = this.props.options || [];
        let builder = this.getMenuBuilder();

        let hasHighlightedItem = false;

        for (let i = 0; i < options.length; i++) {
            let option = options[i];
            let shouldHighlight = false;
            if (!hasHighlightedItem) {
                if (option.isHighlight()) {
                    shouldHighlight = true;
                    hasHighlightedItem = true;
                }
            }
            items.push(
                builder.build({
                    menu: this,
                    key: i,
                    highlight: shouldHighlight,
                    menuID: this.props.id,
                    width: this.props.width,
                    option: option,
                    onClick: this._onClick,
                    parentMenuID: this.props.parentMenuID || null,
                    title: option.getTitle()
                })
            );
        }

        const initialStyle = {
            transition: `opacity ${100}ms ease-in-out`,
            opacity: 0
        };

        const transitions = {
            entering : { opacity: 0 },
            entered  : { opacity: 1 }
        };

        let transActive = this.props.active === undefined ? true : this.props.active;

        return (
            <Transition
                in={transActive}
                timeout={{
                    enter: 1,
                    exit: 100
                }}
                appear
                mountOnEnter
                unmountOnExit
                onEnter={(node, isAppearing) => {
                    self.reposition(node);
                    self._resetFuzzySearch();
                    KeyboardListener.register(KeyboardListener.E_KEYDOWN, self._onKeyDown);
                }}
                onExited={(node) => {
                    KeyboardListener.unregister(KeyboardListener.E_KEYDOWN, self._onKeyDown);
                }}
            >
                {(state) => {
                    return (
                        <div 
                            className={`Menu ${this.props.className} ${this.props.scrollable ? 'scrollable' : ''} ${state}`} 
                            ref={(node) => { this._node = node;}} 
                            data-menu-id={this.props.id}
                            data-parent-menu-id={this.props.parentMenuID}
                            style={{
                                ...initialStyle,
                                ...transitions[state]
                            }}
                        >
                            {items}
                        </div>
                    );
                }}
            </Transition>
        )
    }
}

Menu.ALIGNMENT_BOT_LEFT  = 'botleft';
Menu.ALIGNMENT_BOT_RIGHT = 'botright';
Menu.ALIGNMENT_TOP_LEFT  = 'topleft';
Menu.ALIGNMENT_TOP_RIGHT = 'topright';
Menu.ALIGNMENT_LEFT_TOP  = 'lefttop';
Menu.ALIGNMENT_RIGHT_TOP = 'righttop';

Menu.propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    relativeTo: PropTypes.instanceOf(Element),
    allowFlip: PropTypes.bool,
    alignment: PropTypes.string,
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    onClick: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.instanceOf(Option),
        PropTypes.instanceOf(OptionGroup)
    ])).isRequired,
    id: PropTypes.string,
    className: PropTypes.string,
    parentMenuID: PropTypes.string,
    scrollable: PropTypes.bool,
    active: PropTypes.bool,
    builder: PropTypes.instanceOf(MenuBuilder)
};

export { Menu };
