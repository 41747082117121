'use strict';

import {Action} from './Action';

class PickUpDroppableAction extends Action {
    getTag() {
        return 'start-dnd';
    }

    _shouldDispatchOnError() {
        return false;
    }

    _execute(droppable) {
        return Promise.resolve(droppable);
    }
}

const PickUpDroppable = new PickUpDroppableAction();

export { PickUpDroppable };
