'use strict';

import * as React from 'react';
import {Modal} from './Modal';
import {Button} from './Button';
// import {Label} from './Label';
import {ModalPopAction} from '../actions/ModalPopAction';
import "../style/ErrorModal.less";
import PropTypes from 'prop-types';

class ErrorModal extends Modal {
    constructor(props) {
        super(props);
        this._addClassName('ErrorModal');
    }

    getTitle() {
        return 'Error';
    }

    isFlex() {
        return true;
    }

    _render() {
        // var viewNode;
        // if (this.props.text) {
        //  viewNode = <Label text={this.props.text} />;
        // }
        // else {
        //  viewNode = <iframe src={this.props.file} />;
        // }

        return (
            <div className="error-container">
                <p>
                    An unexpected error occurred.<br />
                    If this issue persists please contact your administrator.
                </p>
                <Button text="Continue" noTopBorderRadius={true} color="green" onTap={() => {
                    ModalPopAction.execute();
                }} />
            </div>
        );
    }
}

ErrorModal.propTypes = {
    text: PropTypes.string,
    file: PropTypes.any
};

export { ErrorModal };
