import {CustomEvent} from './CustomEvent';
import {MOUSE_OUT_OF_WINDOW} from './Events';

class MouseOutOfWindowEvent extends CustomEvent {
    constructor() {
        super(null);
    }

    getEventName() {
        return MOUSE_OUT_OF_WINDOW;
    }
}

export {MouseOutOfWindowEvent};
