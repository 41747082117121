'use strict';

class OrderFilter {
    static execute(data, predicate, reverse, compareFn) {
        if (!data) {
            return [];
        }

        data = data.slice();

        let c = null;
        if (predicate !== undefined && predicate !== null) {
            if (typeof predicate === 'string') {
                if (predicate.indexOf('.') > -1) {
                    c = predicate.split('.');
                }
                else {
                    c = [predicate];
                }
            }
            else {
                c = predicate;
            }

            //Attempting to sort a large list of values that are the same takes extremely long, so it is significantly faster
            //to check to see if there is at least varying values in the dataset before we actually do a sort.
            //You can't really sort a list that all contains the same value anyway.
            let v;
            let requiresSort = false;
            for (let i = 0; i < data.length; i++) {
                let value = data[i];

                for (let j = 0; j < c.length; j++) {
                    if (value && value[c[j]] !== undefined) {
                        value = value[c[j]];
                    }
                    else {
                        value = null;
                    }
                }
                
                if (i === 0) {
                    v = value;
                }
                else {
                    if (v !== value) {
                        requiresSort = true;
                        break;
                    }
                }
            }

            if (!requiresSort) {
                return data;
            }
        }

        data.sort((a, b) => {
            let aVal, bVal;

            if (a && c) {
                aVal = a;
                for (let i = 0; i < c.length; i++) {
                    if (aVal && aVal[c[i]] !== undefined) {
                        aVal = aVal[c[i]];
                    }
                    else {
                        aVal = null;
                    }
                }
            }

            if (b && c) {
                bVal = b;
                for (let j = 0; j < c.length; j++) {
                    if (bVal && bVal[c[j]] !== undefined) {
                        bVal = bVal[c[j]];
                    }
                    else {
                        bVal = null;
                    }
                }
            }

            let ret = 0;

            //Place null values last, regardless of sort direction
            if (c && (aVal === undefined || aVal === null || aVal === '')) {
                ret = 1;
            }
            else if (c && (bVal === undefined || bVal === null || bVal === '')) {
                ret = -1;
            }

            if (ret !== 0) {
                return ret;
            }

            if (c && ((typeof aVal === 'number' && typeof bVal === 'number') || (aVal instanceof Date && bVal instanceof Date))) {
                ret = reverse ? (bVal - aVal) : (aVal - bVal);
            }
            else if (!!c) {
                if (aVal === null || aVal === undefined) {
                    aVal = '';
                }

                if (bVal === null || bVal === undefined) {
                    bVal = '';
                }

                aVal = aVal.toString();
                bVal = bVal.toString();
                ret = reverse ? bVal.localeCompare(aVal) : aVal.localeCompare(bVal);
            }

            if (!!compareFn) {
                let compareRet = compareFn(a, b, ret);
                return compareRet === undefined ? ret : compareRet;
            }
            else {
                return ret;
            }
        });

        return data;
    }
}

export { OrderFilter };
