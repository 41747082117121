
import {TextfieldPropsBuilder} from './TextfieldPropsBuilder';
import { NumberFormatTextfield} from '../components/NumberFormatTextfield';
import {InputType} from '../utils/InputType';

class NumberFormatTextfieldPropsBuilder extends TextfieldPropsBuilder {

    _getInputType() {
        return InputType.NUMBER_FORMAT;
    }

    _getAdditionalDefaultProps() {
        return {
            fixedDecimalScale: false,
            allowNegative: true,
            displayType: NumberFormatTextfield.DisplayType.INPUT
        };
    }

    setAllowNegative(bool) {
        this._props.allowNegative = bool;
        return this;
    }

    setDisplayType(type) {
        if (type !== NumberFormatTextfield.DisplayType.INPUT && type !== NumberFormatTextfield.DisplayType.SPAN) {
            throw new Error("NumberFormatTextfield DisplayType must be one of the values defined by NumberFormatTextfield.DisplayType.");
        }
        this._props.displayType = type;
        return this;
    }

    setDecimalScale(count) {
        this._props.decimalScale = count;
        return this;
    }

    setDecimalSeparator(str) {
        this._props.decimalSeparator = str;
        return this;
    }

    setIsFixedDecimalScale(bool) {
        this._props.fixedDecimalScale = bool;
        return this;
    }

    setGroupSeparator(str) {
        this._props.groupSeparator = str;
        return this;
    }

    setGroupStyle(enumValue) {
        this._props.groupStyle = enumValue;
        return this;
    }

    setPrefixSymbol(str) {
        this._props.prefixSymbol = str;
        return this;
    }

    setSuffixSymbol(str) {
        this._props.suffixSymbol = str;
        return this;
    }

    setInputRef(func) {
        this._props.getInputRef = func;
        return this;
    }
}

export {NumberFormatTextfieldPropsBuilder};
