'use strict';

import {Store} from '@totalpave/store';

let _instance = null;

/*
A datastore that maintains a stack of opened modals.
 */
export class ActionStore extends Store {
    constructor() {
        super();

        this._actionsPending = 0;
        //map of running actions. 
        //Keyed by action tag and holds a value to indicate how many of this action is currently running.
        //Actions that have not been ran will not existing in the map. So undefined is an possible value.
        this._runningActions = {}; 
    }

    static getInstance() {
        if (!_instance) {
            _instance = new ActionStore();
        }
        return _instance;
    }

    isActionsPending() {
        return this._actionsPending > 0;
    }

    isActionRunning(tag) {
        return !!this._runningActions[tag] && this._runningActions[tag] > 0;
    }

    /*
        It's technically possible for an action to complete and bring actionsPending to 0 before something else calls on action.execute.

        In the current planned usage with the logout signal, this will not matter; because, no actions can run while logging out.
    */
    _update(actionData) {
        let data = actionData.getData();
        switch (actionData.getTag()) {
            case 'action_start':
                if (!this._runningActions[data]) {
                    this._runningActions[data] = 1;
                }
                else {
                    this._runningActions[data] = this._runningActions[data] + 1;
                }
                this._actionsPending = this._actionsPending + 1;
                return true;
            case 'action_completed':
                this._runningActions[data] = this._runningActions[data] - 1;
                this._actionsPending = this._actionsPending - 1;
                return true;
        }

        return false;
    }
}
