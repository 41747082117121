'use strict';

import {
    TransitionStrategy,
    View
} from '@breautek/router';

interface ITransitionEvent {
    propertyName: string;
}

export class TransitionFadeOut extends TransitionStrategy {
    protected override async _execute(incomingView: View, exitingView: View): Promise<void> {
        return new Promise((resolve) => {
            let exitingNode: HTMLElement = exitingView.getNode();
            let incomingNode: HTMLElement = incomingView.getNode();
            let transitionTimeout: number = null;
            let errorTimeout: number = null;

            let end = (event: ITransitionEvent) => {
                if (event.propertyName !== 'opacity') {
                    return;
                }
                exitingNode.removeEventListener('transitionend', end);
                window.clearTimeout(errorTimeout);

                // Remove CSS added by this transition
                exitingNode.style.opacity = "";
                exitingNode.style.position = "";
                exitingNode.style.top = "";
                exitingNode.style.left = "";
                exitingNode.style.right = "";
                exitingNode.style.zIndex = "";
                exitingNode.style.bottom = "";
                exitingNode.style.transition = "";
                incomingNode.style.zIndex = "";

                resolve();
            }

            errorTimeout = window.setTimeout(() => {
                console.warn("TransitionFadeOut hit error timeout.");
                window.clearTimeout(transitionTimeout);
                end({propertyName: 'opacity'});
            }, 1000);

            // Initial CSS (including transition property itself)
            exitingNode.style.opacity = '1';
            exitingNode.style.position = "absolute";
            exitingNode.style.top = '0px';
            exitingNode.style.left = '0px';
            exitingNode.style.right = '0px';
            exitingNode.style.bottom = '0px';
            exitingNode.style.zIndex = '2';
            exitingNode.style.transition = "opacity 0.5s ease-in-out";
            incomingNode.style.zIndex = '1';

            transitionTimeout = window.setTimeout(() => {
                // Cause transition here
                exitingNode.style.opacity = '0';
            }, 1);
        });
    }
}
