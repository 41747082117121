'use strict';

import {Action} from './Action';

class MouseButtonDownActionSingleton extends Action {
    getTag() {
        return 'mousebutton_down';
    }

    _shouldDispatchOnError() {
        return false;
    }

    _execute({button} = {}) {
        return Promise.resolve({button:button});
    }
}

const MouseButtonDownAction = new MouseButtonDownActionSingleton();

export { MouseButtonDownAction };
