import * as React from 'react';
import PropTypes from 'prop-types';
import {FormItem} from './FormItem';
import {LabeledCheckbox} from './LabeledCheckbox';

export class FormItemLabeledCheckbox extends React.Component {
    constructor(props) {
        super(props);
        this.$classNames = ["FormItemLabeledCheckbox"];
    }

    _addClassName(name) {
        this.$classNames.push(name);
    }

    render() {
        return <FormItem
            label={this.props.label}
            isRequired={this.props.isRequired}
            className={`${this.props.className} ${this.$classNames}`}
            toolTipMessage={this.props.toolTipMessage}
        >
            <LabeledCheckbox {...this.props.contentProps} />
        </FormItem>;
    }
}

// FormItemLabeledCheckbox does not contain hasError as error state for a LabeledCheckbox makes very little sense.
FormItemLabeledCheckbox.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    toolTipMessage: PropTypes.string,
    /**
     * Defaults to false
     */
    isRequired: PropTypes.bool,
    contentProps: PropTypes.exact(LabeledCheckbox.propTypes)
}
