import * as React from 'react';
import PropTypes from 'prop-types';
import '../style/FormGroup.less';

export class FormGroup extends React.Component {
    constructor(props) {
        super(props);
        this.$classNames = ["FormGroup"];
    }

    _addClassName(name) {
        this.$classNames.push(name);
    }

    $getClassName() {
        return `${this.$classNames} ${this.props.className}`;
    }

    render() {
        return <div className={this.$getClassName()}>
            {this.props.children}
        </div>;
    }
}

FormGroup.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node // Should be either FormItem (or extending classes) or FormGroup.
}
