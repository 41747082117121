/**
 * Copyright 2024-2024 Total Pave Inc.
 * All Rights Reserved.
 */

import * as React from 'react';
import {ClassName} from '../utils/ClassName';
import style from '../style/components/Header.less';

export interface IHeaderProps {
    title: string;

    responsiveFont?: boolean;
    centered?: boolean;

    leftIcons?: React.ReactNode | React.ReactNode[];
    rightIcons?: React.ReactNode | React.ReactNode[];
}

interface IHeaderState {
    lineCount: number;
}

export class Header extends React.Component<IHeaderProps, IHeaderState> {
    private $tickID: number | null;
    private $isUnmounting: boolean;
    private $node: HTMLDivElement | null;
    private $leftIconsNode: HTMLDivElement | null;
    private $rightIconsNode: HTMLDivElement | null;

    public constructor(props: IHeaderProps) {
        super(props);

        this.$isUnmounting = false;
        this.$tickID = null;
        this.$node = null;
        this.$leftIconsNode = null;
        this.$rightIconsNode = null;

        this.$responsiveRenderTick = this.$responsiveRenderTick.bind(this);

        this.state = {
            lineCount: 0
        };
    }

    public override componentDidMount(): void {
        style.use();
        this.$isUnmounting = false;
        this.$tickID = window.requestAnimationFrame(this.$responsiveRenderTick);
    }

    public override componentWillUnmount(): void {
        style.unuse();
        this.$isUnmounting = true;
        window.cancelAnimationFrame(this.$tickID);
    }

    private $responsiveRenderTick(): void {
        if (this.$isUnmounting) {
            return;
        }

        let lineCount = this.getLineCount();
        
        if (lineCount > 0) {
            // this.$isDirty = false;
            this.setState({
                lineCount: lineCount
            });
        }

        this.$tickID = window.requestAnimationFrame(this.$responsiveRenderTick);
    }

    public isOverflowing(): boolean {
        if (!this.$node) {
            return false;
        }

        let iconsWidth: number = 0;

        if (this.$leftIconsNode) {
            iconsWidth = this.$leftIconsNode.offsetWidth;
        }

        if (this.$rightIconsNode) {
            iconsWidth = this.$rightIconsNode.offsetWidth;
        }

        let span: HTMLSpanElement = this.$node.querySelector('.title.logical');

        return (span.offsetWidth + iconsWidth) >= this.$node.offsetWidth;
    }

    public getLineCount(): number {
        if (!this.$node) {
            return 0;
        }

        let span: HTMLSpanElement = this.$node.querySelector('.title.logical');

        return Math.ceil(span.clientHeight / parseInt(window.getComputedStyle(span).lineHeight));
    }

    public override render() {
        let responsiveFontClass: string = '';
        let lineCount: number = this.state.lineCount;

        if (this.props.responsiveFont && lineCount > 1) {
            responsiveFontClass = `responsive-font-${lineCount}`;
        }

        return (
            <div
                className={ClassName.execute({
                    [responsiveFontClass]: this.props.responsiveFont && lineCount > 1,
                    'centered': this.props.centered
                }, [ 'Header' ])}
                ref={(n) => { this.$node = n; }}
            >
                <div className="title-container">
                    <span className="title logical">{this.props.title}</span>
                    <span className={`title user-display${this.isOverflowing() ? ' overflowing' : ''}`}>{this.props.title}</span>
                </div>
                <div className="header-icons">
                    <div className="left-icons" ref={(n) => { this.$leftIconsNode = n; }}>
                        {this.props.leftIcons || []}
                    </div>
                    <div className="right-icons" ref={(n) => { this.$rightIconsNode = n; }}>
                        {this.props.rightIcons || []}
                    </div>
                </div>
            </div>
        );
    }
}
