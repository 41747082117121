'use strict';

import {Store} from '@totalpave/store';
import {UnitMode} from '@totalpave/math';
import {Role, OrganizationAccessLevel, SamplingMethod} from '@totalpave/interfaces';
import {Position} from '../model/Position';
import {ApplicationInstance} from '@totalpave/application-instance';
import {WebStorageStrategy} from '../strategies/WebStorageStrategy';

const USER_KEY = "user";
const USER_FLAGS_KEY = "user_flags";

let _instance = null;

/*
A datastore containing the users profile data and authentication token.
*/
export class UserStore extends Store {
    constructor() {
        super();

        this.migrateStorageSystem = this.migrateStorageSystem.bind(this);

        this._user = null;
        this._shouldLogout = false;
        this._unit = UnitMode.METRIC;
        this._userMigrationRequired = false;
        this._organizationUsers = [];
        this._customerAddressLocation = null;
    }

    initialize() {
        return new Promise((resolve, reject) => {
            let storage = this._getStorageStrategy();
            if (!storage) {
                reject('Application has not properly setup an StorageStrategy.');
                return;
            }

            storage.initialize().then(() => {
                this._user = storage.get(USER_KEY);
                if (this._user) {
                    this._user = JSON.parse(this._user);
        
                    if (!this._user.organization.settings) {
                        this._userMigrationRequired = true;
                    }
                    else {
                        this._unit = this._user.organization.settings.units;
                    }
                }
                resolve();
            }).catch(reject);
        });
    }

    static getInstance() {
        if (!_instance) {
            _instance = new UserStore();
        }
        return _instance;
    }

    async migrateStorageSystem() {
        if (localStorage.user || sessionStorage.user) {
            let storage = localStorage.user ? "localStorage" : "sessionStorage";
            if (this._getStorageStrategy() instanceof WebStorageStrategy) {
                // setPersist is specific to WebStorageStrategy... Normally only the application that actually use the strategy would control this value; but,
                // migration needs to know incase if the old system was using persistent storage.
                this._getStorageStrategy().setPersist(!!localStorage.user);
            }
            await this._getStorageStrategy().save({
                [USER_KEY]: window[storage].user,
                [USER_FLAGS_KEY]: window[storage].user_flags
            });
            delete window.localStorage.user;
            delete window.sessionStorage.user;
            delete window.localStorage.user_flags;
            delete window.sessionStorage.user_flags;
        }
    }

    _getStorageStrategy() {
        return ApplicationInstance.getInstance().getStorageStrategy();
    }

    _modifyUsers(users) {
        for (let j = 0; j < users.length; j++) {
            let user = users[j];

            for (let i = 0; i < this._organizationUsers.length; i++) {
                let oUser = this._organizationUsers[i];

                if (user.token === oUser.token) {
                    oUser.f_name = user.firstName;
                    oUser.l_name = user.lastName;
                    oUser.role = user.role;
                }
            }
        }
    }

    _reset() {
        return new Promise((resolve, reject) => {
            this._user = null;
            this._shouldLogout = false;
            
            // why aren't we deleting user_flags here as well?
            // apparently user flags are saved on a per user basis

            this._unit = UnitMode.METRIC;
            this._userMigrationRequired = false;
            this._organizationUsers = [];
            // this.isSession = false;
            // this._persist = true;
            this._customerAddressLocation = null;
            // delete window.localStorage.user;
            // delete window.sessionStorage.user;
            this._getStorageStrategy().delete(USER_KEY).then(resolve).catch((error) => {
                reject(new (ApplicationInstance.getInstance().getErrorClass())({
                    message: "Could not reset UserStore",
                    details: error
                }));
            });
        });
    }

    isSuperUser() {
        if (!this._user) {
            return false;
        }

        return this._user.role === Role.ADMINISTRATOR;
    }

    isAdministrator() {
        if (!this._user) {
            return false;
        }
        
        const adminRoles = [
            Role.ADMINISTRATOR,
            Role.LOCAL_ADMINISTRATOR,
            Role.CUSTOMER_ADMINISTRATOR
        ];
        return adminRoles.indexOf(this._user.role) > -1;
    }

    isCustomerAdministrator() {
        if (!this._user) {
            return false;
        }

        return this._user.role === Role.CUSTOMER_ADMINISTRATOR;
    }

    getOrganizationID() {
        return this._user.organization.id;
    }

    getOrganizationCurrency() {
        return this._user.organization.settings.currency;
    }

    getOrganizationCountry() {
        return this._user.organization.settings.country;
    }

    isManager() {
        return this._user.role === Role.DATA_MANAGER;
    }

    getRole() {
        return this._user.role;
    }

    getUsers() {
        return (this._organizationUsers || []).slice();
    }

    _deleteUsers(tokens) {
        let newList = [];

        for (let i = 0; i < this._organizationUsers.length; i++) {
            let user = this._organizationUsers[i];
            if (tokens.indexOf(user.token) === -1) {
                newList.push(user);
            }
        }

        this._organizationUsers = newList;
    }

    _getFlags() {
        let token = this.getToken();
        if (!token) {
            return null;
        }

        let flags = JSON.parse(this._getStorageStrategy().get(USER_FLAGS_KEY) || '{}');
        // if (this.isSession) {
        //     flags = JSON.parse(window.sessionStorage.user_flags || '{}');
        // }
        // else {
        //     flags = JSON.parse(window.localStorage.user_flags || '{}');
        // }

        if (!flags[token]) {
            flags[token] = {};
        }

        return flags[token];
    }

    _saveUserFlags(flags) {
        let token = this.getToken();
        if (!token) {
            return null;
        }

        let userFlags = JSON.parse(this._getStorageStrategy().get(USER_FLAGS_KEY) || '{}');
        // if (this.isSession) {
        //     userFlags = JSON.parse(window.sessionStorage.user_flags || '{}');
        // }
        // else {
        //     userFlags = JSON.parse(window.localStorage.user_flags || '{}');
        // }

        userFlags[token] = flags;

        this._getStorageStrategy().save({[USER_FLAGS_KEY]: JSON.stringify(userFlags)}).then(() => {
            return this._forceUpdate();
        }).catch((error) => {
            new (ApplicationInstance.getInstance().getErrorClass())({
                message: "Could not save user flags in UserStore",
                details: error
            });
        });
        // if (this.isSession) {
        //     window.sessionStorage.user_flags = JSON.stringify(userFlags);
        // }
        // else {
        //     window.localStorage.user_flags = JSON.stringify(userFlags);
        // }
    }

    _setUserFlag(flagName, flagValue) {
        let flags = this._getFlags();
        flags[flagName] = flagValue;
        this._saveUserFlags(flags);
    }

    getUserFlag(flagName) {
        return this._getFlags()[flagName];
    }

    hasUserFlag(flagName) {
        let flag = this._getFlags()[flagName];
        return flag !== undefined && flag !== null;
    }

    getOrganizationName() {
        if (!this._user) {
            return null;
        }

        return this._user.organization.name;
    }

    getOrganizationAccessLevel() {
        if (!this._user) {
            return null;
        }
        return this._user.organization.access;
    }

    hasWorkplanAccess() {
        let result = false;

        if (this.getOrganizationAccessLevel() & OrganizationAccessLevel.WORKPLAN) {
            if (!this._user.organization.workplanCusAdminOnly) {
                result = true;
            }
            else {
                if (this.isCustomerAdministrator() || this.isSuperUser()) {
                    result = true;
                }
            }
        }

        return result;
    }

    isAccessValid() {
        let user = this.getUser();
        if (!user) {
            return false;
        }

        let access = user.access;

        if (!access) {
            return false;
        }

        let parts = access.split('.');
        let body;
        try {
            body = JSON.parse(window.atob(parts[1]));
        }
        catch (ex) {
            return false;
        }

        let expirationDate = ApplicationInstance.getInstance().getDateFactory().create(body.exp * 1000);
        return ApplicationInstance.getInstance().getDateFactory().create() < expirationDate;
    }

    isRenewValid() {
        let user = this.getUser();
        if (!user) {
            return false;
        }

        let renew = user.renew;

        if (!renew) {
            return false;
        }

        let parts = renew.split('.');
        let body;
        try {
            body = JSON.parse(window.atob(parts[1]));
        }
        catch (ex) {
            return false;
        }

        let expirationDate = ApplicationInstance.getInstance().getDateFactory().create(body.exp * 1000);
        return ApplicationInstance.getInstance().getDateFactory().create() < expirationDate;
    }

    getUser() {
        return this._user;
    }

    getToken() {
        if (this._user) {
            return this._user.token
        }
        return null;
    }

    getAccessToken() {
        if (this._user) {
            return this._user.access;
        }
        return null
    }

    getRenewToken() {
        if (this._user) {
            return this._user.renew;
        }
        return null
    }

    isManualPermitted() {
        if (!this._user) {
            return false;
        }

        return this._user.manual_permitted === 1;
    }

    shouldLogout() {
        return this._shouldLogout;
    }

    getUnit() {
        return this._unit;
    }

    getDefaultNetworkView() {
        let user = this.getUser();
        if (!user) {
            return null;
        }

        return this._user.organization.settings.defaultView;
    }

    getCustomerAddress() {
        if (!this._user) {
            return null;
        }

        return this._user.organization.address;
    }

    //organization location. 
    getCustomerPosition() {
        if (!this._user) {
            return null;
        }

        if (this._user.organization.latitude === undefined || this._user.organization.longitude === null) {
            return null;
        }
        else {
            return new Position({
                coords : {
                    latitude : this._user.organization.latitude,
                    longitude : this._user.organization.longitude
                },
                timestamp : ApplicationInstance.getInstance().getDateFactory().create().getTime()
            });
        }
    }

    getSamplingMethod() {
        return this._user.organization.settings.samplingMethod || SamplingMethod.ACCEPTABLE_ERROR;
    }

    getAcceptableError() {
        let ae = this._user.organization.settings.confidenceLevel;
        if (ae === undefined || ae === null) {
            ae = 5;
        }

        return ae;
    }

    isIRIStrictMode() {
        return this._user.organization.settings.mapMatchEnabled || 0;
    }

    _setUser(user) {
        this._user = user;

        let sentry = this.getSentryClient();
        if (sentry) {
            if (user) {
                sentry.setUser(user.id);
                sentry.setOrganizationID(user.organization.id);
                sentry.setOrganizationName(user.organization.name);
            }
            else {
                sentry.setUser(null);
                sentry.setOrganizationID(null);
                sentry.setOrganizationName(null);
            }
        }

        // this._user.persist = this._persist;
        // this.setError(null);
        this._getStorageStrategy().save({[USER_KEY]: JSON.stringify(user)}).then(() => {
            return this._forceUpdate();
        }).catch((error) => {
            new (ApplicationInstance.getInstance().getErrorClass())({
                message: "Could not set user in UserStore",
                details: error
            });
        });
        // if (this.isSession) {
        //     window.sessionStorage.user = JSON.stringify(user);
        // }
        // else {
        //     window.localStorage.user = JSON.stringify(user);
        // }
        this._unit = user.organization.settings.units;
    }

    _update(actionData) {
        let data = actionData.getData();
        let sentry = this.getSentryClient();
        switch (actionData.getTag()) {
            case 'flag_update':
                this._setUserFlag(data.flagName, data.flagValue);
                return false;
            case 'reset-password':
                // fall through
            case 'confirm-account':
                // fall through
                // if (data.success && (data.user.role === Role.LOCAL_ADMIN || data.user.role === Role.DATA_MANAGER)) {
                //     this._shouldLogout = false;
                //     this._setUser(data.user);
                // }
                // else {
                //     this._user = null;
                // }
                // return true;
            case 'login_action':
                this._shouldLogout = false;
                // this._persist = data.persist;
                // this.isSession = !data.persist;
                if (data.success) {
                    this._setUser(data.user, data);
                    // data.user.persist = this._persist;
                }
                else {
                    this._user = null;
                }

                // if _setUser is called; then, this should return false.
                // return !data.success;
                
                // This HAS to return true to ensure the login process functions properly.
                // If this doesn't return true; then, the store will not fire updates, so networkclients will not update to get the access token.
                // In the case of a login, the AuthClient was not getting an access token when hitting an whoami api. So it called RequestLogoutAction.
                return true;
            case 'logout_action':
                if (sentry) {
                    sentry.setUser(null);
                    sentry.setOrganizationID(null);
                    sentry.setOrganizationName(null);
                }
                return true;
            case 'organization_settings_change':
                if (data.settings && data.settings.units !== undefined) {
                    this._unit = data.settings.units;
                    return true;
                }
                return false;
            case 'request_logout_action':
                this._shouldLogout = true; 
                return true;
            /*
            migrateheck sets _shouldLogout to true. _shouldLogout has been changed to a very controlled value inside UserStore that should only ever be set by 
            request_logout_action and logout_action (via this._reset).
            If _shouldLogout is set to true; then, most actions and most Queues (currently only available in the Issue Tracker app), will prevent execution and usage.
            This was implemented for action refactoring to prevent new actions from executing after a User has logged out; but, also wait for existing actions to 
            finish executing before finalizing the logout.

            Usage of migrateheck should be refactored to NOT dispatch and instead execute the RequestLogoutAction and, when it is safe to finalize the logout, the 
            LogoutAction. See usage of ActionStore and LogoutAction inside tp-app-common's Application class for an example.

            case 'migrateheck':
                this._user = null;
                this.setError(null);
                this._shouldLogout = true;
                delete window.localStorage.user;
                delete window.sessionStorage.user;
                getRouter().replaceState('/login');
                getRouter().clear();
                return true;*/
            case 'access_token_update':
                //this._shouldLogout = false;
                this._setUser(data, this._persist);
                return false;
            case 'user_refresh':
                if (data.error) {
                    return true;
                }

                this._unit = data.user.units;
                this._user.organization.units = data.user.units;
                this._user.organization.settings.samplingMethod = data.user.sampling_method;
                this._saveUser(this._user);
                return false;
            case 'users_loaded':
                this._organizationUsers = data;
                return true;
            case 'user_settings_change':
                if (data.firstName) {
                    this._user.fname = data.firstName;
                }
                if (data.lastName) {
                    this._user.lname = data.lastName;
                }
                this._saveUser(this._user);
                return false;
            case 'create_user':
                this._organizationUsers.push(data);
                return true;
            case 'delete_users':
                this._deleteUsers(data);
                return true;
            case 'modify_users':
                this._modifyUsers(data.users);
                return true;
        }

        return false;
    }

    _saveUser(user) {
        let sentry = this.getSentryClient();
        if (sentry) {
            sentry.setUser(user.id);
            sentry.setOrganizationID(user.organization.id);
            sentry.setOrganizationName(user.organization.name);
        }

        this._getStorageStrategy().save({[USER_KEY]: JSON.stringify(user)}).then(() => {
            return this._forceUpdate();
        }).catch((error) => {
            new (ApplicationInstance.getInstance().getErrorClass())({
                message: "Could not save user in UserStore",
                details: error
            });
        });
        // if (this.isSession) {
        //     window.sessionStorage.user = JSON.stringify(user);
        // }
        // else {
        //     window.localStorage.user = JSON.stringify(user);
        // }
    }
}
