'use strict';

import * as React from 'react';
import {ComparisonParamsBuilder} from '../builders/ComparisonParamsBuilder';
import {Select} from '../components/Select';
import {Option} from '../utils/Option';

const SELECT_WIDTH = '100%';

export class BooleanComparisonParamsBuilder extends ComparisonParamsBuilder {
    constructor(extra) {
        super();
        
        if (extra && extra.options) {
            this.options = extra.options;
        }
        else {
            this.options = [
                new Option('True', true), 
                new Option('False', false)
            ];
        }
    }

    getDefaultValue() {
        return null;
    }

    $findOptionByValue(value) {
        for (let i = 0; i < this.options.length; i++) {
            let option = this.options[i];
            if (option.getValue() === value) {
                return option;
            }
        }

        return null;
    }

    _buildSelectBoxAndSpan(value, onChange, editable) {
        let option = this.$findOptionByValue(value);
        if (editable) {
            return <Select
                value={option}
                allowSelectNullOption={false}
                options={this.options}
                onChange={(option) => {
                    onChange(option.getValue())
                }}
                scrollable={false}
                width={SELECT_WIDTH}
            />;
        }
        else {
            return <span>{option.getText().toLowerCase()}</span>
        }
    }

    _buildIsEqualTo(value, onChange, editable) {
        return this._buildSelectBoxAndSpan(value, onChange, editable);
    }

    _buildIsNotEqualTo(value, onChange, editable) {
        return this._buildSelectBoxAndSpan(value, onChange, editable);
    }
}
