'use strict';

import {Action} from './Action';
import {OrganizationClient} from '../net/OrganizationClient';
import {TPError} from '@totalpave/error';

class LoadOrganizationPreferencesSingleton extends Action {
    getTag() {
        return 'organization_preferences_load';
    }

    _shouldDispatchOnError() {
        return false;
    }

    _execute() {
        return new Promise((resolve, reject) => {
            OrganizationClient.getInstance().getPreferences().then((preferences) => {
                resolve(preferences);
            }).catch((ex) => {
                let error = new TPError({
                    message: "Could not load Organization Preferences.",
                    details: ex
                });
                reject(error);
            });
        });
    }
}

const LoadOrganizationPreferences = new LoadOrganizationPreferencesSingleton();

export { LoadOrganizationPreferences };
