'use strict';

import {Action} from './Action';

class UpdateProgressActionSingleton extends Action {
    constructor() {
        super();
    }

    getTag() {
        return 'update_progress_action';
    }

    _shouldDispatchOnError() {
        return false;
    }

    _execute({id, value} = {}) {
        return Promise.resolve({id: id, value: value});
    }
}

const UpdateProgressAction = new UpdateProgressActionSingleton();

export { UpdateProgressAction };
