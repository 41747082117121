import {Formatter} from './Formatter';
import NumberFormat from 'react-number-format';
import {LocalizationStore} from '../store/LocalizationStore';
import {ObjectUtils} from '@totalpave/object';

export class CurrencyFormatter extends Formatter {
    constructor(format) {
        super();
        if (ObjectUtils.isVoid(format)) {
            format = LocalizationStore.getInstance().getCurrencyFormat();
        }
        this._numberFormat = new NumberFormat({
            decimalScale: format.get('decimalScale'),
            decimalSeparator: format.get('decimalSeparator'),
            thousandSeparator: format.get('groupSeparator'),
            thousandsGroupStyle: format.get('groupStyle'),
            prefix: format.get('prefixSymbol'),
            suffix: format.get('suffixSymbol'),
            fixedDecimalScale: true
        });
    }

    format(number) {
        return this._numberFormat.formatValueProp(number);
    }
}
