import * as React from 'react';
import {InputType} from '../utils/InputType';
import {Textfield} from '../components/Textfield';
import {NumberFormatTextfield} from '../components/NumberFormatTextfield';
import {CurrencyTextfield} from '../components/CurrencyTextfield';

class TextfieldFactory {
    constructor() {}

    create(props) {
        switch (props.type) {
            case InputType.TEXT:
            case InputType.EMAIL:
            case InputType.PASSWORD:
            case InputType.DECIMAL:
            case InputType.NUMBER:
                return <Textfield {...props} />;
            case InputType.NUMBER_FORMAT:
                return <NumberFormatTextfield {...props} />;
            case InputType.CURRENCY:
                return <CurrencyTextfield {...props} />;
            default:
                throw new Error(`TextfieldFactory received unsupported input type: ${props.type}`);
        }
    }
}

export { TextfieldFactory };
