'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';
import '../style/Frame.less';

/**
 * A component that interfaces to iframes.
 * This component renders an iOS compatible iframe, but also works with Android.
 *
 * This component will resolve iOS iframe bug that allows iframe content to overflow outside
 * the iframe (even if overflow is hidden) by wrapping the iframe in a div node.
 */
class Frame extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="Frame">
                <iframe src={this.props.source} />
            </div>
        );
    }
}

Frame.propTypes = {
    source: PropTypes.string.isRequired
};

export { Frame };
