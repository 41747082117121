'use strict';

import React from 'react';
import {ApplicationInstance} from '@totalpave/application-instance';
import {TPComponent} from './TPComponent';
import {ObjectUtils} from '../utils/ObjectUtils';
import {TextualCrumbBuilder} from '../builders/TextualCrumbBuilder';
import PropTypes from 'prop-types';
import "../style/Breadcrumb.less";

/**
 * In order to navigate to different crumbs, use the public functions back, next, and go.
 * In addition, use canGoBack and canGoForward to determine if it would be valid to navigate using back and next.
 */
export class Breadcrumb extends TPComponent {
    constructor(props) {
        super(props);

        this.state = {
            index: props.index || 0
        };

        this.setIndex = this.setIndex.bind(this);
    }

    static getDerivedStateFromProps(nextProps, state) {
        if (!ObjectUtils.isVoid(nextProps.index)) {
            if (state.index !== nextProps.index) {
                return {
                    index: nextProps.index || 0
                };
            }
        }

        return null;
    }

    render() {
        return (
            <div className={`Breadcrumb ${this.props.className ? this.props.className : ''}`}>
                <div className="crumb-container">
                    {this._buildCrumbs()}
                </div>
                <div className="container">
                    {this._renderContent()}
                </div>
            </div>
        );
    }

    getCrumbBuilder() {
        let builder = null;
        if (!this.props.crumbBuilder) {
            builder = new TextualCrumbBuilder();
        }
        else {
            builder = this.props.crumbBuilder;
        }
        return builder;
    }

    _buildCrumbs() {
        return this.getCrumbBuilder().build(this.props.options, this.state.index, this);
    }

    setIndex(index) {
        let oldIndex = this.state.index
        
        let promise;
        if (this.props.onPreNavigate) {
            promise = this.props.onPreNavigate(index, oldIndex, this);
            
        }

        if (!promise) {
            promise = Promise.resolve();
        }

        promise.then(() => {
            this.setState({
                index: index
            }, () => {
                if (this.props.onChange) {
                    this.props.onChange(index, oldIndex);
                }
            });
        }).catch(() => {
            // Do nothing
        })
    }

    _renderContent() {
        let renderer = this.props.options[this.state.index];
        if (renderer && renderer.render) {
            return renderer.render();
        }
        else {
            ApplicationInstance.getInstance().getLogger().warn('Missing render method for index', this.state.index);
            return null;
        }
    }

    getIndex() {
        return this.state.index;
    }

    canGoBack() {
        return this.state.index > 0 && (this.props.canGoBack ? this.props.canGoBack() : true);
    }

    canGoForward() {
        // The isFinished condition is to support the submit button.
        return (
            this.state.index < (this.props.options || []).length - 1 ||
            this.isFinished()
        ) &&
        (
            this.props.canGoForward ? this.props.canGoForward() : true
        );
    }

    isFinished() {
        return !(this.state.index < (this.props.options || []).length - 1);
    }

    back() {
        let oldIndex = this.state.index;
        let newIndex = this.state.index - 1;
        if (newIndex < 0) {
            newIndex = 0;
        }

        let promise;

        if (this.props.onPreNavigate) {
            promise = this.props.onPreNavigate(newIndex, oldIndex, this);
            
        }

        if (!promise) {
            promise = Promise.resolve();
        }

        promise.then(() => {
            this.setState({
                index : newIndex
            }, () => {
                if (this.props.onChange) {
                    this.props.onChange(newIndex, oldIndex);
                }
            });
        }).catch(() => {
            // Do nothing
        });
    }

    next() {
        let oldIndex = this.state.index;
        let newIndex = this.state.index + 1;
        if (newIndex > this.props.options.length) {
            newIndex = this.props.options.length;
        }

        let promise;
        if (this.props.onPreNavigate) {
            promise = this.props.onPreNavigate(newIndex, oldIndex, this);
        }

        if (!promise) {
            promise = Promise.resolve();
        }

        promise.then(() => {
            this.setState({
                index : newIndex
            }, () => {
                if (this.props.onChange) {
                    this.props.onChange(newIndex, oldIndex);
                }
            });
        }).catch(() => {
            // Do nothing
        });
    }

    go(to) {
        let currentIndex = this.state.index;
        let newIndex = currentIndex + to;
        if (newIndex > this.props.options.length) {
            newIndex = this.props.options.length;
        }
        if (newIndex < 0) {
            newIndex = 0;
        }

        let promise;
        if (this.props.onPreNavigate) {
            promise = this.props.onPreNavigate(newIndex, currentIndex, this);
        }

        if (!promise) {
            promise = Promise.resolve();
        }

        promise.then(() => {
            this.setState({
                index : newIndex
            }, () => {
                if (this.props.onChange) {
                    this.props.onChange(newIndex, currentIndex);
                }
            });
        }).catch(() => {
            // Do nothing
        });
    }
}

Breadcrumb.propTypes = {
    index: PropTypes.number,
    className: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.any).isRequired,
    onChange: PropTypes.func,
    onPreNavigate: PropTypes.func,
    canGoBack: PropTypes.func,
    canGoForward: PropTypes.func
};
