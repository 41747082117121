'use strict';

import React from 'react';
import {Store} from '@totalpave/store';

let _instance = null;

/**
@deprecated Use @totalpave/ui-menu
A datastore that maintains a stack of opened modals.
 */
export class MenuStore extends Store {
    constructor() {
        super();
        this._menus = [];
    }

    static getInstance() {
        if (!_instance) {
            _instance = new MenuStore();
        }
        return _instance;
    }

    _reset() {
        this._menus = [];
        return Promise.resolve();
    }

    getMenus() {
        return this._menus.slice();
    }

    containsMenu(id) {
        for (let i = 0; i < this._menus.length; i++) {
            let menu = this._menus[i];
            if (menu.props.id === id) {
                return true;
            }
        }
        return false;
    }

    _update(actionData) {
        switch (actionData.getTag()) {
            case 'add_menu':
                this._addMenu(actionData.getData());
                // this._menus.push(actionData.getData());
                return true;
            case 'delete_menu':
                this._deleteMenu(actionData.getData());
                return true;
            case 'clear_menus':
                // this._menus = [];
                this._clear();
                return true;
            case 'logout_action':
                // this._reset();
                return true;
        }

        return false;
    }

    _clear() {
        for (let i = 0; i < this._menus.length; i++) {
            let menu = this._menus[i];
            this._deleteMenu(menu.props.id);
        }
    }

    _addMenu(menu) {
        if (this.containsMenu(menu.props.id)) {
            for (let i = 0; i < this._menus.length; i++) {
                let m = this._menus[i];
                if (menu.props.id === m.props.id) {
                    this._menus[i] = React.cloneElement(menu, {active:true});
                    break;
                }
            }
            // this._deleteMenu(menu.props.id);
        }
        else {
            this._menus.push(React.cloneElement(menu, {active:true}));
        }
    }

    _deleteMenu(id) {
        for (let i = 0; i < this._menus.length; i++) {
            let menu = this._menus[i];
            if (menu.props.id === id) {
                this._menus[i] = React.cloneElement(menu, {active:false});
                break;
            }
        }
    }
}
