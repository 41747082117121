
import * as React from 'react';
import PropTypes from 'prop-types';
import { FormModal } from './FormModal';
import { FormGroup } from './FormGroup';
import { FormItem } from './FormItem';
import { FormItemSelect } from './FormItemSelect';
import { Select } from './Select';

import { SelectOption } from '../utils/SelectOption';

import { TableDefinitionFactory } from '../factories/TableDefinitionFactory';
import { TableEditorFactory } from '../factories/TableEditorFactory';

import "../style/BulkEditModal.less";

const NULL_OPTION_TEXT = "Select a column";
const NULL_OPTION = new SelectOption(NULL_OPTION_TEXT, null);

export class BulkEditModal extends FormModal {
    constructor(props) {
        super(props);
        this._addClassName('BulkEditModal');

        this.state.column = NULL_OPTION;
        this.state.columnOptions = [];
        this.state.value = undefined; // Null may be an valid value. We don't know. Undefined on the otherhand will NEVER be valid.
        this.state.hasClickedDisabledSubmit = false;
        this.state.shouldShowColumnError = false;

        let defs = this.props.definitionFactory.getDefinitions(); 
        for (let i in defs) {
            if ((typeof defs[i].readonly === 'function' || defs[i].readonly !== true) && defs[i].bulkEditable !== false) {
                this.state.columnOptions.push(new SelectOption(defs[i].text, defs[i].key));
            }
        }

        this._onSelectChange = this._onSelectChange.bind(this);
        this.$onInputStateChange = this.$onInputStateChange.bind(this);
    }

    getTitle() {
        return 'Bulk Edit';
    }

    $getColumnError(column) {
        if (!column.getValue()) {
            return 'Please select a column';
        }

        return null;
    }

    _onDisabledSubmit() {
        let updateObj = {
            hasClickedDisabledSubmit: true
        };

        if (!!this.$getColumnError(this.state.column)) {
            updateObj.shouldShowColumnError = true;
        }

        this.setState(updateObj);

        return super._onDisabledSubmit();
    }

    _getDisabledSubmitErrorMessage() {
        let columnError = this.$getColumnError(this.state.column);
        if (!!columnError) {
            return columnError;
        }

        return null;
    }

    _onSubmit() {
        return this.props.onSubmit({
            column: this.state.column.getValue(), 
            value: this.state.value
        });
    }

    $onInputStateChange() {
        if (this.state.hasClickedDisabledSubmit) {
            this._setError(this._getDisabledSubmitErrorMessage());
        }
    }

    _onSelectChange(option) {
        this.setState({
            column: option,
            shouldShowColumnError: !!this.$getColumnError(option) && this.state.hasClickedDisabledSubmit
        }, this.$onInputStateChange);
    }

    _renderContent() {
        let valueInput = null;
        if (this.state.column.getValue() !== null) {
            let def = this.props.definitionFactory.getDefinition(this.state.column.getValue());
            valueInput = this.props.editorFactory.create(
                def.type, 
                this.state.value, 
                (value) => {
                    this.setState({
                        value: value
                    });
                }, 
                {
                    ...def.extra,
                    width: 200
                },
                def
            );
        }

        return (
            <FormGroup className='bulk-edit-modal-container'>
                <FormItemSelect
                    hasError={this.state.shouldShowColumnError}
                    contentProps={{
                        value: this.state.column,
                        readonly: false,
                        nullOptionText: NULL_OPTION_TEXT,
                        allowSelectNullOption: true,
                        options: this.state.columnOptions,
                        onChange: this._onSelectChange,
                        scrollable: true,
                        width: Select.WIDTH_OPTIONS.UNSET
                    }}
                />
                <FormItem className='value'>
                    {valueInput}
                </FormItem>
            </FormGroup>
        );
    }
}

BulkEditModal.propTypes = {
    definitionFactory: PropTypes.instanceOf(TableDefinitionFactory).isRequired,
    editorFactory: PropTypes.instanceOf(TableEditorFactory).isRequired,
    onSubmit: PropTypes.func.isRequired
}
