'use strict';

import {NetworkClient} from './NetworkClient';

let _instance = null;

class IRIClient extends NetworkClient {
    constructor() {
        super();
    }

    static getInstance() {
        if (!_instance) {
            _instance = new IRIClient();
        }

        return _instance;
    }

    _getServiceBase() {
        return 'iri';
    }

    updateNote(sessionID, note) {
        return this.post('note/update', {
            sessionID: sessionID,
            note: note
        });
    }

    getHistoryRange() {
        return this.get('history/range');
    }

    deleteIRIYears(sectionID, years) {
        return this.post('iri/years/delete', {
            sectionID: sectionID,
            years : years
        });
    }

    deleteIRIs(ids) {
        return this.post('iri/delete', {
            ids: ids
        });
    }
}

export { IRIClient };
