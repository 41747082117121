'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';
import { KeyCode } from '../utils/KeyCode';
import '../style/Searchfield.less';
import { IconFactory } from '../factories/IconFactory';

const BATCH_DELAY = 300;

class Searchfield extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value : props.value ? props.value : ''
        };

        this._onChange = this._onChange.bind(this);
        this._onIconClick = this._onIconClick.bind(this);
        this._onKeyPress= this._onKeyPress.bind(this);
        this._node;
        this._textfield;
        this._batchTimer;
    }

    blur() {
        this._textfield.blur();
    }

    _onChange(e) {
        this.setState({
            value : e.target.value
        }, () => {
            clearTimeout(this._batchTimer);
            this._batchTimer = setTimeout(() => {
                this.props.onSearch(this.state.value);
            }, this.props.batchDelay === undefined ? BATCH_DELAY : this.props.batchDelay);
        });
    }

    _onIconClick(e) {
        this.setState({
            value : ''
        }, () => {
            this.props.onSearch(this.state.value);
        });
        this._textfield.value = '';
        this._textfield.focus();
    }

    _onKeyPress(event) {
        if (event.keyCode === KeyCode.ENTER) {
            this._textfield.blur();
            event.preventDefault();
        }
    }

    $getIcon() {
        let icon = null;
        if (this.props.loading) {
            icon = IconFactory.create(IconFactory.Icons.LOADING_SPINNER, {
                isAnimated: true,
                pulse: true
            });
        }
        else if (this.state.value.length > 0) {
            icon = IconFactory.create(IconFactory.Icons.THIN_CANCEL, {
                onClick: this.props.readonly ? null : this._onIconClick
            });
        }
        else {
            icon = IconFactory.create(IconFactory.Icons.SEARCH, {
                onClick: this.props.readonly ? null : this._onIconClick
            });
        }

        return icon;
    }

    render() {
        let props = {
            className : `Searchfield ${this.props.readonly ? 'readonly' : ''}`,
            ref : (c) => { this._node = c; }
        };

        props.onKeyPress = this._onKeyPress;
        props.onClick = this.props.onClick;

        return (
            <div {...props}>
                <input className="search-text" ref={(c) => { this._textfield = c; }} type="text" placeholder="Search..." onChange={this._onChange} readOnly={this.props.readonly} value={this.state.value}/>
                {this.$getIcon()}
            </div>
        );
    }
}

Searchfield.propTypes = {
    // If the SearchField is removed from the DOM (which has happened in usages related to Lists) and then brought back, the text is wiped out.
    // This props allows the users to maintain the text if they desire to.
    value: PropTypes.string,
    onSearch: PropTypes.func.isRequired,
    batchDelay: PropTypes.number,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    readonly: PropTypes.bool
};

export { Searchfield };
