'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import '../style/Progressbar.less';

let STRING_DIRECTIONS;

/**
 * @deprecated Use `@totalpave/ui-progress` package ProgressBar instead.
 */
export class Progressbar extends React.Component {
    constructor(props) {
        super(props);
    }

    _getDirection() {
        return this.props.direction ? this.props.direction : Progressbar.DIRECTIONS.RIGHT;
    }

    _getClassName() {
        return STRING_DIRECTIONS[this._getDirection()] + (this.props.className ? ' ' + this.props.className : '');
    }

    render() {
        let min = this.props.min || 0;
        let max = this.props.max || 100;
        let value = this.props.value || 0;
        let percent = ((value - min) / (max - min)) * 100;
        let fillBarStyle = {};

        switch (this._getDirection()) {
            case Progressbar.DIRECTIONS.LEFT:
                fillBarStyle.marginLeft = `${100 - percent}%`;
                fillBarStyle.width = '100%';
                break;
            case Progressbar.DIRECTIONS.RIGHT:
                fillBarStyle.width = `${percent}%`;
                break;
            case Progressbar.DIRECTIONS.UP:
                fillBarStyle.marginTop = `${100 - percent}%`;
                fillBarStyle.height = '100%';
                break;
            case Progressbar.DIRECTIONS.DOWN:
                fillBarStyle.height = `${percent}%`;
                break;
        }

        let workingIndicator = null;
        if (this.props.showWorkingIndicator) {
            workingIndicator = <div className='working-indicator'></div>;
        }

        return (
            <div className={`Progressbar ${this._getClassName()}`}>
                <div className="fill-bar" style={fillBarStyle}></div>
                {workingIndicator}
            </div>
        );
    }
}

Progressbar.DIRECTIONS = {
    LEFT: 0x0001,
    RIGHT: 0x0002,
    UP: 0x0003,
    DOWN: 0x0004
}

STRING_DIRECTIONS = {
    [Progressbar.DIRECTIONS.LEFT]: 'left',
    [Progressbar.DIRECTIONS.RIGHT]: 'right',
    [Progressbar.DIRECTIONS.UP]: 'up',
    [Progressbar.DIRECTIONS.DOWN]: 'down'
}

Progressbar.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    value: PropTypes.number.isRequired,
    className: PropTypes.string,
    direction: PropTypes.oneOf([
        Progressbar.DIRECTIONS.LEFT,
        Progressbar.DIRECTIONS.RIGHT,
        Progressbar.DIRECTIONS.UP,
        Progressbar.DIRECTIONS.DOWN
    ]),
    // Defaults to false. Is a little bar that pulses through the fill-bar to indicator that the app has not frozen.
    showWorkingIndicator: PropTypes.bool
};
