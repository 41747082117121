'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';
import {MouseStore} from '../store/MouseStore';
import {MouseButton} from '../utils/MouseButton';
import "../style/DraggableComponent.less";

export class DraggableComponent extends React.Component {
    constructor(props) {
        super(props);
        this._onMouseDown = this._onMouseDown.bind(this);
        this._onMouseUpdate = this._onMouseUpdate.bind(this);
        this._active = false;
        this._node = null;
    }

    componentDidMount() {
        MouseStore.getInstance().register(this._onMouseUpdate);
    }

    componentWillUnmount() {
        MouseStore.getInstance().unregister(this._onMouseUpdate);
    }

    _onMouseUpdate() {
        if (this._active) {
            if (MouseStore.getInstance().isButtonPressed(MouseButton.LEFT)) {
                this._onDrag();
            }
            else {
                this._onDragStop();
            }
        }
    }

    render() {
        let child = this.props.children;
        if (!child || child instanceof Array) {
            return null;
        }

        return (
            <div 
                ref={(n) => {this._node = n;}}
                className="DraggableComponent"
                onMouseDown={this._onMouseDown}
            >
                {child}
            </div>
        );
    }

    _onMouseDown(e) {
        if (!this._active) {
            if (this.props.onDragStart) {
                this.props.onDragStart(e);
            }

            this._active = true;
        }
    }

    _onDrag() {
        this._fireCallback(this.props.onDrag);
    }

    _onDragStop() {
        this._active = false;
        this._fireCallback(this.props.onDragStop);
    }

    _fireCallback(callback) {
        window.requestAnimationFrame(() => {
            let x = MouseStore.getInstance().getX();
            let y = MouseStore.getInstance().getY();
            if (callback) {
                callback({
                    x,
                    y,
                    node: this._node
                });
            }
        });
    }
}

DraggableComponent.propTypes = {
    children: PropTypes.node.isRequired,
    onDragStart: PropTypes.func,
    onDrag: PropTypes.func,
    onDragStop: PropTypes.func,
    axis: PropTypes.oneOf([
        'x', 
        'y', 
        'xy'
    ])
};
