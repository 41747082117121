'use strict';

import {Action} from './Action';
import {Store} from  '@totalpave/store';

//Tells the app we are logging out right NOW.
class LogoutActionSingleton extends Action {
    constructor() {
        super();
        this.setForceExecute(true);
    }

    getTag() {
        return 'logout_action';
    }

    _shouldDispatchOnError() {
        return false;
    }

    /**
     * public logout
     *
     *  This will clear the user's local storage.
     * 
     * @return {void} 
     */
    _execute() {
        return Promise.all(
            Store.getInstances().map((store) => {
                return store.reset();
            })
        );
    }
}

const LogoutAction = new LogoutActionSingleton();

export { LogoutAction };
