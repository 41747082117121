'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';
import "../style/SlideMenu.less";
import { AddBodyClass } from '../actions/AddBodyClass';
import { RemoveBodyClass } from '../actions/RemoveBodyClass';
import { UserStore } from '../store/UserStore';

class SlideMenu extends React.Component {
    constructor(props) {
        super(props);
        this.className = 'SlideMenu';
        this._onTap = this._onTap.bind(this);
    }

    _onTap() {
        if (this.props.onHide) {
            this.props.onHide();
        }
    }

    $callActiveCallback() {
        if (this.props.active && this.props.onShow) {
            this.props.onShow();
        }
        else if (!this.props.active && this.props.onHide) {
            this.props.onHide();
        }
    }

    componentDidMount() {
        this.$callActiveCallback();
        AddBodyClass.execute('has-slide-menu');
    }

    componentWillUnmount() {
        if (!UserStore.getInstance().shouldLogout()) {
            RemoveBodyClass.execute('has-slide-menu');
        }
    }

    componentDidUpdate(props) {
        if (this.props.active !== props.active) {
            this.$callActiveCallback();
        }
    }

    render() {
        let className = this.className;
        if (this.props.active) {
            className += ' active';
        }

        let anchor = 'left';
        if (this.props.useRight) {
            anchor = 'right';
        }

        className += ` ${anchor}`;

        return (
            <div className={className} onClick={this._onTap} style={this.props.style}>
                <div className="menu-overlay" onClick={this._onTap}>
                    &nbsp;
                </div>
                <div className="menu-content">
                    {this.props.children}
                </div>
                <div className="menu-bottom-bar" />
            </div>
        );
    }
}

SlideMenu.propTypes = {
    onHide: PropTypes.func,
    onShow: PropTypes.func,
    active: PropTypes.bool,
    useRight: PropTypes.bool,
    children: PropTypes.node,
    style: PropTypes.object
};

export { SlideMenu };
