import {Browser} from './Browser';

//An abstraction layer for HTMLElement that for a consistent API between IE and other browsers.

class Node {
    constructor(n) {
        this._node = n;
    }

    getNode() {
        return this._node;
    }

    containsClass(className) {
        return this.getClasses().indexOf(className) > -1;
    }

    getClasses() {
        let className = this.getAttribute('class');
        if (!className) {
            return [];
        }

        return className.split(' ');
    }

    getAttribute(key) {
        if (!this._node.getAttribute) {
            return null;
        }
        return this._node.getAttribute(key);
    }

    getDataValue(key) {
        return this.getAttribute(`data-${key}`);
    }

    getNextSibling() {
        if (Browser.isIE()) {
            return this._getNextSibling_ie();
        }
        else {
            if (this.getNode().nextElementSibling) {
                return new Node(this.getNode().nextElementSibling);
            }
            else {
                return null;
            }
        }
    }

    getParent() {
        let node = this.getNode().parentNode;
        if (!node) {
            return null;
        }

        return new Node(node);
    }

    getPreviousSibling() {
        if (Browser.isIE()) {
            return this._getPreviousSibling_ie();
        }
        else {
            if (this.getNode().previousElementSibling) {
                return new Node(this.getNode().previousElementSibling);
            }
            else {
                return null;
            }
        }
    }

    _getNextSibling_ie() {
        let node = this.getNode();
        while ((node = node.nextSibling)) {
            if (node.nodeType === Element.ELEMENT_NODE) {
                return new Node(node);
            }
        }
        return null;
    }

    _getPreviousSibling_ie() {
        let node = this.getNode();
        while ((node = node.previousSibling)) {
            if (node.nodeType === Element.ELEMENT_NODE) {
                return new Node(node);
            }
        }
        return null;
    }

    querySelector(selector) {
        let node = this.getNode().querySelector(selector);
        if (node) {
            return new Node(node);
        }
        else {
            return null;
        }
    }

    focus() {
        this.getNode().focus();
    }

    blur() {
        return this.getNode().blur();
    }
}

export {Node};
