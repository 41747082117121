
'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';
import {InlineBlock} from './InlineBlock';
import {Checkbox} from './Checkbox';
import '../style/LabeledCheckbox.less';

class LabeledCheckbox extends React.Component {
    constructor(props) {
        super(props);
        this._checkbox = null;
    }

    render() {
        return (
            <div className="LabeledCheckbox" onClick={(e) => {
                this._checkbox.onClick(e);
            }}>
                <Checkbox ref={(checkbox) => {this._checkbox = checkbox; }} value={this.props.value} onChange={this.props.onChange} readonly={this.props.readonly} />
                <InlineBlock>{this.props.children}</InlineBlock>
            </div>
        );
    }
}

LabeledCheckbox.propTypes = {
    children: PropTypes.node,
    onChange: PropTypes.func.isRequired,
    readonly: PropTypes.bool,
    value: PropTypes.number.isRequired
};

export { LabeledCheckbox };
