
/**
 * @deprecated - Use @totalpave/currency instead
 */
export const Currency = {
    AFGHANI                 : "AFN",
    LEK                     : "ALL",
    ALGERIAN_DINAR          : "DZD",
    US_DOLLAR               : "USD",
    EURO                    : "EUR",
    KWANZA                  : "AOA",
    EAST_CARRIBBEAN_DOLLAR  : "XCD",
    ARGENTINE_PESO          : "ARS",
    ARMENIAN_DRAM           : "AMD",
    ARUBAN_FLORIN           : "AWG",
    AUSTRALIAN_DOLLAR       : "AUD",
    AZERBAIJANIAN_MANAT     : "AZN",
    BAHAMIAN_DOLLAR         : "BSD",
    BAHRAINI_DINAR          : "BHD",
    TAKA                    : "BDT",
    BARBADOS_DOLLAR         : "BBD",
    BELARUSSIAN_RUBLE       : "BYR",
    BELIZE_DOLLAR           : "BZD",
    CFA_FRANC_BCEAO         : "XOF",
    BERMUDIAN_DOLLAR        : "BMD",
    NGULTRUM                : "BTN",
    INDIAN_RUPEE            : "INR",
    BOLIVIANO               : "BOB",
    MVDOL                   : "BOV",
    CONVERTIBLE_MARK        : "BAM",
    PULA                    : "BWP",
    NORWEGIAN_KRONE         : "NOK",
    BRAZILIAN_REAL          : "BRL",
    BRUNEI_DOLLAR           : "BND",
    BULGARIAN_LEV           : "BGN",
    BURUNDI_FRANC           : "BIF",
    CABO_VERDE_ESCUDO       : "CVE",
    RIEL                    : "KHR",
    CANADIAN_DOLLAR         : "CAD",
    CAYMAN_ISLANDS_DOLLAR   : "KYD",
    UNIDAD_DE_FOMENTO       : "CLF",
    CHILEAN_PESO            : "CLP",
    YUAN_RENMINBI           : "CNY",
    COLOMBIAN_PESO          : "COP",
    UNIDAD_DE_VALOR_REAL    : "COU",
    COMORO_FRANC            : "KMF",
    CONGOLESE_FRANC         : "CDF",
    NEW_ZEALAND_DOLLAR      : "NZD",
    COSTA_RICAN_COLON       : "CRC",
    KUNA                    : "HRK",
    PESO_CONVERTIBLE        : "CUC",
    CUBAN_PESO              : "CUP",
    NETHERLANDS_ANTILLEAN_GUILDER : "ANG",
    CZECH_KORUNA            : "CZK",
    DANISH_KRONE            : "DKK",
    DJIBOUTI_FRANC          : "DJF",
    DOMINICAN_PESO          : "DOP",
    EGYPTIAN_POUND          : "EGP",
    EL_SALVADOR_COLON       : "SVC",
    CFA_FRANC_BEAC          : "XAF",
    NAKFA                   : "ERN",
    ETHIOPIAN_BIRR          : "ETB",
    FALKLAND_ISLANDS_POUND  : "FKP",
    FIJI_DOLLAR             : "FJD",
    CFP_FRANC               : "XPF",
    DALASI                  : "GMD",
    LARI                    : "GEL",
    GHANA_CEDI              : "GHS",
    GIBRALTAR_POUND         : "GIP",
    QUETZAL                 : "GTQ",
    POUND_STERLING          : "GBP",
    GUINEA_FRANC            : "GNF",
    GUYANA_DOLLAR           : "GYD",
    GOURDE                  : "HTG",
    LEMPIRA                 : "HNL",
    HONG_KONG_DOLLAR        : "HKD",
    FORINT                  : "HUF",
    ICELAND_KRONA           : "ISK",
    RUPIAH                  : "IDR",
    SDR                     : "XDR",
    IRANIAN_RIAL            : "IRR",
    IRAQI_DINAR             : "IQD",
    NEW_ISRAELI_SHEQEL      : "ILS",
    JAMAICAN_DOLLAR         : "JMD",
    YEN                     : "JPY",
    JORDANIAN_DINAR         : "JOD",
    KENYAN_SHILLING         : "KES",
    NORTH_KOREAN_WON        : "KPW",
    WON                     : "KRW",
    KUWAITI_DINAR           : "KWD",
    SOM                     : "KGS",
    KIP                     : "LAK",
    LEBANESE_POUND          : "LBP",
    LOTI                    : "LSL",
    RAND                    : "ZAR",
    LIBERIAN_DOLLAR         : "LRD",
    LIBYAN_DINAR            : "LYD",
    SWISS_FRANC             : "CHF",
    PATACA                  : "MOP",
    DENAR                   : "MKD",
    MALAGASY_ARIARY         : "MGA",
    KWACHA                  : "MWK",
    MALAYSIAN_RINGGIT       : "MYR",
    RUFIYAA                 : "MVR",
    OUGUIYA                 : "MRU",
    MAURITIUS_RUPEE         : "MUR",
    ADB_UNIT_OF_ACCOUNT     : "XUA",
    MEXICAN_PESO            : "MXN",
    MEXICAN_UNIDAD_DE_INVERSION : "MXV",
    MOLDOVAN_LEU            : "MDL",
    TUGRIK                  : "MNT",
    MOROCCAN_DIRHAM         : "MAD",
    MOZAMBIQUE_METICAL      : "MZN",
    KYAT                    : "MMK",
    NAMIBIA_DOLLAR          : "NAD",
    NEPALESE_RUPEE          : "NPR",
    CORDOBA_ORO             : "NIO",
    RIAL_OMANI              : "OMR",
    PAKISTAN_RUPEE          : "PKR",
    BALBOA                  : "PAB",
    KINA                    : "PGK",
    GUARANI                 : "PYG",
    NUEVO_SOL               : "PEN",
    PHILIPPINE_PESO         : "PHP",
    ZLOTY                   : "PLN",
    QATARI_RIAL             : "QAR",
    ROMANIAN_LEU            : "RON",
    RUSSIAN_RUBLE           : "RUB",
    RWANDA_FRANC            : "RWF",
    SAINT_HELENA_POUND      : "SHP",
    TALA                    : "WST",
    DOBRA                   : "STN",
    SAUDI_RIYAL             : "SAR",
    SERBIAN_DINAR           : "RSD",
    SEYCHELLES_RUPEE        : "SCR",
    LEONE                   : "SLL",
    SINGAPORE_DOLLAR        : "SGD",
    SUCRE                   : "XSU",
    SOLOMON_ISLANDS_DOLLAR  : "SBD",
    SOMALI_SHILLING         : "SOS",
    SOUTH_SUDANESE_POUND    : "SSP",
    SRI_LANKA_RUPEE         : "LKR",
    SUDANESE_POUND          : "SDG",
    SURINAM_DOLLAR          : "SRD",
    LILANGENI               : "SZL",
    SWEDISH_KRONA           : "SEK",
    WIR_EURO                : "CHE",
    WIR_FRANC               : "CHW",
    SYRIAN_POUND            : "SYP",
    NEW_TAIWAN_DOLLAR       : "TWD",
    SOMONI                  : "TJS",
    TANZANIAN_SHILLING      : "TZS",
    BAHT                    : "THB",
    PAANGA                  : "TOP",
    TRINIDAD_AND_TOBAGO_DOLLAR : "TTD",
    TUNISIAN_DINAR          : "TND",
    TURKMENISTAN_NEW_MANAT  : "TMT",
    UGANDA_SHILLING         : "UGX",
    HRYVNIA                 : "UAH",
    UAE_DIRHAM              : "AED",
    US_DOLLAR_NEXT          : "USN",
    URUGUAY_PESO_EN_UNIDADES_INDEXADAS : "UYI",
    PESO_URUGUAYO           : "UYU",
    UZBEKISTAN_SUM          : "UZS",
    VATU                    : "VUV",
    BOLIVAR                 : "VEF",
    DONG                    : "VND",
    YEMENI_RIAL             : "YER",
    ZAMBIAN_KWACHA          : "ZMW",
    ZIMBABWE_DOLLAR         : "ZWL"
};
