
import * as React from 'react';
import {Modal} from './Modal';
import {ObjectUtils} from '@totalpave/object';
import {TPError} from '@totalpave/error';
import {Button} from './Button';
import '../style/FormModal.less';
import {Browser} from '../utils/Browser';
import {Form} from './Form';

export class FormModal extends Modal {
    constructor(props) {
        super(props);
        this._addClassName('FormModal');
        this._containerNode = React.createRef();

        this.state.error = null;

        this.$onSubmit = this.$onSubmit.bind(this);
        this.$onCancel = this.$onCancel.bind(this);
        this._onDisabledSubmit = this._onDisabledSubmit.bind(this);
    }
    
    //=====================================================================
    //      Abstract APIs
    //=====================================================================
    
    /**
     * @abstract Render function for your Modal's content.
     * @returns JSX|Array<JSX>
     */
    _renderContent() {
        throw new Error('FormModal._renderContent is abstract.');
    }

    /**
     * Override this function to run your submit code.
     * 
     * The Button waitForCallback is supported by default and managed by FormModal.
     * 
     * @param {MouseEvent} event 
     * @returns Promise<void>
     */
    _onSubmit(event) {
        throw new Error('FormModal._onSubmit is abstract');
    }

    //=====================================================================
    //      Override-able APIs
    //=====================================================================

    /**
     * Override this to provide error messages for data fields that contain invalid data.
     * 
     * @returns Nullable String
     */
    _getDisabledSubmitErrorMessage() {
        return null;
    }
    

    /**
     * Can be overridden to change the UI for the error message.
     * 
     * @param {TPError} error Note, error is nullable.
     */
    _renderErrorMessage(error) {
        return <span className='error'>{ObjectUtils.isVoid(error) ? '' : error.getMessage()}</span>;
    }

    /**
     * Can be overridden in conjunction with _getSubmitButtonProps to use a completely different button.
     * 
     * If you just want to edit or change props for the Submit button, do NOT override this function. See _getSubmitButtonProps instead.
     * 
     * @param {Object} props The props for the button.
     * @returns JSX for the Submit Button
     */
    _getSubmitButton(props) {
        return <Button
            {...props}
            key='submit'
            onClick={this.$onSubmit}
            onDisabledClick={this._onDisabledSubmit}
            enabled={this._isSubmitEnabled()}
        />;
    }

    /**
     * Can be overridden to edit or completely replace the default properties for the Submit button.
     * 
     * Note, the properties key, onClick, onDisabledClick, and enabled can not be overridden through this function.
     * When managing enabled state please use _isSubmitEnabled.
     * For onClick, and onDisabledClick please use their protected hooks, _onSubmit and _getDisabledSubmitErrorMessage.
     * For key, there's no reason for this be overridden.
     * 
     * If, for whatever reason, you absolutely must override any of these properties, you will need to override _getSubmitButton as well.
     * 
     * @param {Object} props 
     * @returns Object containing the props for the Submit button.
     */
    _getSubmitButtonProps(props) {
        return props;
    }
        
    /**
     * Can be overridden in conjunction with _getCancelButtonProps to use a completely different button.
     * 
     * If you just want to edit or change props for the Cancel button, do NOT override this function. See _getCancelButtonProps instead.
     * 
     * @param {Object} props The props for the button.
     * @returns JSX for the Cancel Button
     */
    _getCancelButton(props) {
        return <Button
            {...props}
            key='cancel'
            onClick={this.$onCancel}
        />;
    }

    /**
     * Can be overridden to edit or completely replace the default properties for the Cancel button.
     * 
     * Note, the properties key and onClick can not be overridden through this function.
     * For onClick, please use the protected hook, _onCancelClick.
     * For key, there's no reason for this be overridden.
     * 
     * If, for whatever reason, you absolutely must override any of these properties, you will need to override _getCancelButton as well.
     *
     * @param {Object} props 
     * @returns Object containing the props for the Cancel button.
     */
    _getCancelButtonProps(props) {
        return props;
    }

    /**
     * Can be overridden to hook into the cancel process.
     * 
     * The Button waitforCallback is not supported.
     */
    _onCancel() {
    }

    /**
     * Can be overridden to manage the Submit button's enabled state.
     * 
     * By default, _isSubmitEnabled will compare against the default value of 'no d.
     * 
     * @returns boolean
     */
    _isSubmitEnabled() {
        return !this._getDisabledSubmitErrorMessage();
    }

    /**
     * Can be overridden to define more buttons that will be placed to the right of the Submit button.
     * 
     * FormModal only supports the ability to render the buttons in the same area as the Submit and Cancel buttons. You are completely responsible for managing all state and events of all additional buttons.
     *
     * Please ensure all additional buttons have the key property defined as they will be rendered as an Array\<JSX>.
     * 
     * FormModal buttons are rendered at the bottom of the Modal from right to left. 
     * The ordering of buttons are as follows:
     * Additional Tertiary Buttons | Cancel Button | Additional Secondary Buttons | Submit Button | Additional Primray Buttons
     * 
     * The ordering of additional buttons is decided by the ordering of the Buttons array. In otherwords.
     * E.X: Array index 0 will always be the furthest right of the additional buttons.
     * 
     * @returns Array\<JSX>
     */
    _getAdditionalPrimaryButtons() {
        return [];
    }

    /**
     * Can be overridden to define more buttons that will be placed between the Cancel and Submit buttons.
     * 
     * FormModal only supports the ability to render the buttons in the same area as the Submit and Cancel buttons. You are completely responsible for managing all state and events of all additional buttons.
     *
     * Please ensure all additional buttons have the key property defined as they will be rendered as an Array\<JSX>.
     * 
     * FormModal buttons are rendered at the bottom of the Modal from right to left. 
     * The ordering of buttons are as follows:
     * Additional Tertiary Buttons | Cancel Button | Additional Secondary Buttons | Submit Button | Additional Primray Buttons
     * 
     * The ordering of additional buttons is decided by the ordering of the Buttons array. In otherwords.
     * E.X: Array index 0 will always be the furthest right of the additional buttons.
     * 
     * @returns Array\<JSX>
     */
    _getAdditionalSecondaryButtons() {
        return [];
    }

    /**
     * Can be overridden to define more buttons that will be placed to the left of the Cancel button.
     * 
     * FormModal only supports the ability to render the buttons in the same area as the Submit and Cancel buttons. You are completely responsible for managing all state and events of all additional buttons.
     *
     * Please ensure all additional buttons have the key property defined as they will be rendered as an Array\<JSX>.
     * 
     * FormModal buttons are rendered at the bottom of the Modal from right to left. 
     * The ordering of buttons are as follows:
     * Additional Tertiary Buttons | Cancel Button | Additional Secondary Buttons | Submit Button | Additional Primray Buttons
     * 
     * The ordering of additional buttons is decided by the ordering of the Buttons array.
     * E.X: Array index 0 will always be the furthest right of the additional buttons.
     * 
     * @returns Array\<JSX>
     */
    _getAdditionalTertiaryButtons() {
        return [];
    }

    _onDisabledSubmit() {
        this._setError(this._getDisabledSubmitErrorMessage());
    }
    
    /**
     * @returns Content to be rendered in the footer area, to the left of the buttons.
     */
    _renderFooterContent() {
        return null;
    }

    /**
     * @returns Returns the primary content container. Concrete modal shouldn't be overriding this, see _renderContent instead.
     */
    _renderContentContainer() {
        return <Form
            ref={this._containerNode}
            className='content-container'
            content={this._renderContent()}
            footer={this._getFooterContent()}
        />;
    }

    //=====================================================================
    //      Private/Don't Touch APIs
    //=====================================================================

    /**
     * 
     * @returns Returns content that goes into the footer. This function should not over ridden as it provides protected access to a few private functions.
     */
    _getFooterContent() {
        return <React.Fragment>
            {this.$renderFooterContent()}
            {this.$renderButtons()}
        </React.Fragment>
    }

    /**
     * Renaming this function to create pattern of override _onSubmit and/or _onCancel.
     */
    onCancel() {
        this._onCancel();
    }

    _getContainerNode() {
        return this._containerNode.current;
    }


    /**
     * Sets the FormModal's error message.
     * 
     * @param {string|TPError} error 
     */
    _setError(error) {
        if (!(error instanceof TPError) && !ObjectUtils.isVoid(error)) {
            error = new TPError(error);
        }
        this.setState({
            error: error
        });
    }

    /**
     * FormModal's primary render function. Don't override this.
     * @returns JSX|Array\<JSX>
     */
    _render() {
        return <React.Fragment>
            {this.$renderErrorContainer()}
            {this._renderContentContainer()}
        </React.Fragment>;
    }

    $renderErrorContainer() {
        return <div className={`error-container ${ObjectUtils.isVoid(this.state.error) ? '' : 'hasMessage'}`}>
            {this._renderErrorMessage(this.state.error)}
        </div>;
    }

    $renderFooterContent() {
        return <div className='footer-content-container'>
            {this._renderFooterContent()}
        </div>;
    }

    $renderButtons() {
        return <div className='button-container'>
            {this.$getButtons()}
        </div>;
    }

    $getButtons() {
        return [
            ...this._getAdditionalPrimaryButtons(),
            this._getSubmitButton(
                this.$getSubmitButtonProps()
            ),
            ...this._getAdditionalSecondaryButtons(),
            this._getCancelButton(
                this.$getCancelButtonProps()
            ),
            ...this._getAdditionalTertiaryButtons()
        ];
    }

    $getSubmitButtonProps(){
        return this._getSubmitButtonProps({
            waitForCallback: true,
            text: 'Submit'
        });
    }

    $getCancelButtonProps() {
        return this._getCancelButtonProps({
            // fill in default props here
            className: Browser.isApplication() ? 'grey' : 'cancel',
            text: 'Cancel'
        });
    }

    $onSubmit(event, callback) {
        this._onSubmit(event).then(() => {
            !ObjectUtils.isVoid(callback) && callback();
            this.close();
        }).catch((error) => {
            let e = TPError.wrap(error);
            this._setError(e);
            !ObjectUtils.isVoid(callback) && callback();
        });
    }

    $onCancel() {
        this._cancel();
    }
}
