'use strict';

import React from 'react';
import {TPComponent} from './TPComponent';
import {MouseStore} from '../store/MouseStore';
import {PickUpDroppable} from '../actions/PickUpDroppable';
import {NodeGeometry} from '../utils/NodeGeometry';
import "../style/DroppableComponent.less";
import PropTypes from 'prop-types';

const DEFAULT_MOVE_THRESHOLD = 10;

export class DroppableComponent extends TPComponent {
    constructor(props) {
        super(props);

        if (!props.tag) {
            throw new Error('tag is a required prop');
        }

        if (!props.represents) {
            throw new Error('represents is a required prop');
        }

        this._onMouseDown = this._onMouseDown.bind(this);
        this._onMouseUp = this._onMouseUp.bind(this);
        this._onMouseUpdate = this._onMouseUpdate.bind(this);
        // this._onMouseMove = this._onMouseMove.bind(this);

        this._draggingNode = null;

        this._node = null;
        this._startPosX = null;
        this._startPosY = null;
    }

    getX() {
        return NodeGeometry.getAbsoluteX(this._node);
    }

    getY() {
        return NodeGeometry.getAbsoluteY(this._node);
    }

    getNode() {
        return this._node;
    }

    getDropTag() {
        return this.props.tag;
    }

    getRepresentative() {
        return this.props.represents;
    }

    getDragNode() {
        if (!this._draggingNode) {
            this._draggingNode = this._createDragNode();
        }

        return this._draggingNode;
    }

    _createDragNode() {
        if (!this._node) {
            return null;
        }

        let node = this._node.cloneNode(true);
        node = this._applyDragNodeStyle(node, this._node);
        return node;
    }

    _applyDragNodeStyle(node, originalNode) {
        node.setAttribute('class', node.getAttribute('class') + ' dragging');
        node.style.position = 'absolute';
        node.style.zIndex = 9999999;
        node.style.width = originalNode.clientWidth + 'px';
        node.style.pointerEvents = 'none';
        node.style.opacity = 0.75;
        return node;
    }

    // setActive(active) {
    //  this.setState({
    //      active : !!active
    //  });

    //  if (active) {
    //      this._draggingNode = this._createDragNode();
    //      MouseStore.register(this._onMouseMove);
    //  }
    // }

    // isActive() {
    //  return this.state.active;
    // }

    _onMouseDown() {
        this._startPosX = MouseStore.getInstance().getX();
        this._startPosY = MouseStore.getInstance().getY();
        MouseStore.getInstance().register(this._onMouseUpdate);
    }

    _onMouseUp() {
        MouseStore.getInstance().unregister(this._onMouseUpdate);
    }

    // _onMouseMove() {

    // }

    _onMouseUpdate() {
        let diffX = Math.abs(this._startPosX - MouseStore.getInstance().getX());
        let diffY = Math.abs(this._startPosY - MouseStore.getInstance().getY());

        let moveThreshold = this.props.moveThreshold === undefined || this.props.moveThreshold === null ? DEFAULT_MOVE_THRESHOLD : this.props.moveThreshold;

        if (diffX > moveThreshold || diffY > moveThreshold) {
            MouseStore.getInstance().unregister(this._onMouseUpdate);
            PickUpDroppable.execute(this);
        }
    }

    render() {
        return (
            <div 
                id={this.getID()} 
                className="DroppableComponent" 
                ref={(n) => { this._node = n; }}
                onMouseDown={this._onMouseDown}
                onMouseUp={this._onMouseUp}
            >
                {this.props.children}
            </div>
        );
    }
}

DroppableComponent.propTypes = {
    tag: PropTypes.string.isRequired,
    represents: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired
};
